import React, { FC } from "react";
import styled from "styled-components";
import { color_green, color_midnight } from "../util/color";

// prop 타입 정의
type CardLayoutProps = {
  children: React.ReactNode;
  text: string;
};

// 상수 정의
const CARD_WIDTH = 400;
const CARD_HEIGHT = 656;
const LOGO_WIDTH = 216;
const LOGO_HEIGHT = 72;

const CardLayout: FC<CardLayoutProps> = ({ children, text }) => {
  return (
    <Container>
      <LoginCard>
        <Top>
          <Logo src="/image/logo.png" alt="Logo" />
          <Text>{text}</Text>
        </Top>
        <Bottom>{children}</Bottom>
      </LoginCard>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${color_midnight.lily};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginCard = styled.div`
  width: ${CARD_WIDTH}px;
  height: ${CARD_HEIGHT}px;
  background-color: #ffffff;
  box-shadow: 4px 4px 4px 0px #0000001a;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 36px;

  position: relative;
`;

const Top = styled.div`
  width: 300px;
  height: 116px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

const Bottom = styled.div`
  width: 300px;
  height: 374px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

const Logo = styled.img`
  width: ${LOGO_WIDTH}px;
  height: ${LOGO_HEIGHT}px;
`;

const Text = styled.span`
  color: ${color_green.green6};
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.2px;
  font-weight: 700;
`;

export default CardLayout;
