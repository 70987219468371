import React, { useState } from "react";
import styled from "styled-components";
import RemoveIcon from "@mui/icons-material/Remove";
import CheckIcon from "@mui/icons-material/Check";
import InputBox from "../utility/InputBox";
import { createMenu, createOption } from "../../apis/menu";
import { CreateDesignType } from "../../types";
import DesignAdd from "../utility/DesignAdd";

type propType = {
  deleteCallback: () => void;
  submitCallback?: () => void;
  presubmitCallback?: () => void;
  cancleOnclick: () => void;
  shopId: string;
  category: string;
};

const AddMenuItem = ({
  deleteCallback,
  cancleOnclick,
  submitCallback,
  presubmitCallback,
  shopId,
  category,
}: propType) => {
  const [name, setName] = useState("");
  const [engName, setEngName] = useState("");
  const [description, setDescription] = useState("");
  const [engDescription, setEngDescription] = useState("");
  const [price, setPrice] = useState("");
  const [eventPrice, setEventPrice] = useState("");
  const [runningTime, setRunningTime] = useState("");

  const [isEvent, setIsEvent] = useState(false);

  const [AddDesignList, setAddDesignList] = useState<CreateDesignType[]>([]);

  return (
    <Container>
      <div>
        <div>
          <Box>
            <span>*메뉴명(한글)</span>
            <InputBox
              placeholder={""}
              type={"text"}
              width={300}
              setValue={setName}
              value={name}
              maxLength={12}
            />
          </Box>
          <Box>
            <span>*메뉴명(영어)</span>
            <InputBox
              placeholder={""}
              type={"text"}
              width={300}
              setValue={setEngName}
              value={engName}
              maxLength={30}
            />
          </Box>
        </div>
        <div>
          <Box>
            <span>설명(한글)</span>
            <InputBox
              placeholder={
                "메뉴에 대한 설명을 입력해주세요. ex. 보트르내일만의 이달의 아트"
              }
              type={"text"}
              width={800}
              setValue={setDescription}
              value={description}
            />
          </Box>
        </div>
        <div>
          <Box>
            <span>설명(영어)</span>
            <InputBox
              placeholder={
                "메뉴에 대한 설명을 입력해주세요. ex. 보트르내일만의 이달의 아트"
              }
              type={"text"}
              width={800}
              setValue={setEngDescription}
              value={engDescription}
            />
          </Box>
        </div>
        <div style={{ gap: "36px" }}>
          <Box>
            <span>*가격</span>
            <InputBox
              placeholder={"ex.50,000"}
              type={"money"}
              width={184}
              setValue={setPrice}
              value={price}
            />
          </Box>
          <EventBox>
            <div
              onClick={() => setIsEvent((old) => !old)}
              style={{ cursor: "pointer" }}
            >
              <CheckIcon style={{ color: isEvent ? "#62D4D2" : "#ffffff" }} />
            </div>
            <span>Event</span>
            <span>이벤트가</span>
            <InputBox
              placeholder={"ex.50,000"}
              type={"money"}
              width={184}
              setValue={setEventPrice}
              value={eventPrice}
            />
          </EventBox>
          <Box>
            <span>*소요시간</span>
            <InputBox
              placeholder={"00 ( ex : 단위 분  50분 -> 50)"}
              type={"text"}
              width={184}
              setValue={setRunningTime}
              value={runningTime}
            />
          </Box>
        </div>
      </div>
      <span
        style={{
          color: "#62D4D2",
          fontSize: "12px",
          fontWeight: "700",
          marginLeft: "24px",
        }}
      >
        이벤트 가격 설정 시 정상가를 입력해주세요.
      </span>
      {category && (
        <div style={{ display: "flex", gap: "24px" }}>
          <span
            style={{ fontSize: "16px", color: "#F14262", fontWeight: "700" }}
          >
            디자인
          </span>
          <span
            style={{
              width: "20px",
              height: "20px",
              backgroundColor: "#F14262",
              borderRadius: "50%",
              color: "#ffffff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() =>
              setAddDesignList((old) => {
                let temp = [...old];
                temp.push({
                  name: "",
                  price: "",
                  runningTime: "",
                  imageUrl: "",
                });
                return temp;
              })
            }
          >
            +
          </span>
        </div>
      )}
      <div>
        {AddDesignList.map((item, i) => (
          <DesignAdd
            key={i}
            setValue={setAddDesignList}
            index={i}
            value={AddDesignList[i]}
          />
        ))}
      </div>
      <div>
        <span onClick={cancleOnclick} style={{ cursor: "pointer" }}>
          취소
        </span>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (presubmitCallback) presubmitCallback();

            if (name === "" || price === "" || runningTime === "") {
              alert("메뉴 이름 , 가격 , 소요시간을 확인해주세요.");
              return;
            }

            if (
              AddDesignList.length !==
              AddDesignList.filter((item) => item.name !== "").length
            ) {
              alert("디자인 리스트에 빠진내용 없는지 확인해주세요.");
              return;
            }

            if (!category) {
              createOption({
                shopId: shopId,
                name: name,
                engName: engName,
                description: description,
                engDescription: engDescription,
                runningTime: runningTime ? runningTime : "0",
                price: price,
                eventPrice: isEvent ? eventPrice : "-1",
              })
                .then((res) => {
                  if (submitCallback) submitCallback();
                })
                .catch((e) => console.log(e));
            } else {
              createMenu({
                shopId: shopId,
                name: name,
                engName: engName,
                description: description,
                engDescription: engDescription,
                category: category,
                runningTime: runningTime ? runningTime : "0",
                price: price,
                eventPrice: isEvent ? eventPrice : "-1",
                designList: AddDesignList,
              })
                .then((res) => {
                  if (submitCallback) submitCallback();
                })
                .catch((e) => console.log(e));
            }
          }}
        >
          저장
        </span>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 2px solid #f14262;
  box-sizing: border-box;
  margin-bottom: 32px;

  display: flex;
  padding: 16px 32px;
  padding-left: 64px;
  flex-direction: column;
  justify-content: flex-end;
  gap: 16px;

  & > :first-child {
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 24px;

    & > div {
      display: flex;
      align-items: center;
      gap: 24px;
    }
  }

  & > :last-child {
    display: flex;
    align-self: flex-end;
    & > :first-child {
      display: flex;
      width: 92px;
      height: 44px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;

      color: #000000;
      font-size: 14px;
      font-weight: 500;
    }
    & > :last-child {
      display: flex;
      width: 92px;
      height: 44px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: #f14262;

      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
    }
  }
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  & > :first-child {
    color: #f14262;
    font-size: 16px;
    font-weight: 700;
  }
`;

const EventBox = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  & > :nth-child(1) {
    width: 24px;
    height: 24px;
    border: 2px solid #62d4d2;
    box-sizing: border-box;

    border-radius: 4px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > :nth-child(2) {
    padding: 4px 7px;
    border-radius: 4px;
    background: #fada07;

    color: #f9fafb;
    font-size: 12px;
    font-weight: 700;
  }

  & > :nth-child(3) {
    color: #62d4d2;
    font-size: 14px;
    font-weight: 700;
  }
`;

export default AddMenuItem;
