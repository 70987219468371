import axios from "axios";
import { baseUrl, config } from "./api";
import { CreateFeedType, SaveFeedType } from "../types";

// shop feed api
export const getFeedList = async (id: string) => {
  return await axios.get(
    baseUrl + `/admin/shop/feed?limit=100&offset=1&shopId=${id}`,
    config()
  );
};

export const createFeed = async (data: CreateFeedType) => {
  return await axios.post(baseUrl + "/admin/shop/feed", data, config());
};

export const saveFeed = async (data: SaveFeedType) => {
  return await axios.put(baseUrl + "/admin/shop/feed", data, config());
};

export const deleteFeed = async (data: { feedIdList: string[] }) => {
  return await axios.delete(baseUrl + "/admin/shop/feed", {
    headers: config().headers,
    data: data,
  });
};

export const checkInsta = async () => {
  return await axios.get(baseUrl + `/member/instagram/check`, config());
};

export const getInstaImageList = async (instaToken: string, shopId: string) => {
  return await axios.get(
    baseUrl +
      `/admin/shop/feed/instagram?instagramAccessToken=${instaToken} &shopId=${shopId}`,
    config()
  );
};

export const getInstaImageList2 = async (
  instaToken: string,
  mediaId: string
) => {
  return await axios.get(
    baseUrl +
      `/admin/shop/feed/instagram/children?instagramAccessToken=${instaToken}&mediaId=${mediaId}`,
    config()
  );
};

export const getInstaImageList3 = async (url: string) => {
  return await axios.post(
    baseUrl + `/admin/shop/feed/instagram/cursor`,
    { url: url },
    config()
  );
};

export const UploadInstaUrl = async (url: string) => {
  return await axios.post(
    baseUrl + "/upload/instagram",
    { imageUrl: url },
    config()
  );
};
