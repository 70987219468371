import { styled } from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import { RegisterDataAtom, registerStepAtom } from "../../atoms/atom";
import InputBox from "../utility/InputBox";
import { useState } from "react";

const RegisterStep6 = () => {
  const [name, setName] = useState("");
  const setStep = useSetRecoilState(registerStepAtom);
  const [data, setData] = useRecoilState(RegisterDataAtom);

  const nextHandler = () => {
    setData((old) => ({
      email: old.email,
      passwd: old.passwd,
      name: old.name,
      phone: old.phone,
      isAdmin: old.isAdmin,
      tos: old.tos,
      token: old.token,
      shopname: name,
      address: old.address,
    }));
    setStep(7);
  };

  return (
    <>
      <ResultContainer>
        <div>
          <h2>{data.name}</h2>
          <span>대표</span>
        </div>
        <InputContainer>
          <Label>샵 이름</Label>
          <InputBox
            placeholder="이름을 입력해주세요."
            type="text"
            width={300}
            setValue={setName}
            value={name}
          />
        </InputContainer>
      </ResultContainer>
      <NextBtn disabled={!name ? true : false} onClick={() => nextHandler()}>
        다음
      </NextBtn>
    </>
  );
};

const InputContainer = styled.div`
  width: 300px;
  height: 82px;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const ResultContainer = styled.div`
  width: 300px;
  height: 300px;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 16px;
  box-sizing: border-box;

  & > div:first-child {
    display: flex;
    align-items: center;
    width: 100%;
  }

  & h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.2px;
  }

  & > div:first-child > span {
    width: 35px;
    height: 22px;
    background: #fada07;
    border-radius: 4px;
    color: #ffffff;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 10px;
    font-weight: 500;
    line-height: 14px;

    margin-left: 10px;
  }
`;

const Label = styled.span`
  color: #a0a6b1;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.2px;
  font-weight: 700;
`;

const NextBtn = styled.button<{ disabled: boolean }>`
  border: none;
  width: 300px;
  height: 44px;
  border-radius: 8px;
  color: #f9fafb;
  background-color: ${(props) => (!props.disabled ? "#ff5d7a" : "#d3d6db")};

  font-family: "Noto Sans KR", sans-serif;

  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: -0.2px;
`;

export default RegisterStep6;
