import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components";

// propType 타입 정의
type propType = {
  placeholder: string;
  type: string;
  width: number;
  setValue: Dispatch<SetStateAction<string>>;
  value: string;
  disabled?: boolean;
  clearHandler?: () => void;
  noReset?: boolean;
  maxLength?: number;
};

// InputBox 컴포넌트
const InputBox = (props: propType) => {
  const resetHandler = (event: React.MouseEvent) => {
    const parent = event.currentTarget.parentElement;

    if (parent) {
      const temp = parent.children[0] as HTMLInputElement;
      temp.value = "";
      props.setValue("");
    }

    if (props.clearHandler) {
      props.clearHandler();
    }
  };

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    let temp = target.value;

    if (props.type === "money" && temp !== "") {
      temp = temp.replace(/,/gi, "");
      if (!/^[0-9]+$/.test(temp)) return;
    } else if (props.type === "phone") {
      temp = temp.replace(/\D/g, "").slice(0, 11);
    } else if (props.type === "minute") {
      temp = temp.replace(/\D/g, "");
    } else if (props.type === "number-only") {
      temp = temp.replace(/\D/g, ""); // 숫자 이외의 문자 제거
    }

    props.setValue(temp);
  };

  return (
    <InputWrap
      width={props.width}
      disabled={props.disabled ? props.disabled : false}
    >
      <Input
        width={props.width}
        type={
          props.type === "money" ||
          props.type === "phone" ||
          props.type === "minute" ||
          props.type === "number-only"
            ? "text"
            : props.type
        }
        value={
          props.type === "money"
            ? props.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : props.type === "minute"
            ? `${props.value}분`
            : props.value
        }
        placeholder={props.placeholder}
        onChange={changeHandler}
        disabled={props.disabled ? props.disabled : false}
        maxLength={props.maxLength ? props.maxLength : 100}
      />
      {!props.noReset && props.value && props.type !== "date" && (
        <IconWrap onClick={resetHandler}>
          <Icon src="/image/addcircle.png" />
        </IconWrap>
      )}
    </InputWrap>
  );
};

// 스타일 컴포넌트 정의
const InputWrap = styled.div<{ width: number; disabled: boolean }>`
  background-color: ${(props) => (props.disabled ? "#E5E7EB" : "#f4f5f7")};
  width: ${(props) => props.width}px;
  height: 44px;
  border-radius: 8px;

  display: flex;
  align-items: center;
`;

const Input = styled.input<{ width: number; type: string }>`
  background-color: transparent;
  width: ${(props) =>
    props.width
      ? props.type === "date"
        ? props.width - 22
        : props.width - 44
      : props.width}px;
  height: 24px;
  border: none;
  box-sizing: border-box;
  padding-left: 20px;

  outline: none;

  color: black;
  font-size: ${(props) =>
    props.value ? (props.type === "password" ? "1em" : "12px") : "12px"};
  line-height: 18px;
  font-weight: 700;

  font-family: "Noto Sans KR", sans-serif;

  &::placeholder {
    color: #a0a6b1;
  }
`;

const IconWrap = styled.div`
  width: 44px;
  height: 44px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
`;

export default InputBox;
