import React, { useState } from "react";
import styled from "styled-components";
import BookRequestModal from "../utility/BookRequestModal";
import BookModifyModal from "../utility/BookModifyModal";
import { RefrashCounter, ShopData } from "../../atoms/atom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { ReqApprove, ReqCancel } from "../../apis/book";
import BookCancelModal from "../utility/BookCancelModal";
import { getCustomerOne } from "../../apis/customer";
import useSendbirdStateContext from "@sendbird/uikit-react/useSendbirdStateContext";
import sendbirdSelectors, {
  getCreateGroupChannel,
} from "@sendbird/uikit-react/sendbirdSelectors";

const BookItem = ({ data, callback }: any) => {
  const [isRequestOpen, setIsRequestOpen] = useState(false);

  const [isBookOpen, setIsBookOpen] = useState(false);
  const [isCancelOPen, setIsCancelOpen] = useState(false);

  const [shopData] = useRecoilState(ShopData);
  const setRefrashCount = useSetRecoilState(RefrashCounter);

  const getStatusComponent = (statusText: string, bookId: string) => {
    switch (statusText) {
      case "bookComplete":
        return <Status $backgroundColor="#FCA2B3">시술예정</Status>;
      case "nailComplete":
        return <Status $backgroundColor="#3A66EC">시술완료</Status>;
      case "bookCanceled":
        return <Status $backgroundColor="#F14262">시술취소</Status>;
      case "noShow":
        return <Status $backgroundColor="#272F3E">노쇼</Status>;
      case "bookRequest":
        return (
          <div style={{ display: "flex", gap: "10px" }}>
            <span
              style={{
                width: "55px",
                height: "24px",
                fontSize: "12px",
                color: "#ffffff",
                backgroundColor: "#A0A6B1",
                borderRadius: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={(e) => {
                setIsCancelOpen(true);

                e.stopPropagation();
              }}
            >
              거절
            </span>
            <span
              style={{
                width: "55px",
                height: "24px",
                fontSize: "12px",
                color: "#ffffff",
                backgroundColor: "#F14262",
                borderRadius: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                ReqApprove(bookId).then(() => {
                  callback();
                });
              }}
            >
              승인
            </span>
          </div>
        );
      default:
        return null;
    }
  };

  const globalStore = useSendbirdStateContext();
  sendbirdSelectors.getSdk(globalStore);

  const createChannel = getCreateGroupChannel(globalStore);

  const createChannelAndSendMessage = async (reason: string) => {
    const user = await getCustomerOne(data.customerId).then(
      (res) => res.data.data
    );
    console.log(user);

    try {
      const params = {
        invitedUserIds: [user.userId, shopData.id],
        name: shopData.name,
        coverUrl:
          "https://storage.finger-princess.com/5071c892-2053-4ac7-b7a8-e7726989f433",
        isDistinct: true,
      };
      const channel = await createChannel(params);

      const formattedDate = new Date(data.date); // 예약 날짜
      const dayOfWeek = formattedDate.toLocaleDateString("ko-KR", {
        weekday: "short", // 요일 (예: 월, 화)
      });
      const datePart = `${String(formattedDate.getMonth() + 1).padStart(
        2,
        "0"
      )}/${String(formattedDate.getDate()).padStart(2, "0")}`; // MM/DD 형식으로 날짜 생성
      const timePart = formattedDate.toLocaleTimeString("ko-KR", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false, // 24시간 형식
      });

      let korReason = "";

      switch (reason) {
        case "duplicate":
          korReason = "중복예약으로";
          break;
        case "noManager":
          korReason = "담당자 부재로";
          break;
        case "noTime":
          korReason = "일정 불가로";
          break;
        case "shop":
          korReason = "샵의 사정으로";
          break;
        case "noRequirement":
          korReason = "요청 사랑 불가로";
          break;
      }

      const messageText = `${korReason} ${datePart}(${dayOfWeek}) ${timePart} 예약이 취소되었습니다. 🥲`;
      channel.sendUserMessage({ message: messageText });
    } catch (error) {
      console.error("Error creating channel or sending message:", error);
    }
  };

  return (
    <>
      <TableItem
        onClick={() => {
          if (data.bookState === "bookRequest") {
            console.log("click this");
            setIsRequestOpen(true);
          } else {
            setIsBookOpen(true);
          }
        }}
        className="bookTableItem"
      >
        <span>
          <div>
            <span>
              {data.customerNumber}.{data.customerName}
            </span>
            <span>{data.phoneNumber}</span>
          </div>
        </span>
        <span>
          {new Date(data.date)
            .toLocaleDateString("ko-KR", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .slice(0, -1)}{" "}
          {new Date(data.date).toLocaleTimeString("ko-KR", {
            hour: "numeric",
            minute: "2-digit",
            hour12: false,
          })}
          {" ~ "}
          {new Date(
            new Date(data.date).getTime() + parseInt(data.duration) * 60000
          ).toLocaleTimeString("ko-KR", {
            hour: "numeric",
            minute: "2-digit",
            hour12: false,
          })}
        </span>
        <span>{data.menuName}</span>
        <span>
          {data.totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </span>
        <span>{getStatusComponent(data.bookState, data.bookId)}</span>
      </TableItem>
      {isRequestOpen && (
        <BookRequestModal
          open={isRequestOpen}
          setOpen={setIsRequestOpen}
          data={data}
          callback={() => {
            setRefrashCount((old) => old + 1);
          }}
          callback2={() => {
            setIsCancelOpen(true);
          }}
        />
      )}
      {isBookOpen && (
        <BookModifyModal
          open={isBookOpen}
          setOpen={setIsBookOpen}
          data={data}
          callback={() => {
            setRefrashCount((old) => old + 1);
            callback();
          }}
          callback2={() => {
            setIsCancelOpen(true);
          }}
        />
      )}
      {isCancelOPen && (
        <BookCancelModal
          open={isCancelOPen}
          setOpen={setIsCancelOpen}
          data={undefined}
          callback={(reason) => {
            if (!reason) return;

            ReqCancel(data.bookId, reason).then(() => {
              setIsCancelOpen(false);

              // 예약 취소 메세지 전송
              createChannelAndSendMessage(reason);

              callback();
            });
          }}
        />
      )}
    </>
  );
};

const TableItem = styled.div`
  width: 100%;

  display: flex;
  height: 44px;
  padding: 0px 24px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;

  border-radius: 16px;

  & > span {
    display: flex;
    justify-content: center;
    align-items: center;

    color: #394150;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;

    color: #000000;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
  }

  & > :nth-child(1) {
    display: flex;
    gap: 24px;
    width: 200px;

    & > :first-child {
      display: flex;
      flex-direction: column;

      & > span {
        color: #000000;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
      }
    }
  }
  & > :nth-child(2) {
    display: flex;
    width: 144px;
  }
  & > :nth-child(3) {
    display: flex;
    width: 144px;
  }
  & > :nth-child(4) {
    display: flex;
    width: 96px;
  }
  & > :nth-child(5) {
    display: flex;
    width: 96px;
  }
`;

const Status = styled.div<{ $backgroundColor: string }>`
  width: 53px;
  height: 22px;
  background-color: ${(props) => props.$backgroundColor};
  color: #ffffff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
`;

export default BookItem;
