import React, {
  useState,
  createRef,
  useEffect,
  SetStateAction,
  Dispatch,
} from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { styled } from "styled-components";

type propType = {
  imgsrc: string;
  setCropData: Dispatch<SetStateAction<string>>;
  setSave: Dispatch<SetStateAction<boolean>>;
  rotate: number;
};

const CropImg2 = ({ imgsrc, setCropData, setSave, rotate }: propType) => {
  const [image, setImage] = useState("");
  const cropperRef = createRef<ReactCropperElement>();

  useEffect(() => {
    cropperRef.current?.cropper.setAspectRatio(4 / 3);

    if (imgsrc.indexOf("storage.finger-princess") !== -1) {
      fetch(imgsrc)
        .then((response) => response.blob())
        .then((blob) => {
          console.log(blob);
          setImage(URL.createObjectURL(blob));
        })
        .catch((error) => {
          console.error("Error fetching image:", error);
        });
    } else {
      setImage(imgsrc);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      setSave(false);
    }
  };

  useEffect(() => {
    console.log(rotate);
    cropperRef.current?.cropper.rotateTo(rotate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rotate]);

  return (
    <Container>
      <div style={{ width: "500px", height: "100%", backgroundColor: "gray" }}>
        <Cropper
          ref={cropperRef}
          style={{ width: "500px", height: "100%" }}
          zoomTo={0.5}
          initialAspectRatio={1}
          preview=".img-preview"
          src={image}
          viewMode={1}
          background={false}
          responsive={false}
          autoCropArea={0.8}
          checkOrientation={false}
          guides={true}
          cropBoxResizable={true}
          dragMode={"none"}
          zoomable={false}
          rotatable={true}
        />
      </div>
      <button onClickCapture={getCropData}>완료</button>
    </Container>
  );
};

const Container = styled.div`
  & .box {
    display: inline-block;
    padding: 10px;
    box-sizing: border-box;
  }
  & .img-preview {
    overflow: hidden;
  }

  & > button {
    border: none;
    width: 80px;
    height: 40px;
    border-radius: 8px;
    background-color: #ff5d7a;
    color: #ffffff;

    position: absolute;
    right: 43px;
    bottom: 37px;
    z-index: 20;
  }
`;

export default CropImg2;
