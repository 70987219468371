import React from "react";
import Chart from "react-apexcharts";
import { color_rose } from "../../util/color";

const SalesChart = ({ data, isMonthly, setIsStaticsMonthly }: any) => {
  const options = {
    chart: {
      type: "area" as const, // 'area'로 설정하여 선 아래를 채울 수 있음
      height: 220,
      toolbar: {
        show: false, // 툴바 숨기기
      },
    },
    stroke: {
      width: 3,
      curve: "smooth" as const, // 'smooth' 곡선 스타일
      colors: ["#F3D45D"], // 선 색상
    },
    fill: {
      colors: ["#F3D45D"], // 선 아래를 채울 색상 (노란색)
      opacity: 0.3, // 채우기 투명도 설정
    },
    markers: {
      size: 6,
      colors: ["#F3D45D"],
      strokeColors: "#FFFFFF",
      strokeWidth: 2,
      hover: {
        size: 8,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: data.map((e: any) =>
        isMonthly
          ? e.date.slice(2).replace("-", ".")
          : e.date.slice(5).replace("-", ".")
      ), // 동적 라벨
      labels: {
        rotate: -45, // 라벨을 45도 회전
        style: {
          fontSize: "10px", // 폰트 크기 조절
          colors: ["#333"], // 라벨 색상 설정
        },
        offsetX: 0,
      },
      tooltip: {
        enabled: true, // 마우스를 올렸을 때 라벨 전체를 보여줌
      },
    },
    yaxis: {
      show: false,
    },
    tooltip: {
      enabled: true,
      shared: false, // 점 위에서만 툴팁 표시
      intersect: true, // 마우스가 점에 있을 때만 툴팁 표시
      y: {
        formatter: (val: any) =>
          `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} 만원`, // 툴팁에서 값을 표시하는 형식
      },
      // 툴팁의 위치를 차트 영역 안으로 강제로 이동시키기 위한 설정
      fixed: {
        enabled: true,
        position: "bottomLeft", // 툴팁의 기본 위치를 설정
        offsetX: -100,
        offsetY: -100,
      },
    },
  };

  const series = [
    {
      name: "매출",
      data: data.map((e: any) => parseInt(e.totalSales)), // 8개의 데이터 포인트
    },
  ];

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <h2 style={{ margin: "0px" }}>매출 추이</h2>
        <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
          <div
            style={{
              width: "36px",
              height: "36px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: !isMonthly ? color_rose.rose6 : "#fff",
              color: !isMonthly ? "#fff" : "#000",
              cursor: "pointer",
            }}
            onClick={() => {
              setIsStaticsMonthly(false);
            }}
          >
            일
          </div>
          <div
            style={{
              width: "36px",
              height: "36px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: isMonthly ? color_rose.rose6 : "#fff",
              color: isMonthly ? "#fff" : "#000",
              cursor: "pointer",
            }}
            onClick={() => {
              setIsStaticsMonthly(true);
            }}
          >
            월
          </div>
        </div>
      </div>
      <Chart
        options={options}
        series={series}
        type="area"
        height={220}
        width={320} // 차트 너비를 100%로 설정하여 충분한 공간 확보
      />
    </div>
  );
};

export default SalesChart;
