import React, { Dispatch, SetStateAction } from "react";
import { styled } from "styled-components";
import { Switch } from "@mui/material";
import TimePickers from "../utility/TimePickers";
import { BusinessHour } from "../../types";

type propType = {
  day: string;
  isChecked: boolean;
  onTogle: () => void;
  data: BusinessHour;
  setData: Dispatch<SetStateAction<BusinessHour>>;
};

const DaySetBox = ({ day, isChecked, onTogle, data, setData }: propType) => {
  return (
    <Container>
      <div>
        <span>{day}</span>
        <TogleWrap>
          <span>휴무</span>
          <Switch
            checked={isChecked}
            onClick={onTogle}
            sx={{
              scale: "0.7",
            }}
          />
        </TogleWrap>
      </div>
      <div>
        <div>
          <span>근무시작</span>
          <TimePickers
            width="144px"
            height="34px"
            value={new Date(data.open)}
            onChange={(event) =>
              setData((old) => ({
                dayOfWeek: old.dayOfWeek,
                open: new Date(event).toISOString(),
                close: old.close,
              }))
            }
          />
        </div>
        <div>
          <span>근무종료</span>
          <TimePickers
            width="144px"
            height="34px"
            value={new Date(data.close)}
            onChange={(event) =>
              setData((old) => ({
                dayOfWeek: old.dayOfWeek,
                open: old.open,
                close: new Date(event).toISOString(),
              }))
            }
          />
        </div>
      </div>
    </Container>
  );
};

const TogleWrap = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  justify-content: space-between;
  align-items: center;

  & > span {
    font-size: 10px;
    font-weight: 700;
    color: #a0a6b1;
  }

  .Mui-checked + .MuiSwitch-track {
    background-color: red !important;
  }
  .Mui-checked {
    color: #ff5d7a !important;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 260px;
  height: 82px;

  & > div:first-child {
    width: 44px;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    & > :first-child {
      margin-top: 8px;
      display: flex;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;

      color: #181e2d;
      font-size: 10px;
      font-weight: 700;
      line-height: 140%;

      border-radius: 16px;
      background: #d8e0f8;
    }
  }

  & > div:last-child {
    display: flex;
    flex-direction: column;
    gap: 12px;
    & > div {
      display: flex;
      align-items: center;
      gap: 16px;

      & > span {
        color: #a0a6b1;
        font-size: 10px;
        font-weight: 700;
      }
    }
  }
`;

export default DaySetBox;
