import { Link } from "react-router-dom";
import styled from "styled-components";
import CardLayout from "../../layout/CardLayout";
import { useEffect, useState } from "react";
import InputBox from "../../components/utility/InputBox";
import MessageModal from "../../components/utility/MessageModal";
import { getAccessToken, login, oauthLogin } from "../../apis/auth";
import { color_midnight, color_rose } from "../../util/color";
import { IoChevronForward } from "react-icons/io5";

const SSOSignIn = () => {
  const [id, setId] = useState<string>("");
  const [pw, setPw] = useState<string>("");
  const [showFailMessage, setShowFailMessage] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    autoLogin();
  }, []);

  const autoLogin = (): void => {
    localStorage.removeItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    if (refreshToken) {
      getAccessToken(refreshToken)
        .then((res) => {
          localStorage.setItem("accessToken", res.data.accessToken);
          //navigate("/home");

          const queryString = window.location.href.split("?")[1];
          const queryArray = queryString.split("&");

          // 객체 타입을 명시적으로 지정
          const queryObject: { [key: string]: string } = {}; // 또는 Record<string, string>

          queryArray.forEach((pair) => {
            const [key, value] = pair.split("=");
            queryObject[key] = decodeURIComponent(value); // URL 디코딩
          });

          console.log(queryObject);

          oauthLogin(
            queryObject["client_id"],
            queryObject["state"],
            queryObject["redirect_uri"]
          )
            .then((res) => {
              window.location.href = res.data.redirectUrl;
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          localStorage.removeItem("autoLogin");
          console.log(e, "Invalid token");
        });
    } else {
      console.log("missing refreshToken, please login again!!");
    }
  };

  const handleLogin = (): void => {
    if (!id || !pw) {
      alert("아이디 혹은 비밀번호를 입력해주세요.");
      return;
    }

    login({ id, passwd: pw, loginType: "web" })
      .then((res) => {
        if (checked) {
          localStorage.setItem("autoLogin", "true");
        } else {
          localStorage.removeItem("autoLogin");
        }

        localStorage.setItem("refreshToken", res.data.refreshToken);
        localStorage.setItem("accessToken", res.data.accessToken);
        localStorage.setItem("userId", id);

        console.log(res.data.accessToken);

        const queryString = window.location.href.split("?")[1];
        const queryArray = queryString.split("&");

        // 객체 타입을 명시적으로 지정
        const queryObject: { [key: string]: string } = {}; // 또는 Record<string, string>

        queryArray.forEach((pair) => {
          const [key, value] = pair.split("=");
          queryObject[key] = decodeURIComponent(value); // URL 디코딩
        });

        console.log(queryObject);

        oauthLogin(
          queryObject["client_id"],
          queryObject["state"],
          queryObject["redirect_uri"]
        )
          .then((res) => {
            window.location.href = res.data.redirectUrl;
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch(() => {
        setShowFailMessage(true);
        setTimeout(() => setShowFailMessage(false), 1000);
      });
  };

  const handleKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === "Enter") handleLogin();
  };

  return (
    <CardLayout text="SSO Login">
      <InputContainer onKeyDown={handleKeyDown}>
        <Label>ID</Label>
        <InputBox
          placeholder="ID를 입력해주세요."
          type="text"
          width={300}
          setValue={setId}
          value={id}
        />
      </InputContainer>
      <InputContainer onKeyDown={handleKeyDown}>
        <Label>PASSWORD</Label>
        <InputBox
          placeholder="비밀번호를 입력해주세요."
          type="password"
          width={300}
          setValue={setPw}
          value={pw}
        />
      </InputContainer>
      <Check>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            paddingLeft: "5px",
          }}
        >
          <CheckBox
            type="checkbox"
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
          />
          <CheckLabel>자동로그인</CheckLabel>
        </div>
        <FindPwText to="/findid">
          {"비밀번호 찾기"}
          <IoChevronForward size={14} color={color_rose.rose4} />
        </FindPwText>
      </Check>
      <LoginBtn onClick={handleLogin}>로그인</LoginBtn>
      <Etc>
        <CsText to="https://pf.kakao.com/_KRBrG">고객센터</CsText>
      </Etc>
      <MessageModal
        showFailMessage={showFailMessage}
        text="아이디 또는 비밀번호가 일치하지 않습니다."
      />
    </CardLayout>
  );
};

const InputContainer = styled.div`
  width: 300px;
  height: 82px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Label = styled.span`
  color: ${color_midnight.midnight4};
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.2px;
  font-weight: 700;
`;

const Check = styled.div`
  box-sizing: border-box;
  width: 300px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CheckBox = styled.input`
  cursor: pointer;
  appearance: none;
  border: 2px solid ${color_rose.rose8};
  border-radius: 3px;
  width: 18px;
  height: 18px;
  &:checked {
    background-image: url(/image/checked.png);
    background-size: contain;
  }
`;

const CheckLabel = styled.span`
  color: ${color_midnight.midnight8};
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
`;

const LoginBtn = styled.button`
  cursor: pointer;
  width: 300px;
  height: 44px;
  background-color: ${color_rose.rose8};
  border: none;
  border-radius: 8px;
  color: ${color_midnight.lily};
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: -0.2px;
  &:hover {
    background-color: ${color_rose.rose6};
  }
`;

const Etc = styled.div`
  width: 300px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const CsText = styled(Link)`
  color: ${color_rose.rose4};
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
`;

const FindPwText = styled(Link)`
  color: ${color_rose.rose4};
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default SSOSignIn;
