import { Box, Modal } from "@mui/material";
import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WaitingModal from "../utility/WaitingModal";

type propType = {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  data: any[];
  callback: (uploadList: any[]) => void;
};

const ImgSubmitModal = ({ open, setOpen, data, callback }: propType) => {
  const [uploadList, setUploadList] = useState<{ id: string; url: string }[]>(
    []
  );

  const [openWaiting] = useState(false);

  const [startPoint, setStartPoint] = useState<number | null>(null);

  const scrollRef = useRef<HTMLDivElement>(null);
  const handleScroll = () => {
    if (!scrollRef.current) return;
    const { scrollTop, clientHeight, scrollHeight } = scrollRef.current;

    if (scrollTop + clientHeight >= scrollHeight) {
      console.log("스크롤이 끝에 도달했습니다.");
      // 스크롤이 끝에 도달했을 때 실행할 코드를 추가하세요.
    }
  };

  const UploadHandler = () => {
    callback(uploadList);
    setOpen(false);
  };

  return (
    <>
      <Modal open={open}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 860,
            height: 800,
            backgroundColor: "#ffffff",
            outline: "none",
            borderRadius: "16px",
            padding: "24px 40px 40px 40px",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "64px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <span
              style={{ color: "#272F3E", fontSize: "20px", fontWeight: "700" }}
            >
              내 포트폴리오
            </span>
            <span
              style={{
                fontSize: "12px",
                position: "absolute",
                right: "32px",
                border: "1px solid #cccccc",
                padding: "4px",
                borderRadius: "4px",
                color: "#cccccc",
              }}
              onClick={() => setUploadList([])}
            >
              초기화
            </span>
            <CloseIcon
              style={{ fontSize: "24px", position: "absolute", right: "0px" }}
              onClick={() => setOpen(false)}
            />
          </div>
          <ImageContainer
            ref={scrollRef}
            onScroll={handleScroll}
            onMouseDown={(e) => {
              const target = e.target as HTMLImageElement;
              console.log(target.alt);

              setStartPoint(parseInt(target.alt));
            }}
            onMouseUp={(e) => {
              const target = e.target as HTMLImageElement;
              console.log(target.alt);

              if (startPoint === parseInt(target.alt)) return;

              console.log("0000");
              if (startPoint! < parseInt(target.alt)) {
                console.log("222");
                setUploadList((old) => [
                  ...old,
                  ...data.filter(
                    (_item, i) => startPoint! <= i && i <= parseInt(target.alt)
                  ),
                ]);
              } else {
                console.log("111");
                setUploadList((old) => [
                  ...old,
                  ...data.filter(
                    (_item, i) => parseInt(target.alt) <= i && i <= startPoint!
                  ),
                ]);
              }

              setStartPoint(null);
            }}
          >
            {data.map((item, idx) => (
              <div
                key={idx}
                style={{
                  width: "144px",
                  height: "144px",
                  position: "relative",
                }}
              >
                <img
                  alt={idx + ""}
                  src={item.imageUrl}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  onClick={() => {
                    if (
                      uploadList.filter((item2) => item2.id === item.id)
                        .length > 0
                    ) {
                      setUploadList((old) =>
                        old.filter((item2) => item2.id !== item.id)
                      );
                    } else {
                      setUploadList((old) => [...old, item]);
                    }
                  }}
                />
                {uploadList.filter((item2) => item2.id === item.id).length >
                0 ? (
                  <CheckCircleIcon
                    style={{
                      fontSize: "24px",
                      position: "absolute",
                      top: "16px",
                      right: "16px",
                      color: "#F14262",
                    }}
                    onClick={() => {
                      if (
                        uploadList.filter((item2) => item2.id === item.id)
                          .length > 0
                      ) {
                        setUploadList((old) =>
                          old.filter((item2) => item2.id !== item.id)
                        );
                      } else {
                        setUploadList((old) => [...old, item]);
                      }
                    }}
                  />
                ) : (
                  <CheckCircleOutlineIcon
                    style={{
                      fontSize: "24px",
                      position: "absolute",
                      top: "16px",
                      right: "16px",
                      color: "#ffffff",
                    }}
                    onClick={() => {
                      if (
                        uploadList.filter((item2) => item2.id === item.id)
                          .length > 0
                      ) {
                        setUploadList((old) =>
                          old.filter((item2) => item2.id !== item.id)
                        );
                      } else {
                        setUploadList((old) => [...old, item]);
                      }
                    }}
                  />
                )}
              </div>
            ))}
          </ImageContainer>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "44px",
              gap: "24px",
              alignSelf: "flex-end",
            }}
          >
            <span
              style={{ fontSize: "12px", fontWeight: "700", color: "#6C727F" }}
            >
              <span style={{ color: "#F14262" }}>{uploadList.length}</span>개의
              사진이 선택되었습니다.
            </span>
            <span
              style={{
                width: "88px",
                height: "100%",
                borderRadius: "8px",
                backgroundColor: "#F14262",
                color: "#ffffff",
                fontSize: "14px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={UploadHandler}
            >
              업로드
            </span>
          </div>
        </Box>
      </Modal>
      <WaitingModal open={openWaiting} />
    </>
  );
};

const ImageContainer = styled.div`
  width: 100%;
  height: 624px;

  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  overflow-y: scroll;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
  &::-webkit-scrollbar {
    display: none;
  }

  & img {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
`;

export default ImgSubmitModal;
