import React, { useState } from "react";

import { IoChevronDown } from "react-icons/io5";
import { IoChevronUp } from "react-icons/io5";
import { Announcement } from "../../types";

type propType = {
  data: Announcement;
};

const NotiItem = ({ data }: propType) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#F9FAFB",
        borderRadius: "8px",
        padding: "16px",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "44px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          position: "relative",
          cursor: "pointer",
        }}
        onClick={() => setIsOpen((old) => !old)}
      >
        <div style={{ fontSize: "14px", color: "#A0A6B1" }}>
          {new Date(data.updatedAt).toLocaleDateString("ko-KR", {
            year: "numeric",
            month: "short",
            day: "2-digit",
          })}
        </div>
        <h1 style={{ margin: "0px", fontSize: "24px" }}>{data.title}</h1>
        {isOpen ? (
          <IoChevronUp
            style={{
              fontSize: "24px",
              position: "absolute",
              right: "0px",
              top: "10px",
              color: "#A0A6B1",
            }}
          />
        ) : (
          <IoChevronDown
            style={{
              fontSize: "24px",
              position: "absolute",
              right: "0px",
              top: "10px",
              color: "#A0A6B1",
            }}
          />
        )}
      </div>
      {isOpen && (
        <div
          style={{
            borderTop: "1px solid #E5E7EB",
            marginTop: "20px",
            paddingTop: "20px",
            boxSizing: "border-box",
          }}
        >
          <div>{data.body}</div>
          {data.imgageUrl && <img src={data.imgageUrl} alt="" />}
        </div>
      )}
    </div>
  );
};

export default NotiItem;
