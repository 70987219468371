import React, { Dispatch, SetStateAction, useState } from "react";
import { styled } from "styled-components";
import { BusinessHour } from "../../types";
import { transDay } from "../../util/util";
import DaySetBox from "./DaySetBox";
import { IoMdRefresh } from "react-icons/io";
import { useRecoilValue } from "recoil";
import { shopDataSelector } from "../../atoms/atom";

type propType = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  list: BusinessHour[];
  setList: Dispatch<SetStateAction<BusinessHour[]>>;
  callback: (list: BusinessHour[]) => void;
};

const WorkTime = ({ open, setOpen, list, setList, callback }: propType) => {
  const shopData = useRecoilValue(shopDataSelector);

  const [monday, setMonday] = useState<BusinessHour>(
    list.filter((item) => item.dayOfWeek === transDay("월")).length > 0
      ? list.filter((item) => item.dayOfWeek === transDay("월"))[0]
      : {
          dayOfWeek: "monday",
          open: new Date().toISOString(),
          close: new Date().toISOString(),
        }
  );
  const [tuesday, setTuesday] = useState<BusinessHour>(
    list.filter((item) => item.dayOfWeek === transDay("화")).length > 0
      ? list.filter((item) => item.dayOfWeek === transDay("화"))[0]
      : {
          dayOfWeek: "tuesday",
          open: new Date().toISOString(),
          close: new Date().toISOString(),
        }
  );
  const [wednesday, setWednesday] = useState<BusinessHour>(
    list.filter((item) => item.dayOfWeek === transDay("수")).length > 0
      ? list.filter((item) => item.dayOfWeek === transDay("수"))[0]
      : {
          dayOfWeek: "wednesday",
          open: new Date().toISOString(),
          close: new Date().toISOString(),
        }
  );
  const [thursday, setThursday] = useState<BusinessHour>(
    list.filter((item) => item.dayOfWeek === transDay("목")).length > 0
      ? list.filter((item) => item.dayOfWeek === transDay("목"))[0]
      : {
          dayOfWeek: "thursday",
          open: new Date().toISOString(),
          close: new Date().toISOString(),
        }
  );
  const [friday, setFriday] = useState<BusinessHour>(
    list.filter((item) => item.dayOfWeek === transDay("금")).length > 0
      ? list.filter((item) => item.dayOfWeek === transDay("금"))[0]
      : {
          dayOfWeek: "friday",
          open: new Date().toISOString(),
          close: new Date().toISOString(),
        }
  );
  const [saturday, setSaturday] = useState<BusinessHour>(
    list.filter((item) => item.dayOfWeek === transDay("토")).length > 0
      ? list.filter((item) => item.dayOfWeek === transDay("토"))[0]
      : {
          dayOfWeek: "saturday",
          open: new Date().toISOString(),
          close: new Date().toISOString(),
        }
  );
  const [sunday, setSunday] = useState<BusinessHour>(
    list.filter((item) => item.dayOfWeek === transDay("일")).length > 0
      ? list.filter((item) => item.dayOfWeek === transDay("일"))[0]
      : {
          dayOfWeek: "sunday",
          open: new Date().toISOString(),
          close: new Date().toISOString(),
        }
  );

  return (
    <WorkingTimeBox>
      <div>
        {open && (
          <div
            style={{
              width: "20px",
              height: "20px",
              borderRadius: "12px",
              backgroundColor: "#A0A6B1",
              position: "absolute",
              left: "12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              if (
                !window.confirm(
                  "시간을 초기화하시겠습니까?\n샵 운영시간과 동일하게 적용됩니다."
                )
              )
                return;

              callback(shopData.businessHourList);
              setOpen(false);
            }}
          >
            <IoMdRefresh style={{ color: "#ffffff", fontSize: "14px" }} />
          </div>
        )}
        근무 시간
        <span
          onClick={() => {
            if (open) {
              const temp = [
                monday,
                tuesday,
                wednesday,
                thursday,
                friday,
                saturday,
                sunday,
              ];

              callback(
                temp.filter(
                  (item) =>
                    list.filter((item2) => item2.dayOfWeek === item.dayOfWeek)
                      .length > 0
                )
              );
            }

            setOpen((old) => !old);
          }}
          style={{
            backgroundColor: open ? "#F14262" : "#E5E7EB",
            color: open ? "#ffffff" : "#272F3E",
            cursor: "pointer",
          }}
        >
          {open ? "저장" : "수정"}
        </span>
      </div>
      {!open &&
        ["월", "화", "수", "목", "금", "토", "일"].map((item, idx) => (
          <div
            key={idx}
            style={{ display: "flex", alignItems: "center", gap: "12px" }}
          >
            <span
              style={{
                backgroundColor: item === "일" ? "#F9E8EC" : "#D8E0F8",
                width: "32px",
                height: "32px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "12px",
                fontWeight: "700",
              }}
            >
              {item}
            </span>
            <span>
              {list.filter((item2) => item2.dayOfWeek === transDay(item))
                .length > 0
                ? `${new Date(
                    list.filter(
                      (item2) => item2.dayOfWeek === transDay(item)
                    )[0].open
                  ).toLocaleTimeString("ko-KR", {
                    timeZone: "Asia/Seoul",
                    hour12: false,
                    hour: "2-digit",
                    minute: "2-digit",
                  })}~${new Date(
                    list.filter(
                      (item2) => item2.dayOfWeek === transDay(item)
                    )[0].close
                  ).toLocaleTimeString("ko-KR", {
                    timeZone: "Asia/Seoul",
                    hour12: false,
                    hour: "2-digit",
                    minute: "2-digit",
                  })}`
                : "휴무"}
            </span>
          </div>
        ))}
      {open && (
        <div
          style={{
            zoom: "1",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <DaySetBox
            data={monday}
            setData={setMonday}
            day={"월"}
            isChecked={
              list.filter((item2) => item2.dayOfWeek === transDay("월"))
                .length < 1
            }
            onTogle={() =>
              setList((old) =>
                old.filter((item2) => item2.dayOfWeek === transDay("월"))
                  .length < 1
                  ? [
                      ...old,
                      {
                        dayOfWeek: transDay("월"),
                        open: new Date().toISOString(),
                        close: new Date().toISOString(),
                      },
                    ]
                  : old.filter((item2) => item2.dayOfWeek !== transDay("월"))
              )
            }
          />
          <DaySetBox
            data={tuesday}
            setData={setTuesday}
            day={"화"}
            isChecked={
              list.filter((item2) => item2.dayOfWeek === transDay("화"))
                .length < 1
            }
            onTogle={() =>
              setList((old) =>
                old.filter((item2) => item2.dayOfWeek === transDay("화"))
                  .length < 1
                  ? [
                      ...old,
                      {
                        dayOfWeek: transDay("화"),
                        open: new Date().toISOString(),
                        close: new Date().toISOString(),
                      },
                    ]
                  : old.filter((item2) => item2.dayOfWeek !== transDay("화"))
              )
            }
          />
          <DaySetBox
            data={wednesday}
            setData={setWednesday}
            day={"수"}
            isChecked={
              list.filter((item2) => item2.dayOfWeek === transDay("수"))
                .length < 1
            }
            onTogle={() =>
              setList((old) =>
                old.filter((item2) => item2.dayOfWeek === transDay("수"))
                  .length < 1
                  ? [
                      ...old,
                      {
                        dayOfWeek: transDay("수"),
                        open: new Date().toISOString(),
                        close: new Date().toISOString(),
                      },
                    ]
                  : old.filter((item2) => item2.dayOfWeek !== transDay("수"))
              )
            }
          />
          <DaySetBox
            data={thursday}
            setData={setThursday}
            day={"목"}
            isChecked={
              list.filter((item2) => item2.dayOfWeek === transDay("목"))
                .length < 1
            }
            onTogle={() =>
              setList((old) =>
                old.filter((item2) => item2.dayOfWeek === transDay("목"))
                  .length < 1
                  ? [
                      ...old,
                      {
                        dayOfWeek: transDay("목"),
                        open: new Date().toISOString(),
                        close: new Date().toISOString(),
                      },
                    ]
                  : old.filter((item2) => item2.dayOfWeek !== transDay("목"))
              )
            }
          />
          <DaySetBox
            data={friday}
            setData={setFriday}
            day={"금"}
            isChecked={
              list.filter((item2) => item2.dayOfWeek === transDay("금"))
                .length < 1
            }
            onTogle={() =>
              setList((old) =>
                old.filter((item2) => item2.dayOfWeek === transDay("금"))
                  .length < 1
                  ? [
                      ...old,
                      {
                        dayOfWeek: transDay("금"),
                        open: new Date().toISOString(),
                        close: new Date().toISOString(),
                      },
                    ]
                  : old.filter((item2) => item2.dayOfWeek !== transDay("금"))
              )
            }
          />
          <DaySetBox
            data={saturday}
            setData={setSaturday}
            day={"토"}
            isChecked={
              list.filter((item2) => item2.dayOfWeek === transDay("토"))
                .length < 1
            }
            onTogle={() =>
              setList((old) =>
                old.filter((item2) => item2.dayOfWeek === transDay("토"))
                  .length < 1
                  ? [
                      ...old,
                      {
                        dayOfWeek: transDay("토"),
                        open: new Date().toISOString(),
                        close: new Date().toISOString(),
                      },
                    ]
                  : old.filter((item2) => item2.dayOfWeek !== transDay("토"))
              )
            }
          />
          <DaySetBox
            data={sunday}
            setData={setSunday}
            day={"일"}
            isChecked={
              list.filter((item2) => item2.dayOfWeek === transDay("일"))
                .length < 1
            }
            onTogle={() =>
              setList((old) =>
                old.filter((item2) => item2.dayOfWeek === transDay("일"))
                  .length < 1
                  ? [
                      ...old,
                      {
                        dayOfWeek: transDay("일"),
                        open: new Date().toISOString(),
                        close: new Date().toISOString(),
                      },
                    ]
                  : old.filter((item2) => item2.dayOfWeek !== transDay("일"))
              )
            }
          />
        </div>
      )}
    </WorkingTimeBox>
  );
};

const WorkingTimeBox = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 24px;
  box-sizing: border-box;
  flex-direction: column;
  gap: 16px;

  border-radius: 16px;
  background: #f9fafb;

  & > div:first-child {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #000000;
    font-size: 16px;
    font-weight: 700;
    line-height: 140%;

    position: relative;
    & > span {
      display: flex;
      width: 44px;
      height: 24px;
      padding: 0px 8px;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;

      position: absolute;
      right: 0;

      border-radius: 16px;
      background: #e5e7eb;

      color: #272f3e;
      font-size: 10px;
      font-weight: 500;
      line-height: 140%;
    }
  }
`;

export default WorkTime;
