import axios from "axios";
import { baseUrl, config } from "./api";
import {
  CreateDesignType2,
  CreateOptionType,
  CreateSurgeryMenuType,
  SaveDesignType,
  SaveSurgeryMenuType,
  SaveSurgeryOptionType,
} from "../types";

// shop menu api
export const getMenuList = async (id: string) => {
  return await axios.get(
    baseUrl + `/admin/shop/menu?limit=100&offset=1&shopId=${id}`,
    config()
  );
};

export const createMenu = async (data: CreateSurgeryMenuType) => {
  return await axios.post(baseUrl + "/admin/shop/menu", data, config());
};

export const saveMenu = async (data: SaveSurgeryMenuType) => {
  return await axios.put(baseUrl + "/admin/shop/menu", data, config());
};

export const saveMenuOrder = async (menuIds: string[]) => {
  return await axios.put(
    baseUrl + "/admin/shop/menu/order",
    { menuIds: menuIds },
    config()
  );
};

export const createDesign = async (data: CreateDesignType2) => {
  return await axios.post(baseUrl + "/admin/shop/menu/design", data, config());
};

export const saveDesign = async (data: SaveDesignType) => {
  return await axios.put(baseUrl + "/admin/shop/menu/design", data, config());
};

export const deleteMenu = async (data: { menuIdList: string[] }) => {
  return await axios.delete(baseUrl + "/admin/shop/menu", {
    headers: config().headers,
    data: data,
  });
};

// option api
export const getOptionList = async (id: string) => {
  return await axios.get(
    baseUrl + `/admin/shop/option?limit=100&offset=1&shopId=${id}`,
    config()
  );
};

export const createOption = async (
  data: CreateOptionType,
  accessToken?: string
) => {
  return await axios.post(
    baseUrl + "/admin/shop/option",
    data,
    config(accessToken)
  );
};

export const saveOption = async (data: SaveSurgeryOptionType) => {
  return await axios.put(baseUrl + "/admin/shop/option", data, config());
};

export const saveOptionOrder = async (ids: string[]) => {
  return await axios.put(
    baseUrl + "/admin/shop/option/order",
    { ids: ids },
    config()
  );
};

export const deleteOption = async (data: { optionsIdList: string[] }) => {
  return await axios.delete(baseUrl + "/admin/shop/option", {
    headers: config().headers,
    data: data,
  });
};
