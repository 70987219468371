const Store = () => {
  return (
    <div>
      <h1>Store</h1>
      <p>상점 페이지</p>
    </div>
  );
};

export default Store;
