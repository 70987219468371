import { styled } from "styled-components";
import MainLayout from "../../layout/MainLayout";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { ShopData } from "../../atoms/atom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Wallet } from "iconsax-react";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import PageNation from "../../components/customer/PageNation";
import ScheduleItem from "../../components/home/ScheduleItem";
import Calendar2 from "../../components/utility/Calendar2";
import { getSalesList, getSalesStatistic } from "../../apis/sales";
import { changeMethod } from "../../util/util";
import { getBookList } from "../../apis/book";
import SalesRegisterModal from "../../components/utility/SalesRegisterModal";
import Calendar3 from "../../components/utility/Calendar3";
import { getManagerList } from "../../apis/manager";
import Select2 from "../../components/utility/Select2";
import SalesHistoryModal from "../../components/utility/SalesHistoryModal";
import MessageListener from "../../components/utility/MessageListener";

const Revenue = () => {
  const [zoom, setZoom] = useState(1.0);

  const shopData = useRecoilValue(ShopData);

  const [active, setActive] = useState(0);
  const [active2, setActive2] = useState(0);

  const [isMonth, setIsMonth] = useState(false);

  const [salesList, setSalesList] = useState<any[]>([]);

  const [pageNum, setPageNum] = useState(1);

  const [salesCount, setSalesCount] = useState(0);

  const [statisticData, setStatisticData] = useState<{
    topManagerName: string;
    monthlyTotalPrice: string;
    dailyTotalPrice: string;
  }>({ topManagerName: "", monthlyTotalPrice: "", dailyTotalPrice: "" });

  const [bookList, setBookList] = useState<any[]>([]);

  const [isSaleOpen, setIsSaleOpen] = useState(false);

  const [selData, setSelData] = useState<any>();
  const [updateCounter, setUpdateCounter] = useState(0);

  const [date, setDate] = useState(new Date());

  const [managerList, setManagerList] = useState<any>([]);
  const [manager, setManager] = useState<any>({
    name: "전체",
    id: "",
  });

  const [selData2, setSelData2] = useState<any>({ id: "" });
  const [open, setOpen] = useState(false);

  function formatDateToKoreanTime(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false, // 24시간 형식 사용
      timeZone: "Asia/Seoul", // 한국 표준시 사용
    };

    const formattedDate: string = new Intl.DateTimeFormat(
      "ko-KR",
      options
    ).format(date);

    return formattedDate;
  }

  const getSortColumn = () => {
    switch (active) {
      case 0:
        return "date";
      case 1:
        return "price";
      case 2:
        return "managerName";

      default:
        return "date";
    }
  };

  useEffect(() => {
    if (document.body.scrollHeight > window.innerHeight) {
      setZoom(
        1 -
          (document.body.scrollHeight - window.innerHeight) /
            document.body.scrollHeight
      );
    }

    getSalesList(
      pageNum,
      getSortColumn(),
      active2 === 0 ? "ASC" : "DESC",
      shopData.id,
      isMonth ? "monthly" : "daily",
      date.toISOString(),
      manager.id
    ).then((res) => {
      console.log(res.data.data.salesList);
      setSalesList(res.data.data.salesList);
      setSalesCount(res.data.data.salesCount);
    });

    getSalesStatistic(shopData.id, new Date().toISOString()).then((res) => {
      setStatisticData(res.data.data);
    });

    getBookList(new Date().toISOString(), "daily", true, true).then((res) => {
      let bookList = res.data.data.bookList;
      setBookList(bookList);
    });

    getManagerList(shopData.id).then((res) => {
      setManagerList(res.data.data.managerList);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, active2, pageNum, updateCounter, date, manager]);

  return (
    <MainLayout active={"revenue"}>
      <Container $zoom={zoom}>
        <Main>
          <Contents>
            <Content1>
              <h2>어서오세요! {shopData.name}님</h2>
              <span>핑프와 오늘도 좋은 하루 되세요 🙂</span>
            </Content1>
            <Content2>
              <Left>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <StatusCard>
                    <span>
                      {statisticData.dailyTotalPrice
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      원
                    </span>
                    <span>
                      금일 총 매출{" "}
                      <strong style={{ color: "#3A66EC" }}></strong>
                    </span>
                    <div style={{ backgroundColor: "#EB607777" }}></div>
                    <Wallet size="42" color="#EB6077" variant="Bold" />
                  </StatusCard>
                  <StatusCard>
                    <span>
                      {statisticData.monthlyTotalPrice
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      원
                    </span>
                    <span>
                      이 달 총 매출{" "}
                      <strong style={{ color: "#3A66EC" }}></strong>
                    </span>
                    <div style={{ backgroundColor: "#7685C977" }}></div>
                    <CalendarMonthIcon
                      style={{ fontSize: "42px", color: "#6275C7" }}
                    />
                  </StatusCard>
                  <StatusCard>
                    <span>{statisticData.topManagerName}</span>
                    <span>이 달 매출 TOP</span>
                    <div style={{ backgroundColor: "#EDCF0A77" }}></div>
                    <WorkspacePremiumIcon
                      style={{ fontSize: "42px", color: "#EDCF0A" }}
                    />
                  </StatusCard>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "580px",
                    backgroundColor: "#ffffff",
                    borderRadius: "16px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                    padding: "24px",
                    boxSizing: "border-box",
                  }}
                >
                  <TopBar>
                    <BtnWrap>
                      <span
                        style={{
                          color: active === 0 ? "#f14262" : "#A0A6B1",
                          cursor: "pointer",
                        }}
                        onClick={() => setActive(0)}
                      >
                        시술일정
                      </span>
                      <span
                        style={{
                          color: active === 1 ? "#f14262" : "#A0A6B1",
                          cursor: "pointer",
                        }}
                        onClick={() => setActive(1)}
                      >
                        결제금액
                      </span>
                      <span
                        style={{
                          color: active === 2 ? "#f14262" : "#A0A6B1",
                          cursor: "pointer",
                        }}
                        onClick={() => setActive(2)}
                      >
                        담당자
                      </span>
                    </BtnWrap>
                    <BtnWrap>
                      <span
                        style={{
                          color: active2 === 0 ? "#f14262" : "#A0A6B1",
                          cursor: "pointer",
                        }}
                        onClick={() => setActive2(0)}
                      >
                        오름차순
                      </span>
                      <span
                        style={{
                          color: active2 === 1 ? "#f14262" : "#A0A6B1",
                          cursor: "pointer",
                        }}
                        onClick={() => setActive2(1)}
                      >
                        내림차순
                      </span>
                    </BtnWrap>
                  </TopBar>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                      marginBottom: "16px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: "16px",
                        backgroundColor: "#F9FAFB",
                        height: "44px",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          width: "200px",
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "16px",
                          color: "#000000",
                          fontWeight: "500",
                        }}
                      >
                        고객정보
                      </span>
                      <span
                        style={{
                          width: "144px",
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "16px",
                          color: "#000000",
                          fontWeight: "500",
                        }}
                      >
                        시술일정
                      </span>
                      <span
                        style={{
                          width: "144px",
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "16px",
                          color: "#000000",
                          fontWeight: "500",
                        }}
                      >
                        결제금액
                      </span>
                      <span
                        style={{
                          width: "96px",
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "16px",
                          color: "#000000",
                          fontWeight: "500",
                        }}
                      >
                        결제방법
                      </span>
                      <span
                        style={{
                          width: "96px",
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "16px",
                          color: "#000000",
                          fontWeight: "500",
                        }}
                      >
                        담당자
                      </span>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                        minHeight: "340px",
                      }}
                    >
                      {salesList.map((item, idx) => (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            borderRadius: "16px",
                            backgroundColor: "#F9FAFB",
                            height: "44px",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSelData2(item);
                            setOpen(true);
                          }}
                          className="salesTableItem"
                        >
                          <span
                            style={{
                              width: "200px",
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "16px",
                              color: "#000000",
                              fontWeight: "500",
                            }}
                          >
                            {item.customerName} {item.customerPhoneNumber}
                          </span>
                          <span
                            style={{
                              width: "144px",
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "16px",
                              color: "#000000",
                              fontWeight: "500",
                            }}
                          >
                            {formatDateToKoreanTime(new Date(item.date))}
                          </span>
                          <span
                            style={{
                              width: "144px",
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "16px",
                              color: "#000000",
                              fontWeight: "500",
                            }}
                          >
                            {item.price
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </span>
                          <span
                            style={{
                              width: "96px",
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "16px",
                              color: "#000000",
                              fontWeight: "500",
                            }}
                          >
                            {changeMethod(item.paymentMethod, false)}
                          </span>
                          <span
                            style={{
                              width: "96px",
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "16px",
                              color: "#000000",
                              fontWeight: "500",
                            }}
                          >
                            {item.managerName}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <PageNation
                    data={Array.from(
                      { length: Math.ceil(salesCount / 7) },
                      (_, index) => index + 1
                    )}
                    pageNum={pageNum}
                    setPageNum={setPageNum}
                  />
                </div>
              </Left>
              <Right>
                <div
                  style={{
                    width: "100%",
                    height: "44px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      width: "212px",
                      height: "100%",
                      display: "flex",
                      gap: "16px",
                    }}
                  >
                    <span
                      style={{
                        width: "72px",
                        height: "100%",
                        borderRadius: "16px",
                        backgroundColor: "#ffffff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#F14262",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      오늘
                    </span>
                    <div
                      style={{
                        width: "124px",
                        height: "100%",
                        borderRadius: "16px",
                        backgroundColor: "#ffffff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#F14262",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      <span
                        style={{
                          width: "50%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: !isMonth ? "#A0A6B1" : "#F14262",
                          fontSize: "16px",
                          fontWeight: "500",
                          cursor: "pointer",
                        }}
                        onClick={() => setIsMonth(true)}
                      >
                        월
                      </span>
                      <span
                        style={{
                          width: "50%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: isMonth ? "#A0A6B1" : "#F14262",
                          fontSize: "16px",
                          fontWeight: "500",
                          cursor: "pointer",
                        }}
                        onClick={() => setIsMonth(false)}
                      >
                        일
                      </span>
                    </div>
                  </div>
                  <Select2
                    option={[{ name: "전체", id: "" }, ...managerList]}
                    defaultValue={manager.name}
                    setResult={setManager}
                    bgColor={"#ffffff"}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "320px",
                    borderRadius: "16px",
                    backgroundColor: "#ffffff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {isMonth ? (
                    <Calendar2 zoom={1.05} date={date} setDate={setDate} />
                  ) : (
                    <Calendar3
                      zoom={1.05}
                      date={date}
                      setDate={setDate}
                      bookList={bookList}
                      managerId={manager.id}
                    />
                  )}
                </div>
                <SalesRegisterBox>
                  <div>
                    <span>매출 등록</span>
                    {bookList.length !== 0 ? (
                      <span>{bookList.length}</span>
                    ) : (
                      <span style={{ display: "none" }}></span>
                    )}
                  </div>
                  <div>
                    {bookList.length === 0 ? (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          gap: "16px",
                        }}
                      >
                        <img src={"./image/icon2.png"} alt="이모티콘2" />
                        <span style={{ color: "#A0A6B1" }}>
                          매출 등록 할 일정이 없습니다.
                        </span>
                      </div>
                    ) : (
                      bookList.map((item, idx) => (
                        <ScheduleItem
                          width="100%"
                          key={idx}
                          data={{
                            name: item.customerName,
                            time:
                              new Date(item.date).toLocaleDateString() +
                              new Date(item.date).toLocaleTimeString("ko-KR", {
                                timeStyle: "short",
                              }),
                            contens: item.menuName,
                            managerName: item.managerName,
                          }}
                          onClick={() => {
                            setSelData(item);
                            setIsSaleOpen(true);
                          }}
                        />
                      ))
                    )}
                  </div>
                </SalesRegisterBox>
              </Right>
            </Content2>
          </Contents>
        </Main>
        {isSaleOpen && (
          <SalesRegisterModal
            open={isSaleOpen}
            setOpen={setIsSaleOpen}
            data={selData}
            callback={() => {
              setUpdateCounter((old) => old + 1);
            }}
          />
        )}
        {open && (
          <SalesHistoryModal
            open={open}
            setOpen={setOpen}
            data={selData2}
            callback={() => {
              setUpdateCounter((old) => old + 1);
            }}
          />
        )}
        <MessageListener />
      </Container>
    </MainLayout>
  );
};

const Container = styled.div<{ $zoom: number }>`
  width: 100%;
  display: flex;

  zoom: ${(props) => props.$zoom};

  & .salesTableItem:hover {
    background-color: #f9e8ec !important;
  }
`;

const Main = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  min-width: 1200px;
`;

const Contents = styled.div`
  width: 1200px;
  margin-top: 50px;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Content1 = styled.div`
  min-width: 400px;
  height: 51px;

  margin-left: 15px;

  & h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.2px;
  }

  & span {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    color: #a0a6b1;
  }
`;

const Content2 = styled.div`
  display: flex;
  width: 100%;
  height: 720px;
  align-items: center;
  gap: 50px;

  border-radius: 16px;
`;

const Left = styled.div`
  width: 64%;
  height: 100%;

  gap: 24px;
  display: flex;
  flex-direction: column;
`;

const Right = styled.div`
  width: 384px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StatusCard = styled.div`
  width: 240px;
  height: 120px;
  border-radius: 16px;
  background: #ffffff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 24px;
  box-sizing: border-box;

  overflow: hidden;

  position: relative;

  & > span:nth-child(1) {
    color: #272f3e;
    font-size: 24px;
    font-weight: 700;
    line-height: 140%;
  }
  & > span:nth-child(2) {
    color: #6c727f;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
  }

  & > div:nth-child(3) {
    width: 96px;
    height: 96px;
    background: #eb607777;
    border-radius: 50%;
    position: absolute;
    top: -20px;
    right: -20px;
  }

  & > :nth-child(4) {
    position: absolute;
    top: 16px;
    right: 16px;
  }
`;

const TopBar = styled.div`
  display: flex;
  width: 100%;
  height: 44px;
  align-items: flex-start;
  gap: 24px;

  position: relative;
`;

const BtnWrap = styled.div`
  display: flex;
  padding: 11px 10px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background: #f9fafb;
  height: 44px;

  & > span {
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
  }
`;

const SalesRegisterBox = styled.div`
  width: 100%;
  height: 320px;
  background-color: #ffffff;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 16px 24px;
  box-sizing: border-box;

  & > div:first-child {
    width: 100%;
    height: 28px;
    display: flex;
    gap: 8px;
    align-items: center;

    & > span:first-child {
      color: #272f3e;
      font-size: 20px;
      font-weight: 700;
      line-height: 140%;
    }
    & > span:last-child {
      display: flex;
      width: 33px;
      height: 24px;
      justify-content: center;
      align-items: center;
      background-color: #f14262;
      border-radius: 50px;

      color: #ffffff;
      font-size: 14px;
      font-weight: 700;
      line-height: 140%;
    }
  }

  & > div:last-child {
    width: 100%;
    height: 100%;

    overflow-y: scroll;
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
    &::-webkit-scrollbar {
      display: none;
    }

    padding: 0px 10px;
    box-sizing: border-box;

    & > li:hover {
      background-color: #f9e8ec !important;
    }
  }
`;
export default Revenue;

/*

<ManagerAddBtn onClick={() => setIsSaleOpen2(true)}>
                      매출 추가 +
                    </ManagerAddBtn>

                    const ManagerAddBtn = styled.div`
  display: flex;
  width: 80px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  right: 0px;

  border-radius: 16px;
  background: #f14262;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
`;

*/
