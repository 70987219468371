import React, { useState } from "react";
import styled from "styled-components";
import RemoveIcon from "@mui/icons-material/Remove";
import SettingsIcon from "@mui/icons-material/Settings";
import { SurgeryOptionType } from "../../types";
import InputBox from "../utility/InputBox";
import CheckIcon from "@mui/icons-material/Check";
import { saveOption } from "../../apis/menu";
import { color_midnight } from "../../util/color";
import { IoMenu } from "react-icons/io5";

type propType = {
  isReordering: boolean;
  data: SurgeryOptionType;
  deleteCallback: () => void;
  submitCallback?: () => void;
  presubmitCallback?: () => void;
};

const OptionItem = ({
  isReordering,
  data,
  deleteCallback,
  submitCallback,
  presubmitCallback,
}: propType) => {
  const [isEdit, setIsEdit] = useState(false);

  const [description, setDescription] = useState(data.description);
  const [engDescription, setEngDescription] = useState(data.engDescription);

  const [price, setPrice] = useState(data.price);
  const [eventPrice, setEventPrice] = useState(
    data.eventPrice === "-1" ? "0" : data.eventPrice
  );
  const [runningTime, setRunningTime] = useState(data.runningTime);

  const [isEvent, setIsEvent] = useState(data.eventPrice !== "-1");

  const [name, setName] = useState(data.name);
  const [engName, setEngName] = useState(data.engName);

  return (
    <>
      {isEdit ? (
        <Container2>
          <div>
            <div>
              <Box>
                <span>*메뉴명(한글)</span>
                <InputBox
                  placeholder={""}
                  type={"text"}
                  width={300}
                  setValue={setName}
                  value={name}
                />
              </Box>
              <Box>
                <span>*메뉴명(영어)</span>
                <InputBox
                  placeholder={""}
                  type={"text"}
                  width={300}
                  setValue={setEngName}
                  value={engName}
                  maxLength={30}
                />
              </Box>
            </div>
            <div>
              <Box>
                <span>설명(한글)</span>
                <InputBox
                  placeholder={
                    "메뉴에 대한 설명을 입력해주세요. ex. 보트르내일만의 이달의 아트"
                  }
                  type={"text"}
                  width={800}
                  setValue={setDescription}
                  value={description}
                />
              </Box>
            </div>
            <div>
              <Box>
                <span>설명(영어)</span>
                <InputBox
                  placeholder={
                    "메뉴에 대한 설명을 입력해주세요. ex. 보트르내일만의 이달의 아트"
                  }
                  type={"text"}
                  width={800}
                  setValue={setEngDescription}
                  value={engDescription}
                />
              </Box>
            </div>
            <div style={{ gap: "36px" }}>
              <Box>
                <span>*가격</span>
                <InputBox
                  placeholder={"ex.50,000"}
                  type={"money"}
                  width={184}
                  setValue={setPrice}
                  value={price}
                />
              </Box>
              <EventBox>
                <div
                  onClick={() => setIsEvent((old) => !old)}
                  style={{ cursor: "pointer" }}
                >
                  <CheckIcon
                    style={{ color: isEvent ? "#62D4D2" : "#ffffff" }}
                  />
                </div>
                <span>Event</span>
                <span>이벤트가</span>
                <InputBox
                  placeholder={"ex.50,000"}
                  type={"money"}
                  width={184}
                  setValue={setEventPrice}
                  value={eventPrice}
                />
              </EventBox>
              <Box>
                <span>*소요시간</span>
                <InputBox
                  placeholder={"00 ( ex : 단위 분  50분 -> 50)"}
                  type={"minute"}
                  width={184}
                  setValue={setRunningTime}
                  value={runningTime}
                />
              </Box>
            </div>
          </div>
          <span
            style={{
              color: "#62D4D2",
              fontSize: "12px",
              fontWeight: "700",
              marginLeft: "24px",
            }}
          >
            이벤트 가격 설정 시 정상가를 입력해주세요.
          </span>
          <div>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsEdit(false);
              }}
            >
              취소
            </span>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (presubmitCallback) presubmitCallback();
                saveOption({
                  id: data.id,
                  shopId: data.shopId,
                  name: name,
                  engName: engName,
                  description: description,
                  engDescription: engDescription,
                  category: data.category,
                  runningTime: runningTime ? runningTime : "0",
                  price: price,
                  eventPrice: isEvent ? eventPrice : "-1",
                })
                  .then((res) => {
                    if (submitCallback) submitCallback();
                    setIsEdit(false);
                  })
                  .catch((e) => console.log(e));
              }}
            >
              저장
            </span>
          </div>
          <RemoveIcon
            onClick={() => deleteCallback()}
            sx={{
              backgroundColor: "#F71807",
              color: "#ffffff",
              borderRadius: "50%",
              width: "20px",
              height: "20px",
              position: "absolute",
              top: "20px",
              left: "20px",
            }}
          />
        </Container2>
      ) : (
        <Container>
          <div style={{ flexShrink: 0 }}>
            <IoMenu
              style={{
                color: !isReordering ? "#fff" : color_midnight.midnight9,
                borderRadius: "50%",
                width: "20px",
                height: "20px",
                cursor: "pointer",
              }}
            />
          </div>
          <div style={{ flexGrow: 1 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h3>
                {data.name} {data.engName ? `( ${data.engName} )` : ""}
              </h3>
              <span>{data.description}</span>
              {engDescription && <span>{data.engDescription}</span>}
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                fontSize: isEvent ? "14px" : "16px",
                fontWeight: isEvent ? "500" : "700",
              }}
            >
              {isEvent && (
                <span
                  style={{
                    color: "#F14262",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  {eventPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  원
                </span>
              )}
              {isEvent
                ? `(기존${price
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원)`
                : `${data.price
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원`}
              <span>
                {runningTime
                  ? `(${String(Math.floor(parseInt(runningTime) / 60)).padStart(
                      2,
                      "0"
                    )}:${String(parseInt(runningTime) % 60).padStart(2, "0")})`
                  : ""}
              </span>
            </div>
            <div></div>
          </div>
          {!isReordering && (
            <div style={{ flexShrink: 0 }}>
              <SettingsIcon
                sx={{
                  color: "#E5E7EB",
                  width: "22px",
                  height: "22px",
                  cursor: "pointer",
                }}
                onClick={() => setIsEdit(true)}
              />
            </div>
          )}
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  background-color: #fff;

  width: 100%;
  min-height: 100px;
  border: 2px solid #e5e7eb;
  box-sizing: border-box;

  margin-bottom: 32px;

  border-radius: 8px;

  display: flex;

  padding: 20px;

  & > div:nth-child(1) {
    margin-right: 10px;
  }

  & > div:nth-child(2) {
    box-sizing: border-box;

    & > div:nth-child(1) {
      width: 100%;

      display: flex;

      & > h3 {
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: -0.2px;
      }
      & > span {
        color: #a0a6b1;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: -0.2px;
      }
    }
    & > div:nth-child(2) {
      margin-top: 16px;

      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: -0.2px;

      & > span {
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: -0.2px;
        color: #a0a6b1;
      }
    }
    & > div:nth-child(3) {
      & > div {
        height: 44px;

        display: flex;
        align-items: center;

        margin-bottom: 8px;

        & > h4 {
          width: 66px;

          font-size: 16px;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: -0.2px;
        }
        & > span {
          font-size: 16px;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: -0.2px;
        }
        & > div {
          width: 44px;
          height: 44px;
          border-radius: 8px;
          background-color: #f4f5f7;
          margin-right: 24px;
        }
      }
    }
  }
  & > div:nth-child(3) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Container2 = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 2px solid #f14262;
  box-sizing: border-box;
  margin-bottom: 32px;

  display: flex;
  padding: 16px 32px;
  padding-left: 64px;
  flex-direction: column;
  justify-content: flex-end;
  gap: 16px;

  position: relative;

  & > :nth-child(1) {
    display: flex;
    flex-direction: column;
    gap: 24px;

    & > div {
      display: flex;
      align-items: center;
      gap: 24px;
    }
  }

  & > :nth-child(3) {
    display: flex;
    align-self: flex-end;
    & > :first-child {
      display: flex;
      width: 92px;
      height: 44px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;

      color: #000000;
      font-size: 14px;
      font-weight: 500;
    }
    & > :last-child {
      display: flex;
      width: 92px;
      height: 44px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: #f14262;

      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
    }
  }
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  & > :first-child {
    color: #f14262;
    font-size: 16px;
    font-weight: 700;
  }
`;

const EventBox = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  & > :nth-child(1) {
    width: 24px;
    height: 24px;
    border: 2px solid #62d4d2;
    box-sizing: border-box;

    border-radius: 4px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > :nth-child(2) {
    padding: 4px 7px;
    border-radius: 4px;
    background: #fada07;

    color: #f9fafb;
    font-size: 12px;
    font-weight: 700;
  }

  & > :nth-child(3) {
    color: #62d4d2;
    font-size: 14px;
    font-weight: 700;
  }
`;

export default OptionItem;
