import { styled } from "styled-components";
import MainLayout from "../../layout/MainLayout";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { ShopData } from "../../atoms/atom";
import ManagerItem from "../../components/manager/ManagerItem";
import SideBar from "../../components/manager/SideBar";
import { ManagerType } from "../../types";
import { getManagerList } from "../../apis/manager";
import WaitingModal from "../../components/utility/WaitingModal";
import MessageListener from "../../components/utility/MessageListener";

const Manager = () => {
  const [zoom, setZoom] = useState(1.0);

  const shopData = useRecoilValue(ShopData);

  const [managerList, setManagerList] = useState<ManagerType[]>([]);

  const [active, setActive] = useState(0);
  const [active2, setActive2] = useState(0);

  const [focused, setFocused] = useState(-1);

  const [isAdd, setIsAdd] = useState(false);

  const [data, setData] = useState<ManagerType>({
    name: "",
    businessHourList: [],
    category: "employee",
    color: "",
    id: "",
    imageUrl: "",
    phoneNumber: "",
    shopId: shopData.id,
    description: "",
    memo: "",
    salesCount: "",
    totalPrice: "",
    lastSales: null,
  });

  const [openWaiting, setOpenWaiting] = useState(false);

  useEffect(() => {
    if (document.body.scrollHeight > window.innerHeight) {
      setZoom(
        1 -
          (document.body.scrollHeight - window.innerHeight) /
            document.body.scrollHeight
      );
    } else {
      setZoom((document.body.scrollHeight - 40) / 885);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getManagerList(shopData.id).then((res) => {
      console.log(res.data.data.managerList);
      setManagerList(res.data.data.managerList);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout active={"manager"}>
      <Container $zoom={zoom}>
        <Main>
          <Contents>
            <Left>
              <Content1>
                <h2>어서오세요! {shopData.name}님</h2>
                <span>핑프와 오늘도 좋은 하루 되세요 🙂</span>
              </Content1>
              <Content2
                style={
                  focused !== -1
                    ? { animation: "SlideIn 0.5s forwards" }
                    : { animation: "SlideOut 0.5s forwards" }
                }
              >
                <TopBar>
                  <BtnWrap>
                    <span
                      style={{ color: active === 0 ? "#f14262" : "#A0A6B1" }}
                      onClick={() => setActive(0)}
                    >
                      담당자명
                    </span>
                    <span
                      style={{ color: active === 1 ? "#f14262" : "#A0A6B1" }}
                      onClick={() => setActive(1)}
                    >
                      매출액
                    </span>
                    <span
                      style={{ color: active === 2 ? "#f14262" : "#A0A6B1" }}
                      onClick={() => setActive(2)}
                    >
                      시술건
                    </span>
                  </BtnWrap>
                  <BtnWrap>
                    <span
                      style={{ color: active2 === 0 ? "#f14262" : "#A0A6B1" }}
                      onClick={() => setActive2(0)}
                    >
                      오름차순
                    </span>
                    <span
                      style={{ color: active2 === 1 ? "#f14262" : "#A0A6B1" }}
                      onClick={() => setActive2(1)}
                    >
                      내림차순
                    </span>
                  </BtnWrap>
                  <ManagerAddBtn
                    onClick={() => {
                      setFocused(1000);
                      setData({
                        name: "",
                        businessHourList: [],
                        category: "employee",
                        color: "",
                        id: "",
                        imageUrl: "",
                        phoneNumber: "",
                        shopId: shopData.id,
                        description: "",
                        memo: "",
                        salesCount: "",
                        totalPrice: "",
                        lastSales: null,
                      });
                      setIsAdd(true);
                    }}
                    className="addBtn"
                  >
                    추가 +
                  </ManagerAddBtn>
                </TopBar>
                <Wrap>
                  <ManagerListBox>
                    {managerList.length === 0 ? (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            color: "#cccccc",
                            lineHeight: "20px",
                          }}
                        >
                          아직 추가된 직원이 없습니다.
                          <br />
                          직원을 추가해 주세요.
                        </p>
                      </div>
                    ) : (
                      <>
                        {managerList.map((item, idx) => (
                          <ManagerItem
                            data={item}
                            key={idx}
                            focused={focused === idx}
                            onClick={() => {
                              setData(item);

                              if (isAdd) {
                                setFocused(idx);
                                setIsAdd(false);
                              } else {
                                setFocused((old) => (old === -1 ? idx : -1));
                              }
                            }}
                          />
                        ))}
                      </>
                    )}
                  </ManagerListBox>
                </Wrap>
              </Content2>
            </Left>
            {focused !== -1 && (
              <Right
                style={
                  focused !== -1 ? { animation: "SlideIn2 0.6s forwards" } : {}
                }
              >
                <SideBar
                  managerList={managerList.filter((e) => e.id !== data.id)}
                  isAdd={isAdd}
                  setISAdd={setIsAdd}
                  focused={focused}
                  setFocused={setFocused}
                  data={data}
                  saveCallback={() => {
                    setOpenWaiting(true);
                    setFocused(-1);
                    getManagerList(shopData.id).then((res) => {
                      setManagerList(res.data.data.managerList);
                      setOpenWaiting(false);
                    });
                  }}
                />
              </Right>
            )}
          </Contents>
        </Main>
      </Container>
      <MessageListener />
      <WaitingModal open={openWaiting} />
    </MainLayout>
  );
};

const Container = styled.div<{ $zoom: number }>`
  width: 100%;
  display: flex;

  zoom: ${(props) => props.$zoom};
`;

const Main = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  min-width: 1200px;
`;

const Contents = styled.div`
  width: 1200px;

  display: flex;
  gap: 50px;

  align-items: center;
`;

const Left = styled.div`
  width: 100%;

  margin-top: 50px;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const Right = styled.div`
  width: 90%;
  height: 840px;
  margin-top: 40px;
  padding-left: 80px;
  box-sizing: border-box;

  display: flex;

  position: relative;
  left: 0px;

  @keyframes SlideIn2 {
    from {
      left: 300px;
    }
    to {
      left: 0px;
    }
  }
`;

const Content1 = styled.div`
  min-width: 400px;
  height: 51px;

  margin-left: 15px;

  & h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.2px;
  }

  & span {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    color: #a0a6b1;
  }
`;

const Content2 = styled.div`
  background-color: #ffffff;

  display: flex;
  width: 100%;
  height: 720px;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  border-radius: 16px;

  @keyframes SlideIn {
    from {
      width: 180%;
    }
    to {
      width: 100%;
    }
  }

  @keyframes SlideOut {
    from {
      width: 50%;
    }
    to {
      width: 100%;
    }
  }
`;

const TopBar = styled.div`
  display: flex;
  width: 100%;
  height: 44px;
  align-items: flex-start;
  gap: 24px;

  position: relative;

  & .addBtn:hover {
    background-color: #fd7f96 !important;
  }
`;

const BtnWrap = styled.div`
  display: none;
  padding: 11px 10px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background: #f9fafb;
  height: 44px;

  & > span {
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
  }
`;
const ManagerAddBtn = styled.div`
  cursor: pointer;
  display: flex;
  width: 80px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  right: 0px;

  border-radius: 16px;
  background: #f14262;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
`;

const Wrap = styled.div`
  width: 100%;
  height: 90%;
`;

const ManagerListBox = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;

  overflow-y: scroll;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default Manager;
