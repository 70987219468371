import { styled } from "styled-components";
import { useSetRecoilState } from "recoil";
import { RegisterDataAtom, registerStepAtom } from "../../atoms/atom";
import InputBox from "../utility/InputBox";
import { useState } from "react";

const RegisterStep5 = () => {
  const [pw, setPw] = useState("");
  const [pw2, setPw2] = useState("");
  const setStep = useSetRecoilState(registerStepAtom);
  const setData = useSetRecoilState(RegisterDataAtom);

  const nextHandler = () => {
    setData((old) => ({
      email: old.email,
      passwd: pw,
      name: old.name,
      phone: old.phone,
      isAdmin: old.isAdmin,
      tos: old.tos,
      token: old.token,
      shopname: old.shopname,
      address: old.address,
    }));

    setStep(6);
  };

  return (
    <>
      <ResultContainer>
        <Label>PASSWORD</Label>
        <InputBox
          placeholder="새로운 비밀번호를 입력해 주세요."
          type="password"
          width={300}
          setValue={setPw}
          value={pw}
          clearHandler={() => setPw2("")}
        />
        {pw.length >= 8 ? (
          <>
            <InputBox
              placeholder="새로운 비밀번호를 재입력해 주세요."
              type="password"
              width={300}
              setValue={setPw2}
              value={pw2}
            />
            <Text>비밀번호는 8자리 이상 20자리 이내로 입력해 주세요.</Text>
            {pw !== pw2 ? <Text2>비밀번호가 일치하지 않습니다.</Text2> : <></>}
          </>
        ) : (
          <Text>비밀번호는 8자리 이상 20자리 이내로 입력해 주세요.</Text>
        )}
      </ResultContainer>
      <NextBtn
        disabled={pw2.length >= 8 && pw === pw2 ? false : true}
        onClick={nextHandler}
      >
        다음
      </NextBtn>
    </>
  );
};

const ResultContainer = styled.div`
  width: 300px;
  height: 300px;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 20px;
  box-sizing: border-box;
`;

const Label = styled.span`
  color: #a0a6b1;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.2px;
  font-weight: 700;
  width: 100%;
`;

const NextBtn = styled.button<{ disabled: boolean }>`
  border: none;
  width: 300px;
  height: 44px;
  border-radius: 8px;
  color: #f9fafb;
  background-color: ${(props) => (!props.disabled ? "#ff5d7a" : "#d3d6db")};

  font-family: "Noto Sans KR", sans-serif;

  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: -0.2px;
`;

const Text = styled.div`
  width: 300px;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  color: #ade4e3;

  box-sizing: border-box;
  padding-left: 10px;
`;
const Text2 = styled.div`
  width: 300px;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  color: #ff5d7a;

  box-sizing: border-box;
  padding-left: 10px;
`;

export default RegisterStep5;
