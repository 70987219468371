import React, { useState } from "react";

const Hidden = () => {
  const [checked, setChecked] = useState("");
  const [textData, setTextData] = useState("");

  const [bookList, setBookList] = useState<any[]>([]);

  const handleChange = (text: string) => {
    setChecked((old) => (text === old ? "" : text));
  };

  const removeDuplicates = (arr: any, prop: string) => {
    return arr.filter(
      (obj: any, index: number, self: any) =>
        index === self.findIndex((t: any) => t[prop] === obj[prop])
    );
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div style={{ display: "flex", gap: "10px" }}>
        <div>
          <input
            type="checkbox"
            checked={checked === "공비서"}
            onChange={() => handleChange("공비서")}
          />
          <label>공비서</label>
        </div>
        <div>
          <input
            type="checkbox"
            checked={checked === "뷰티사랑넷"}
            onChange={() => handleChange("뷰티사랑넷")}
          />
          <label>뷰티사랑넷</label>
        </div>
        <div>
          <input
            type="checkbox"
            checked={checked === "아하소프트"}
            onChange={() => handleChange("아하소프트")}
          />
          <label>아하소프트</label>
        </div>
      </div>
      <div
        style={{ width: "100%", height: "100%", display: "flex", gap: "20px" }}
      >
        <div style={{ width: "50%", height: "100%" }}>
          <textarea
            style={{ width: "100%", height: "80%" }}
            onChange={(e) => setTextData(e.target.value)}
            value={textData}
          />
          <button
            onClick={() => {
              if (JSON.parse(textData)) {
                setBookList((old) =>
                  removeDuplicates(
                    [...old, ...JSON.parse(textData).data.content],
                    "bookNo"
                  )
                );
              }

              setTextData("");
            }}
          >
            리스트 추가
          </button>
          <button
            onClick={() => {
              /*console.log(
                bookList.map((item) => {
                  return {customerName: item.customer.name,
                    customerPhoneNumber: item.customer.contact,
                    date: new Date(item.bookingStartDateTime).toISOString(),
                    duration: (parseInt(item.procedureTime.split(':')[0])*60 + parseInt(item.procedureTime.split(':')[1])).toString(),
                    menuName: item.content,
                    designName: "",
                    optionName: "",
                    managerId: ?,
                    managerName: ?,
                    totalPrice: ,
                    bookState: "bookComplete",};
                })
              );*/
            }}
          >
            예약 추가
          </button>
        </div>
        <div
          style={{
            width: "50%",
            height: "100%",
            backgroundColor: "#eeeeee",
            overflowY: "auto",
          }}
        >
          <h2 style={{ margin: "10px" }}>예약리스트</h2>
          {bookList.map((item) => (
            <div
              style={{
                width: "92%",
                height: "120px",
                backgroundColor: "#cccccc",
                marginLeft: "4%",
                marginBottom: "16px",
                display: "flex",
                flexDirection: "column",
                padding: "10px",
                boxSizing: "border-box",
                gap: "4px",
              }}
            >
              <span>{item.customer.name}</span>
              <span>{item.customer.contact}</span>
              <span>
                {item.bookingStartDateTime} ~ {item.bookingEndDateTime}
              </span>
              <span>{item.content}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Hidden;
