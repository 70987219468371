import React from "react";
import { deleteBlock, getBlockList } from "../../apis/book";
import { RefrashCounter2 } from "../../atoms/atom";
import { useSetRecoilState } from "recoil";

const BCEventWrap = ({ event, onSelect }: any) => {
  const setRefrashCount = useSetRecoilState(RefrashCounter2);

  return (
    <>
      {event.type === "block" ? (
        <div
          className="rbc-event-block"
          style={{
            width: "100%",
            height: "200px",
            display: "flex",
            justifyContent: "start",
            gap: "4px",
            paddingLeft: "4px",
            boxSizing: "border-box",
            backgroundColor: "rgb(0 0 0 / 17%)",
            position: "relative",
            top: "-40px",
            left: "0px",
          }}
          onClick={() => {
            const result = window.confirm("off를 취소겠습니까?");

            if (!result) return;

            getBlockList(event.start.toISOString(), "monthly").then((res) => {
              let timeTableList = res.data.data.timeTableList.filter(
                (e: any) => e.date === event.start.toISOString()
              );

              timeTableList.forEach((e: any, idx: number) => {
                deleteBlock(e.timeTableId).then(() => {
                  if (idx === timeTableList.length - 1) {
                    setRefrashCount((old) => old + 1);
                  }
                });
              });
            });
          }}
        />
      ) : (
        <div
          style={{
            width: "45%",
            height: "16px",
            display: "flex",
            justifyContent: "start",
            gap: "4px",
            paddingLeft: "4px",
            boxSizing: "border-box",
            backgroundColor: "#ffffff",
            marginLeft: "5%",
            position: "relative",
            top: event.indexNum > 4 ? "-80px" : "0px",
            left: event.indexNum > 4 ? "45%" : "0px",
            cursor: "pointer",
          }}
          className="rbc-event"
          onClick={() => {
            onSelect(event);
          }}
        >
          <div
            style={{
              backgroundColor: event.color,
              width: "4px",
              height: "4px",
              borderRadius: "50%",
            }}
          />
          <span>{event.name}</span>
          <span>{event.count}건</span>
        </div>
      )}
    </>
  );
};

export default BCEventWrap;
