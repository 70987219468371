import React, { useEffect, useRef, useState } from "react";
import { ReqCancel, deleteBlock, getBlockList } from "../../apis/book";
import { useRecoilState, useSetRecoilState } from "recoil";
import { RefrashCounter, ShopData } from "../../atoms/atom";
import { BsExclamationCircle } from "react-icons/bs";
import BookRequestModal from "./BookRequestModal";
import BookModifyModal from "./BookModifyModal";
import { BsExclamationDiamondFill } from "react-icons/bs";
import BookCancelModal from "./BookCancelModal";
import { getCustomerOne } from "../../apis/customer";
import useSendbirdStateContext from "@sendbird/uikit-react/useSendbirdStateContext";
import sendbirdSelectors, {
  getCreateGroupChannel,
} from "@sendbird/uikit-react/sendbirdSelectors";

const BCEventWrapWeekly = ({ event }: any) => {
  const [isShow, setIsShow] = useState(false);
  const setRefrashCount = useSetRecoilState(RefrashCounter);

  const calculateTop = (time: Date) => {
    const totalMinutes = time.getHours() * 60 + time.getMinutes();
    const percentage = (totalMinutes / (24 * 60)) * 100;

    return `${percentage}%`;
  };

  const calculateHeight = (start: Date, end: Date) => {
    const startMinutes = start.getHours() * 60 + start.getMinutes();
    const endMinutes = end.getHours() * 60 + end.getMinutes();
    const durationMinutes = endMinutes - startMinutes;
    const percentage = (durationMinutes / (24 * 60)) * 100;

    return `${percentage}%`;
  };

  const divRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(10000);

  const [isRequestOpen, setIsRequestOpen] = useState(false);
  const [isBookOpen, setIsBookOpen] = useState(false);
  const [isCancelOPen, setIsCancelOpen] = useState(false);

  const [shopData] = useRecoilState(ShopData);

  const globalStore = useSendbirdStateContext();
  sendbirdSelectors.getSdk(globalStore);

  const createChannel = getCreateGroupChannel(globalStore);

  const createChannelAndSendMessage = async (reason: string) => {
    const user = await getCustomerOne(event.dataOri.customerId).then(
      (res) => res.data.data
    );

    try {
      const params = {
        invitedUserIds: [user.userId, shopData.id],
        name: shopData.name,
        coverUrl:
          "https://storage.finger-princess.com/5071c892-2053-4ac7-b7a8-e7726989f433",
        isDistinct: true,
      };
      const channel = await createChannel(params);

      const formattedDate = new Date(event.start); // 예약 날짜
      const dayOfWeek = formattedDate.toLocaleDateString("ko-KR", {
        weekday: "short", // 요일 (예: 월, 화)
      });
      const datePart = `${String(formattedDate.getMonth() + 1).padStart(
        2,
        "0"
      )}/${String(formattedDate.getDate()).padStart(2, "0")}`; // MM/DD 형식으로 날짜 생성
      const timePart = formattedDate.toLocaleTimeString("ko-KR", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false, // 24시간 형식
      });

      let korReason = "";

      switch (reason) {
        case "duplicate":
          korReason = "중복예약으로";
          break;
        case "noManager":
          korReason = "담당자 부재로";
          break;
        case "noTime":
          korReason = "일정 불가로";
          break;
        case "shop":
          korReason = "샵의 사정으로";
          break;
        case "noRequirement":
          korReason = "요청 사랑 불가로";
          break;
      }

      const messageText = `${korReason} ${datePart}(${dayOfWeek}) ${timePart} 예약이 취소되었습니다. 🥲`;
      channel.sendUserMessage({ message: messageText });
    } catch (error) {
      console.error("Error creating channel or sending message:", error);
    }
  };

  useEffect(() => {
    if (divRef.current) {
      setWidth(divRef.current.clientWidth);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divRef.current]);

  console.log(event);

  return (
    <>
      {event.type === "block" ? (
        <div
          style={{
            backgroundColor: "#E5E7EB",
            position: "absolute",
            top: calculateTop(event.start),
            color: "black",
            border: "none",
            height: calculateHeight(event.start, event.end),
            flexDirection: "column",
            gap: "4px",
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "14px",
            width: `calc( 100% / ${1} )`,
            left: `calc( ${100 * 0}% / ${1})`,
            display: "flex",
            overflow: "visible",
            borderRadius: "0px",
            flexWrap: "nowrap",
          }}
          className="rbc-event"
          onClick={() => {
            const result = window.confirm("off를 취소겠습니까?");

            if (!result) return;

            getBlockList(event.start.toISOString(), "weekly").then((res) => {
              let timeTableList = res.data.data.timeTableList.filter(
                (e: any) => e.date === event.start.toISOString()
              );

              console.log(timeTableList);

              timeTableList.forEach((e: any, idx: number) => {
                deleteBlock(e.timeTableId).then(() => {
                  if (idx === timeTableList.length - 1) {
                    setRefrashCount((old) => old + 1);
                  }
                });
              });
            });
          }}
        ></div>
      ) : (
        <div
          ref={divRef}
          onMouseEnter={() => setIsShow(true)}
          onMouseLeave={() => setIsShow(false)}
          style={{
            backgroundColor:
              event.bookState === "nailComplete" ? "#cccccc" : event.color,
            position: "absolute",
            top: calculateTop(event.start),
            color: "black",
            border: "none",
            height: calculateHeight(event.start, event.end),
            flexDirection: "row",
            gap: "4px",
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "14px",
            width: `calc( 100% / ${event.AmountNum} )`,
            left: `calc( ${100 * event.indexNum}% / ${event.AmountNum})`,
            display: "flex",
            overflow: "visible",
            flexWrap: "nowrap",
            justifyContent: "center",
          }}
          className="rbc-event"
          onClickCapture={() => {
            if (event.bookState === "bookRequest") {
              setIsRequestOpen(true);
            } else {
              setIsBookOpen(true);
            }
          }}
        >
          {width > 72 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                width: "100%",
              }}
            >
              <span style={{ color: "#ffffff", fontSize: "12px" }}>
                {event.name}
              </span>
              <span style={{ color: "#ffffff" }}>- {event.menuName}</span>
            </div>
          )}
          {width > 24 && (
            <span
              style={{
                width: "40px",
                height: "100%",
                display: "flex",
                justifyContent: "end",
                padding: "4px",
                boxSizing: "border-box",
              }}
            >
              {event.bookState === "bookComplete" &&
                new Date(event.start) < new Date() && (
                  <BsExclamationDiamondFill
                    style={{
                      fontSize: "20px",
                      color: "#ffffff",
                      backgroundColor: event.color,
                      borderRadius: "50%",
                    }}
                  />
                )}
              {event.bookState === "bookRequest" && (
                <BsExclamationCircle
                  style={{
                    fontSize: "20px",
                    color: event.color,
                    backgroundColor: "#ffffff",
                    borderRadius: "50%",
                  }}
                />
              )}
            </span>
          )}
          {event.bookState === "bookRequest" && width <= 24 && (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                paddingTop: "2px",
                minWidth: "6px",
                paddingLeft: "0px",
              }}
            >
              <span
                style={{
                  width: "6px",
                  height: "6px",
                  borderRadius: "50%",
                  backgroundColor: "#ffffff",
                }}
              ></span>
            </div>
          )}
          <div
            style={{
              display: isShow ? "flex" : "none",
              padding: "16px 0px",
              boxSizing: "border-box",
              position: "absolute",
              top: "-180px",
              left:
                new Date(event.start).getDay() === 6
                  ? `calc( -160px + 50% )`
                  : `50%`,
              width: "160px",
              height: "180px",
              backgroundColor: "#F9FAFB",
              zIndex: 10,
              borderRadius: "8px",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              boxShadow: "0 0 4px rgba(0, 0, 0, 0.2)",
            }}
          >
            <span>{event.name}</span>
            <span>{event.phoneNumber}</span>
            <span>{`${event.start.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            })} ~ ${event.end.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            })}`}</span>
            <span>{event.menuName}</span>
            <span>{event.managerName}</span>
          </div>
        </div>
      )}
      {isRequestOpen && (
        <BookRequestModal
          open={isRequestOpen}
          setOpen={setIsRequestOpen}
          data={event.dataOri}
          callback={() => {
            setRefrashCount((old) => old + 1);
          }}
          callback2={() => {
            setIsCancelOpen(true);
          }}
        />
      )}
      {isBookOpen && (
        <BookModifyModal
          open={isBookOpen}
          setOpen={setIsBookOpen}
          data={event.dataOri}
          callback={() => {
            setRefrashCount((old) => old + 1);
          }}
          callback2={() => {
            setIsCancelOpen(true);
          }}
        />
      )}
      {isCancelOPen && (
        <BookCancelModal
          open={isCancelOPen}
          setOpen={setIsCancelOpen}
          data={undefined}
          callback={(reason) => {
            if (!reason) return;

            ReqCancel(event.dataOri.bookId, reason).then(() => {
              setIsCancelOpen(false);
              createChannelAndSendMessage(reason);
              setRefrashCount((old) => old + 1);
            });
          }}
        />
      )}
    </>
  );
};

export default BCEventWrapWeekly;
