import { styled } from "styled-components";
import { useSetRecoilState } from "recoil";
import InputBox from "../utility/InputBox";
import { useState } from "react";
import { RegisterDataAtom, registerStepAtom } from "../../atoms/atom";

const RegisterStep2 = () => {
  const [name, setName] = useState("");
  const setStep = useSetRecoilState(registerStepAtom);
  const setData = useSetRecoilState(RegisterDataAtom);

  const nextHandler = () => {
    setData((old) => ({
      email: old.email,
      passwd: old.passwd,
      name: name,
      phone: old.phone,
      isAdmin: old.isAdmin,
      tos: old.tos,
      token: old.token,
      shopname: old.shopname,
      address: old.address,
    }));

    setStep(3);
  };

  return (
    <>
      <ResultContainer>
        <InputContainer>
          <Label>이름</Label>
          <InputBox
            placeholder="이름을 입력해주세요."
            type="text"
            width={300}
            setValue={setName}
            value={name}
          />
        </InputContainer>
      </ResultContainer>
      <OkBtn disabled={!name ? true : false} onClick={() => nextHandler()}>
        다음
      </OkBtn>
    </>
  );
};

const InputContainer = styled.div`
  width: 300px;
  height: 82px;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const ResultContainer = styled.div`
  width: 300px;
  height: 300px;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 40px;
  box-sizing: border-box;
`;

const Label = styled.span`
  color: #a0a6b1;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.2px;
  font-weight: 700;
`;

const OkBtn = styled.button<{ disabled: boolean }>`
  border: none;
  width: 300px;
  height: 44px;
  border-radius: 8px;
  color: #f9fafb;
  background-color: ${(props) => (!props.disabled ? "#ff5d7a" : "#d3d6db")};

  font-family: "Noto Sans KR", sans-serif;

  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: -0.2px;
`;

export default RegisterStep2;
