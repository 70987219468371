import React from "react";
import styled from "styled-components";

const TermRefund = () => {
  return (
    <Container>
      <iframe
        width="90%"
        height="90%"
        src="https://storage.finger-princess.com/policy/shop_refund_policy.html"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      ></iframe>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  & > iframe {
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export default TermRefund;
