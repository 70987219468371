import styled from "styled-components";
import MainLayout from "../../layout/MainLayout";
import { useEffect, useState } from "react";
import { getShopData } from "../../apis/shop";
import { FeedType, ShopType } from "../../types";
import { useRecoilState } from "recoil";
import { ShopData } from "../../atoms/atom";
import MessageBox from "../../components/talk/MessageBox";
import Setting from "../../components/talk/Setting";

import "@sendbird/uikit-react/dist/index.css";
import SendbirdProvider, {
  useSendbirdStateContext,
} from "@sendbird/uikit-react/SendbirdProvider";
import GroupChannelList from "@sendbird/uikit-react/GroupChannelList";
import GroupChannel from "@sendbird/uikit-react/GroupChannel";

import { FiSearch } from "react-icons/fi";
import sendbirdSelectors, {
  getGetGroupChannel,
  getSendFileMessage,
} from "@sendbird/uikit-react/sendbirdSelectors";
import { useGroupChannelListContext } from "@sendbird/uikit-react/GroupChannelList/context";

import { IoGridSharp } from "react-icons/io5";
import { getFeedList } from "../../apis/feed";
import ImgSubmitModal from "../../components/talk/ImgSubmitModal";
import kr from "date-fns/locale/ko";

const ChannelListHeader = ({ searchText, setSearchText }: any) => {
  const context = useGroupChannelListContext();

  useEffect(() => {
    context.refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "90%",
          height: "36px",
          padding: "8px",
          boxSizing: "border-box",
          backgroundColor: "#F9FAFB",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "8px",
        }}
      >
        <FiSearch
          style={{
            padding: "8px",
            fontSize: "18px",
            color: "#AEAEAE",
          }}
        />
        <input
          type="search"
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#F9FAFB",
            outline: "none",
            border: "none",
          }}
          placeholder="이름을 입력해주세요"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target!.value);
          }}
        />
      </div>
    </div>
  );
};

const ChatApp = () => {
  const [shopData] = useRecoilState(ShopData);

  const globalStore = useSendbirdStateContext();
  sendbirdSelectors.getSdk(globalStore);

  const sendFile = getSendFileMessage(globalStore);
  const getGroupChannel = getGetGroupChannel(globalStore);

  const [currentChannelUrl, setCurrentChannelUrl] = useState<string>();
  const [searchText, setSearchText] = useState("");

  const [feedList, setFeedList] = useState<FeedType[]>([]);

  const [isOpen, setIsOpen] = useState(false);

  const fetchImageAsFile = async (imageUrl: string) => {
    return fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const fileName = "customFileName.jpg"; // 파일명을 설정할 수 있습니다.
        return new File([blob], fileName, { type: "image/jpeg" });
      });
  };

  const sendImg = (uploadList: any[]) => {
    if (uploadList.length <= 0) return;

    getGroupChannel(currentChannelUrl!).then((channel) => {
      uploadList.forEach(async (e) => {
        const fileObj = await fetchImageAsFile(e.imageUrl);
        if (fileObj) {
          sendFile(channel, {
            file: fileObj,
          });
        }
      });
    });
  };

  useEffect(() => {
    getFeedList(shopData.id).then((res) => {
      setFeedList(res.data.data.feedList);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const markMessagesAsRead = async (channelUrl: string) => {
    if (!channelUrl) return;
    const channel = await getGroupChannel(channelUrl);
    channel.markAsRead();
  };

  useEffect(() => {
    if (currentChannelUrl) {
      markMessagesAsRead(currentChannelUrl);
    }
  }, [currentChannelUrl]);

  return (
    <>
      <div
        className="sendbird-app__conversation-wrap"
        style={{ width: "100%", position: "relative" }}
      >
        <GroupChannel channelUrl={currentChannelUrl!} />
        <div
          className="gridIcon"
          style={{
            padding: "6px",
            position: "absolute",
            right: "72px",
            bottom: "38px",
            borderRadius: "50%",
            cursor: "pointer",
          }}
          onClick={() => setIsOpen(true)}
        >
          <IoGridSharp
            style={{
              color: "#ffffff",
              fontSize: "20px",
            }}
          />
        </div>
      </div>
      <div className="sendbird-app__channellist-wrap">
        <GroupChannelList
          renderChannelPreview={(props) => {
            const temp_channel = props.channel as any;

            return (
              <div
                style={{
                  width: "100%",
                  height: "80px",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: props.isSelected ? "#FD91A5" : "#F4F5F7",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "16px",
                    position: "relative",
                  }}
                  onClick={props.onClick}
                >
                  <img
                    alt=""
                    src={
                      temp_channel.creator.plainProfileUrl
                        ? temp_channel.creator.plainProfileUrl
                        : "https://storage.finger-princess.com/profile_basic.png"
                    }
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                      margin: "0px 12px",
                      backgroundColor: "#ffffff",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                      justifyContent: "space-between",
                      width: "60%",
                      maxHeight: "44px",
                      overflowY: "hidden",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        color: props.isSelected ? "#ffffff" : "#000000",
                      }}
                    >
                      {temp_channel.creator.nickname}
                    </span>
                    <span
                      style={{
                        fontSize: "12px",
                        color: props.isSelected ? "#ffffff" : "#A0A6B1",
                        lineHeight: "16px",
                      }}
                    >
                      {temp_channel.lastMessage
                        ? temp_channel.lastMessage.message
                        : ""}
                    </span>
                  </div>
                  <span
                    style={{
                      position: "absolute",
                      top: "12px",
                      right: "12px",
                      fontSize: "10px",
                      color: props.isSelected ? "#ffffff" : "#A0A6B1",
                    }}
                  >
                    {new Date(
                      temp_channel.lastMessage
                        ? temp_channel.lastMessage.createdAt
                        : null
                    ).toLocaleTimeString(["ko-KR"], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                  {temp_channel.unreadMessageCount > 0 && (
                    <div
                      style={{
                        position: "absolute",
                        right: "16px",
                        bottom: "16px",
                        backgroundColor: "#FD91A5",
                        width: "24px",
                        height: "24px",
                        borderRadius: "50%",
                        color: "#ffffff",
                        fontSize: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {temp_channel.unreadMessageCount}
                    </div>
                  )}
                </div>
              </div>
            );
          }}
          selectedChannelUrl={currentChannelUrl}
          onChannelCreated={(channel) => {
            setCurrentChannelUrl(channel.url);
          }}
          onChannelSelect={(channel) => {
            setCurrentChannelUrl(channel?.url);
          }}
          channelListQueryParams={{
            nicknameContainsFilter: searchText,
          }}
          renderHeader={() => (
            <ChannelListHeader
              searchText={searchText}
              setSearchText={setSearchText}
            />
          )}
        />
      </div>
      {isOpen && (
        <ImgSubmitModal
          setOpen={setIsOpen}
          open={isOpen}
          data={feedList}
          callback={(uploadList) => sendImg(uploadList)}
        />
      )}
    </>
  );
};

const Talk = () => {
  const [zoom] = useState(1.0);
  const [shopData, setShopData] = useRecoilState(ShopData);
  const [pageState, setPageState] = useState(true);

  useEffect(() => {
    getShopData().then((res) => {
      const data = res.data.data.shop as ShopType;
      setShopData(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout active={"talk"}>
      <Container $zoom={zoom}>
        {false && (
          <Main>
            <Contents>
              <Content1>
                <h2>어서오세요! {shopData.name}님</h2>
                <span>보트르네일과 좋은 하루 되세요 🙂</span>
              </Content1>
              {pageState ? (
                <MessageBox onChange={() => setPageState(false)} />
              ) : (
                <Setting onChange={() => setPageState(true)} />
              )}
            </Contents>
          </Main>
        )}
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "80%",
              height: "800px",
              display: "flex",
              backgroundColor: "#ffffff",
              padding: "20px",
              boxSizing: "border-box",
              borderRadius: "16px",
            }}
          >
            <SendbirdProvider
              appId={"53949A19-8F6E-45E5-BAE5-2D2DEEF48E15"}
              userId={shopData.id}
              nickname={shopData.name}
              dateLocale={kr}
            >
              <ChatApp />
            </SendbirdProvider>
          </div>
        </div>
      </Container>
    </MainLayout>
  );
};

const Container = styled.div<{ $zoom: number }>`
  width: 100%;
  display: flex;

  zoom: ${(props) => props.$zoom};

  .gridIcon:hover {
    background: #ffffff55;
  }
  .sendbird-iconbutton:hover {
    background: #ffffff55;
    border-radius: 50%;
    padding: 6px;
  }

  .sendbird-badge {
    background-color: #fd91a5;
  }

  .sendbird-conversation__messages .sendbird-conversation__messages-padding {
    overflow-y: scroll;
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .sendbird-channel-header .sendbird-iconbutton {
    display: none;
  }
  .sendbird-channel-preview__action {
    display: none;
  }
  .sendbird-message-input .sendbird-message-input--textarea {
    overflow-y: scroll;
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .sendbird-channel-list__body > div {
    padding-left: 5%;
    box-sizing: border-box;
  }

  .sendbird-channel-preview {
    background-color: #f9fafb;
    border: none;
    width: 95%;
    display: flex;
    margin-bottom: 12px;
    border-radius: 8px;

    & .sendbird-label--caption-2 {
      display: none;
    }
  }

  .sendbird-channel-preview--active {
    color: #000000;
    background-color: #fd91a5;

    & .sendbird-label--subtitle-2 {
      font-weight: 700;
      color: #ffffff;
    }
    & .sendbird-label--subtitle-2 {
      font-weight: 700;
      color: #ffffff;
    }
    & .sendbird-label--body-2 {
      color: #ffffff;
    }

    & .sendbird-label--caption-3 {
      color: #ffffff;
    }
  }

  .sendbird-channel-preview__content > div {
    width: 100% !important;
  }

  .sendbird-label--subtitle-2 {
    font-weight: 700;
    color: #000000;
  }

  .sendbird-conversation {
    border: none;
  }

  .sendbird-chat-header__right {
    visibility: hidden;
  }

  .sendbird-text-message-item-body.outgoing {
    background-color: #fd91a5 !important;
  }

  .sendbird-message-input-text-field {
    border: none !important;
    border-radius: 16px !important;

    background-color: #fd91a5 !important;

    color: #ffffff !important;

    &.sendbird-icon-color--content-inverse [class*="fill"] {
      fill: #ffffff !important;
    }
  }

  .sendbird-message-input--placeholder {
    color: rgb(255 255 255 / 38%) !important;
  }

  .icon-attach_svg__fill,
  .icon-send_svg__fill {
    fill: #ffffff !important;
  }
  .icon-chevron-down_svg__fill {
    fill: #fd91a5 !important;
  }
`;

const Main = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  min-width: 1200px;
`;

const Contents = styled.div`
  width: 1200px;
  margin-top: 50px;

  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Content1 = styled.div`
  width: 400px;
  height: 51px;

  margin-left: 15px;

  & h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.2px;
  }

  & span {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    color: #a0a6b1;
  }
`;

export default Talk;
