// 검은색 ,회색 계열 색상
export const color_midnight = {
  lily: "#F9FAFB",
  midnight1: "#F4F5F7",
  midnight2: "#E5E7EB",
  midnight3: "#D3D6DB",
  midnight4: "#A0A6B1",
  midnight5: "#6C727F",
  midnight6: "#4D5562",
  midnight7: "#394150",
  midnight8: "#272F3E",
  midnight9: "#181E2D",
};

// 파란색 계열 색상
export const color_iris = {
  iris1: "#E8EDF9",
  iris2: "#D8E0F8",
  iris3: "#C8D4F7",
  iris4: "#B7C7F6",
  iris5: "#A7BAF4",
  iris6: "#97AEF3",
  iris7: "#86A1F2",
  iris8: "#7694F1",
  iris9: "#6688F0",
  iris10: "#3A66EC",
};

// 빨랑 , 분홍 계열 색상
export const color_rose = {
  rose0: "#F9E8EC",
  rose1: "#FAD7DE",
  rose2: "#FBC5D0",
  rose3: "#FBB4C1",
  rose4: "#FCA2B3",
  rose5: "#FD91A5",
  rose6: "#FD7F96",
  rose7: "#FE6E88",
  rose8: "#FF5D7A",
  rose9: "#F14262",
};

// 초록 , 민트 계열 색상
export const color_green = {
  green1: "#F2F9F8",
  green2: "#D8F5F5",
  green3: "#BDEDEC",
  green4: "#ADE4E3",
  green5: "#7FDBD9",
  green6: "#62D4D2",
};
