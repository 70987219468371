import React, { useState } from "react";
import styled from "styled-components";
import { Switch } from "@mui/material";

type propType = {
  onChange: () => void;
};

const Setting = ({ onChange }: propType) => {
  const [autoResponse, setAutoResponse] = useState(false);
  return (
    <Container>
      <div>
        <span onClick={() => onChange()}>{`메세지로 돌아가기 >`}</span>
        <h2>FAQ 수정</h2>
        <h3>FAQ 1. 예약 안내</h3>
        <div>
          <textarea />
        </div>
        <h3>FAQ 2. 예약 관련 문의</h3>
        <div>
          <textarea />
        </div>
        <h2>챗봇 자동 응답</h2>
        <div>
          <span>시술 중 자동 응답</span>
          <Switch
            onClick={() => setAutoResponse((old) => !old)}
            checked={autoResponse}
            sx={{
              ".css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
                { backgroundColor: "red" },
              ".css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked":
                { color: "#FF5D7A" },
            }}
          />
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 740px;

  background-color: #ffffff;

  border-radius: 8px;

  display: flex;
  justify-content: space-between;

  padding: 40px;
  box-sizing: border-box;

  & > div {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    gap: 16px;

    position: relative;

    & > span {
      position: absolute;
      top: 0px;
      right: 0px;

      cursor: pointer;

      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: -0.2px;
      color: #6c727f;
    }

    & > h2 {
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: -0.2px;
      color: #6c727f;
    }

    & > h3 {
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: -0.2px;
      color: #fd91a5;
    }

    & > div {
      width: 480px;
      height: 96px;

      background-color: #f4f5f7;
      border-radius: 8px;

      & > textarea {
        width: 100%;
        height: 96px;

        background: #f4f5f7;
        border: none;

        box-sizing: border-box;
        padding: 16px 32px;

        outline: none;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #000000;
        font-family: Noto Sans KR;
        border-radius: 8px;
        resize: none;
      }
    }

    & > div:last-child {
      background-color: transparent;

      & > span {
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
        color: #a0a6b1;
      }
    }
  }
`;

export default Setting;
