import React from "react";
import styled from "styled-components";

const ServerMaintenancePage: React.FC = () => {
  return (
    <Container>
      <Message>
        <h1>핑프메이커</h1>
        <p>현재 서버 점검중으로 서비스 이용이 불가합니다.</p>
        <p>불편을 드려 죄송합니다.</p>
      </Message>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Message = styled.div`
  text-align: center;
`;

export default ServerMaintenancePage;
