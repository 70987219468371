import styled from "styled-components";
import MainLayout from "../../layout/MainLayout";
import { useEffect, useState } from "react";
import TermUse from "../../components/setting/TermUse";
import PolicyPrivate from "../../components/setting/PolicyPrivate";
import { Link } from "react-router-dom";
import UserInfoChange from "../../components/setting/UserInfoChange";
import { useNavigate } from "react-router-dom";
import TermRefund from "../../components/setting/TermRefund";
import MessageListener from "../../components/utility/MessageListener";
import NotificationList from "../../components/setting/NotificationList";
import Hidden from "../../components/utility/Hidden";

const Setting = () => {
  const navigate = useNavigate();

  const [zoom, setZoom] = useState(1.0);
  const [active, setActive] = useState(0);

  const [hiddenCount, setHiddenCount] = useState(0);

  useEffect(() => {
    if (document.body.scrollHeight > window.innerHeight) {
      setZoom(
        1 -
          (document.body.scrollHeight - window.innerHeight) /
            document.body.scrollHeight
      );
    } else {
      setZoom((document.body.scrollHeight - 40) / 885);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logoutHadler = () => {
    // 기존 refreshToken , accessToken 삭제
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");

    // 자동 로그인 옵션 삭제
    localStorage.removeItem("autoLogin");

    //로그인 화면 이동
    navigate("/signin");
  };

  const getPage = () => {
    switch (active) {
      case 0:
        return <UserInfoChange />;
      case 1:
        return <TermUse />;
      case 2:
        return <PolicyPrivate />;
      case 3:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "80px",
              width: "100%",
              height: "100%",
              gap: "40px",
            }}
          >
            <h2
              style={{ fontSize: "30px" }}
              onClick={() => {
                if (hiddenCount + 1 === 5) {
                  setActive(100);
                }
                setHiddenCount((old) => old + 1);
              }}
            >
              핑프 고객센터 문의
            </h2>
            <Link to={"https://pf.kakao.com/_KRBrG"} target="_blank">
              🙋‍♀️ https://pf.kakao.com/_KRBrG
            </Link>
          </div>
        );
      case 4:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "24px",
            }}
          >
            <span
              style={{ fontSize: "20px", fontWeight: "700", color: "#272F3E" }}
            >
              로그아웃
            </span>
            <span
              style={{ fontSize: "14px", fontWeight: "500", color: "#6C727F" }}
            >
              정말 로그아웃하시겠습니까?
            </span>
            <img
              src="/image/logoutImg.png"
              style={{ marginBottom: "40px", width: "300px", height: "392px" }}
              alt="로그아웃 이미지"
            />
            <span
              style={{
                width: "88px",
                height: "44px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                backgroundColor: "#F14262",
                color: "#ffffff",
                fontSize: "14px",
                alignSelf: "flex-end",
                cursor: "pointer",
                position: "absolute",
                bottom: "40px",
                right: "40px",
              }}
              onClick={logoutHadler}
            >
              로그아웃
            </span>
          </div>
        );
      case 5:
        return <TermRefund />;
      case 6:
        return <NotificationList />;
      case 100:
        return <Hidden />;
    }
    return <div></div>;
  };

  return (
    <MainLayout active={"setting"}>
      <Container $zoom={zoom}>
        <Main>
          <Contents>
            <Content2>
              <Left>
                <Box>
                  <span>회원정보</span>
                  <div>
                    <span
                      onClick={() => setActive(0)}
                      style={{
                        backgroundColor: active === 0 ? "#F14262" : "#F9FAFB",
                        color: active === 0 ? "#ffffff" : "#A0A6B1",
                        cursor: "pointer",
                      }}
                    >
                      회원정보
                    </span>
                  </div>
                </Box>
                <Box>
                  <span>약관</span>
                  <div>
                    <span
                      onClick={() => setActive(1)}
                      style={{
                        backgroundColor: active === 1 ? "#F14262" : "#F9FAFB",
                        color: active === 1 ? "#ffffff" : "#A0A6B1",
                        cursor: "pointer",
                      }}
                    >
                      이용약관
                    </span>
                    <span
                      onClick={() => setActive(2)}
                      style={{
                        backgroundColor: active === 2 ? "#F14262" : "#F9FAFB",
                        color: active === 2 ? "#ffffff" : "#A0A6B1",
                        cursor: "pointer",
                      }}
                    >
                      개인정보처리방침
                    </span>
                    <span
                      onClick={() => setActive(5)}
                      style={{
                        backgroundColor: active === 5 ? "#F14262" : "#F9FAFB",
                        color: active === 5 ? "#ffffff" : "#A0A6B1",
                        cursor: "pointer",
                      }}
                    >
                      환불정책
                    </span>
                  </div>
                </Box>
                <Box>
                  <span>고객센터</span>
                  <div>
                    <span
                      onClick={() => setActive(6)}
                      style={{
                        backgroundColor: active === 6 ? "#F14262" : "#F9FAFB",
                        color: active === 6 ? "#ffffff" : "#A0A6B1",
                        cursor: "pointer",
                      }}
                    >
                      공지사항
                    </span>
                    <span
                      onClick={() => setActive(3)}
                      style={{
                        backgroundColor: active === 3 ? "#F14262" : "#F9FAFB",
                        color: active === 3 ? "#ffffff" : "#A0A6B1",
                        cursor: "pointer",
                      }}
                    >
                      1:1 문의하기
                    </span>
                  </div>
                </Box>
                <Box>
                  <span onClick={() => {}}>회원관리</span>
                  <div>
                    <span
                      onClick={() => setActive(4)}
                      style={{
                        backgroundColor: active === 4 ? "#F14262" : "#F9FAFB",
                        color: active === 4 ? "#ffffff" : "#A0A6B1",
                        cursor: "pointer",
                      }}
                    >
                      로그아웃
                    </span>
                  </div>
                </Box>
              </Left>
              <Right>{getPage()}</Right>
            </Content2>
          </Contents>
        </Main>
        <MessageListener />
      </Container>
    </MainLayout>
  );
};

const Container = styled.div<{ $zoom: number }>`
  width: 100%;
  display: flex;

  zoom: ${(props) => props.$zoom};
`;

const Main = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  min-width: 1200px;
`;
const Contents = styled.div`
  width: 1200px;
  margin-top: 50px;

  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Content2 = styled.div`
  width: 100%;
  height: 780px;

  display: flex;
  gap: 50px;
`;

const Left = styled.div`
  width: 476px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Right = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  border-radius: 16px;
  background: #ffffff;

  position: relative;
`;

const Box = styled.div`
  width: 100%;
  display: flex;
  padding: 24px 40px;
  box-sizing: border-box;
  flex-direction: column;
  gap: 24px;
  border-radius: 16px;
  background: #ffffff;

  & > span:first-child {
    color: #272f3e;
    font-size: 16px;
    font-weight: 700;
  }

  & > div:last-child {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    & > span {
      width: 100%;
      height: 44px;
      padding: 0px 18px;
      box-sizing: border-box;
      border-radius: 16px;
      background: #f9fafb;

      color: #a0a6b1;
      font-size: 16px;
      font-weight: 500;

      display: flex;
      align-items: center;
    }
  }
`;

export default Setting;
