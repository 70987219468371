import axios from "axios";
import { baseUrl, config } from "./api";

export const getCustomerList = async (
  shopId: string,
  sortColumn: string,
  sort: string,
  offset: number,
  searchType?: string,
  search?: string
) => {
  return await axios.get(
    baseUrl + `/admin/shop/customer`,
    config(undefined, {
      limit: 10,
      offset: offset,
      sortColumn: sortColumn,
      sort: sort,
      shopId: shopId,
      searchType: searchType ? searchType : null,
      search: search ? search : null,
    })
  );
};

export const saveCustomer = async (customerId: string, memo: string) => {
  return await axios.put(
    baseUrl + "/admin/shop/customer",
    { customerId: customerId, memo: memo },
    config()
  );
};

export const saveCustomer2 = async (
  customerId: string,
  customerName: string,
  customerPhoneNumber: string,
  customerBirthday: string,
  customerGender: string
) => {
  return await axios.put(
    baseUrl + "/admin/shop/customer",
    {
      customerId: customerId,
      customerName: customerName,
      customerPhoneNumber: customerPhoneNumber,
      customerBirthday: customerBirthday,
      customerGender: customerGender,
    },
    config()
  );
};

export const saveRegular = async (customerId: string) => {
  return await axios.put(
    baseUrl + "/admin/shop/customer/regular",
    {
      customerId: customerId,
    },
    config()
  );
};

export const getCustomerOne = async (customerId: string) => {
  return await axios.get(
    baseUrl + `/admin/shop/customer/one`,
    config(undefined, {
      customerId: customerId,
    })
  );
};

export const getTickets = async (customerId: string) => {
  return await axios.get(
    baseUrl + `/admin/shop/ticket`,
    config(undefined, {
      customerId: customerId,
    })
  );
};

export const createTicket = async (data: {
  customerId: string;
  name: string;
  balance: number;
  title: string;
  startDate: string;
  endDate: string;
}) => {
  return await axios.post(baseUrl + "/admin/shop/ticket", data, config());
};

export const newTicketHistory = async (data: {
  ticketId: string;
  charge: number;
  title: string;
}) => {
  return await axios.put(
    baseUrl + "/admin/shop/ticket/balance",
    data,
    config()
  );
};

export const saveTicketDate = async (data: {
  ticketId: string;
  startDate: string;
  endDate: string;
  title: string;
}) => {
  return await axios.put(baseUrl + "/admin/shop/ticket/date", data, config());
};
