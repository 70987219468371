import React, { Dispatch, SetStateAction } from "react";
import { styled } from "styled-components";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

type propType = {
  data: number[];
  pageNum?: number;
  setPageNum?: Dispatch<SetStateAction<number>>;
};

const PageNation = ({ data, pageNum = 1, setPageNum }: propType) => {
  const itemsPerPage = 10;
  const maxPageNum = data.length;
  const startIdx = Math.floor((pageNum - 1) / itemsPerPage) * itemsPerPage;
  const endIdx = startIdx + itemsPerPage;
  const currentPageItems = data.slice(startIdx, endIdx);

  return (
    <Container>
      <PageItem
        $active={false}
        onClick={() => {
          if (setPageNum) {
            setPageNum((old) => (old > 1 ? old - 1 : 1));
          }
        }}
      >
        <ChevronLeftIcon />
      </PageItem>
      {currentPageItems.map((item, i) => (
        <PageItem
          key={i}
          $active={item === pageNum}
          onClick={() => {
            if (setPageNum) {
              setPageNum(item);
            }
          }}
        >
          {item}
        </PageItem>
      ))}
      <PageItem
        $active={false}
        onClick={() => {
          if (setPageNum) {
            setPageNum((old) => (old < maxPageNum ? old + 1 : maxPageNum));
          }
        }}
      >
        <ChevronRightIcon />
      </PageItem>
    </Container>
  );
};

const Container = styled.div`
  min-width: 200px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  gap: 5px;

  margin-bottom: 20px;
`;

const PageItem = styled.button<{ $active: boolean }>`
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;

  font-size: 14px;
  font-weight: 400;
  line-height: 22px;

  background-color: ${(props) => (props.$active ? "#FF5D7A" : "transparent")};
  color: ${(props) => (props.$active ? "#ffffff" : "#000000")};

  border-radius: 16px;

  &:hover {
    background-color: ${(props) => (props.$active ? "#FF5D7A" : "#e0e0e0")};
  }
`;

export default PageNation;
