import React from "react";
import { NavigateAction, ToolbarProps, View } from "react-big-calendar";
import { styled } from "styled-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useRecoilState } from "recoil";
import { CalanderActiveDate, CalanderView } from "../../atoms/atom";

const BCToolbar = (props: ToolbarProps) => {
  const [view, setView] = useRecoilState(CalanderView);
  const [activeDate, setActiveDate] = useRecoilState(CalanderActiveDate);

  const { date, label } = props;

  const navigate = (action: NavigateAction) => {
    props.onNavigate(action);
  };
  const navigate2 = (view: View) => {
    props.onView(view);
  };

  return (
    <Container
      className="rbc-toolbar"
      style={
        view === "month"
          ? { justifyContent: "center" }
          : { justifyContent: "end" }
      }
    >
      <span className="rbc-btn-group">
        <button
          type="button"
          onClick={navigate.bind(null, "PREV")}
          style={{
            backgroundColor: "#F4F5F7",
            borderRadius: "50%",
            cursor: "pointer",
          }}
        >
          <ArrowBackIcon style={{ fontSize: "18px", color: "#A0A6B1" }} />
        </button>
        {view === "month" ? (
          <span className="rbc-toolbar-label">{`${date.getFullYear()}년 ${
            date.getMonth() + 1
          }월`}</span>
        ) : view === "week" ? (
          <span className="rbc-toolbar-label">{label}</span>
        ) : (
          <span className="rbc-toolbar-label">{label}</span>
        )}
        <button
          type="button"
          onClick={navigate.bind(null, "NEXT")}
          style={{
            backgroundColor: "#F4F5F7",
            borderRadius: "50%",
            cursor: "pointer",
          }}
        >
          <ArrowForwardIcon style={{ fontSize: "18px", color: "#A0A6B1" }} />
        </button>
      </span>
      <div
        style={{
          display: "flex",
          width: "72px",
          height: "44px",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "16px",
          backgroundColor: "#F9FAFB",
          position: "absolute",
          left: "0px",
          color:
            activeDate.toLocaleDateString() === new Date().toLocaleDateString()
              ? "#F14262"
              : "#A0A6B1",
          fontSize: "16px",
          cursor: "pointer",
        }}
        onClick={() => {
          setActiveDate(new Date());
        }}
      >
        오늘
      </div>
      <ViewBtnWrap>
        <ViewBtn
          type="button"
          onClick={navigate2.bind(null, "month")}
          onMouseDown={() => setView("month")}
          $active={view === "month"}
        >
          월
        </ViewBtn>
        <ViewBtn
          type="button"
          onClick={navigate2.bind(null, "week")}
          onMouseDown={() => setView("week")}
          $active={view === "week"}
        >
          주
        </ViewBtn>
        <ViewBtn
          type="button"
          onClick={navigate2.bind(null, "day")}
          onMouseDown={() => setView("day")}
          $active={view === "day"}
        >
          일
        </ViewBtn>
      </ViewBtnWrap>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 44px;
  justify-content: center;
  position: relative;

  & .rbc-btn-group {
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 12px;
  }

  & .rbc-btn-group > button {
    border: none;
    width: 36px;
    height: 36px;
    padding: 0;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .rbc-toolbar-label {
    color: #272f3e;
    font-size: 24px;
    font-weight: 700;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ViewBtnWrap = styled.span`
  width: 184px;
  height: 44px;
  border-radius: 8px;

  position: absolute;
  left: 100px;

  display: flex;
  overflow: hidden;

  box-sizing: border-box;

  & > button {
    border: none;
    border-radius: 0px;
    width: 71px;
    height: 100%;

    padding: 0;
  }
`;

const ViewBtn = styled.button<{ $active: boolean }>`
  color: ${(props) => (props.$active ? "#F14262" : "#A0A6B1")} !important;
  background-color: #f9fafb !important;
  box-shadow: none;
  border: none !important;
  cursor: pointer;
`;

export default BCToolbar;
