import CardLayout2 from "../../layout/CardLayout2";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { IoTicket } from "react-icons/io5";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { ShopData } from "../../atoms/atom";
import { paymentTicket } from "../../apis/shop";
import { useNavigate } from "react-router-dom";

declare global {
  interface Window {
    IMP: any;
  }
}

const Billing = () => {
  const navigate = useNavigate();
  const shopData = useRecoilValue(ShopData);

  const IMP = window.IMP;
  IMP.init(`imp70703121`);

  const req_pay = () => {
    IMP.request_pay(
      {
        customer_uid: `${shopData.shopUserId}`, // 카드(빌링키)와 1:1로 대응하는 값
        pg: "tosspayments.iamporttest_4",
        pay_method: "card", // 'card'만 지원됩니다.
        merchant_uid: `${shopData.shopUserId}_${new Date().getTime() / 1000}`, // 상점에서 관리하는 주문 번호
        name: "핑프 이용권_" + getProduct(),
        amount: getPrice(), // 결제창에 표시될 금액. 실제 승인이 이뤄지지는 않습니다.
        buyer_name: shopData.name,
        buyer_tel: shopData.phoneNumber,
      },
      function (rsp: any) {
        // callback
        if (rsp.success) {
          // 빌링키 발급 성공
          console.log(rsp);
          const customer_uid = rsp.customer_uid;

          paymentTicket(customer_uid, getPeriod(), getPrice()).then(() => {
            navigate("/signin");
          });
        } else {
          // 빌링키 발급 실패
          console.log(rsp);
        }
      }
    );
  };

  const [selItem, setSelItem] = useState(-1);

  const getPrice = () => {
    switch (selItem) {
      case 0:
        return 600;
      case 1:
        return 550;
      case 2:
        return 450;

      default:
        return 0;
    }
  };

  const getProduct = () => {
    switch (selItem) {
      case 0:
        return "1개월권";
      case 1:
        return "6개월권";
      case 2:
        return "12개월권";

      default:
        return 0;
    }
  };

  const getPeriod = () => {
    switch (selItem) {
      case 0:
        return "1";
      case 1:
        return "6";
      case 2:
        return "12";

      default:
        return "1";
    }
  };

  return (
    <CardLayout2>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "56px 0px",
          gap: "8px",
        }}
      >
        <span style={{ color: "#272F3E", fontWeight: "700", fontSize: "28px" }}>
          이용권 구매
        </span>
        <span style={{ color: "#6C727F", fontWeight: "700", fontSize: "16px" }}>
          정기권을 결제하시고 서비스를 계속 이용하세요.
        </span>
      </div>
      <div
        style={{
          width: "100%",
          height: "412px",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        {[
          { color: "#FD91A5", title: "01개월 권", price: "60,000원", text: "" },
          {
            color: "#FD91A5",
            title: "06개월 권",
            price: "55,000원",
            text: "06개월 약정 금액",
          },
          {
            color: "#62D4D2",
            title: "12개월 권",
            price: "45,000원",
            text: "12개월 약정 금액",
          },
        ].map((item, idx) => (
          <div
            key={idx}
            style={{
              cursor: "pointer",
              width: "240px",
              height: "100%",
              backgroundColor: "#F9FAFB",
              borderRadius: "25px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "24px 0px",
              boxSizing: "border-box",
            }}
            onClick={() => setSelItem(idx)}
          >
            {idx === selItem ? (
              <CheckCircleIcon
                style={{
                  fontSize: "40px",
                  color: item.color,
                  marginBottom: "24px",
                  padding: "4px",
                }}
              />
            ) : (
              <CheckCircleOutlineIcon
                style={{
                  fontSize: "40px",
                  color: "#A0A6B1",
                  marginBottom: "24px",
                  padding: "4px",
                }}
              />
            )}
            <IoTicket
              size={"60px"}
              color={item.color}
              style={{ marginBottom: "32px" }}
            />
            <span
              style={{
                fontSize: "28px",
                fontWeight: "700",
                color: "#272F3E",
                marginBottom: "24px",
              }}
            >
              {item.title}
            </span>
            <span
              style={{
                fontSize: "36px",
                fontWeight: "700",
                color:
                  idx === 0 ? "#272F3E" : idx === 1 ? "#FD91A5" : "#F14262",
                marginBottom: "12px",
              }}
            >
              {item.price}
            </span>
            <span
              style={{
                fontSize: "20px",
                fontWeight: "700",
                color: "#A0A6B1",
                marginBottom: "36px",
                textDecorationLine: "line-through",
              }}
            >
              60,000원
            </span>
            <span
              style={{
                fontSize: "16px",
                fontWeight: "700",
                color: "#272F3E",
              }}
            >
              {item.text}
            </span>
          </div>
        ))}
      </div>
      <div
        style={{
          width: "88px",
          height: "44px",
          backgroundColor: "#F14262",
          borderRadius: "8px",
          color: "#ffffff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          right: "40px",
          bottom: "40px",
          cursor: "pointer",
        }}
        onClick={async () => {
          if (selItem === -1) return;

          req_pay();
        }}
      >
        결제하기
      </div>
    </CardLayout2>
  );
};

export default Billing;
