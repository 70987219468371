import GroupChannelHandler from "@sendbird/uikit-react/handlers/GroupChannelHandler";
import sendbirdSelectors from "@sendbird/uikit-react/sendbirdSelectors";
import useSendbirdStateContext from "@sendbird/uikit-react/useSendbirdStateContext";
import React, { useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import { UnReadMessageCount } from "../../atoms/atom";

const MessageListener = () => {
  const globalStore = useSendbirdStateContext();
  const sdkInstance = sendbirdSelectors.getSdk(globalStore);

  const audioRef = useRef<HTMLAudioElement | null>(null);

  const [, setUnReadCount] = useRecoilState(UnReadMessageCount);

  const audioPlay = () => {
    audioRef.current!.play().catch((e) => {
      console.log(e);
    });
  };

  useEffect(() => {
    if (sdkInstance.appId) {
      sdkInstance.groupChannel.getTotalUnreadMessageCount().then((res) => {
        setUnReadCount(res);
      });

      sdkInstance.groupChannel.addGroupChannelHandler(
        "123123",
        new GroupChannelHandler({
          onMessageReceived(channel, message) {
            sdkInstance.groupChannel
              .getTotalUnreadMessageCount()
              .then((res) => {
                setUnReadCount(res);
              });

            const temp_channel = channel as any;
            const temp_message = message as any;

            const title = temp_channel.lastMessage.sender.nickname;
            new Notification(title, {
              body: temp_message.message,
            });

            audioPlay();
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdkInstance]);

  return (
    <>
      <audio ref={audioRef}>
        <source src="./notiBellSound.mp3" />
      </audio>
    </>
  );
};

export default MessageListener;
