import { styled } from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import { RegisterDataAtom, registerStepAtom } from "../../atoms/atom";
import InputBox from "../utility/InputBox";
import { useState } from "react";
import MessageModal from "../utility/MessageModal";
import { OtpCertification, getOTP, getTempToken } from "../../apis/auth";

const RegisterStep3 = () => {
  const [phone, setPhone] = useState("");
  const [auth, setAuth] = useState(false);
  const [authNum, setAuthNum] = useState("");
  const setStep = useSetRecoilState(registerStepAtom);
  const [data, setData] = useRecoilState(RegisterDataAtom);

  const [authWait, setAuthWait] = useState(false);

  const [showFailMessage, setShowFailMessage] = useState(false);

  const [timer, setTimeer] = useState(180);

  const [timerId, setTimerId] = useState<NodeJS.Timer>();

  const AuthWaitHandler = () => {
    getTempToken()
      .then((res) => {
        setData((old) => ({
          email: old.email,
          passwd: old.passwd,
          name: old.name,
          phone: old.phone,
          isAdmin: old.isAdmin,
          tos: old.tos,
          token: res.data.tempToken,
          shopname: old.shopname,
          address: old.address,
        }));

        getOTP({ phone: phone, token: res.data.tempToken }).then((res) => {
          setAuthWait(true);
          const id = setInterval(() => {
            if (timer !== 0) {
              setTimeer((prev) => prev - 1);
            } else {
              clearInterval(timerId);
            }
          }, 1000);

          setTimerId(id);
        });
      })
      .catch((e) => console.log(e));
  };

  const AuthHandler = () => {
    OtpCertification({ number: authNum, token: data.token })
      .then(() => {
        setAuth(true);
        clearInterval(timerId);

        setData((old) => ({
          email: old.email,
          passwd: old.passwd,
          name: old.name,
          phone: phone,
          isAdmin: old.isAdmin,
          tos: old.tos,
          token: old.token,
          shopname: old.shopname,
          address: old.address,
        }));
      })
      .catch((e) => {
        setShowFailMessage(true);
        setTimeout(() => setShowFailMessage(false), 1000);
      });
  };

  return (
    <>
      <ResultContainer>
        <InputContainer>
          <Label>휴대폰번호</Label>
          <PhoneAuth>
            <InputBox
              placeholder="휴대폰번호을 입력해주세요."
              type="tel"
              width={184}
              setValue={setPhone}
              value={phone}
              disabled={authWait}
            />
            <AuthBtn
              disabled={!phone || authNum ? true : false}
              onClick={() => AuthWaitHandler()}
            >
              {authWait ? "인증번호 재전송" : "인증번호 전송"}
            </AuthBtn>
          </PhoneAuth>
        </InputContainer>
        {authWait ? (
          <>
            <InputContainer2>
              <PhoneAuth>
                <InputBox
                  placeholder="인증번호를 입력해주세요."
                  type="text"
                  width={184}
                  setValue={setAuthNum}
                  value={authNum}
                  disabled={auth}
                />
                <AuthBtn
                  disabled={authNum.length !== 4 || auth}
                  onClick={() => AuthHandler()}
                >
                  인증번호 확인
                </AuthBtn>
              </PhoneAuth>
            </InputContainer2>
            {auth ? (
              <Timer>
                {"0" + parseInt((timer / 60).toString())}:
                {parseInt((timer % 60).toString()) < 10
                  ? "0" + parseInt((timer % 60).toString())
                  : parseInt((timer % 60).toString())}
              </Timer>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </ResultContainer>
      <OkBtn disabled={!auth ? true : false} onClick={() => setStep(4)}>
        다음
      </OkBtn>
      <MessageModal
        showFailMessage={showFailMessage}
        text="입력하신 인증번호가 일치하지 않습니다."
      />
    </>
  );
};

const InputContainer = styled.div`
  width: 300px;
  height: 82px;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const InputContainer2 = styled.div`
  width: 300px;
  height: 44px;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const ResultContainer = styled.div`
  width: 300px;
  height: 300px;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 20px;
  box-sizing: border-box;
`;

const Label = styled.span`
  color: #a0a6b1;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.2px;
  font-weight: 700;
`;

const OkBtn = styled.button<{ disabled: boolean }>`
  border: none;
  width: 300px;
  height: 44px;
  border-radius: 8px;
  color: #f9fafb;
  background-color: ${(props) => (!props.disabled ? "#ff5d7a" : "#d3d6db")};

  font-family: "Noto Sans KR", sans-serif;

  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: -0.2px;
`;

const AuthBtn = styled.button<{ disabled: boolean }>`
  border: none;
  width: 104px;
  height: 44px;
  border-radius: 8px;
  color: #f9fafb;
  background-color: ${(props) => (!props.disabled ? "#ff5d7a" : "#d3d6db")};

  font-family: "Noto Sans KR", sans-serif;

  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
`;

const PhoneAuth = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Timer = styled.div`
  color: #ff5d7a;

  font-size: 12px;
  line-height: 18px;
  font-weight: 700;

  width: 300px;

  padding-left: 10px;
  box-sizing: border-box;

  margin-top: -10px;
`;

export default RegisterStep3;
