import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import CardLayout from "../../layout/CardLayout";

const SecondPwSucces = () => {
  const navigate = useNavigate();

  return (
    <CardLayout text="">
      <Text>2차 비밀번호 설정이 완료 되었습니다.</Text>
      <Image src="image/PasswordCode.png" />
      <NotiMessage>
        2차 비밀번호는 <RedStrong>계정 대표자의 인증 키</RedStrong>로{" "}
        <RedStrong>비밀번호가 노출되지 않게 주의</RedStrong>해주세요.
      </NotiMessage>
      <LoginBtn onClick={() => navigate("/signin")}>로그인</LoginBtn>
      <Etc>
        <CsText>고객센터 &nbsp;&nbsp;&nbsp;0000-0000</CsText>
      </Etc>
    </CardLayout>
  );
};

const Text = styled.span`
  color: #62d4d2;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.2px;
  font-weight: 700;

  display: flex;
  width: 180px;
  text-align: center;
  position: relative;
  top: -60px;
`;

const Image = styled.img`
  margin-top: -40px;
`;

const NotiMessage = styled.p`
  font-size: 12px;
  font-weight: 700;

  width: 200px;
  line-height: 140%;

  margin-top: -20px;
`;

const RedStrong = styled.strong`
  color: #f14262;
`;

const LoginBtn = styled.button`
  width: 300px;
  height: 44px;
  background-color: #ff5d7a;
  border: none;
  border-radius: 8px;

  color: #f9fafb;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: -0.2px;
`;

const Etc = styled.div`
  width: 300px;
  height: 14px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const CsText = styled.span`
  color: #fca2b3;
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
`;

export default SecondPwSucces;
