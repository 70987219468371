import React, { useState } from "react";
import CountChart from "../../components/utility/CountChart";

import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import PageNation from "../../components/customer/PageNation";
import styled from "styled-components";

const TotalSales = () => {
  const [activeBtn, setActiveBtn] = useState(1);

  const [year, setYear] = useState(2023);

  return (
    <Container>
      <div>
        <Content3Header $ActiveBtn={activeBtn}>
          <div className="dataBox">
            <div>
              <ArrowCircleLeftIcon
                sx={{
                  color: "#F4F5F7",
                  backgroundColor: "#A0A6B1",
                  borderRadius: "50%",
                  border: "none",
                }}
                onClick={() => setYear((old) => old - 1)}
              />
              <h3>{year}년</h3>
              <ArrowCircleRightIcon
                sx={{
                  color: "#F4F5F7",
                  backgroundColor: "#A0A6B1",
                  borderRadius: "50%",
                  border: "none",
                }}
                onClick={() => setYear((old) => old + 1)}
              />
            </div>
            <div>
              <strong>₩1,320,000</strong>
              <span>지난달 대비 17%</span>
            </div>
          </div>
          <div className="btn_wrap">
            <button onClick={() => setActiveBtn(1)}>일 별</button>
            <button onClick={() => setActiveBtn(2)}>주 별</button>
            <button onClick={() => setActiveBtn(3)}>월 별</button>
          </div>
        </Content3Header>
        <CountChart dataType={activeBtn} width={1024} height={240} />
      </div>
      <div>
        <div>
          <div className="tableHeader">
            <span>매출등록날짜</span>
            <span>고객정보</span>
            <span>시술내용</span>
            <span>총 금액</span>
            <span>결제방법</span>
            <span>예약금</span>
            <span>담당자</span>
          </div>
          {[1, 2, 3, 4, 5].map(() => (
            <div className="tableItem">
              <span>0000-00-00</span>
              <span>박새연 010-0000-0000</span>
              <span>시술내용</span>
              <span>0,000,000원</span>
              <span>카드결제</span>
              <span>
                <div>환불완료</div>
              </span>
              <span>담당자1</span>
            </div>
          ))}
        </div>
        <PageNation data={[1, 2, 3, 4, 5]} />
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 10px;

  & > div:first-child {
    background-color: #ffffff;
    width: 100%;
    height: 340px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border-radius: 8px;
  }

  & > div:last-child {
    width: 100%;
    height: 340px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > div:first-child {
      width: 100%;
      height: 280px;

      background-color: #ffffff;
      border-radius: 8px;

      & .tableHeader {
        width: 100%;
        height: 44px;
        border-bottom: 2px solid #f9fafb;
        display: flex;

        & > span {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: -0.2px;
        }

        & > span:nth-child(1) {
          width: 180px;
        }
        & > span:nth-child(2) {
          width: 288px;
        }
        & > span:nth-child(3) {
          width: 152px;
        }
        & > span:nth-child(4) {
          width: 140px;
        }
        & > span:nth-child(5) {
          width: 140px;
        }
        & > span:nth-child(6) {
          width: 140px;
        }
        & > span:nth-child(7) {
          width: 160px;
        }
      }

      & .tableItem {
        width: 100%;
        height: 44px;
        border-bottom: 2px solid #f9fafb;
        display: flex;

        & > span {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }

        & > span:nth-child(1) {
          width: 180px;
        }
        & > span:nth-child(2) {
          width: 288px;
        }
        & > span:nth-child(3) {
          width: 152px;
        }
        & > span:nth-child(4) {
          width: 140px;
        }
        & > span:nth-child(5) {
          width: 140px;
        }
        & > span:nth-child(6) {
          width: 140px;

          & > div {
            width: 60px;
            height: 24px;

            border-radius: 4px;
            background-color: #ff5d7a;
            color: #ffffff;
            font-size: 12px;

            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        & > span:nth-child(7) {
          width: 160px;
        }
      }
    }
  }
`;

const Content3Header = styled.div<{ $ActiveBtn: number }>`
  display: flex;
  height: 84px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  & .dataBox {
    width: 200px;
    height: 84px;

    & > div:first-child {
      width: 100%;
      height: 42px;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      & > h3 {
        font-size: 20px;
      }
    }
    & > div:last-child {
      width: 100%;
      height: 42px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      & > strong {
        color: #ff5d7a;
        font-size: 20px;
      }
      & > span {
        color: #10b981;
        font-size: 12px;
      }
    }
  }

  & .btn_wrap {
    position: absolute;
    right: 20px;
    top: 20px;

    width: 144px;
    height: 24px;

    border: 1px solid #f4f5f7;
    border-radius: 4px;
    overflow: hidden;

    & > button {
      width: 48px;
      height: 24px;
      border: none;
      background-color: #ffffff;
      color: #4d5562;
      font-size: 10px;
      font-weight: 500;
      line-height: 14px;
    }
    & :nth-child(2) {
      border-left: 1px solid #f4f5f7;
      border-right: 1px solid #f4f5f7;
    }

    & :nth-child(${(props) => props.$ActiveBtn}) {
      background-color: #f9e8ec;
      color: #fd91a5;
    }
  }
`;

export default TotalSales;
