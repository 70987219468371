import { useEffect, useState } from "react";
import { styled } from "styled-components";
import CheckIcon from "@mui/icons-material/Check";
import MoneyIcon from "@mui/icons-material/Money";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PageNation from "./PageNation";
import SalesHistoryModal from "../utility/SalesHistoryModal";
import { Setting2 } from "iconsax-react";
import InputBox from "../utility/InputBox";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import {
  createTicket,
  getTickets,
  saveCustomer,
  saveCustomer2,
  saveRegular,
} from "../../apis/customer";
import { getSalesCumstomerList } from "../../apis/sales";
import Ticket from "./Ticket";
import { color_midnight, color_rose } from "../../util/color";

const SideBar = ({ data, saveCallback }: any) => {
  const [tapNum, setTapNum] = useState(0); // 0 : 고객정보 , 1 : 정액권 , 2: 방문 내역
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);

  const [name, setName] = useState(data.customerName);
  const [phone, setPhone] = useState(data.customerPhoneNumber);
  const [birth, setBirth] = useState(data.birthday);

  const [gender, setGender] = useState(data.gender);

  const [memo, setMemo] = useState(data.memo ? data.memo : "");
  const [isMemoEdit, setIsMemoEdit] = useState(false);

  const [pageNum, setPageNum] = useState(1);

  const [salesList, setSalesList] = useState<any[]>([]);
  const [selData, setSelData] = useState<any>();

  const [isTicketOpen, setIsTicketOpen] = useState(-1);

  const [isAddTicketModalOpen, setIsAddTicketModalOpen] = useState(false); // 정액권 추가 모달 상태
  // 정액권 추가 관련 상태
  const [ticketName, setTicketName] = useState("");
  const [ticketAmount, setTicketAmount] = useState("");
  const [ticketValidity, setTicketValidity] = useState("");

  const [ticketList, setTicketList] = useState<any[]>([]);

  const [ticketUpatedCounter, setTicketUpdateCounter] = useState(0);

  // 정액권 추가 모달 열기
  const openAddTicketModal = () => {
    setIsAddTicketModalOpen(true);
  };

  // 정액권 추가 모달 닫기
  const closeAddTicketModal = () => {
    setIsAddTicketModalOpen(false);
    setTicketName("");
    setTicketAmount("");
    setTicketValidity("");
  };

  // 정액권 추가 처리
  const handleAddTicket = () => {
    console.log("정액권 이름:", ticketName);
    console.log("금액:", ticketAmount);
    console.log("유효기간:", ticketValidity);

    createTicket({
      customerId: data.customerId,
      name: ticketName,
      balance: parseInt(ticketAmount),
      title: "초기 충전 금액",
      startDate: new Date().toISOString(),
      endDate: new Date(ticketValidity).toISOString(),
    })
      .then((res) => {
        setTicketUpdateCounter((old) => old + 1);
      })
      .finally(() => {
        closeAddTicketModal();
      });
    // 추가적인 처리 로직 필요 (예: API 호출)
  };

  useEffect(() => {
    setMemo(data.memo ? data.memo : "");

    setName(data.customerName);
    setPhone(data.customerPhoneNumber);
    setBirth(data.birthday);
    setGender(data.gender);

    setIsEdit(false);

    getSalesCumstomerList(pageNum, data.customerId).then((res) => {
      console.log(res.data);
      setSalesList(res.data.data.salesList);
    });
  }, [data, pageNum]);

  useEffect(() => {
    getTickets(data.customerId).then((res) => {
      setTicketList(res.data.data);
    });
  }, [data, ticketUpatedCounter]);

  return (
    <Container>
      <TapBtnBox>
        <span
          style={{
            color: tapNum === 0 ? "#F14262" : "#A0A6B1",
            cursor: "pointer",
          }}
          onClick={() => setTapNum(0)}
        >
          고객정보
        </span>
        <span
          style={{
            color: tapNum === 1 ? "#F14262" : "#A0A6B1",
            cursor: "pointer",
          }}
          onClick={() => setTapNum(1)}
        >
          정액권
        </span>
        <span
          style={{
            color: tapNum === 2 ? "#F14262" : "#A0A6B1",
            cursor: "pointer",
          }}
          onClick={() => setTapNum(2)}
        >
          방문내역
        </span>
      </TapBtnBox>
      {tapNum === 0 &&
        (isEdit ? (
          <>
            <EditBox>
              <div>
                <span>고객 정보 수정</span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    saveCustomer2(
                      data.customerId,
                      name,
                      phone,
                      birth,
                      gender
                    ).then((res) => {
                      setIsEdit(false);
                      saveCallback();
                    });
                  }}
                >
                  저장
                </span>
              </div>
              <InfoEditBox>
                <Title>고객명</Title>
                <InputBox
                  placeholder={""}
                  type={"text"}
                  width={300}
                  setValue={setName}
                  value={name}
                />
              </InfoEditBox>
              <InfoEditBox>
                <Title>연락처</Title>
                <InputBox
                  placeholder={""}
                  type={"phone"}
                  width={300}
                  setValue={setPhone}
                  value={phone}
                />
              </InfoEditBox>
              <InfoEditBox>
                <Title>생년월일</Title>
                <InputBox
                  placeholder={""}
                  type={"text"}
                  width={300}
                  setValue={setBirth}
                  value={birth}
                />
              </InfoEditBox>
              <InfoEditBox>
                <Title>성별</Title>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    gap: "24px",
                  }}
                  value={gender}
                  onChange={(value) => setGender(value.target.value)}
                >
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="여"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="남"
                  />
                </RadioGroup>
              </InfoEditBox>
            </EditBox>
          </>
        ) : (
          <>
            <NameBox>
              <div>
                <div>
                  <span>{data.customerNumber}</span>
                  <span style={{ width: "80px" }}>{data.customerName}</span>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      marginLeft: "16px",
                    }}
                  >
                    <div
                      style={{
                        width: "16px",
                        height: "16px",
                        border: data.isRegular
                          ? "2px solid #FF5D7A"
                          : "2px solid #A0A6B1",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px",
                        cursor: "pointer",
                        backgroundColor: data.isRegular ? "#FF5D7A" : "#ffffff",
                      }}
                      onClick={() => {
                        //console.log(data);
                        saveRegular(data.customerId).then((res) => {
                          saveCallback();
                        });
                      }}
                    >
                      <CheckIcon
                        style={{
                          fontSize: "14px",
                          color: data.isRegular ? "#ffffff" : "#A0A6B1",
                          fontWeight: "900",
                        }}
                      />
                    </div>
                    단골 설정
                  </span>
                </div>
                <span>{data.customerPhoneNumber}</span>
              </div>
              <Setting2
                size="24"
                color="#A0A6B1"
                variant="Bold"
                style={{
                  position: "absolute",
                  right: "24px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (!data.userId) {
                    setIsEdit(true);
                  } else {
                    window.alert("앱등록 사용자는 수정이 불가능합니다.");
                  }
                }}
              />
            </NameBox>
            <InfoBox>
              <div>
                <div>
                  <CheckIcon style={{ fontSize: "24px", color: "#F14262" }} />
                </div>
                <div>
                  <span>{data.visitCount}</span>
                  <span>방문횟수</span>
                </div>
              </div>
              <div>
                <div>
                  <MoneyIcon style={{ fontSize: "24px", color: "#F14262" }} />
                </div>
                <div>
                  <span>
                    {data.totalPrice
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                  <span>총매출액</span>
                </div>
              </div>
              <div>
                <div>
                  <AccessTimeIcon
                    style={{ fontSize: "24px", color: "#F14262" }}
                  />
                </div>
                <div>
                  <span>
                    {data.lastDate
                      ? new Date(data.lastDate)
                          .toLocaleDateString("ko-KR", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          })
                          .slice(0, -1)
                      : "-"}
                  </span>
                  <span>최근방문일</span>
                </div>
              </div>
              <div>
                <div>
                  <AccessTimeIcon
                    style={{ fontSize: "24px", color: "#F14262" }}
                  />
                </div>
                <div>
                  <span>
                    {new Date(data.createdAt)
                      .toLocaleDateString("ko-KR")
                      .slice(0, -1)}
                  </span>
                  <span>고객등록일자</span>
                </div>
              </div>
            </InfoBox>
            <InfoBox2>
              <span>생년월일 : {data.birthday}</span>
              <span>성별 : {data.gender === "male" ? "남" : "여"} </span>
            </InfoBox2>
            <LastHistory>
              <span>최근 시술 내역</span>
              {data.lastMenu !== "" ? (
                <div>
                  <span>
                    방문일 :{" "}
                    {new Date(data.lastDate)
                      .toLocaleDateString("ko-KR", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })
                      .slice(0, -1)}
                  </span>
                  <span>담당자 : {data.lastManager}</span>
                  <span>시술명 : {data.lastMenu}</span>
                </div>
              ) : (
                <div>
                  <span>없음</span>
                </div>
              )}
            </LastHistory>
            <MemoBox>
              <div>
                <span>메모</span>
                <span
                  onClick={() => {
                    if (isMemoEdit) {
                      saveCustomer(data.customerId, memo).then((res) => {
                        setIsMemoEdit(false);
                        return;
                      });
                    }

                    setIsMemoEdit(true);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  edit
                </span>
              </div>
              {isMemoEdit ? (
                <textarea
                  value={memo}
                  onChange={(event) => setMemo(event.target.value)}
                  style={{ width: "90%", height: "80%" }}
                />
              ) : (
                <div>{memo ? memo : "등록된 내용이 없습니다."}</div>
              )}
            </MemoBox>
          </>
        ))}
      {tapNum === 1 && (
        <div style={{ width: "100%", height: "100%" }}>
          {isTicketOpen === -1 && (
            <div
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "#F9FAFB",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "18px 24px",
                boxSizing: "border-box",
                borderRadius: "10px",
                marginBottom: "24px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "4px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ fontWeight: "900", fontSize: "16px" }}>
                  정액권 잔여 금액
                </span>
                <div
                  onClick={openAddTicketModal}
                  style={{
                    width: "95px",
                    height: "36px",
                    backgroundColor: color_midnight.midnight5,
                    borderRadius: "18px",
                    color: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                >
                  정액권 추가
                </div>
              </div>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: color_rose.rose8,
                  fontWeight: "700",
                  fontSize: "20px",
                }}
              >
                {ticketList.length === 0
                  ? "0"
                  : ticketList
                      .map((e) => e.balance)
                      .reduce((sum, e) => (sum += e))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                원
              </span>
            </div>
          )}
          <TicketBox isTicketOpen={isTicketOpen !== -1}>
            {ticketList.length === 0 && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "100px",
                  justifyContent: "center",
                  alignItems: "center",
                  color: color_midnight.midnight3,
                }}
              >
                등록된 정액권이 없습니다.
              </div>
            )}
            {ticketList.map((item) => (
              <Ticket
                callback={() =>
                  setIsTicketOpen((old) => (old === item ? -1 : item))
                }
                updateCallback={() => {
                  setTicketUpdateCounter((old) => old + 1);
                }}
                isShow={item === isTicketOpen || isTicketOpen === -1}
                data={item}
              />
            ))}
          </TicketBox>
        </div>
      )}
      {tapNum === 2 && (
        <>
          <Title>방문내역</Title>
          <HistoryBox>
            {salesList.map((item, i) => (
              <div
                key={i}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSelData(item);
                  setOpen(true);
                }}
              >
                <div>
                  <div>
                    <div></div>
                  </div>
                  <div></div>
                </div>
                <div>
                  <div>
                    <span>
                      {new Date(item.date).toLocaleDateString().slice(0, -1)}
                    </span>
                    <span>{item.menuName}</span>
                  </div>
                  <span>
                    {parseInt(
                      (
                        (new Date().getTime() - new Date(item.date).getTime()) /
                        (24 * 60 * 60 * 1000)
                      ).toString()
                    )}
                    일전
                  </span>
                </div>
              </div>
            ))}
          </HistoryBox>
          <PageNation
            data={[1, 2, 3, 4, 5]}
            pageNum={pageNum}
            setPageNum={setPageNum}
          />
        </>
      )}
      {open && (
        <SalesHistoryModal open={open} setOpen={setOpen} data={selData} />
      )}
      {/* 정액권 추가 모달 */}
      {isAddTicketModalOpen && (
        <ModalOverlay>
          <ModalContent>
            <div
              style={{
                width: "100%",
                height: "56px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#ffffff",
              }}
            >
              <h3 style={{ margin: "0px" }}>정액권 추가</h3>
            </div>
            <div
              style={{
                width: "100%",
                padding: "24px",
                boxSizing: "border-box",
                backgroundColor: color_midnight.lily,
              }}
            >
              <div
                style={{
                  width: "100%",
                  background: "#ffffff",
                  padding: "24px",
                  boxSizing: "border-box",
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  borderRadius: "8px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <span style={{ fontWeight: "700", fontSize: "14px" }}>
                    정액권 이름
                  </span>
                  <InputBox
                    placeholder={"정액권 이름"}
                    type={"text"}
                    width={300}
                    setValue={setTicketName}
                    value={ticketName}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <span style={{ fontWeight: "700", fontSize: "14px" }}>
                    금액
                  </span>
                  <InputBox
                    placeholder={"금액 입력"}
                    type={"money"}
                    width={300}
                    setValue={setTicketAmount}
                    value={ticketAmount}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <span style={{ fontWeight: "700", fontSize: "14px" }}>
                    유효기간
                  </span>
                  <InputBox
                    placeholder={"YYYY-MM-DD"}
                    type={"date"}
                    width={300}
                    setValue={setTicketValidity}
                    value={ticketValidity}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                  paddingTop: "24px",
                }}
              >
                <button
                  onClick={closeAddTicketModal}
                  style={{
                    backgroundColor: color_midnight.midnight2,
                    border: "none",
                    borderRadius: "8px",
                    color: "#000",
                    cursor: "pointer",
                    width: "160px",
                    height: "44px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  취소
                </button>
                <button
                  onClick={handleAddTicket}
                  style={{
                    backgroundColor: color_rose.rose8,
                    border: "none",
                    borderRadius: "8px",
                    color: "#fff",
                    cursor: "pointer",
                    width: "160px",
                    height: "44px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  확인
                </button>
              </div>
            </div>
          </ModalContent>
        </ModalOverlay>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 840px;
  background-color: #ffffff;

  border-radius: 16px;

  display: flex;
  padding: 24px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const TapBtnBox = styled.div`
  display: flex;
  width: 320px;
  box-sizing: border-box;
  padding: 11px 10px;
  justify-content: center;
  align-items: center;
  gap: 40px;
`;

const NameBox = styled.div`
  display: flex;
  width: 100%;
  height: 88px;
  padding: 16px 24px;
  box-sizing: border-box;
  align-items: center;
  gap: 24px;

  border-radius: 16px;
  background: #f9fafb;

  position: relative;
  & > :first-child {
    display: flex;
    flex-direction: column;
    gap: 8px;

    & > :first-child {
      display: flex;
      gap: 8px;
      align-items: center;
      & > :first-child {
        display: flex;
        height: 24px;
        padding: 0px 8px;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        gap: 10px;

        border-radius: 8px;
        background: #f9e8ec;

        color: #f14262;
        font-size: 10px;
        font-weight: 500;
        line-height: 140%;
      }
      & > :last-child {
        color: #000000;
        font-size: 16px;
        font-weight: 700;
        line-height: 140%;
      }
    }
    & > :last-child {
      color: #000000;
      font-size: 16px;
      font-weight: 700;
      line-height: 140%;
    }
  }
`;

const InfoBox = styled.div`
  display: flex;
  width: 100%;
  height: 134px;
  padding: 16px 24px;
  box-sizing: border-box;

  border-radius: 16px;
  background: #f9fafb;

  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  & > div {
    display: flex;
    width: 48%;
    height: 44px;
    align-items: center;
    gap: 10px;

    & > div:first-child {
      width: 40px;
      height: 40px;
      border-radius: 6px;
      background: #f9e8ec;

      display: flex;
      justify-content: center;
      align-items: center;
    }
    & > div:last-child {
      display: flex;
      flex-direction: column;

      & > span:first-child {
        color: #000000;
        font-size: 12px;
        font-weight: 700;
        line-height: 140%;
      }
      & > span:last-child {
        color: #a0a6b1;
        font-size: 10px;
        font-weight: 500;
        line-height: 140%;
      }
    }
  }
`;

const InfoBox2 = styled.div`
  display: flex;
  width: 100%;
  height: 72px;
  padding: 16px 24px;
  box-sizing: border-box;
  flex-direction: column;

  border-radius: 16px;
  background: #f9fafb;

  & > span {
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
  }
`;

const LastHistory = styled.div`
  display: flex;
  width: 100%;
  height: 132px;
  padding: 16px 24px;
  box-sizing: border-box;
  flex-direction: column;
  gap: 12px;

  border-radius: 16px;
  background: #f9fafb;

  & > :first-child {
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    line-height: 140%;
  }
  & > :last-child {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 4px;

    & > span {
      color: #000000;
      font-size: 14px;
      font-weight: 400;
      line-height: 140%;
    }
  }
`;

const MemoBox = styled.div`
  display: flex;
  width: 100%;
  height: 212px;
  padding: 16px 24px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  border-radius: 16px;
  background: #f9fafb;

  & > div:first-child {
    display: flex;
    width: 100%;
    align-items: center;

    justify-content: space-between;

    & > span:first-child {
      color: #000000;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      line-height: 140%;
    }

    & > span:last-child {
      display: flex;
      width: 44px;
      height: 24px;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      background: #e5e7eb;

      color: #272f3e;
      font-size: 10px;
      font-weight: 500;
      line-height: 140%;
    }
  }

  & > div:last-child {
    display: flex;
    width: 100%;
  }
`;

const Title = styled.span`
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
  width: 100%;
`;

const HistoryBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  & > div:hover {
    background-color: #f9e8ec;
    border-radius: 8px;
  }

  & > div {
    width: 100%;
    height: 72px;

    display: flex;
    justify-content: space-between;

    & > div:first-child {
      padding-left: 16px;
      box-sizing: border-box;
      width: 12px;
      height: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;

      & > div:first-child {
        width: 18px;
        height: 18px;
        background-color: #ff4c5122;
        border-radius: 9px;
        margin-top: 12px;
        margin-bottom: 12px;
        display: flex;
        justify-content: center;
        align-items: center;

        & > div {
          width: 12px;
          height: 12px;
          background-color: #ff4c51;
          border-radius: 6px;
        }
      }

      & > div:last-child {
        width: 1px;
        height: 35px;
        background-color: #e5e7eb;
      }
    }
    & > div:last-child {
      width: 292px;
      height: 100%;

      display: flex;
      justify-content: space-between;
      align-items: center;

      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #a0a6b1;

      & > div {
        width: 180px;
        height: 54px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 24px;

        & > span:first-child {
          font-size: 16px;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: -0.2px;
          color: #3a3541de;
        }

        & > span:last-child {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          color: #6c727f;
        }
      }
      & > span {
        margin-right: 21px;
      }
    }
  }
`;

const EditBox = styled.div`
  display: flex;
  width: 100%;
  height: 584px;
  padding: 16px 24px 32px 24px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  border-radius: 16px;
  background: #f9fafb;

  & > div:nth-child(1) {
    display: flex;
    width: 100%;
    height: 40px;
    justify-content: center;
    align-items: center;

    position: relative;

    & > span:first-child {
      color: #272f3e;
      font-size: 16px;
      font-weight: 700;
    }
    & > span:last-child {
      display: flex;
      width: 44px;
      height: 24px;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      background: #ff5d7a;

      position: absolute;
      right: 0px;

      color: #ffffff;
      font-size: 10px;
      font-weight: 500;
    }
  }
`;

const InfoEditBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
`;

const TicketBox = styled.div<{ isTicketOpen: boolean }>`
  width: 100%;
  height: ${(props) => (props.isTicketOpen ? "100%" : "640px")};

  overflow-y: scroll;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #ffffff;
  padding: 0;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Header와 Body의 배경색 차이로 overflow 숨김 */
`;

export default SideBar;
