import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { styled } from "styled-components";
import { ManagerType, SurgeryMenuType, SurgeryOptionType } from "../../types";
import { getMenuList, getOptionList } from "../../apis/menu";
import { RefrashCounter, RefrashCounter2, ShopData } from "../../atoms/atom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { createBook } from "../../apis/book";
import { IoSearch } from "react-icons/io5";
import InputBox from "../utility/InputBox";
import DatePicker from "../utility/DatePicker";
import TimePickers from "../utility/TimePickers";
import Select2 from "../utility/Select2";
import { getCustomerList } from "../../apis/customer";
import { IoMedical } from "react-icons/io5";
import { handleEmptyValue } from "../../util/util";
import MenuSelection from "../utility/MenuSelection";
import { IoMdClose } from "react-icons/io";

type propType = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  managerList: any[];
  callback: () => void;
};

const BookRegisterModal = ({
  open,
  setOpen,
  managerList,
  callback,
}: propType) => {
  const [shopData] = useRecoilState(ShopData);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [amount, setAmount] = useState("");

  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(new Date());

  const [manager, setManager] = useState<ManagerType>();

  const [isSelMenuOpen, setIsSelMenuOpen] = useState(false);
  const [isSelCustomerOpen, setIsSelCustomerOpen] = useState(false);

  const [selCategory, setSelCategory] = useState("메뉴를 선택해 주세요.");

  const [menuAll, setMenuAll] = useState<SurgeryMenuType[]>([]);
  const [optionAll, setOptionAll] = useState<SurgeryOptionType[]>([]);

  const [selMenu, setSelMenu] = useState<any>({ id: "" });
  const [selDesign, setSelDesign] = useState<any>({ id: "" });

  const [selOptionList, setSelOptionList] = useState<any[]>([]);

  const calPrice = () => {
    let menu_price = handleEmptyValue(
      selMenu ? parseInt(selMenu.price !== "" ? selMenu.price : 0) : 0
    );
    let design_price = handleEmptyValue(
      selDesign ? parseInt(selDesign.price !== "" ? selDesign.price : 0) : 0
    );

    if (selMenu && selMenu.eventPrice && selMenu.eventPrice !== "-1") {
      menu_price = parseInt(selMenu.eventPrice);
    }

    if (selDesign && selDesign.eventPrice && selDesign.eventPrice !== "-1") {
      design_price = parseInt(selDesign.eventPrice);
    }

    let total_option_price = 0;

    selOptionList.forEach((e) => {
      if (e.eventPrice === "-1") {
        total_option_price += parseInt(e.price !== "" ? e.price : 0);
      } else {
        total_option_price += parseInt(e.eventPrice !== "" ? e.eventPrice : 0);
      }
    });

    return menu_price + design_price + total_option_price;
  };

  const calDuration = () => {
    let menu_runningTime = handleEmptyValue(
      selMenu
        ? parseInt(selMenu.runningTime !== "" ? selMenu.runningTime : 0)
        : 0
    );
    let design_runningTime = handleEmptyValue(
      selDesign
        ? parseInt(selDesign.runningTime !== "" ? selDesign.runningTime : 0)
        : 0
    );

    let total_option_runningTime = 0;

    selOptionList.forEach((e) => {
      total_option_runningTime += parseInt(e.runningTime);
    });

    return menu_runningTime + design_runningTime + total_option_runningTime;
  };
  const [duration, setDuration] = useState("");

  const setRefrashCount = useSetRecoilState(RefrashCounter);
  const setRefrashCount2 = useSetRecoilState(RefrashCounter2);

  const [searchText, setSearchText] = useState("");
  const [customerList, setCustomerList] = useState<any[]>([]);
  const [userCount, setUserCount] = useState(0);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [memo, setMemo] = useState("");

  const handleInput = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // 높이를 초기화하여 스크롤 높이를 정확히 계산
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // 내용에 맞게 높이 조정
    }
  };

  useEffect(() => {
    handleInput(); // 초기화 (예: textarea에 기본값이 있을 때)
  }, []);

  useEffect(() => {
    getMenuList(shopData.id).then((res) => {
      setMenuAll(res.data.data.menuList);
    });

    getOptionList(shopData.id).then((res) => {
      setOptionAll(res.data.data.optionList);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopData]);

  useEffect(() => {
    setAmount(calPrice().toString());
    setDuration(calDuration().toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selMenu, selOptionList, selDesign]);

  useEffect(() => {
    setCustomerList([]);

    const searchType = searchText
      ? /\d/.test(searchText)
        ? "phone"
        : "name"
      : "";

    getCustomerList(shopData.id, "regular", "ASC", 1, searchType, searchText)
      .then((res) => {
        setUserCount(res.data.data.customerCount);
        setCustomerList(res.data.data.customerList);
      })
      .catch((e) => console.log(e));
  }, [searchText, shopData]);

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isSelMenuOpen || isSelCustomerOpen ? "1280px" : "680px",
            height: "880px",
            borderRadius: "16px",
            p: 4,
            boxShadow: "4px 4px 4px 0px #00000040",
            boxSizing: "border-box",
            backgroundColor: "#F9FAFB",
            zoom: 0.9,
            padding: 0,
            overflow: "hidden",
            display: "flex",
          }}
        >
          <Container>
            <div
              style={{
                width: "100%",
                maxWidth: "680px",
                height: "90px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#ffffff",
                position: "relative",
              }}
            >
              <h2 style={{ fontSize: "20px", fontWeight: "700" }}>예약 추가</h2>
              <IoMdClose
                style={{
                  position: "absolute",
                  right: "32px",
                  fontSize: "32px",
                  cursor: "pointer",
                }}
                onClick={() => setOpen(false)}
              />
            </div>

            <div
              style={{
                overflow: "auto",
                height: "766px",
                display: "flex",
                justifyContent: "center",
                paddingTop: "24px",
              }}
            >
              <div style={{ width: "608px" }}>
                <div
                  style={{
                    width: "608px",
                    backgroundColor: "#ffffff",
                    paddingLeft: "30px",
                    padding: "16px 30px",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    boxSizing: "border-box",
                    borderRadius: "16px",
                    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                    gap: "12px",
                    marginBottom: "24px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "44px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#F9E8EC",
                      borderRadius: "10px",
                      color: "#FD7F96",
                      cursor: "pointer",
                      gap: "4px",
                    }}
                    onClick={() => {
                      setIsSelMenuOpen(false);
                      setIsSelCustomerOpen((old) => !old);
                    }}
                  >
                    <IoSearch />
                    고객 검색
                  </div>
                  <div style={{ display: "flex", gap: "20px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Title>고객명</Title>
                      <InputBox
                        placeholder={"고객명"}
                        type={"text"}
                        width={132}
                        setValue={setName}
                        value={name}
                      />
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Title>휴대폰 번호</Title>
                      <InputBox
                        placeholder={"휴대폰 번호"}
                        type={"text"}
                        width={226}
                        setValue={setPhone}
                        value={phone}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "608px",
                    backgroundColor: "#ffffff",
                    paddingLeft: "30px",
                    padding: "16px 30px",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    boxSizing: "border-box",
                    borderRadius: "16px",
                    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                    gap: "12px",
                    marginBottom: "24px",
                  }}
                >
                  <Line>
                    <Title>시술 일정</Title>
                    <div
                      style={{
                        width: "84%",
                        height: "44px",
                        borderRadius: "8px",
                        display: "flex",
                        gap: "16px",
                        alignItems: "center",
                      }}
                    >
                      <DatePicker value={date} setValue={setDate} />
                      <TimePickers value={time} setValue={setTime} />
                    </div>
                  </Line>
                  <div
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h2
                      style={{
                        fontWeight: "700",
                        height: "128px",
                        paddingTop: "24px",
                        boxSizing: "border-box",
                      }}
                    >
                      시술 내용
                    </h2>
                    <div
                      style={{
                        width: "84%",
                        height: "128px",
                        backgroundColor: "#F4F5F7",
                        borderRadius: "8px",
                        boxSizing: "border-box",
                        padding: "14px",
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setIsSelCustomerOpen(false);
                        setIsSelMenuOpen((old) => !old);
                      }}
                      className="menuSelBox"
                    >
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            boxSizing: "border-box",
                            padding: "10px",
                          }}
                        >
                          {selCategory && (
                            <span
                              style={{
                                fontSize: 16,
                                fontWeight: 700,
                                color: "#000000",
                              }}
                            >
                              {`(${selCategory})`}
                            </span>
                          )}
                          <span>- {selMenu.name}</span>
                          {selDesign.id !== "" && (
                            <span>- {selDesign.name}</span>
                          )}
                          {selOptionList.length > 0 && (
                            <span>
                              - {selOptionList.map((e) => e.name).join(",")}
                            </span>
                          )}
                        </div>
                      </>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Title>담당자</Title>
                    <div
                      style={{
                        width: "84%",
                        height: "44px",
                        borderRadius: "8px",
                        display: "flex",
                        gap: "16px",
                      }}
                    >
                      <Select2
                        option={managerList}
                        setResult={setManager}
                        defaultValue="담당자"
                      />
                    </div>
                  </div>
                  <Line>
                    <Title style={{ fontWeight: "700" }}>시술 금액</Title>
                    <InputBox
                      placeholder={"시술 금액"}
                      type={"money"}
                      width={180}
                      setValue={setAmount}
                      value={amount}
                    />
                  </Line>
                  <Line>
                    <Title style={{ fontWeight: "700" }}>소요 시간</Title>
                    <InputBox
                      placeholder={"소요시간(분 단위 )"}
                      type={"text"}
                      width={180}
                      setValue={setDuration}
                      value={duration}
                    />
                  </Line>
                </div>
                <div
                  style={{
                    width: "608px",
                    backgroundColor: "#ffffff",
                    paddingLeft: "30px",
                    padding: "16px 30px",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    boxSizing: "border-box",
                    borderRadius: "16px",
                    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                    gap: "12px",
                    marginBottom: "24px",
                  }}
                >
                  <Line style={{ height: "100%" }}>
                    <Title>시술 메모</Title>
                    <div
                      style={{
                        display: "flex",
                        gap: "12px",
                        color: "#000000",
                        fontSize: "16px",
                      }}
                    >
                      <span
                        style={{
                          width: "462px",
                          minHeight: "100px",
                          backgroundColor: "#F4F5F7",
                          boxSizing: "border-box",
                          padding: "16px 16px",
                          borderRadius: "10px",
                        }}
                      >
                        <textarea
                          ref={textareaRef}
                          style={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: "#F4F5F7",
                            border: "none",
                            outline: "none",
                            resize: "none",
                          }}
                          placeholder="메모"
                          onInput={handleInput}
                          value={memo}
                          onChange={(event) => {
                            setMemo(event.target.value);
                          }}
                        />
                      </span>
                    </div>
                  </Line>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingBottom: "32px",
                  }}
                >
                  <span
                    style={{
                      width: "184px",
                      height: "44px",
                      borderRadius: "8px",
                      backgroundColor: "#F14262",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#ffffff",
                      fontSize: "14px",
                    }}
                    onClick={() => {
                      if (!name && !phone) {
                        window.alert("고객정보를 입력했는지 확인해주세요.");
                        return;
                      }

                      if (selMenu.id === "") {
                        window.alert("메뉴를 선택했는지 확인해주세요.");
                        return;
                      }

                      if (!manager) {
                        window.alert("담당자를 선택했는지 확인해주세요.");
                        return;
                      }

                      createBook({
                        customerName: name,
                        customerPhoneNumber: phone,
                        date: new Date(
                          date.getFullYear(),
                          date.getMonth(),
                          date.getDate(),
                          time.getHours(),
                          time.getMinutes()
                        ).toISOString(),
                        duration: duration,
                        menuName: selMenu.name,
                        designName: selDesign.id !== "" ? selDesign.name : "",
                        optionName: selOptionList.map((e) => e.name),
                        managerId: manager!.id,
                        managerName: manager!.name,
                        totalPrice: amount,
                        bookState: "bookComplete",
                        memo: memo,
                      })
                        .then((res) => {
                          setRefrashCount((old) => old++);
                          setRefrashCount2((old) => old++);

                          setOpen(false);
                          callback();
                        })
                        .catch((e) => {
                          console.log(e);
                        });
                    }}
                  >
                    등록하기
                  </span>
                </div>
              </div>
            </div>
          </Container>
          {isSelMenuOpen && (
            <MenuSelection
              selMenu={selMenu}
              setSelMenu={setSelMenu}
              selDesign={selDesign}
              setSelDesign={setSelDesign}
              selOptionList={selOptionList}
              setSelOptionList={setSelOptionList}
              selCategory={selCategory}
              setSelCategory={setSelCategory}
            />
          )}
          {isSelCustomerOpen && (
            <div
              style={{
                width: "600px",
                height: "100%",
                paddingRight: "3%",
                paddingLeft: "3%",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "600px",
                  height: "800px",
                  backgroundColor: "#ffffff",
                  border: "1px solid #cccccc",
                  borderRadius: "8px",
                  boxSizing: "border-box",
                  padding: "24px",
                  gap: "16px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#F4F5F7",
                    width: "100%",
                    height: "44px",
                    borderRadius: "8px",
                    boxSizing: "border-box",
                    padding: "0px 10px",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <input
                    type="text"
                    style={{
                      width: "230px",
                      height: "24px",
                      border: "none",
                      backgroundColor: "transparent",
                      outline: "none",
                    }}
                    placeholder="이름, 휴대폰번호 검색"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  <IoSearch style={{ fontSize: "24px" }} />
                </div>
                <div>고객 {userCount}</div>
                <hr
                  style={{
                    width: "100%",
                    height: "1px",
                    border: "none",
                    backgroundColor: "#cccccc",
                  }}
                />
                <div
                  style={{
                    width: "100%",
                    height: "500px",
                    overflowY: "scroll",
                  }}
                >
                  {customerList.map((item, idx) => (
                    <div
                      key={idx}
                      style={{
                        width: "100%",
                        height: "60px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: "8px",
                        boxSizing: "border-box",
                        padding: "0px 10px",
                        cursor: "pointer",
                        borderRadius: "8px",
                      }}
                      className="customerItem"
                      onClick={() => {
                        setName(item.customerName);
                        setPhone(item.customerPhoneNumber);
                      }}
                    >
                      <span
                        style={{
                          color: "#000000",
                          fontSize: "16px",
                        }}
                      >
                        {item.customerName}
                        {item.isRegular && (
                          <IoMedical
                            style={{ color: "#62D4D2", fontSize: "12px" }}
                          />
                        )}
                      </span>
                      <span
                        style={{
                          color: "#A0A6B1",
                          fontSize: "12px",
                        }}
                      >
                        {item.customerPhoneNumber}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </Box>
      </Modal>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;

  overflow: hidden;

  .customerItem:hover {
    background-color: #cccccc;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

const Title = styled.span`
  width: 86px;
  font-size: 16px;
  font-weight: 700;
  color: #4d5562;
`;

export default BookRegisterModal;
