import { Box, Modal } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import CropImg from "../CropImg";

type propType = {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  setData: Dispatch<SetStateAction<string>>;
  image: File;
};

const ImageCropModal = ({ setOpen, open, setData, image }: propType) => {
  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            height: 450,
            boxShadow: 24,
            p: 4,
            backgroundColor: "#ffffff",
            outline: "none",
            borderRadius: "12px",
          }}
        >
          <CropImg
            imgsrc={image!}
            setCropData={setData}
            onClose={() => setOpen(false)}
          />
        </Box>
      </Modal>
    </>
  );
};

export default ImageCropModal;
