import * as React from "react";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers";

type propType = {
  value?: Date;
  setValue?: React.Dispatch<React.SetStateAction<Date>>;
  disabled?: boolean;
  backgroundColor?: string;
};

export default function DatePicker(props: propType) {
  const [open, setOpen] = React.useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
      <DesktopDatePicker
        disabled={props.disabled}
        value={props.value ? dayjs(props.value) : dayjs(Date.now())}
        onChange={(e) => {
          if (!e!.isValid()) return;

          return props.setValue ? props.setValue(e!.toDate()) : "";
        }}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        sx={{
          width: "184px",
          height: "44px",
          backgroundColor: props.backgroundColor
            ? props.backgroundColor
            : "#f4f5f7",
          borderRadius: "8px",
          "& >div": { height: "44px" },
          "& fieldset": { border: "none" },
          "& input ": {
            color: open ? "#7FDBD9" : "rgba(0, 0, 0, 0.54)",
            fontFamily: "Noto Sans KR",
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "22px",
            letterSpacing: "-0.2px",
          },
          "& svg ": {
            color: open ? "#7FDBD9" : "rgba(0, 0, 0, 0.54)",
          },
        }}
        slotProps={{
          calendarHeader: {
            sx: {
              ".MuiPickersCalendarHeader-label": {
                fontSize: "20px",
                fontWeight: "700",
              },
            },
          },
          day: {
            sx: {
              "&.MuiPickersDay-today": {
                backgroundColor: "#ffb0be",
                color: "#ffffff",
                border: "none",
              },
              "&:hover": {
                backgroundColor: "#FF5D7A",
                color: "#ffffff",
              },
              "&.Mui-selected,&.Mui-selected:focus": {
                backgroundColor: "#FF5D7A",
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
}
