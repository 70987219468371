import React, { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import { color_midnight, color_rose } from "../../util/color";
import InputBox from "../utility/InputBox";
import { newTicketHistory } from "../../apis/customer";

type propType = {
  updateCallback: () => void;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  data: any;
};

const TicketMoneyModal = ({
  updateCallback,
  setIsModalOpen,
  data,
}: propType) => {
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [operation, setOperation] = useState("add"); // 'add' for 넣기, 'subtract' for 빼기

  const closeModal = () => {
    setIsModalOpen(false);
    setAmount("");
    setNote("");
  };

  const handleConfirm = () => {
    // 금액과 수정 내역 처리 로직 추가
    console.log("Operation:", operation, "Amount:", amount, "Note:", note);

    newTicketHistory({
      ticketId: data.ticketId,
      charge: operation === "add" ? parseInt(amount) : parseInt(amount) * -1,
      title: note,
    }).finally(() => {
      updateCallback();
      closeModal();
    });
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <div
          style={{
            width: "100%",
            height: "56px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#ffffff",
          }}
        >
          <h3 style={{ margin: "0px" }}>정액권 사용/충전</h3>
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            padding: "24px",
            boxSizing: "border-box",
            backgroundColor: color_midnight.lily,
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              background: "#ffffff",
              padding: "24px",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              borderRadius: "8px",
            }}
          >
            <div>
              <label style={{ marginRight: "10px" }}>
                <input
                  type="radio"
                  name="operation"
                  value="add"
                  checked={operation === "add"}
                  onChange={() => setOperation("add")}
                />
                충전
              </label>
              <label>
                <input
                  type="radio"
                  name="operation"
                  value="subtract"
                  checked={operation === "subtract"}
                  onChange={() => setOperation("subtract")}
                />
                사용
              </label>
            </div>
            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <span style={{ fontWeight: "700", fontSize: "14px" }}>금액</span>
              <InputBox
                placeholder={"금액"}
                type={"money"}
                width={300}
                setValue={setAmount}
                value={amount}
              />
            </div>
            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <span style={{ fontWeight: "700", fontSize: "14px" }}>내역</span>
              <InputBox
                placeholder={"내역"}
                type={"text"}
                width={300}
                setValue={setNote}
                value={note}
                maxLength={20}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              paddingTop: "24px",
            }}
          >
            <button
              onClick={closeModal}
              style={{
                backgroundColor: color_midnight.midnight2,
                border: "none",
                borderRadius: "8px",
                color: "#000",
                cursor: "pointer",
                width: "160px",
                height: "44px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              취소
            </button>
            <button
              onClick={handleConfirm}
              style={{
                backgroundColor: color_rose.rose8,
                border: "none",
                borderRadius: "8px",
                color: "#fff",
                cursor: "pointer",
                width: "160px",
                height: "44px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              확인
            </button>
          </div>
        </div>
      </ModalContent>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  width: 450px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export default TicketMoneyModal;
