import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { getManagerList } from "../../apis/manager";
import { useRecoilValue } from "recoil";
import { ShopData } from "../../atoms/atom";
import RegisterSales from "./RegisterSales";
import { handleEmptyValue } from "../../util/util";

type propType = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  data: any;
  callback: () => void;
};

const SalesRegisterModal = ({ open, setOpen, data, callback }: propType) => {
  const shopData = useRecoilValue(ShopData);

  const [amount, setAmount] = useState(data.totalPrice);
  const [date] = useState(new Date(data.date));
  const [time] = useState(new Date(data.date));
  const [method, setMethod] = useState("");
  const [manager, setManager] = useState<any>({
    name: data.managerName,
    id: data.managerId,
  });
  const [managerList, setManagerList] = useState<any>([]);
  const [isSelMenuOpen, setIsSelMenuOpen] = useState(false);
  const [selMenu, setSelMenu] = useState<any>({ id: "" });
  const [selDesign, setSelDesign] = useState<any>({ id: "" });
  const [selOptionList, setSelOptionList] = useState<any[]>([]);

  useEffect(() => {
    getManagerList(shopData.id).then((res) => {
      setManagerList(res.data.data.managerList);
    });
  }, [shopData.id]);

  useEffect(() => {
    if (selMenu.id !== "") {
      setAmount(calPrice());
    }
  }, [selMenu, selDesign, selOptionList]);

  const calPrice = () => {
    let menu_price = handleEmptyValue(
      selMenu ? parseInt(selMenu.price !== "" ? selMenu.price : 0) : 0
    );
    let design_price = handleEmptyValue(
      selDesign ? parseInt(selDesign.price !== "" ? selDesign.price : 0) : 0
    );

    if (selMenu && selMenu.eventPrice && selMenu.eventPrice !== "-1") {
      menu_price = parseInt(selMenu.eventPrice);
    }

    if (selDesign && selDesign.eventPrice && selDesign.eventPrice !== "-1") {
      design_price = parseInt(selDesign.eventPrice);
    }

    let total_option_price = 0;

    selOptionList.forEach((e) => {
      if (e.eventPrice === "-1") {
        total_option_price += parseInt(e.price !== "" ? e.price : 0);
      } else {
        total_option_price += parseInt(e.eventPrice !== "" ? e.eventPrice : 0);
      }
    });

    return menu_price + design_price + total_option_price;
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <RegisterSales
        setOpen={setOpen}
        data={data}
        date={date}
        time={time}
        manager={manager}
        setManager={setManager}
        managerList={managerList}
        selMenu={selMenu}
        setSelMenu={setSelMenu}
        selDesign={selDesign}
        setSelDesign={setSelDesign}
        selOptionList={selOptionList}
        setSelOptionList={setSelOptionList}
        setIsSelMenuOpen={setIsSelMenuOpen}
        isSelMenuOpen={isSelMenuOpen}
        amount={amount}
        setAmount={setAmount}
        method={method}
        setMethod={setMethod}
        callback={callback}
        isSalesModal={true}
      />
    </Modal>
  );
};

export default SalesRegisterModal;
