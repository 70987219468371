import React, { useEffect, useRef, useState } from "react";
import { Calendar, View, dayjsLocalizer } from "react-big-calendar";
import dayjs from "dayjs";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "dayjs/locale/ko";
import { styled } from "styled-components";
import BCToolbar from "./BCToolbar";
import BCEventWrap from "./BCEventWrap";
import BCEventWrapWeekly from "./BCEventWrapWeekly";
import { createBlock, getBlockList, getBookList } from "../../apis/book";
import { getManagerList } from "../../apis/manager";
import {
  CalanderActiveDate,
  CalanderView,
  RefrashCounter,
  RefrashCounter2,
  ShopData,
} from "../../atoms/atom";
import { useRecoilState, useRecoilValue } from "recoil";

const localizer = dayjsLocalizer(dayjs);
dayjs.locale("ko");

type propType = {
  notShowList: string[];
  style: React.CSSProperties;
};

const BigCalendar = ({ style, notShowList }: propType) => {
  const shopData = useRecoilValue(ShopData);
  const [view, setView] = useRecoilState(CalanderView);

  const [monthData, setMonthData] = useState<any[]>([]);
  const [weeklyData, setWeeklyData] = useState<any[]>([]);

  const [managerList, setManagerList] = useState<any[]>([]);
  const [refrashCount, setRefrashCount] = useRecoilState(RefrashCounter);
  const [refrashCount2, setRefrashCount2] = useRecoilState(RefrashCounter2);

  const [activeDate, setActiveDate] = useRecoilState(CalanderActiveDate);

  const calendarRef = useRef(null);
  const [isSelecting, setIsSelecting] = useState(false);

  useEffect(() => {
    const handleMouseMove = (e: any) => {
      if (!isSelecting) return; // 선택 중이 아닐 때는 아무 작업도 하지 않음

      const calendarContent =
        document.getElementsByClassName("rbc-time-content")[0];
      if (calendarContent) {
        const { bottom } = calendarContent.getBoundingClientRect();
        const mouseY = e.clientY;

        // requestAnimationFrame을 사용하여 부드러운 스크롤
        const scrollAmount = 10;
        if (mouseY > bottom - 20) {
          requestAnimationFrame(() => {
            calendarContent.scrollTop += scrollAmount; // 스크롤 다운
          });
        } else if (mouseY < calendarContent.getBoundingClientRect().top + 20) {
          requestAnimationFrame(() => {
            calendarContent.scrollTop -= scrollAmount; // 스크롤 업
          });
        }
      }
    };

    // 컴포넌트가 마운트되면 이벤트 리스너를 추가
    document.addEventListener("mousemove", handleMouseMove);

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isSelecting]);

  useEffect(() => {
    setActiveDate(new Date());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (view !== "month") return;

    getManagerList(shopData.id).then((res) => {
      let temp = res.data.data.managerList;
      setManagerList(res.data.data.managerList);

      getBookList(activeDate.toISOString(), "monthly").then((res) => {
        let bookList = res.data.data.bookList;
        console.log(bookList);

        const result: { [key: string]: any } = {};

        bookList.forEach((booking: any) => {
          const key = `${new Date(booking.date).toLocaleDateString("ko-KR")}-${
            booking.managerId
          }`;

          if (result[key]) {
            // 이미 존재하는 경우 count 증가
            result[key].count += 1;
          } else {
            // 새로운 경우 초기화
            if (booking.managerId) {
              result[key] = {
                type: "book",
                name: temp.filter((e: any) => booking.managerId === e.id)[0]
                  .name,
                color: temp.filter((e: any) => booking.managerId === e.id)[0]
                  .color,
                start: new Date(booking.date),
                end: new Date(booking.date),
                managerId: booking.managerId,
                count: 1,
                indexNum: Object.values(result).filter(
                  (item) =>
                    item.start.toLocaleDateString("ko-KR") ===
                    `${new Date(booking.date).toLocaleDateString("ko-KR")}`
                ).length,
              };
            }
          }
        });
        setMonthData(Object.values(result));
      });

      getBlockList(activeDate.toISOString(), "monthly").then((res) => {
        let timeTableList = res.data.data.timeTableList;

        const result = [
          ...new Set(
            timeTableList
              .filter((e: any) => e.duration === "1440")
              .map((e: any) => {
                return JSON.stringify({
                  start: e.date,
                  end: new Date(
                    new Date(e.date).getTime() + 1440 * 60000
                  ).toISOString(),
                });
              })
          ),
        ].map((e: any) => {
          const temp = JSON.parse(e);
          return {
            start: new Date(temp.start),
            end: new Date(temp.end),
            type: "block",
          };
        });
        setMonthData((old) => [...old, ...result]);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, refrashCount2, activeDate]);

  useEffect(() => {
    if (view !== "month") {
      getManagerList(shopData.id).then((res) => {
        let temp = res.data.data.managerList;

        getBookList(activeDate.toISOString(), "weekly").then((res) => {
          let bookList = res.data.data.bookList;

          bookList = bookList.filter(
            (e: any) => !notShowList.includes(e.managerId)
          );

          const result = bookList.map((booking: any) => {
            return {
              name: booking.customerName,
              color: temp.filter((e: any) => booking.managerId === e.id)[0]
                .color,
              start: new Date(booking.date),
              end: new Date(
                new Date(booking.date).getTime() + booking.duration * 60000
              ),
              managerId: booking.managerId,
              AmountNum: [
                ...new Set(
                  bookList
                    .filter(
                      (e: any) =>
                        new Date(e.date).toLocaleDateString("ko-KR") ===
                        new Date(booking.date).toLocaleDateString("ko-KR")
                    )
                    .map((e: any) => e.managerId)
                ),
              ].length,
              indexNum: [
                ...new Set(
                  bookList
                    .filter(
                      (e: any) =>
                        new Date(e.date).toLocaleDateString("ko-KR") ===
                        new Date(booking.date).toLocaleDateString("ko-KR")
                    )
                    .map((e: any) => e.managerId)
                ),
              ].indexOf(booking.managerId),
              menuName: booking.menuName,
              phoneNumber: booking.phoneNumber,
              managerName: booking.managerName,
              type: "book",
              bookState: booking.bookState,
              dataOri: booking,
            };
          });
          setWeeklyData(result);
        });

        getBlockList(activeDate.toISOString(), "weekly").then((res) => {
          console.log(res.data.data.timeTableList);
          let timeTableList = res.data.data.timeTableList;

          const result = [
            ...new Set(
              timeTableList.map((e: any) => {
                return JSON.stringify({
                  start: e.date,
                  end: new Date(
                    new Date(e.date).getTime() +
                      parseInt(e.duration) * 60000 -
                      1
                  ).toISOString(),
                });
              })
            ),
          ].map((e: any) => {
            const temp = JSON.parse(e);
            return {
              start: new Date(temp.start),
              end: new Date(temp.end),
              type: "block",
            };
          });

          console.log(result);
          setWeeklyData((old) => [...old, ...result]);
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, notShowList, refrashCount, activeDate]);

  return (
    <Container ref={calendarRef}>
      <Calendar
        onNavigate={(newDate, view, action) => {
          setView(action === "DATE" ? "day" : view);
          setActiveDate(newDate);
        }}
        scrollToTime={activeDate}
        localizer={localizer}
        style={style}
        view={view as View}
        views={["month", "week", "day"]}
        events={view === "month" ? monthData : weeklyData}
        showAllEvents={true}
        onSelecting={(range) => {
          setIsSelecting(true); // 선택 중임을 설정
          return true;
        }}
        onSelectSlot={(slotInfo) => {
          setIsSelecting(false);

          if (view !== "month") {
            const result = window.confirm(
              slotInfo.start.toLocaleTimeString("ko-KR") +
                "부터 ~ " +
                slotInfo.end.toLocaleTimeString("ko-KR") +
                "까지 시간을 off 하시겠습니까?"
            );

            if (!result) return;

            const duration = (
              (slotInfo.end.getTime() - slotInfo.start.getTime()) /
              60000
            ).toString();

            createBlock(
              managerList.map((item) => item.id),
              slotInfo.start.toISOString(),
              duration
            )
              .then(() => {
                setRefrashCount(refrashCount + 1);
              })
              .catch((error) => {
                if (error.response.status === 409) {
                  // 토큰 만료
                  window.alert("선택한 부분을 다시 확인해주세요.");
                }
              });
          } else {
            console.log(slotInfo);

            if (slotInfo.action === "click") return;
            // double click -> one day off
            // select -> 기간 off

            if (slotInfo.action === "doubleClick") {
              const result = window.confirm(
                slotInfo.start.toLocaleDateString("ko-KR") +
                  "일을 off 하시겠습니까?"
              );

              if (!result) return;

              const duration = (24 * 60).toString();

              slotInfo.start.setHours(0);
              slotInfo.start.setMinutes(0);
              slotInfo.start.setSeconds(0);

              createBlock(
                managerList.map((item) => item.id),
                slotInfo.start.toISOString(),
                duration
              )
                .then(() => {
                  setRefrashCount2(refrashCount2 + 1);
                })
                .catch((error) => {
                  if (error.response.status === 409) {
                    // 토큰 만료
                    window.alert("선택한 부분을 다시 확인해주세요.");
                  }
                });
            }

            if (slotInfo.action === "select") {
              console.log(slotInfo);

              const result = window.confirm(
                slotInfo.start.toLocaleDateString("ko-KR") +
                  "일부터" +
                  slotInfo.end.toLocaleDateString("ko-KR") +
                  "전까지 off 하시겠습니까?"
              );

              if (!result) return;

              const duration = (24 * 60).toString();

              slotInfo.slots.forEach((slot, idx) => {
                let temp = slot;

                temp.setHours(0);
                temp.setMinutes(0);
                temp.setSeconds(0);

                createBlock(
                  managerList.map((item) => item.id),
                  temp.toISOString(),
                  duration
                )
                  .then(() => {
                    if (slotInfo.slots.length - 1 === idx) {
                      setRefrashCount2(refrashCount2 + 1);
                    }
                  })
                  .catch((error) => {
                    if (error.response.status === 409) {
                      // 토큰 만료
                      window.alert("선택한 부분을 다시 확인해주세요.");
                    }
                  });
              });
            }
          }
        }}
        selectable={true}
        onView={(view) => setView(view)}
        date={activeDate}
        onSelectEvent={(event) => {
          setView("day");
          setActiveDate(event.start!);
        }}
        components={
          view === "month"
            ? {
                toolbar: BCToolbar,
                eventWrapper: BCEventWrap,
              }
            : {
                toolbar: BCToolbar,
                eventWrapper: BCEventWrapWeekly,
                timeGutterHeader: () => <div>시간</div>,
              }
        }
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;

  background-color: #ffffff;
  padding: 24px 42px;
  border-radius: 16px;

  .rbc-month-view .rbc-event {
    cursor: default;
  }

  .rbc-button-link:hover {
    color: #f14262;
  }
  .rbc-month-view .rbc-event:hover {
    background-color: #fad7de !important;
    border-radius: 8px;
  }

  & .rbc-row-content-scroll-container {
    overflow: visible;
  }

  & .rbc-calendar > div:last-child {
    background-color: #ffffff;
  }

  & .rbc-timeslot-group {
    min-height: 100px;
  }

  & .rbc-month-view {
    border-radius: 20px;
    overflow: hidden;

    & .rbc-month-header {
      height: 44px;
    }

    & .rbc-header {
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: none;

      &:first-child {
        color: #ff5d7a;
      }

      &:last-child {
        color: #6688f0;
      }
    }

    & .rbc-date-cell {
      height: 40px;
    }

    & .rbc-date-cell {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: end;

      & > button {
        width: 36px;
        height: 36px;
      }
    }

    & .rbc-now {
      & > button {
        width: 36px;
        height: 36px;
        background-color: #ff5d7a;
        border-radius: 50%;
        color: #ffffff;
      }
    }

    & .rbc-today {
      background-color: #ffffff;
    }

    & .rbc-off-range-bg {
      background: #ffffff;
    }

    & .rbc-event {
      width: 100%;
      height: 14px;
      padding: 0;
      border-radius: 0;
      background-color: #d8f5f5;
      border-bottom: 1px solid #ffffff;

      font-size: 10px;
      font-weight: 500;
      line-height: 14px;
      color: #000000;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    & .rbc-row-segment {
      padding: 0;
    }
  }

  & .rbc-time-view {
    border: none;

    & .rbc-allday-cell {
      display: none;
    }

    & .rbc-header {
      border: none;

      &.rbc-today {
        color: #f14262;
      }
    }

    & .rbc-today {
      background-color: transparent;
    }

    & .rbc-time-header-content {
      border: none;
      display: flex;
      justify-content: center;

      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.2px;
    }

    & .rbc-time-header {
      height: 54px;
      border: none;
      margin: 0 !important;
    }

    & .rbc-time-header-gutter {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      color: #a0a6b1;

      width: 80px;
    }

    & .rbc-time-content {
      border: none;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    & .rbc-time-gutter {
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      color: #222222;
      width: 80px;
      opacity: 0.4;

      border-right: 1px solid #ebebeb;
    }

    & .rbc-time-slot {
      display: flex;
      justify-content: center;
      align-items: start;
    }

    & .rbc-timeslot-group {
      border: none;
    }

    & .rbc-day-slot {
      & > div:first-child > div:first-child {
        border-top: 1px solid #ebebeb;
      }
      & > div > div {
        border-bottom: 1px solid #ebebeb;
        border-right: 1px solid #ebebeb;
      }
    }

    & .rbc-events-container {
      margin: 0;
    }
  }
`;

/*components={
  view === "month"
    ? {
        toolbar: BCToolbar,
        eventWrapper: BCEventWrap,
      }
    : {
        toolbar: BCToolbar,
        eventWrapper: BCEventWrapWeekly,
        timeGutterHeader: () => <div>시간</div>,
      }
}*/

export default BigCalendar;

/*
& .rbc-time-view {
  border: none;

  & .rbc-allday-cell {
    display: none;
  }

  & .rbc-header {
    border: none;
  }

  & .rbc-today {
    background-color: transparent;
  }

  & .rbc-time-header-content {
    border: none;
    display: flex;
    justify-content: center;

    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.2px;
  }

  & .rbc-time-header {
    height: 54px;
    border: none;
    margin: 0 !important;
  }

  & .rbc-time-header-gutter {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    color: #a0a6b1;

    width: 80px;
  }

  & .rbc-time-content {
    border: none;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    & > div {
      margin-top: -39.7%;
    }
  }

  & .rbc-time-gutter {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    color: #222222;
    width: 80px;
    opacity: 0.4;

    border-right: 1px solid #ebebeb;
  }

  & .rbc-time-slot {
    display: flex;
    justify-content: center;
    align-items: start;
  }

  & .rbc-timeslot-group {
    border: none;
  }

  & .rbc-day-slot {
    & > div:first-child > div:first-child {
      border-top: 1px solid #ebebeb;
    }
    & > div > div {
      border-bottom: 1px solid #ebebeb;
      border-right: 1px solid #ebebeb;
    }
  }

  & .rbc-events-container {
    margin: 0;
  }
}
*/
