import styled from "styled-components";
import CardLayout from "../../layout/CardLayout";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Expired = () => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("autoLogin");
  }, []);

  return (
    <CardLayout
      text="정기권 이용 기간이
    만료되었습니다."
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src="./image/cardImg.png" width="160px" height="160px" alt="" />
        <div
          style={{
            padding: "16px",
            backgroundColor: "#F9FAFB",
            borderRadius: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            fontSize: "12px",
            fontWeight: "700",
          }}
        >
          <span>정기권을 구매하시고 더 나아진</span>
          <span>핑프메이커 서비스를 이용해보세요.</span>
        </div>
      </div>
      <NextBtn
        onClick={() => {
          navigate("/billing");
        }}
      >
        이용권 구매
      </NextBtn>
    </CardLayout>
  );
};

const NextBtn = styled.button`
  width: 300px;
  height: 44px;
  background-color: #ff5d7a;
  border: none;
  border-radius: 8px;

  color: #f9fafb;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: -0.2px;
`;

export default Expired;
