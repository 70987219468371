import axios from "axios";
import { baseUrl, config } from "./api";
import { CreateShopEventType, ShopEventType2 } from "../types";

// shop event api
export const getEventList = async (id: string) => {
  return await axios.get(
    baseUrl + `/admin/shop/event?limit=100&offset=1&shopId=${id}`,
    config()
  );
};

export const createEvent = async (data: CreateShopEventType) => {
  return await axios.post(baseUrl + "/admin/shop/event", data, config());
};

export const saveEvent = async (data: ShopEventType2) => {
  return await axios.put(baseUrl + "/admin/shop/event", data, config());
};

export const deleteEvent = async (data: { eventIdList: string[] }) => {
  return await axios.delete(baseUrl + "/admin/shop/event", {
    headers: config().headers,
    data: data,
  });
};
