import React, { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import { FeedType } from "../../types";

type propType = {
  setShowFailMessage: Dispatch<SetStateAction<boolean>>;
  data: FeedType;
  callback: () => void;
  editCallback: () => void;
};

const FeedItem = ({
  setShowFailMessage,
  data,
  callback,
  editCallback,
}: propType) => {
  const [showOverlay, setShowOverlay] = useState(false);

  return (
    <Container>
      <img
        src={data.imageUrl}
        onMouseOver={() => setShowOverlay(true)}
        alt=""
        style={{ cursor: "pointer" }}
      />
      <HeartBox>
        <FavoriteBorderIcon style={{ color: "#ffffff", fontSize: "16px" }} />
        <span>{data.likeCount}</span>
      </HeartBox>
      {showOverlay && (
        <Overlay
          style={{ cursor: "pointer", zIndex: "500" }}
          onMouseLeave={() => setShowOverlay(false)}
        >
          <div>
            <DeleteIcon
              style={{ color: "#ffffff", fontSize: "28px", cursor: "pointer" }}
              onClickCapture={(e) => {
                console.log("delete");
                if (window.confirm("피드를 삭제하시겠습니까?")) {
                  callback();
                  setShowFailMessage(true);
                  setTimeout(() => setShowFailMessage(false), 1500);
                }

                e.stopPropagation();
              }}
            />
            |
            <CreateIcon
              style={{ color: "#ffffff", fontSize: "28px", cursor: "pointer" }}
              onClick={editCallback}
            />
          </div>
        </Overlay>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 328px;
  height: 328px;
  justify-content: center;
  align-items: center;

  background-color: #cccccc;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }

  position: relative;
`;

const HeartBox = styled.div`
  display: flex;
  width: 80px;
  height: 36px;
  padding: 7px 15.5px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  box-sizing: border-box;

  position: absolute;
  left: 24px;

  top: 24px;
  border-radius: 50px;
  background: rgba(0, 0, 0, 0.5);

  & > span {
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    line-height: 140%;
  }
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);

  position: absolute;
  top: 0px;
  left: 0px;

  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    display: flex;
    gap: 28px;
    font-size: 28xp;
    color: #ffffff;

    justify-content: center;
    align-items: center;
  }
`;

export default FeedItem;
