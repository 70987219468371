import axios from "axios";
import { baseUrl, config } from "./api";
import { CreateBookType, SaveBookType } from "../types";

export const getBookList = async (
  date: string,
  dataType: string,
  isSales?: boolean,
  isTotal?: boolean
) => {
  return await axios.get(
    baseUrl +
      `/admin/shop/book?date=${date}&dateType=${dataType}` +
      `${isSales ? "&isSales=true" : ""}` +
      `${isTotal ? "&isTotal=true" : ""}`,
    config()
  );
};

export const getTotalBookList = async (
  sortColumn: string,
  sort: string,
  offset: number,
  bookState: string,
  startDate: string,
  endDate: string
) => {
  return await axios.get(
    baseUrl + `/admin/shop/book/total`,
    config(undefined, {
      limit: 10,
      offset: offset,
      sortColumn: sortColumn,
      sort: sort,
      bookState: bookState ? bookState : null,
      startDate: startDate,
      endDate: endDate,
    })
  );
};

export const getBookReqList = async () => {
  return await axios.get(baseUrl + `/admin/shop/book/request`, config());
};

export const getBlockList = async (date: string, dataType: string) => {
  return await axios.get(
    baseUrl + `/admin/shop/book/timetable?date=${date}&dateType=${dataType}`,
    config()
  );
};

export const createBlock = async (
  managerId: string[],
  date: string,
  duration: string
) => {
  return await axios.post(
    baseUrl + "/admin/shop/book/timetable",
    { managerId: managerId, date: date, duration: duration },
    config()
  );
};

export const createBook = async (data: CreateBookType) => {
  return await axios.post(baseUrl + "/admin/shop/book", data, config());
};

export const deleteBlock = async (timetableId: string) => {
  return await axios.delete(baseUrl + "/admin/shop/book/timetable", {
    headers: config().headers,
    data: { timeTableId: timetableId },
  });
};

export const ReqApprove = async (bookId: string) => {
  return await axios.put(
    baseUrl + "/admin/shop/book/approve",
    { bookId: bookId },
    config()
  );
};

export const ReqCancel = async (bookId: string, reason: string) => {
  return await axios.put(
    baseUrl + "/admin/shop/book/cancel",
    { bookId: bookId, cancelReason: reason },
    config()
  );
};

export const getVisitorCounts = async (date: string) => {
  return await axios.get(
    baseUrl + `/admin/shop/book/visitor?date=${date}`,
    config()
  );
};

export const getVisitorCountsWeekly = async (date: string) => {
  return await axios.get(
    baseUrl + `/admin/shop/book/visitor/weekly?date=${date}`,
    config()
  );
};

export const saveBookNoshow = async (bookId: string) => {
  return await axios.put(
    baseUrl + "/admin/shop/book/noshow",
    { bookId: bookId },
    config()
  );
};

export const saveBook = async (data: SaveBookType) => {
  return await axios.put(baseUrl + "/admin/shop/book", data, config());
};
