import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { styled } from "styled-components";
import { IoMdClose } from "react-icons/io";

type propType = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  data: any;
  callback: (reason: string) => void;
};

const BookCancelModal = ({ open, setOpen, data, callback }: propType) => {
  const [reason, setReason] = useState("");

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "680px",
            height: "540px",
            bgcolor: "background.paper",
            borderRadius: "16px",
            p: 4,
            boxShadow: "4px 4px 4px 0px #00000040",
            padding: "24px 0px 24px 0px",
            boxSizing: "border-box",
            display: "flex",
            outline: "none",
          }}
        >
          <Container
            style={{
              gap: "30px",
              display: "flex",
              flexDirection: "column",
              width: "680px",
              position: "relative",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "68px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h2 style={{ fontSize: "20px", fontWeight: "700" }}>
                거절 사유 선택
              </h2>
              <IoMdClose
                style={{
                  position: "absolute",
                  right: "20px",
                  fontSize: "24px",
                  cursor: "pointer",
                  top: "20px",
                }}
                onClick={() => setOpen(false)}
              />
            </div>
            <div
              style={{
                width: "100%",
                padding: "0px 8%",
                boxSizing: "border-box",
                color: "#FF5D7A",
              }}
            >
              고객에게 전달될 거절 사유를 선택해주세요!
            </div>
            <div
              style={{
                width: "100%",
                padding: "0px 8%",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <div
                style={{
                  width: "16px",
                  height: "16px",
                  borderRadius: "50%",
                  border:
                    reason === "duplicate"
                      ? "2px solid #FF5D7A"
                      : "2px solid #6C727F",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setReason("duplicate")}
              >
                <div
                  style={{
                    width: "12px",
                    height: "12px",
                    borderRadius: "50%",
                    backgroundColor:
                      reason === "duplicate" ? "#FF5D7A" : "#ffffff",
                  }}
                ></div>
              </div>
              중복 예약
            </div>
            <div
              style={{
                width: "100%",
                padding: "0px 8%",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <div
                style={{
                  width: "16px",
                  height: "16px",
                  borderRadius: "50%",
                  border:
                    reason === "noManager"
                      ? "2px solid #FF5D7A"
                      : "2px solid #6C727F",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setReason("noManager")}
              >
                <div
                  style={{
                    width: "12px",
                    height: "12px",
                    borderRadius: "50%",
                    backgroundColor:
                      reason === "noManager" ? "#FF5D7A" : "#ffffff",
                  }}
                ></div>
              </div>
              담당 디자이너 부재
            </div>
            <div
              style={{
                width: "100%",
                padding: "0px 8%",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <div
                style={{
                  width: "16px",
                  height: "16px",
                  borderRadius: "50%",
                  border:
                    reason === "noTime"
                      ? "2px solid #FF5D7A"
                      : "2px solid #6C727F",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setReason("noTime")}
              >
                <div
                  style={{
                    width: "12px",
                    height: "12px",
                    borderRadius: "50%",
                    backgroundColor:
                      reason === "noTime" ? "#FF5D7A" : "#ffffff",
                  }}
                ></div>
              </div>
              일정 불가
            </div>
            <div
              style={{
                width: "100%",
                padding: "0px 8%",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <div
                style={{
                  width: "16px",
                  height: "16px",
                  borderRadius: "50%",
                  border:
                    reason === "shop"
                      ? "2px solid #FF5D7A"
                      : "2px solid #6C727F",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setReason("shop")}
              >
                <div
                  style={{
                    width: "12px",
                    height: "12px",
                    borderRadius: "50%",
                    backgroundColor: reason === "shop" ? "#FF5D7A" : "#ffffff",
                  }}
                ></div>
              </div>
              샵 사정
            </div>
            <div
              style={{
                width: "100%",
                padding: "0px 8%",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <div
                style={{
                  width: "16px",
                  height: "16px",
                  borderRadius: "50%",
                  border:
                    reason === "noRequirement"
                      ? "2px solid #FF5D7A"
                      : "2px solid #6C727F",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setReason("noRequirement")}
              >
                <div
                  style={{
                    width: "12px",
                    height: "12px",
                    borderRadius: "50%",
                    backgroundColor:
                      reason === "noRequirement" ? "#FF5D7A" : "#ffffff",
                  }}
                ></div>
              </div>
              요청 사항 불가
            </div>
            <hr style={{ width: "90%", borderTop: "1px solid #E5E7EB" }} />
            <div
              style={{
                width: "100%",
                height: "44px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "24px",
              }}
            >
              <button
                style={{
                  border: "none",
                  height: "100%",
                  width: "184px",
                  borderRadius: "8px",
                  color: "#A0A6B1",
                  backgroundColor: "#E5E7EB",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpen(false);
                }}
                className="cancelBtn"
              >
                취소
              </button>
              <button
                style={{
                  border: "none",
                  height: "100%",
                  width: "184px",
                  borderRadius: "8px",
                  color: "#ffffff",
                  backgroundColor: "#FF5D7A",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (!reason) {
                    window.alert("사유를 선택해 주세요.");
                  }

                  callback(reason);
                }}
                className="submitBtn"
              >
                확인
              </button>
            </div>
          </Container>
        </Box>
      </Modal>
    </>
  );
};

const Container = styled.div`
  & .menuSelBox:hover {
    background-color: #f9e8ec !important;
  }

  & .selectBox:hover {
    background-color: #f9e8ec !important;
  }

  & .noshowBtn:hover {
    background-color: #394150 !important;
  }

  & .cancelBtn:hover {
    background-color: #d3d6db !important;
  }

  & .submitBtn:hover {
    background-color: #fd7f96 !important;
  }
`;

export default BookCancelModal;
