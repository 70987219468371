import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import RemoveIcon from "@mui/icons-material/Remove";
import ImageIcon from "@mui/icons-material/Image";
import InputBox from "./InputBox";
import CheckIcon from "@mui/icons-material/Check";
import { CreateDesignType } from "../../types";
import ImageCropModal from "./ImageCropModal";
import { UploadImg } from "../../apis/api";
import { ShopData } from "../../atoms/atom";
import { useRecoilValue } from "recoil";

type propType = {
  setValue: Dispatch<SetStateAction<CreateDesignType[]>>;
  value: CreateDesignType;
  index: number;
};

const DesignAdd = ({ value, setValue, index }: propType) => {
  const [name, setName] = useState("");
  const [runningTime, setRunningTime] = useState("");
  const [price, setPrice] = useState("");

  const [isRunningTime, setIsRunningTime] = useState(false);
  const [isPrice, setIsPrice] = useState(false);
  const [isImage, setIsImage] = useState(false);

  const [openCrop, setOpenCrop] = useState(false);
  const [image, setImage] = useState<File>();
  const [profile, setProfile] = useState("");

  const shopData = useRecoilValue(ShopData);

  useEffect(() => {
    setValue((old) => {
      let temp = [...old];
      temp[index] = {
        name: name,
        price: price,
        runningTime: runningTime,
        imageUrl: profile,
      };

      return temp;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, runningTime, price, profile]);

  useEffect(() => {
    if (profile) {
      if (!profile.startsWith("http") && profile) {
        fetch(profile)
          .then((response) => response.blob())
          .then((blob) => {
            const file = new File([blob], "cropImg.png", { type: blob.type });
            UploadImg(file, shopData.id, "feed").then((res) => {
              setProfile(res.data.url);
            });
          });
      }
    }
  }, [profile]);

  return (
    <Container>
      <div>
        <RemoveIcon
          sx={{
            backgroundColor: "#F71807",
            color: "#ffffff",
            borderRadius: "50%",
            marginTop: "30px",
            width: "20px",
            height: "20px",
            cursor: "pointer",
          }}
          onClick={() =>
            setValue((old) => old.filter((item, idx) => idx !== index))
          }
        />
      </div>
      <div>
        <div>
          <h2>디자인</h2>
          <InputBox
            placeholder="디자인명"
            value={name}
            setValue={setName}
            width={300}
            type="text"
          />
        </div>
        <div>
          <div>
            <div>
              <div
                onClick={() => {
                  setIsRunningTime((old) => !old);
                  setRunningTime("");
                }}
                style={{
                  backgroundColor: isRunningTime ? "#FF5D7A" : "#ffffff",
                  cursor: "pointer",
                }}
              >
                {<CheckIcon sx={{ fontSize: 18, color: "#ffffff" }} />}
              </div>
              <h2>추가 시간</h2>
              <InputBox
                placeholder="분으로 입력해주세요 ex)30"
                value={runningTime}
                setValue={setRunningTime}
                width={184}
                type="text"
                disabled={!isRunningTime}
              />
            </div>
            <div>
              <div
                onClick={() => {
                  setIsPrice((old) => !old);
                  setPrice("");
                }}
                style={{
                  backgroundColor: isPrice ? "#FF5D7A" : "#ffffff",
                  cursor: "pointer",
                }}
              >
                {<CheckIcon sx={{ fontSize: 18, color: "#ffffff" }} />}
              </div>
              <h2>추가 금액</h2>
              <InputBox
                placeholder="추가 금액"
                value={price}
                setValue={setPrice}
                width={184}
                type="money"
                disabled={!isPrice}
              />
            </div>
          </div>
          <div>
            <div
              onClick={() => {
                setIsImage((old) => !old);
                setProfile("");
              }}
              style={{
                backgroundColor: isImage ? "#FF5D7A" : "#ffffff",
                cursor: "pointer",
              }}
            >
              {<CheckIcon sx={{ fontSize: 18, color: "#ffffff" }} />}
            </div>
            <h2>이미지</h2>
            {profile !== "" ? (
              <div
                style={{
                  backgroundImage: `url(${profile})`,
                  backgroundSize: "cover",
                }}
              ></div>
            ) : (
              <div>
                <ImageIcon sx={{ fontSize: 40, color: "#A0A6B1" }} />
                <input
                  disabled={!isImage}
                  type="file"
                  onChange={(e) => {
                    if (e.target.files?.length) {
                      setImage(e.target.files[0]);
                      setOpenCrop(true);
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {openCrop && (
        <ImageCropModal
          image={image!}
          open={openCrop}
          setOpen={setOpenCrop}
          setData={setProfile}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 776px;
  height: 196px;

  border: 2px solid #e5e7eb;

  margin-top: 20px;
  border-radius: 8px;

  display: flex;

  & > div:first-child {
    width: 80px;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  & > div:last-child {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;

    & > div:first-child {
      display: flex;
      gap: 24px;

      align-items: center;
      & > h2 {
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: -0.2;

        color: #000000;
      }
    }

    & > div:last-child {
      width: 100%;
      height: 104px;

      display: flex;
      justify-content: space-between;

      & > div:first-child {
        width: 300px;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & > div {
          width: 100%;
          height: 44px;

          display: flex;
          justify-content: space-between;
          align-items: center;

          & > div:first-child {
            width: 18px;
            height: 18px;
            border: 2px solid #ff5d7a;

            border-radius: 4px;
          }

          & > h2 {
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;

            color: #000000;
          }
        }
      }

      & > div:last-child {
        width: 223px;
        height: 100%;

        display: flex;
        justify-content: space-between;
        align-items: center;

        & > h2 {
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;

          color: #000000;
        }

        & > div:first-child {
          width: 18px;
          height: 18px;
          border: 2px solid #ff5d7a;

          border-radius: 4px;
        }

        & > div:last-child {
          position: relative;
          width: 104px;
          height: 104px;
          background-color: #f4f5f7;
          border-radius: 8px;

          margin-right: 24px;

          display: flex;
          justify-content: center;
          align-items: center;

          & > input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            color: transparent;

            &::file-selector-button {
              display: none;
            }
          }
        }
      }
    }
  }
`;

export default DesignAdd;
