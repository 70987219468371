import React from "react";
import { styled } from "styled-components";

type propType = {
  data: { name: string; time: string; contens: string; managerName: string };
  width?: string;
  onClick?: () => void;
};

const ScheduleItem = ({ data, width, onClick }: propType) => {
  return (
    <Container $width={width ? width : ""} onClick={onClick}>
      <Left>
        <NewIcon></NewIcon>
      </Left>
      <TextWrap>
        <Text>
          {data.name} | {data.time}
        </Text>
        <Text2>
          {data.managerName} {data.contens}
        </Text2>
      </TextWrap>
      <Right>
        <NextIcon src="/image/arrow-right.png" alt=">" />
      </Right>
    </Container>
  );
};

const Container = styled.li<{ $width: string }>`
  cursor: pointer;
  width: ${(props) => (props.$width ? props.$width : "352px")};
  height: 72px;
  background: #f9fafb;
  border-radius: 14px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

const Left = styled.div`
  width: 63px;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const NewIcon = styled.div`
  width: 6px;
  height: 6px;

  background: #62d4d2;
  border-radius: 3px;
`;

const TextWrap = styled.div`
  width: 100%;
  height: 48px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Text = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.2px;
`;

const Text2 = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;

const Right = styled.div`
  width: 61px;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const NextIcon = styled.img`
  width: 14px;
  height: 14px;
`;

export default ScheduleItem;
