import { useEffect, useState } from "react";
import { styled } from "styled-components";
import Calendar from "../utility/Calendar";
import ScheduleItem from "./ScheduleItem";
import { getBookList } from "../../apis/book";
import { useNavigate } from "react-router-dom";
import { deleteNoti, getNotiList } from "../../apis/notification";

const SideBar = () => {
  const navigate = useNavigate();

  const [updateCounter, setUpdateCounter] = useState(0);

  const getCurrentDate = () => {
    const today = new Date();

    // 날짜, 월, 년도를 가져오기
    const day = today.getDate();
    const month = today.getMonth() + 1; // 월은 0부터 시작하므로 1을 더합니다.
    const year = today.getFullYear();

    // 한 자리 숫자인 경우 앞에 0을 추가해주기
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    // 'yyyy-mm-dd' 형식으로 반환
    const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;

    return formattedDate;
  };

  const [bookList, setBookList] = useState<any[]>([]);
  const [notiList, setNotiList] = useState<any[]>([]);

  useEffect(() => {
    getBookList(new Date().toISOString(), "daily").then((res) => {
      let bookList = res.data.data.bookList;
      setBookList(bookList);
    });

    getNotiList().then((res) => {
      console.log("NotiList:", res.data.data);
      setNotiList(res.data.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCounter]);

  return (
    <Container>
      <Top>
        <div>
          <span>새 알림</span>
          <span>{notiList.length}</span>
        </div>
        {notiList.length === 0 ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <img src={"./image/icon5.png"} alt="이모티콘1" />
            <span style={{ color: "#A0A6B1" }}>새 알림이 없습니다.</span>
          </div>
        ) : (
          <div>
            {notiList.map((item, idx) => (
              <NotiBox key={idx}>
                <div>
                  <span>{item.title}</span>
                  <span>{item.message}</span>
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    deleteNoti(item.id).then((res) => {
                      setUpdateCounter((old) => old + 1);
                    });
                  }}
                >
                  확인
                </div>
              </NotiBox>
            ))}
          </div>
        )}
      </Top>
      <Middle>
        <Calendar />
      </Middle>
      <Bottom>
        <span>{getCurrentDate() + " 일정"}</span>
        <div>
          {bookList.length === 0 ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <img src={"./image/icon4.png"} alt="이모티콘4" />
              <span style={{ color: "#A0A6B1" }}>일정이 없습니다.</span>
            </div>
          ) : (
            bookList.map((item, idx) => (
              <ScheduleItem
                width={"100%"}
                key={idx}
                data={{
                  name: item.customerName,
                  time: new Date(item.date).toLocaleTimeString("ko-KR", {
                    timeStyle: "short",
                  }),
                  contens: item.menuName,
                  managerName: item.managerName,
                }}
                onClick={() => {
                  console.log("11111");
                  navigate("/schedule", { state: item });
                }}
              />
            ))
          )}
        </div>
      </Bottom>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 840px;

  border-radius: 16px;

  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const Top = styled.div`
  display: flex;
  width: 100%;
  height: 212px;
  padding: 16px;
  box-sizing: border-box;
  flex-direction: column;
  gap: 24px;
  border-radius: 16px;
  background: #ffffff;

  & > :first-child {
    display: flex;
    align-items: center;
    gap: 8px;

    & > :first-child {
      color: #272f3e;
      font-size: 20px;
      font-weight: 700;
      line-height: 140%;

      box-sizing: border-box;
      padding-left: 8px;
    }
    & > :last-child {
      display: flex;
      height: 24px;
      padding: 10px 12px;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 50px;
      background: #f14262;

      color: #ffffff;
      font-size: 14px;
      font-weight: 700;
      line-height: 140%;
    }
  }
  & > :last-child {
    display: flex;
    flex-direction: column;
    gap: 12px;

    overflow-y: scroll;

    padding: 0px 10px;
    box-sizing: border-box;
  }
`;
const Middle = styled.div`
  display: flex;
  width: 100%;
  height: 320px;
  border-radius: 16px;
  background: #ffffff;

  justify-content: center;
  align-items: center;
`;
const Bottom = styled.div`
  display: flex;
  width: 100%;
  height: 314px;

  flex-direction: column;
  border-radius: 16px;
  background: #ffffff;

  padding: 16px 24px;
  box-sizing: border-box;
  flex-direction: column;
  gap: 24px;

  & > :first-child {
    color: #272f3e;
    font-size: 18px;
    font-weight: 700;
    line-height: 140%;
  }

  & > :last-child {
    width: 100%;
    height: 100%;

    overflow-y: scroll;
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
    &::-webkit-scrollbar {
      display: none;
    }

    padding: 0px 10px;
    box-sizing: border-box;

    & > li:hover {
      background-color: #f9e8ec;
    }
  }
`;

const NotiBox = styled.div`
  background-color: #f9fafb;
  border-radius: 16px;

  width: 100%;
  padding: 16px 24px;
  box-sizing: border-box;

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  & > div:first-child {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-bottom: 20px;

    & > span:first-child {
      color: #272f3e;
      font-size: 12px;
      font-weight: 700;
      line-height: 140%;
    }

    & > span:last-child {
      color: #a0a6b1;
      font-size: 10px;
      font-weight: 500;
      line-height: 140%;
    }
  }
  & > div:last-child {
    width: 100%;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #272f3e;
    font-size: 12px;
    font-weight: 700;
    line-height: 140%;
  }
`;
export default SideBar;
