import React, { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { ShopEventType } from "../../types";
import { deleteEvent } from "../../apis/event";
import { MyShopIsEdit } from "../../atoms/atom";
import { useRecoilState } from "recoil";

type propType = {
  setShowFailMessage: Dispatch<SetStateAction<boolean>>;
  isShow: boolean;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  data: ShopEventType;
  setData: Dispatch<SetStateAction<ShopEventType>>;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  deleteCallback: () => void;
};

const EventItem = ({
  setShowFailMessage,
  isShow,
  open,
  setOpen,
  data,
  setData,
  deleteCallback,
  setIsEdit,
}: propType) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [, setMyShopIsEdit] = useRecoilState(MyShopIsEdit);

  return (
    <Container $isShow={isShow} onContextMenu={(e) => e.preventDefault()}>
      <img
        alt=""
        src={data.imageUrl}
        onClick={(e) => {
          e.preventDefault();
          setData(data);
          setShowOverlay(true);
        }}
      />
      {isShow && <ShowTag>게시중</ShowTag>}
      {showOverlay && (
        <Overlay onClick={() => setShowOverlay(false)}>
          <div>
            <RemoveRedEyeIcon
              style={{ color: "#ffffff", fontSize: "28px", cursor: "pointer" }}
              onClick={() => setOpen(true)}
            />
            <div>
              <DeleteIcon
                style={{
                  color: "#ffffff",
                  fontSize: "28px",
                  cursor: "pointer",
                }}
                onClickCapture={(e) => {
                  console.log("delete");
                  console.log(data);
                  if (window.confirm("피드를 삭제하시겠습니까?")) {
                    deleteEvent({ eventIdList: [data.id] }).then((res) => {
                      deleteCallback();
                      setShowFailMessage(true);
                      setTimeout(() => setShowFailMessage(false), 1500);
                    });
                  }
                  e.stopPropagation();
                }}
              />
              |
              <CreateIcon
                style={{
                  color: "#ffffff",
                  fontSize: "28px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setMyShopIsEdit(true);
                  setIsEdit(true);
                }}
              />
            </div>
          </div>
        </Overlay>
      )}
    </Container>
  );
};

const Container = styled.div<{ $isShow: boolean }>`
  width: 240px;
  height: 240px;

  position: relative;

  box-sizing: border-box;
  border: ${(props) =>
    props.$isShow ? "4px solid #F14262" : "0px solid #F14262"};
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
`;

const Overlay = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);

  position: absolute;
  top: 0px;
  left: 0px;

  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    & > div {
      display: flex;
      gap: 28px;
      font-size: 28xp;
      color: #ffffff;

      justify-content: center;
      align-items: center;
    }
  }
`;

const ShowTag = styled.div`
  display: flex;
  height: 36px;
  padding: 7px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  position: absolute;
  left: 24px;
  top: 24px;

  border-radius: 50px;
  background: #f14262;

  box-sizing: border-box;

  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 140%;
`;

export default EventItem;
