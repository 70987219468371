import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { styled } from "styled-components";
import ManagerItem from "./ManagerItem";
import InputBox from "../utility/InputBox";
import ProfileImgInput from "../utility/ProfileImgInput";
import ImageCropModal from "../utility/ImageCropModal";
import { ManagerType } from "../../types";
import CheckIcon from "@mui/icons-material/Check";
import { createManager, deleteManager, saveManager } from "../../apis/manager";
import { useRecoilState } from "recoil";
import { ShopData } from "../../atoms/atom";
import { UploadImg } from "../../apis/api";
import DeleteIcon from "@mui/icons-material/Delete";
import WorkTime from "./WorkTime";
import { changeManagerCategory } from "../../util/util";
import { IoIosClose } from "react-icons/io";

type propType = {
  managerList: ManagerType[];
  setISAdd: Dispatch<SetStateAction<boolean>>;
  isAdd: boolean;
  setFocused: Dispatch<SetStateAction<number>>;
  focused: number;
  data: ManagerType;
  saveCallback: () => void;
};

const SideBar = ({
  managerList,
  isAdd,
  setISAdd,
  focused,
  setFocused,
  data,
  saveCallback,
}: propType) => {
  const [shopData] = useRecoilState(ShopData);
  const [isLeftTap, setIsLeftTap] = useState(true);

  const [isEdit, setIsEdit] = useState(false);
  const [isEdit2, setIsEdit2] = useState(false);

  const [name, setName] = useState(data.name);
  const [phone, setPhone] = useState(data.phoneNumber);
  const [description, setDescription] = useState(data.description);
  const [color, setColor] = useState(data.color);
  const [category, setCategory] = useState(data.category);

  const [memo, setMemo] = useState(data.memo);

  const [businessHourList, setBusinessHourList] = useState(
    data.businessHourList
  );

  const [profile, setProfile] = useState(data.imageUrl);
  const [image, setImage] = useState<File>();
  const [openCrop, setOpenCrop] = useState(false);

  const [isMemoEdit, setIsMemoEdit] = useState(false);

  useEffect(() => {
    if (profile) {
      if (!profile.startsWith("http") && profile) {
        fetch(profile)
          .then((response) => response.blob())
          .then(async (blob) => {
            const file = new File([blob], "cropImg.png", { type: blob.type });

            const res = await UploadImg(file, shopData.id, "manager");
            setProfile(res.data.url);
          });
      }
    }
  }, [profile]);

  useEffect(() => {
    setName(data.name);
    setPhone(data.phoneNumber);
    setDescription(data.description);
    setColor(data.color);
    setCategory(data.category);
    setMemo(data.memo);
    setBusinessHourList(data.businessHourList);
    setProfile(data.imageUrl);
  }, [data]);

  const createHandler = () => {
    if (name === "") {
      window.alert("이름을 확인해주세요.");
      return;
    }

    if (phone === "") {
      window.alert("전화번호가 입력되었는지 확인해주세요.");
      return;
    }

    if (category === "") {
      window.alert("분류를 선택했는지 확인해주세요.");
      return;
    }

    if (color === "") {
      window.alert("색상을 선택했는지 확인해주세요.");
      return;
    }

    createManager({
      shopId: shopData.id,
      name: name,
      imageUrl: profile,
      color: color,
      phoneNumber: phone,
      category: category,
      businessHourList: shopData.businessHourList,
    }).then((res) => {
      setISAdd(false);
      setIsEdit(false);
      saveCallback();
    });
  };

  const saveHandler = () => {
    if (name === "") {
      window.alert("이름을 확인해주세요.");
      return;
    }

    if (phone === "") {
      window.alert("전화번호가 입력되었는지 확인해주세요.");
      return;
    }

    if (category === "") {
      window.alert("분류를 선택했는지 확인해주세요.");
      return;
    }

    if (color === "") {
      window.alert("색상을 선택했는지 확인해주세요.");
      return;
    }

    saveManager({
      managerId: data.id,
      shopId: shopData.id,
      name: name,
      imageUrl: profile,
      color: color,
      phoneNumber: phone,
      category: category,
      businessHourList: businessHourList,
      description: description,
      memo: memo,
    }).then((res) => {
      setIsEdit(false);
      saveCallback();
    });
  };

  return (
    <Container>
      <TapBtnBox>
        <span
          style={{
            color: isLeftTap ? "#F14262" : "#A0A6B1",
            cursor: "pointer",
          }}
          onClick={() => setIsLeftTap(true)}
        >
          담당자정보
        </span>
        {!isAdd && (
          <span
            style={{
              color: !isLeftTap ? "#F14262" : "#A0A6B1",
              cursor: "pointer",
            }}
            onClick={() => setIsLeftTap(false)}
          >
            근무시간
          </span>
        )}
      </TapBtnBox>
      {isLeftTap ? (
        isEdit ? (
          <>
            <div
              style={{
                display: "flex",
                width: "100%",
                padding: "24px",
                boxSizing: "border-box",
                flexDirection: "column",
                gap: "24px",
                borderRadius: "16px",
                backgroundColor: "#F9FAFB",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: "16px", fontWeight: "700" }}>
                담당자 수정
              </span>
              <ProfileImgInput
                zoom={0.7}
                profile={profile}
                onChange={(e) => {
                  if (e.target.files?.length) {
                    setImage(e.target.files[0]);
                    setOpenCrop(true);
                  }
                }}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "8px",
                  flexDirection: "column",
                }}
              >
                <span style={{ fontSize: "16px", fontWeight: "700" }}>
                  담당자명
                </span>
                <InputBox
                  placeholder={""}
                  type={"text"}
                  width={250}
                  setValue={setName}
                  value={name}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "8px",
                  flexDirection: "column",
                }}
              >
                <span style={{ fontSize: "16px", fontWeight: "700" }}>
                  연락처
                </span>
                <InputBox
                  placeholder={""}
                  type={"phone"}
                  width={250}
                  setValue={setPhone}
                  value={phone}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "8px",
                  flexDirection: "column",
                }}
              >
                <span style={{ fontSize: "16px", fontWeight: "700" }}>
                  소개
                </span>
                <InputBox
                  placeholder={""}
                  type={"text"}
                  width={250}
                  setValue={setDescription}
                  value={description}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "8px",
                  flexDirection: "column",
                }}
              >
                <span style={{ fontSize: "16px", fontWeight: "700" }}>
                  분류
                </span>
                <div
                  style={{
                    width: "100%",
                    height: "44px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "24px",
                    padding: "11px 10px",
                    boxSizing: "border-box",
                    display: "flex",
                    borderRadius: "16px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  {["대표", "매니저", "디자이너", "직원"].map((item, idx) => (
                    <span
                      style={{
                        fontSize: "12px",
                        color:
                          changeManagerCategory(item, true) === category
                            ? "#F14262"
                            : "#8A94A6",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setCategory(changeManagerCategory(item, true))
                      }
                    >
                      {item}
                    </span>
                  ))}
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "8px",
                  flexDirection: "column",
                }}
              >
                <span style={{ fontSize: "16px", fontWeight: "700" }}>
                  색상
                </span>
                <div
                  style={{
                    width: "100%",
                    height: "104px",
                    gap: "24px",
                    padding: "20px",
                    boxSizing: "border-box",
                    display: "flex",
                    borderRadius: "16px",
                    backgroundColor: "#ffffff",
                    flexWrap: "wrap",
                  }}
                >
                  {[
                    "#EA4E3D",
                    "#F19A37",
                    "#FADA07",
                    "#5DC466",
                    "#69A8EC",
                    "#5856CE",
                    "#B57AD5",
                    "#FBB4C1",
                    "#998668",
                    "#5D666F",
                  ].map((item, idx) => (
                    <span
                      key={idx}
                      style={{
                        cursor: "pointer",
                        width: "16px",
                        height: "16px",
                        borderRadius: "50%",
                        backgroundColor: item,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        if (
                          managerList.map((item) => item.color).includes(item)
                        )
                          return;

                        setColor(item);
                      }}
                    >
                      {item === color && (
                        <CheckIcon
                          style={{
                            color: "#ffffff",
                            fontSize: "12px",
                          }}
                        />
                      )}
                      {managerList.map((item) => item.color).includes(item) && (
                        <IoIosClose
                          style={{
                            color: "#ffffff",
                            fontSize: "16px",
                          }}
                        />
                      )}
                    </span>
                  ))}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignSelf: "flex-end",
                  gap: "4px",
                  position: "relative",
                }}
              >
                <DeleteIcon
                  style={{
                    position: "absolute",
                    left: "-140px",
                    fontSize: "20px",
                    color: "#A0A6B1",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (data.category === "admin") {
                      window.alert("대표는 삭제할 수 없습니다.");
                      return;
                    }

                    const result = window.confirm("매니저를 삭제하시겠습니까?");

                    if (result) {
                      deleteManager({
                        managerId: data.id,
                      })
                        .then((res) => {
                          setISAdd(false);
                          setIsEdit(false);
                          saveCallback();
                        })
                        .catch((e) => {
                          if (e.response.status === 409) {
                            window.alert(
                              "매니저를 삭제 할 수 없습니다.\n예약 일정을 확인해 주세요."
                            );
                          }
                        });
                    }
                  }}
                />
                <div
                  style={{
                    width: "44px",
                    height: "24px",
                    borderRadius: "16px",
                    color: "#A0A6B1",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsEdit(false);
                  }}
                >
                  취소
                </div>
                <div
                  style={{
                    width: "44px",
                    height: "24px",
                    backgroundColor: "#F14262",
                    borderRadius: "16px",
                    color: "#ffffff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "10px",
                    cursor: "pointer",
                  }}
                  onClick={saveHandler}
                >
                  저장
                </div>
              </div>
            </div>
          </>
        ) : isAdd ? (
          <>
            <div
              style={{
                display: "flex",
                width: "100%",
                padding: "24px",
                boxSizing: "border-box",
                flexDirection: "column",
                gap: "24px",
                borderRadius: "16px",
                backgroundColor: "#F9FAFB",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: "16px", fontWeight: "700" }}>
                담당자 추가
              </span>
              <ProfileImgInput
                zoom={0.7}
                profile={profile}
                onChange={(e) => {
                  if (e.target.files?.length) {
                    setImage(e.target.files[0]);
                    setOpenCrop(true);
                  }
                }}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "8px",
                  flexDirection: "column",
                }}
              >
                <span style={{ fontSize: "16px", fontWeight: "700" }}>
                  담당자명
                </span>
                <InputBox
                  placeholder={""}
                  type={"text"}
                  width={250}
                  setValue={setName}
                  value={name}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "8px",
                  flexDirection: "column",
                }}
              >
                <span style={{ fontSize: "16px", fontWeight: "700" }}>
                  연락처
                </span>
                <InputBox
                  placeholder={""}
                  type={"phone"}
                  width={250}
                  setValue={setPhone}
                  value={phone}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "8px",
                  flexDirection: "column",
                }}
              >
                <span style={{ fontSize: "16px", fontWeight: "700" }}>
                  소개
                </span>
                <InputBox
                  placeholder={""}
                  type={"text"}
                  width={250}
                  setValue={setDescription}
                  value={description}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "8px",
                  flexDirection: "column",
                }}
              >
                <span style={{ fontSize: "16px", fontWeight: "700" }}>
                  분류
                </span>
                <div
                  style={{
                    width: "100%",
                    height: "44px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "24px",
                    padding: "11px 10px",
                    boxSizing: "border-box",
                    display: "flex",
                    borderRadius: "16px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  {["대표", "매니저", "디자이너", "직원"].map((item, idx) => (
                    <span
                      style={{
                        fontSize: "12px",
                        color:
                          changeManagerCategory(item, true) === category
                            ? "#F14262"
                            : "#8A94A6",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setCategory(changeManagerCategory(item, true))
                      }
                    >
                      {item}
                    </span>
                  ))}
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "8px",
                  flexDirection: "column",
                }}
              >
                <span style={{ fontSize: "16px", fontWeight: "700" }}>
                  색상
                </span>
                <div
                  style={{
                    width: "100%",
                    height: "104px",
                    gap: "24px",
                    padding: "20px",
                    boxSizing: "border-box",
                    display: "flex",
                    borderRadius: "16px",
                    backgroundColor: "#ffffff",
                    flexWrap: "wrap",
                  }}
                >
                  {[
                    "#EA4E3D",
                    "#F19A37",
                    "#FADA07",
                    "#5DC466",
                    "#69A8EC",
                    "#5856CE",
                    "#B57AD5",
                    "#FBB4C1",
                    "#998668",
                    "#5D666F",
                  ].map((item, idx) => (
                    <span
                      key={idx}
                      style={{
                        width: "16px",
                        height: "16px",
                        borderRadius: "50%",
                        backgroundColor: item,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        if (
                          managerList.map((item) => item.color).includes(item)
                        )
                          return;
                        setColor(item);
                      }}
                    >
                      {item === color && (
                        <CheckIcon
                          style={{
                            color: "#ffffff",
                            fontSize: "12px",
                          }}
                        />
                      )}
                      {managerList.map((item) => item.color).includes(item) && (
                        <IoIosClose
                          style={{
                            color: "#ffffff",
                            fontSize: "16px",
                          }}
                        />
                      )}
                    </span>
                  ))}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignSelf: "flex-end",
                  gap: "4px",
                }}
              >
                <div
                  style={{
                    width: "44px",
                    height: "24px",
                    borderRadius: "16px",
                    color: "#A0A6B1",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setFocused(-1);
                    setISAdd(false);
                  }}
                >
                  취소
                </div>
                <div
                  style={{
                    width: "44px",
                    height: "24px",
                    backgroundColor: "#F14262",
                    borderRadius: "16px",
                    color: "#ffffff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "10px",
                  }}
                  onClick={createHandler}
                >
                  저장
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <ManagerItem
              data={data}
              focused={false}
              onClick={() => {}}
              isEditBtn={true}
              onEditClick={() => {
                setIsEdit(true);
              }}
            />
            <MemoBox>
              <div>
                <span>메모</span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (isMemoEdit) {
                      saveHandler();
                    }
                    setIsMemoEdit((old) => !old);
                  }}
                >
                  {isMemoEdit ? "save" : "edit"}
                </span>
              </div>
              {isMemoEdit ? (
                <textarea
                  style={{ width: "100%", height: "100%" }}
                  onChange={(e) => {
                    setMemo(e.target.value);
                  }}
                  value={memo}
                />
              ) : (
                <div>{memo ? memo : "메모할 내용을 추가해주세요."}</div>
              )}
            </MemoBox>
            <HistoryBox>
              <span>최근 시술 내역</span>
              {!data.lastSales ? (
                <div>없음.</div>
              ) : (
                <div>
                  <span>
                    방문일 :{" "}
                    {new Date(data.lastSales.date).toLocaleDateString("ko-KR", {
                      year: "numeric",
                      month: "short",
                      day: "2-digit",
                    })}
                  </span>
                  <span>고객명 : {data.lastSales.name}</span>
                  <span>담당자 : {data.lastSales.managerName}</span>
                  <span>
                    시술명:{" "}
                    {`${data.lastSales.menuName} ${
                      data.lastSales.optionName &&
                      data.lastSales.optionName.length > 0
                        ? "외" + data.lastSales.optionName.length
                        : ""
                    }`}
                  </span>
                </div>
              )}
            </HistoryBox>
          </>
        )
      ) : (
        <>
          <WorkTime
            open={isEdit2}
            setOpen={setIsEdit2}
            list={businessHourList}
            setList={setBusinessHourList}
            callback={(list) => {
              saveManager({
                managerId: data.id,
                shopId: shopData.id,
                name: name,
                imageUrl: profile,
                color: color,
                phoneNumber: phone,
                category: data.category,
                businessHourList: list,
                description: data.description,
                memo: memo,
              }).then((res) => {
                setIsEdit(false);
                saveCallback();
              });
            }}
          />
        </>
      )}
      {openCrop && (
        <ImageCropModal
          image={image!}
          open={openCrop}
          setOpen={setOpenCrop}
          setData={setProfile}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 70%;
  height: 840px;
  background-color: #ffffff;

  border-radius: 16px;

  display: flex;
  padding: 24px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const TapBtnBox = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 11px 10px;
  justify-content: center;
  align-items: center;
  gap: 40px;
`;

const MemoBox = styled.div`
  display: flex;
  width: 278px;
  height: 248px;
  padding: 16px 24px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  border-radius: 16px;
  background: #f9fafb;

  & > div:first-child {
    display: flex;
    width: 100%;
    align-items: center;

    justify-content: space-between;

    & > span:first-child {
      color: #000000;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      line-height: 140%;
    }

    & > span:last-child {
      display: flex;
      width: 44px;
      height: 24px;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      background: #e5e7eb;

      color: #272f3e;
      font-size: 10px;
      font-weight: 500;
      line-height: 140%;
    }
  }

  & > div:last-child {
    display: flex;
    width: 100%;
  }
`;

const HistoryBox = styled.div`
  display: flex;
  width: 100%;
  height: 190px;
  box-sizing: border-box;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 16px;
  background: #f9fafb;

  & > span:first-child {
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    line-height: 140%;
  }

  & > div:last-child {
    display: flex;
    flex-direction: column;
    gap: 10px;

    & > span {
      color: #000000;
      font-size: 14px;
      font-weight: 400;
      line-height: 140%;
    }
  }
`;

export default SideBar;
