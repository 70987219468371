import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { RefrashCounterFcm, ShopData } from "../atoms/atom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { saveFcmToken } from "../apis/shop";
import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import { color_midnight } from "../util/color";
import SideNav from "../components/menu/SideNav";

const userAgent = window.navigator.userAgent;
const isMobileBrowser =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    userAgent
  );

const MainLayout = (props: { children: React.ReactNode; active: string }) => {
  const [zoom, setZoom] = useState(1.0);
  const setRefrashCounterFcm = useSetRecoilState(RefrashCounterFcm);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [shopData] = useRecoilState(ShopData);

  useEffect(() => {
    adjustZoom();

    console.log(navigator.serviceWorker);

    if (!isMobileBrowser && navigator.serviceWorker) {
      try {
        initializeFirebaseMessaging();
      } catch (error) {
        console.log(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const adjustZoom = () => {
    if (document.body.scrollHeight > window.innerHeight) {
      setZoom(
        1 -
          (document.body.scrollHeight - window.innerHeight) /
            document.body.scrollHeight
      );
    } else {
      setZoom((document.body.scrollHeight - 40) / 885);
    }
  };

  const initializeFirebaseMessaging = () => {
    const firebaseConfig = {
      apiKey: "AIzaSyD6Y-8jsAOaHZdFn6cIOuAA4Vp6_eZ4tUU",
      authDomain: "votrenaeil-mobileapp-pingp.firebaseapp.com",
      projectId: "votrenaeil-mobileapp-pingp",
      storageBucket: "votrenaeil-mobileapp-pingp.appspot.com",
      messagingSenderId: "580773173831",
      appId: "1:580773173831:web:9638fd58124e0f92922f62",
      measurementId: "G-8T23W6L25P",
    };
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);

    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        getToken(messaging, {
          vapidKey:
            "BHylver-p_sHQguRABhJS-ttRO47KINx-QhM5xH7yPOpWdtWvM0HiWY5ON8gF8NOJRkX7Ymlb4hMGSdxR9xfBX4",
        })
          .then((token) => {
            console.log("FCM token:", token);
            saveFcmToken(token);
          })
          .catch((error) => console.error("Error getting FCM token:", error));
      }
    });

    onMessage(messaging, (payload) => {
      console.log("Message received: ", payload);

      const title = payload.notification?.title || "";
      new Notification(title, {
        body: payload.notification?.body || "",
      });
      playNotificationSound();

      setRefrashCounterFcm((prev) => prev + 1);
    });
  };

  const playNotificationSound = () => {
    audioRef.current?.play().catch((error) => {
      console.error("Error playing notification sound:", error);
    });
  };

  return (
    <SendbirdProvider
      appId="53949A19-8F6E-45E5-BAE5-2D2DEEF48E15"
      userId={shopData.id}
      nickname={shopData.name}
    >
      <Container>
        <SideNav active={props.active} zoom={zoom} />
        <Main>{props.children}</Main>
        <audio ref={audioRef}>
          <source src="./notiBellSound.mp3" />
        </audio>
      </Container>
    </SendbirdProvider>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${color_midnight.lily};
  display: flex;
`;

const Main = styled.div`
  width: 100%;
`;

export default MainLayout;
