import React, { Dispatch, SetStateAction } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { styled } from "styled-components";

type propType = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  data: string;
  setData: Dispatch<SetStateAction<string>>;
  callback: () => void;
};

const NotiModal = ({ open, setOpen, data, callback, setData }: propType) => {
  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "612px",
            height: "363px",
            bgcolor: "background.paper",
            borderRadius: "16px",
            p: 4,
            boxShadow: "4px 4px 4px 0px #00000040",
            padding: "24px 0px 24px 0px",
            boxSizing: "border-box",
            outline: "none",
          }}
        >
          <Container>
            <h2
              style={{
                fontSize: "20px",
                fontWeight: "700",
                padding: "10px 0px",
              }}
            >
              샵 공지사항
            </h2>
            <div
              style={{
                width: "84%",
                height: "240px",
                color: "#A0A6B1",
                backgroundColor: "#F9FAFB",
                borderRadius: "8px",
                padding: "10px",
                position: "relative",
              }}
            >
              <textarea
                placeholder="샵 공지사항을 입력해주세요."
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#F9FAFB",
                  border: "none",
                  resize: "none",
                }}
                value={data}
                onChange={(e) => {
                  if (e.target.value.length <= 300) {
                    setData(e.target.value);
                  }
                }}
              />
              <span
                style={{
                  position: "absolute",
                  right: "10px",
                  bottom: "10px",
                  fontSize: "10px",
                }}
              >
                {data.length} / 300 byte
              </span>
            </div>
            <div
              style={{
                width: "100%",
                height: "24px",
                display: "flex",
                justifyContent: "flex-end",
                gap: "12px",
                paddingRight: "7%",
                boxSizing: "border-box",
              }}
            >
              <button
                style={{
                  color: "#ffffff",
                  backgroundColor: "#6C727F",
                  borderRadius: "16px",
                  width: "44px",
                  height: "24px",
                  border: "none",
                  fontSize: "10px",
                  cursor: "pointer",
                }}
                onClick={() => setOpen(false)}
              >
                취소
              </button>
              <button
                style={{
                  color: "#ffffff",
                  backgroundColor: "#F14262",
                  borderRadius: "16px",
                  width: "44px",
                  height: "24px",
                  border: "none",
                  fontSize: "10px",
                  cursor: "pointer",
                }}
                onClick={() => callback()}
              >
                저장
              </button>
            </div>
          </Container>
        </Box>
      </Modal>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 24px;

  align-items: center;
`;

export default NotiModal;
