import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { styled } from "styled-components";

type propType = {
  option: string[] | number[];
  defaultValue?: string | number;
  isTypeTag?: boolean;
  bgColor?: string;
  color?: string;
  disable?: boolean;
  isTypeColorChip?: boolean;
  setResult?: Dispatch<SetStateAction<string>>;
};

const Select = ({
  option,
  defaultValue,
  isTypeTag,
  bgColor,
  color,
  disable,
  isTypeColorChip,
  setResult,
}: propType) => {
  const [value, setValue] = useState<string | number>(
    defaultValue ? defaultValue : defaultValue === 0 ? 0 : ""
  );

  console.log(value);

  const [show, setShow] = useState(false);

  const typeTag = (type: number | string) => {
    if (isTypeColorChip) {
      switch (type) {
        case 1:
          return <ColorChip $backgroundColor="#EA4E3D"></ColorChip>;
        case 2:
          return <ColorChip $backgroundColor="#F19A37"></ColorChip>;
        case 3:
          return <ColorChip $backgroundColor="#FADA07"></ColorChip>;
        case 4:
          return <ColorChip $backgroundColor="#5DC466"></ColorChip>;
        case 5:
          return <ColorChip $backgroundColor="#69A8EC"></ColorChip>;
        case 6:
          return <ColorChip $backgroundColor="#3578F7"></ColorChip>;
        case 7:
          return <ColorChip $backgroundColor="#5856CE"></ColorChip>;
        case 8:
          return <ColorChip $backgroundColor="#D8506B"></ColorChip>;
        case 9:
          return <ColorChip $backgroundColor="#B57AD5"></ColorChip>;
        case 10:
          return <ColorChip $backgroundColor="#FBB4C1"></ColorChip>;
        case 11:
          return <ColorChip $backgroundColor="#998668"></ColorChip>;
        case 12:
          return <ColorChip $backgroundColor="#5D666F"></ColorChip>;
      }
    } else {
      switch (type) {
        case 0:
          return (
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {"전체"}
            </span>
          );
        case 1:
          return <Status $backgroundColor="#FCA2B3">시술예정</Status>;
        case 2:
          return <Status $backgroundColor="#3A66EC">시술완료</Status>;
        case 3:
          return <Status $backgroundColor="#F14262">시술취소</Status>;
        case 4:
          return <Status $backgroundColor="#272F3E">노쇼</Status>;
        case 5:
          return <Status $backgroundColor="#A0A6B1">확정대기</Status>;
      }
    }
  };

  const getValue = (num: number) => {
    if (isTypeColorChip) {
      switch (num) {
        case 1:
          return "#EA4E3D";
        case 2:
          return "#F19A37";
        case 3:
          return "#FADA07";
        case 4:
          return "#5DC466";
        case 5:
          return "#69A8EC";
        case 6:
          return "#3578F7";
        case 7:
          return "#5856CE";
        case 8:
          return "#D8506B";
        case 9:
          return "#B57AD5";
        case 10:
          return "#FBB4C1";
        case 11:
          return "#998668";
        case 12:
          return "#5D666F";
      }
    } else {
      switch (num) {
        case 0:
          return "";
        case 1:
          return "bookComplete";
        case 2:
          return "nailComplete";
        case 3:
          return "bookCanceled";
        case 4:
          return "noShow";
        case 5:
          return "bookRequest";
      }
    }

    return "";
  };

  const getNum = (color: string) => {
    if (isTypeColorChip) {
      switch (color) {
        case "#EA4E3D":
          return 1;
        case "#F19A37":
          return 2;
        case "#FADA07":
          return 3;
        case "#5DC466":
          return 4;
        case "#69A8EC":
          return 5;
        case "#3578F7":
          return 6;
        case "#5856CE":
          return 7;
        case "#D8506B":
          return 8;
        case "#B57AD5":
          return 9;
        case "#FBB4C1":
          return 10;
        case "#998668":
          return 11;
        case "#5D666F":
          return 12;
      }
    }

    return -1;
  };

  const clickOutside = () => {
    setShow(false);
  };

  useEffect(() => {
    document.body.addEventListener("click", clickOutside);

    return () => {
      document.body.removeEventListener("click", clickOutside);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (defaultValue) {
      if (isTypeColorChip) {
        setValue(getNum(defaultValue.toString()));
      } else {
        setValue(defaultValue);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, isTypeColorChip]);

  return (
    <Container
      $show={show}
      $bgColor={bgColor}
      $color={color}
      className="selectBox"
    >
      <div
        style={{ cursor: "pointer" }}
        onClickCapture={(e) => {
          e.stopPropagation();
          e.preventDefault();

          disable ? setShow(false) : setShow((old) => !old);
        }}
      >
        <span>{isTypeTag ? typeTag(value) : value}</span>
        <img src="/image/more.png" alt="" />
      </div>
      {option.map((item, i) => (
        <Option
          style={{ cursor: "pointer" }}
          key={i}
          onClickCapture={(e) => {
            e.stopPropagation();
            e.preventDefault();

            setShow((old) => !old);
            setValue(item);

            if (setResult) {
              isTypeTag ? setResult(getValue(i)) : setResult(item.toString());
            }
          }}
          $bgColor={bgColor}
          $color={color}
        >
          {isTypeTag ? typeTag(item) : item}
        </Option>
      ))}
    </Container>
  );
};

const Container = styled.div<{
  $show: boolean;
  $bgColor: string | undefined;
  $color: string | undefined;
}>`
  width: 144px;
  height: 44px;
  background-color: ${(props) => (props.$bgColor ? props.$bgColor : "#f4f5f7")};
  color: ${(props) => (props.$color ? props.$color : "#000000")};
  border-radius: ${(props) => (props.$show ? "8px 8px 0px 0px" : "8px")};

  position: relative;

  & > div:first-child {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > div:first-child > span {
    width: 90px;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.2px;
  }

  & > div:first-child > img {
    width: 8px;
    height: 4px;
  }

  & > div:last-child {
    border-radius: 0px 0px 8px 8px;
  }

  & > div {
    display: ${(props) => (props.$show ? "flex" : "none")};
  }
`;

const Option = styled.div<{
  $bgColor: string | undefined;
  $color: string | undefined;
}>`
  width: 144px;
  height: 40px;
  position: relative;

  z-index: 10;

  background-color: ${(props) => (props.$bgColor ? props.$bgColor : "#f9fafb")};
  color: ${(props) => (props.$color ? props.$color : "#000000")};

  display: none;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #ff5d7a;
    color: #ffffff;
  }
`;

const Status = styled.div<{ $backgroundColor: string }>`
  width: 53px;
  height: 22px;
  background-color: ${(props) => props.$backgroundColor};
  color: #ffffff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
`;

const ColorChip = styled.div<{ $backgroundColor: string }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;

  background-color: ${(props) => props.$backgroundColor};
`;

export default Select;
