import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { styled } from "styled-components";
import { changeMethod } from "../../util/util";
import { IoMdClose } from "react-icons/io";
import { IoMedical } from "react-icons/io5";
import { ManagerType, Payment } from "../../types";
import { color_green, color_midnight, color_rose } from "../../util/color";
import InputBox from "../utility/InputBox";
import Checkbox from "@mui/material/Checkbox";
import { IoSearch } from "react-icons/io5";
import Select2 from "../utility/Select2";
import { getCustomerList } from "../../apis/customer";
import { ShopData } from "../../atoms/atom";
import { useRecoilState } from "recoil";
import { saveSalesV2 } from "../../apis/sales";

type propType = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  data: any;
  callback?: () => void;
  managerList: any[];
};

const SalesHistoryModal2 = ({
  open,
  setOpen,
  data,
  callback,
  managerList,
}: propType) => {
  const [shopData] = useRecoilState(ShopData);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInput = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // 높이를 초기화하여 스크롤 높이를 정확히 계산
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // 내용에 맞게 높이 조정
    }
  };

  const [isSalesEdit, setIsSalesEdit] = useState(false);

  const [isSelCustomerOpen, setIsSelCustomerOpen] = useState(false);
  const [category, setCategory] = useState(data.salesCategory); // product or etc
  const [manager, setManager] = useState<ManagerType>(
    managerList.filter((e) => e.name === data.managerName)[0]
  );
  const [menuName, setMenuName] = useState(data.menuName);

  const [memo, setMemo] = useState(data.memo);

  const [name, setName] = useState(data.customerName ? data.customerName : "");
  const [phone, setPhone] = useState(
    data.customerPhoneNumber ? data.customerPhoneNumber : ""
  );

  const [searchText, setSearchText] = useState("");
  const [customerList, setCustomerList] = useState<any[]>([]);
  const [userCount, setUserCount] = useState(0);

  const [customerId, setCustomerId] = useState(data.customerId);

  const [checked, setChecked] = useState<boolean>(data.customerId);

  const [isPayed1, setIsPayed1] = useState(false);
  const [isPayed2, setIsPayed2] = useState(false);
  const [isPayed3, setIsPayed3] = useState(false);
  const [isPayed4, setIsPayed4] = useState(false);

  const [price1, setPrice1] = useState("");
  const [price2, setPrice2] = useState("");
  const [price3, setPrice3] = useState("");
  const [price4, setPrice4] = useState("");

  console.log(data);

  useEffect(() => {
    const card = data.salesPayment.filter(
      (e: any) => e.paymentMethod === "card"
    );

    if (card.length > 0) {
      setIsPayed1(true);
      setPrice1(card[0].price);
    }

    const cash = data.salesPayment.filter(
      (e: any) => e.paymentMethod === "cash"
    );

    if (cash.length > 0) {
      setIsPayed2(true);
      setPrice2(cash[0].price);
    }

    const ticket = data.salesPayment.filter(
      (e: any) => e.paymentMethod === "ticket"
    );

    if (ticket.length > 0) {
      setIsPayed3(true);
      setPrice3(ticket[0].price);
    }

    const account = data.salesPayment.filter(
      (e: any) => e.paymentMethod === "account"
    );

    if (account.length > 0) {
      setIsPayed4(true);
      setPrice4(account[0].price);
    }
  }, []);

  useEffect(() => {
    setCustomerList([]);

    const searchType = searchText
      ? /\d/.test(searchText)
        ? "phone"
        : "name"
      : "";

    getCustomerList(shopData.id, "regular", "ASC", 1, searchType, searchText)
      .then((res) => {
        setUserCount(res.data.data.customerCount);
        setCustomerList(res.data.data.customerList);
      })
      .catch((e) => console.log(e));
  }, [searchText, shopData]);

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isSalesEdit ? (
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "660px",
              height: "880px",
              borderRadius: "16px",
              boxShadow: "4px 4px 4px 0px #00000040",
              boxSizing: "border-box",
              backgroundColor: "#F9FAFB",
              overflow: "auto",
              zoom: 0.9,
            }}
          >
            <Container>
              <div
                style={{
                  width: "100%",
                  height: "90px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#ffffff",
                }}
              >
                <h2 style={{ fontSize: "20px", fontWeight: "700" }}>
                  매출 등록 내역
                </h2>
                <IoMdClose
                  style={{
                    position: "absolute",
                    right: "32px",
                    fontSize: "32px",
                    cursor: "pointer",
                  }}
                  onClick={() => setOpen(false)}
                />
              </div>
              <div
                style={{
                  overflow: "auto",
                  height: "766px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ width: "608px", marginTop: "24px" }}>
                  <div
                    style={{
                      width: "608px",
                      backgroundColor: "#ffffff",
                      paddingLeft: "30px",
                      padding: "26px 30px",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      boxSizing: "border-box",
                      borderRadius: "16px",
                      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                      marginBottom: "24px",
                    }}
                  >
                    <Line>
                      <Title>카테고리</Title>
                      <span style={{ color: "#000000", fontSize: "16px" }}>
                        {data.salesCategory === "item" ? "물품" : "기타"}
                      </span>
                    </Line>
                    <Line>
                      <Title>품목명</Title>
                      <span style={{ color: "#000000", fontSize: "16px" }}>
                        {data.menuName}
                      </span>
                    </Line>
                    <Line>
                      <Title>결제금액</Title>
                      <span style={{ color: "#000000", fontSize: "16px" }}>
                        {data.price
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원"}
                      </span>
                    </Line>
                    <div
                      style={{
                        width: "100%",
                        minHeight: "44px",
                        display: "flex",
                        alignItems: "center",
                        boxSizing: "border-box",
                        gap: "12px",
                      }}
                    >
                      <Title>결제 방법</Title>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                          padding: "10px 0px",
                        }}
                      >
                        {data.salesPayment.map((item: any) => (
                          <span style={{ color: "#000000", fontSize: "16px" }}>
                            {changeMethod(item.paymentMethod, false)}{" "}
                            {item.price
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원"}
                          </span>
                        ))}
                      </div>
                    </div>
                    <Line>
                      <Title>담당자</Title>
                      <span style={{ color: "#000000", fontSize: "16px" }}>
                        {data.managerName}
                      </span>
                    </Line>
                  </div>
                  {data.customerId && (
                    <div
                      style={{
                        width: "608px",
                        backgroundColor: "#ffffff",
                        paddingLeft: "30px",
                        padding: "16px 30px",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                        boxSizing: "border-box",
                        borderRadius: "16px",
                        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                        marginBottom: "24px",
                      }}
                    >
                      <Line>
                        <Title>고객정보</Title>
                        <span style={{ color: "#000000", fontSize: "16px" }}>
                          {name} {phone}
                        </span>
                      </Line>
                    </div>
                  )}
                  <div
                    style={{
                      width: "608px",
                      backgroundColor: "#ffffff",
                      paddingLeft: "30px",
                      padding: "16px 30px",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      boxSizing: "border-box",
                      borderRadius: "16px",
                      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                      marginBottom: "24px",
                    }}
                  >
                    <Line>
                      <Title>매출등록일</Title>
                      <span style={{ color: "#000000", fontSize: "16px" }}>
                        {new Date().toLocaleDateString()}
                      </span>
                    </Line>
                  </div>
                  <div
                    style={{
                      width: "608px",
                      backgroundColor: "#ffffff",
                      paddingLeft: "30px",
                      padding: "16px 30px",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      boxSizing: "border-box",
                      borderRadius: "16px",
                      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                      gap: "12px",
                      marginBottom: "24px",
                    }}
                  >
                    <Line style={{ height: "100%" }}>
                      <Title>매출 메모</Title>
                      <div
                        style={{
                          display: "flex",
                          gap: "12px",
                          color: "#000000",
                          fontSize: "16px",
                        }}
                      >
                        <span
                          style={{
                            width: "462px",
                            minHeight: "100px",
                            backgroundColor: "#F4F5F7",
                            boxSizing: "border-box",
                            padding: "16px 16px",
                            borderRadius: "10px",
                          }}
                        >
                          <textarea
                            ref={textareaRef}
                            style={{
                              width: "100%",
                              height: "100%",
                              backgroundColor: "#F4F5F7",
                              border: "none",
                              outline: "none",
                              resize: "none",
                            }}
                            placeholder="매출에 관련된 메모를 추가해보세요"
                            onInput={handleInput}
                            value={data.memo}
                            disabled={true}
                          />
                        </span>
                      </div>
                    </Line>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "44px",
                      display: "flex",
                      justifyContent: "center",
                      paddingBottom: "32px",
                      paddingTop: data.customerId ? "0px" : "100px",
                    }}
                  >
                    <span
                      style={{
                        width: "184px",
                        height: "44px",
                        backgroundColor: "#272F3E",
                        color: "#ffffff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setIsSalesEdit(true);
                      }}
                    >
                      수정
                    </span>
                  </div>
                </div>
              </div>
            </Container>
          </Box>
        ) : (
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: isSelCustomerOpen ? "1280px" : "680px",
              height: "880px",
              borderRadius: "16px",
              p: 4,
              boxShadow: "4px 4px 4px 0px #00000040",
              boxSizing: "border-box",
              backgroundColor: "#F9FAFB",
              zoom: 0.9,
              padding: 0,
              overflow: "hidden",
              display: "flex",
            }}
          >
            <Container>
              <div
                style={{
                  width: "100%",
                  maxWidth: "680px",
                  height: "90px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#ffffff",
                  position: "relative",
                }}
              >
                <h2 style={{ fontSize: "20px", fontWeight: "700" }}>
                  매출 추가
                </h2>
                <IoMdClose
                  style={{
                    position: "absolute",
                    right: "32px",
                    fontSize: "32px",
                    cursor: "pointer",
                  }}
                  onClick={() => setOpen(false)}
                />
              </div>

              <div
                style={{
                  overflow: "auto",
                  height: "766px",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "24px",
                }}
              >
                <div style={{ width: "608px" }}>
                  <div
                    style={{
                      width: "608px",
                      backgroundColor: "#ffffff",
                      paddingLeft: "30px",
                      padding: "16px 30px",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      boxSizing: "border-box",
                      borderRadius: "16px",
                      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                      gap: "12px",
                      marginBottom: "24px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Title>카테고리</Title>
                      <div style={{ display: "flex", gap: "12px" }}>
                        <div
                          style={{
                            width: "120px",
                            height: "44px",
                            backgroundColor:
                              category !== "item"
                                ? color_midnight.midnight1
                                : color_rose.rose8,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "22px",
                            color:
                              category !== "item"
                                ? color_midnight.midnight4
                                : "#ffffff",
                            cursor: "pointer",
                          }}
                          onClick={() => setCategory("item")}
                        >
                          물품
                        </div>
                        <div
                          style={{
                            width: "120px",
                            height: "44px",
                            backgroundColor:
                              category !== "etc"
                                ? color_midnight.midnight1
                                : color_rose.rose8,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "22px",
                            color:
                              category !== "etc"
                                ? color_midnight.midnight4
                                : "#ffffff",
                            cursor: "pointer",
                          }}
                          onClick={() => setCategory("etc")}
                        >
                          기타
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Title>품목명</Title>
                      <InputBox
                        placeholder={"품목명과 수량 등 정보를 입력하세요."}
                        type={"text"}
                        width={480}
                        setValue={setMenuName}
                        value={menuName}
                        noReset={true}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      width: "608px",
                      backgroundColor: "#ffffff",
                      paddingLeft: "30px",
                      padding: "16px 30px",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      boxSizing: "border-box",
                      borderRadius: "16px",
                      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                      gap: "12px",
                      marginBottom: "24px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <Checkbox
                        sx={{
                          color: "#000",
                          "&.Mui-checked": {
                            color: color_rose.rose8,
                          },
                        }}
                        defaultChecked={checked}
                        value={checked}
                        onChange={(e) => setChecked(e.target.checked)}
                      />
                      고객정보 입력
                    </div>
                    {checked && (
                      <>
                        <div
                          style={{
                            width: "100%",
                            height: "44px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: color_green.green6,
                            borderRadius: "10px",
                            color: "#fff",
                            cursor: "pointer",
                            gap: "4px",
                          }}
                          onClick={() => {
                            setIsSelCustomerOpen((old) => !old);
                          }}
                        >
                          <IoSearch />
                          고객 검색
                        </div>
                        <div style={{ display: "flex", gap: "20px" }}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Title>고객명</Title>
                            <InputBox
                              placeholder={"고객명"}
                              type={"text"}
                              width={132}
                              setValue={setName}
                              value={name}
                            />
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Title>휴대폰 번호</Title>
                            <InputBox
                              placeholder={"휴대폰 번호"}
                              type={"text"}
                              width={226}
                              setValue={setPhone}
                              value={phone}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    style={{
                      width: "608px",
                      backgroundColor: "#ffffff",
                      paddingLeft: "30px",
                      padding: "16px 30px",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      boxSizing: "border-box",
                      borderRadius: "16px",
                      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                      gap: "12px",
                      marginBottom: "24px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        boxSizing: "border-box",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Title>담당자</Title>
                      <div
                        style={{
                          width: "84%",
                          height: "44px",
                          borderRadius: "8px",
                          display: "flex",
                          gap: "16px",
                        }}
                      >
                        <Select2
                          option={managerList}
                          setResult={setManager}
                          defaultValue={data.managerName}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "608px",
                      backgroundColor: "#ffffff",
                      paddingLeft: "30px",
                      padding: "16px 30px",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      boxSizing: "border-box",
                      borderRadius: "16px",
                      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                      gap: "24px",
                      marginBottom: "24px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        boxSizing: "border-box",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Title>결제 금액</Title>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          sx={{
                            color: "#000",
                            "&.Mui-checked": {
                              color: color_rose.rose8,
                            },
                          }}
                          checked={isPayed1}
                          onChange={(e) => setIsPayed1(e.target.checked)}
                        />
                        <span style={{ width: "80px" }}>카드</span>
                        <InputBox
                          type="money"
                          width={180}
                          placeholder={""}
                          setValue={setPrice1}
                          value={price1}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          sx={{
                            color: "#000",
                            "&.Mui-checked": {
                              color: color_rose.rose8,
                            },
                          }}
                          checked={isPayed2}
                          onChange={(e) => setIsPayed2(e.target.checked)}
                        />
                        <span style={{ width: "80px" }}>현금</span>
                        <InputBox
                          type="money"
                          width={180}
                          placeholder={""}
                          setValue={setPrice2}
                          value={price2}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          sx={{
                            color: "#000",
                            "&.Mui-checked": {
                              color: color_rose.rose8,
                            },
                          }}
                          checked={isPayed3}
                          onChange={(e) => setIsPayed3(e.target.checked)}
                        />
                        <span style={{ width: "80px" }}>정액권</span>
                        <InputBox
                          type="money"
                          width={180}
                          placeholder={""}
                          setValue={setPrice3}
                          value={price3}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          sx={{
                            color: "#000",
                            "&.Mui-checked": { color: color_rose.rose8 },
                          }}
                          checked={isPayed4}
                          onChange={(e) => setIsPayed4(e.target.checked)}
                        />
                        <span style={{ width: "80px" }}>계좌이체</span>
                        <InputBox
                          type="money"
                          width={180}
                          placeholder={""}
                          setValue={setPrice4}
                          value={price4}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        boxSizing: "border-box",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Title>총 금액</Title>
                      <InputBox
                        placeholder={""}
                        type={"money"}
                        width={180}
                        setValue={function (
                          value: React.SetStateAction<string>
                        ): void {
                          throw new Error("Function not implemented.");
                        }}
                        value={[
                          isPayed1 && price1 ? price1 : 0,
                          isPayed2 && price2 ? price2 : 0,
                          isPayed3 && price3 ? price3 : 0,
                          isPayed4 && price4 ? price4 : 0,
                        ]
                          .map((e) => parseInt(e.toString()))
                          .reduce((sum, i) => sum + i)
                          .toString()}
                        disabled={true}
                        noReset={true}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      width: "608px",
                      backgroundColor: "#ffffff",
                      paddingLeft: "30px",
                      padding: "16px 30px",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      boxSizing: "border-box",
                      borderRadius: "16px",
                      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                      gap: "12px",
                      marginBottom: "24px",
                    }}
                  >
                    <Line style={{ height: "100%" }}>
                      <Title>매출 메모</Title>
                      <div
                        style={{
                          display: "flex",
                          gap: "12px",
                          color: "#000000",
                          fontSize: "16px",
                        }}
                      >
                        <span
                          style={{
                            width: "462px",
                            minHeight: "100px",
                            backgroundColor: "#F4F5F7",
                            boxSizing: "border-box",
                            padding: "16px 16px",
                            borderRadius: "10px",
                          }}
                        >
                          <textarea
                            ref={textareaRef}
                            style={{
                              width: "100%",
                              height: "100%",
                              backgroundColor: "#F4F5F7",
                              border: "none",
                              outline: "none",
                              resize: "none",
                            }}
                            placeholder="메모"
                            onInput={handleInput}
                            value={memo}
                            onChange={(event) => {
                              setMemo(event.target.value);
                            }}
                          />
                        </span>
                      </div>
                    </Line>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingBottom: "32px",
                      gap: "24px",
                    }}
                  >
                    <span
                      style={{
                        width: "184px",
                        height: "44px",
                        borderRadius: "8px",
                        backgroundColor: color_midnight.midnight2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#000000",
                        fontSize: "14px",
                      }}
                      onClick={() => {
                        setIsSalesEdit(false);
                      }}
                    >
                      취소하기
                    </span>
                    <span
                      style={{
                        width: "184px",
                        height: "44px",
                        borderRadius: "8px",
                        backgroundColor: "#F14262",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#ffffff",
                        fontSize: "14px",
                      }}
                      onClick={() => {
                        if (!manager) {
                          window.alert("담당자를 선택해주세요.");
                          return;
                        }

                        const findPaymentId = (method: string): string =>
                          (
                            data.salesPayment.find(
                              (e: any) => e.paymentMethod === method
                            ) || {}
                          ).id || "";

                        const createPayment = (
                          isPayed: boolean,
                          method: string,
                          price: string
                        ): Payment | undefined => {
                          if (!isPayed) return undefined;

                          const id = findPaymentId(method);

                          // id가 빈 문자열일 경우에는 추가하지 않음
                          return id
                            ? { paymentMethod: method, price, id }
                            : { paymentMethod: method, price };
                        };

                        const salesPayment = [
                          createPayment(isPayed1, "card", price1),
                          createPayment(isPayed2, "cash", price2),
                          createPayment(isPayed3, "ticket", price3),
                          createPayment(isPayed4, "account", price4),
                        ].filter((e) => e) as Payment[];

                        saveSalesV2({
                          shopId: shopData.id,
                          managerId: manager.id,
                          managerName: manager.name,
                          salesCategory: category,
                          menuName: menuName,
                          price: [
                            isPayed1 && price1 ? price1 : 0,
                            isPayed2 && price2 ? price2 : 0,
                            isPayed3 && price3 ? price3 : 0,
                            isPayed4 && price4 ? price4 : 0,
                          ]
                            .map((e) => parseInt(e.toString()))
                            .reduce((sum, i) => sum + i)
                            .toString(),
                          date: new Date().toISOString(),
                          duration: "0",
                          menuCategory: "",
                          memo: memo,
                          customerId: checked ? customerId : "",
                          id: data.id,
                          salesPayment: salesPayment,
                        }).then((res) => {
                          if (callback) {
                            callback();
                          }
                          setOpen(false);
                        });
                      }}
                    >
                      수정하기
                    </span>
                  </div>
                </div>
              </div>
            </Container>
            {isSelCustomerOpen && (
              <div
                style={{
                  width: "600px",
                  height: "100%",
                  paddingRight: "3%",
                  paddingLeft: "3%",
                  boxSizing: "border-box",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "600px",
                    height: "800px",
                    backgroundColor: "#ffffff",
                    border: "1px solid #cccccc",
                    borderRadius: "8px",
                    boxSizing: "border-box",
                    padding: "24px",
                    gap: "16px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#F4F5F7",
                      width: "100%",
                      height: "44px",
                      borderRadius: "8px",
                      boxSizing: "border-box",
                      padding: "0px 10px",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <input
                      type="text"
                      style={{
                        width: "230px",
                        height: "24px",
                        border: "none",
                        backgroundColor: "transparent",
                        outline: "none",
                      }}
                      placeholder="이름, 휴대폰번호 검색"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                    <IoSearch style={{ fontSize: "24px" }} />
                  </div>
                  <div>고객 {userCount}</div>
                  <hr
                    style={{
                      width: "100%",
                      height: "1px",
                      border: "none",
                      backgroundColor: "#cccccc",
                    }}
                  />
                  <div
                    style={{
                      width: "100%",
                      height: "500px",
                      overflowY: "scroll",
                    }}
                  >
                    {customerList.map((item, idx) => (
                      <div
                        key={idx}
                        style={{
                          width: "100%",
                          height: "60px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          gap: "8px",
                          boxSizing: "border-box",
                          padding: "0px 10px",
                          cursor: "pointer",
                          borderRadius: "8px",
                        }}
                        className="customerItem"
                        onClick={() => {
                          setName(item.customerName);
                          setPhone(item.customerPhoneNumber);

                          setCustomerId(item.customerId);
                        }}
                      >
                        <span
                          style={{
                            color: "#000000",
                            fontSize: "16px",
                          }}
                        >
                          {item.customerName}
                          {item.isRegular && (
                            <IoMedical
                              style={{ color: "#62D4D2", fontSize: "12px" }}
                            />
                          )}
                        </span>
                        <span
                          style={{
                            color: "#A0A6B1",
                            fontSize: "12px",
                          }}
                        >
                          {item.customerPhoneNumber}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </Box>
        )}
      </Modal>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;

  & > hr {
    width: 90%;
    color: #e5e7eb;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 44px;

  display: flex;
  align-items: center;

  box-sizing: border-box;

  gap: 12px;
`;

const Title = styled.span`
  width: 86px;
  font-size: 16px;
  font-weight: 700;
  color: #4d5562;
`;
export default SalesHistoryModal2;
