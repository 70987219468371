import React, {
  useState,
  createRef,
  useEffect,
  SetStateAction,
  Dispatch,
} from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { styled } from "styled-components";

type propType = {
  imgsrc: File;
  setCropData: Dispatch<SetStateAction<string>>;
  onClose: () => void;
};

const CropImg = ({ imgsrc, setCropData, onClose }: propType) => {
  const [image, setImage] = useState("");
  const cropperRef = createRef<ReactCropperElement>();

  useEffect(() => {
    cropperRef.current?.cropper.setAspectRatio(1);

    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(imgsrc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      onClose();
      /*fetch(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], "cropImg.png", { type: blob.type });
          console.log(file); //File object
        });*/
    }
  };

  return (
    <Container>
      <div style={{ width: "100%", backgroundColor: "gray" }}>
        <Cropper
          ref={cropperRef}
          style={{ height: "400px", width: "100%" }}
          zoomTo={0.5}
          initialAspectRatio={1}
          preview=".img-preview"
          src={image}
          viewMode={1}
          background={false}
          responsive={false}
          autoCropArea={1}
          checkOrientation={false}
          guides={true}
          cropBoxResizable={true}
          dragMode={"none"}
          zoomable={false}
        />
      </div>
      <button onClickCapture={getCropData}>완료</button>
    </Container>
  );
};

const Container = styled.div`
  & .box {
    display: inline-block;
    padding: 10px;
    box-sizing: border-box;
  }
  & .img-preview {
    overflow: hidden;
  }

  & > button {
    border: none;
    width: 80px;
    height: 40px;
    border-radius: 8px;
    background-color: #ff5d7a;
    color: #ffffff;

    position: absolute;
    right: 20px;
    bottom: 20px;
  }
`;

export default CropImg;
