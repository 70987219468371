import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import MenuItem from "./MenuItem";
import { useRecoilState } from "recoil";
import { MyShopIsEdit, ShopData } from "../../atoms/atom";
import {
  deleteMenu,
  deleteOption,
  getMenuList,
  getOptionList,
  saveMenuOrder,
  saveOptionOrder,
} from "../../apis/menu";
import { SurgeryMenuType, SurgeryOptionType } from "../../types";
import OptionItem from "./OptionItem";
import AddMenuItem from "./AddMenuItem";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { color_green } from "../../util/color";

// 항목 재정렬 함수
const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const SurgeryMenu = () => {
  const [activeCategory, setActiveCategory] = useState("네일");
  const [shopData] = useRecoilState(ShopData);
  const [, setMyShopIsEdit] = useRecoilState(MyShopIsEdit);

  const [, setOpenWaiting] = useState(false);
  const [isReordering, setIsReordering] = useState(false); // State to control reordering

  const [nailMenu, setNailMenu] = useState<SurgeryMenuType[]>([]);
  const [pediMenu, setPediMenu] = useState<SurgeryMenuType[]>([]);
  const [etcMenu, setEtcMenu] = useState<SurgeryMenuType[]>([]);

  const [optionAll, setOptionAll] = useState<SurgeryOptionType[]>([]);
  const [editKey, setEditKey] = useState("");
  const [updateCounter, setUpdateCounter] = useState(0);

  const changeCategory = (data: string, option: boolean) => {
    const enList = ["nail", "pedi", "care", "etc", "extension", "removal"];
    const krList = ["네일", "패디", "케어", "기타", "연장", "제거"];
    return option ? enList[krList.indexOf(data)] : krList[enList.indexOf(data)];
  };

  useEffect(() => {
    setEditKey("");
    setMyShopIsEdit(false);

    getMenuList(shopData.id).then((res) => {
      setNailMenu(
        res.data.data.menuList.filter((item: any) => item.category === "nail")
      );

      setPediMenu(
        res.data.data.menuList.filter((item: any) => item.category === "pedi")
      );

      setEtcMenu(
        res.data.data.menuList.filter((item: any) => item.category === "etc")
      );
    });

    getOptionList(shopData.id).then((res) => {
      setOptionAll(res.data.data.optionList);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCounter]);

  useEffect(() => {
    if (editKey === "") {
      setMyShopIsEdit(false);
    } else {
      setMyShopIsEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editKey]);

  // 항목이 드래그 후 드롭되었을 때 호출되는 함수
  const onDragEnd = (result: any) => {
    if (!result.destination || !isReordering) return;

    const { source, destination } = result;

    if (activeCategory === "네일") {
      const updatedMenu = reorder(nailMenu, source.index, destination.index);
      setNailMenu(updatedMenu);
    } else if (activeCategory === "패디") {
      const updatedMenu = reorder(pediMenu, source.index, destination.index);
      setPediMenu(updatedMenu);
    } else if (activeCategory === "기타") {
      const updatedMenu = reorder(etcMenu, source.index, destination.index);
      setEtcMenu(updatedMenu);
    } else if (activeCategory === "옵션") {
      const updatedMenu = reorder(optionAll, source.index, destination.index);
      setOptionAll(updatedMenu);
    }
  };

  // Function to save the new order
  const saveOrder = () => {
    if (!isReordering) {
      setIsReordering(true);
      return;
    }

    // Call the API with the new order
    if (activeCategory !== "옵션") {
      saveMenuOrder(
        [...nailMenu, ...pediMenu, ...etcMenu].map((e) => e.id)
      ).then(() => {
        setIsReordering(false);
        alert("순서가 저장되었습니다.");
      });
    } else {
      saveOptionOrder(optionAll.map((e) => e.id)).then(() => {
        setIsReordering(false);
        alert("순서가 저장되었습니다.");
      });
    }
  };

  return (
    <Container>
      <MenuBar>
        <Title1>카테고리</Title1>
        {["네일", "패디", "기타"].map((item, idx) => (
          <MenuBtn
            key={idx}
            $active={item === activeCategory}
            onClick={() => {
              if (editKey !== "") {
                const result = window.confirm(
                  "수정 사항이 저장되지 않았습니다. 정말 이동하시겠습니까?"
                );
                if (result) {
                  setEditKey("");
                  setActiveCategory((old) => (old === item ? "" : item));
                }
              } else {
                setEditKey("");
                setActiveCategory((old) => (old === item ? "" : item));
              }
            }}
          >
            {item}
          </MenuBtn>
        ))}
        {["옵션"].map((item, idx) => (
          <MenuBtn
            style={{
              backgroundColor: item === activeCategory ? "#62D4D2" : "#E5E7EB",
            }}
            key={idx}
            $active={item === activeCategory}
            onClick={() => {
              setEditKey("");
              setActiveCategory((old) => (old === item ? "" : item));
            }}
          >
            {item}
          </MenuBtn>
        ))}
        <div
          style={{
            position: "absolute",
            right: "200px",
            backgroundColor: isReordering
              ? color_green.green2
              : color_green.green6,
            width: "100px",
            height: "44px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: isReordering ? color_green.green6 : "#fff",
            borderRadius: "22px",
            cursor: "pointer",
          }}
          onClick={saveOrder} // Enable reordering
        >
          {isReordering ? "순서 저장" : "순서 변경"}
        </div>
        <MenuAddBtn
          onClick={() => {
            if (isReordering) {
              window.alert("순서 변경중엔 새로 생성하실 수 없습니다.");
              return;
            }

            setEditKey("isEdit");
          }}
        >
          메뉴추가 +
        </MenuAddBtn>
      </MenuBar>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="menuList">
          {(provided) => (
            <MenuList ref={provided.innerRef} {...provided.droppableProps}>
              {editKey === "isEdit" && (
                <AddMenuItem
                  deleteCallback={() => {}}
                  cancleOnclick={() => setEditKey("")}
                  shopId={shopData.id}
                  category={changeCategory(activeCategory, true)}
                  presubmitCallback={() => {
                    setOpenWaiting(true);
                  }}
                  submitCallback={() => {
                    setOpenWaiting(false);
                    setEditKey("");

                    setUpdateCounter((old) => old + 1);
                  }}
                />
              )}
              {activeCategory === "옵션"
                ? optionAll.map((item, idx) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={idx}
                      isDragDisabled={!isReordering}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            opacity: snapshot.isDragging ? 1 : 1,
                          }}
                        >
                          <OptionItem
                            isReordering={isReordering}
                            key={item.id}
                            data={item}
                            presubmitCallback={() => {
                              setOpenWaiting(true);
                            }}
                            submitCallback={() => {
                              getOptionList(shopData.id).then((res) => {
                                setOptionAll(res.data.data.optionList);
                                setOpenWaiting(false);
                              });
                            }}
                            deleteCallback={() => {
                              const result =
                                window.confirm("옵션을 삭제하시겠습니까?");
                              if (result) {
                                setOpenWaiting(true);
                                deleteOption({ optionsIdList: [item.id] }).then(
                                  () => {
                                    setOpenWaiting(false);
                                    setUpdateCounter((old) => old + 1);
                                  }
                                );
                              }
                            }}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))
                : ("네일" === activeCategory
                    ? nailMenu
                    : "패디" === activeCategory
                    ? pediMenu
                    : etcMenu
                  ).map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                      isDragDisabled={!isReordering} // Disable dragging if not reordering
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            opacity: snapshot.isDragging ? 1 : 1,
                          }}
                        >
                          <MenuItem
                            isReordering={isReordering}
                            key={index}
                            data={item}
                            editKey={editKey}
                            setEditKey={setEditKey}
                            presubmitCallback={() => {
                              setOpenWaiting(true);
                            }}
                            submitCallback={() => {
                              setUpdateCounter((old) => old + 1);
                              setOpenWaiting(false);
                            }}
                            deleteCallback={() => {
                              const result =
                                window.confirm("메뉴를 삭제하시겠습니까?");
                              if (result) {
                                setOpenWaiting(true);
                                deleteMenu({
                                  menuIdList: [item.id],
                                }).then(() => {
                                  setOpenWaiting(false);
                                  setUpdateCounter((old) => old + 1);
                                });
                              }
                            }}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
              {provided.placeholder}
            </MenuList>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
`;

const MenuBar = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  align-items: flex-start;
  gap: 19px;
  align-items: center;
  position: relative;
`;

const MenuList = styled.div`
  width: 100%;
  height: 600px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Title1 = styled.span`
  color: #f14262;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
`;

const MenuBtn = styled.div<{ $active: boolean }>`
  cursor: pointer;
  display: flex;
  height: 44px;
  padding: 10px 24px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 25px;
  background-color: ${(props) => (props.$active ? "#f14262" : "#E5E7EB")};
  color: ${(props) => (props.$active ? "#ffffff" : "#6C727F")};
`;

const MenuAddBtn = styled.div`
  cursor: pointer;
  display: flex;
  width: 160px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: #f14262;
  position: absolute;
  right: 0px;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  &:hover {
    background-color: #fd7f96;
  }
`;

export default SurgeryMenu;
