import React from "react";
import { styled } from "styled-components";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

type propType = {
  profile: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  zoom?: number;
};

const ProfileImgInput = ({ profile, onChange, zoom }: propType) => {
  return (
    <Container $profileImg={profile} $zoom={zoom ? zoom : 1}>
      <input type="file" onChange={(e) => onChange(e)} />
      <AddAPhotoIcon
        sx={{
          width: "20px",
          height: "20px",
          backgroundColor: "#272F3E",
          borderRadius: "50%",
          position: "absolute",
          right: 0,
          bottom: 0,
          color: "#ffffff",
          padding: "8px",
        }}
      />
    </Container>
  );
};

const Container = styled.div<{ $profileImg: string; $zoom: number }>`
  cursor: pointer;
  width: 96px;
  height: 96px;
  border-radius: 50%;

  zoom: ${(props) => props.$zoom};

  background-color: #d9d9d9;
  ${(props) =>
    props.$profileImg ? `background-image: url(${props.$profileImg});` : ""}
  background-size: cover;

  position: relative;

  & > input {
    width: 100%;
    height: 100%;
    color: transparent;
    cursor: pointer;

    &::file-selector-button {
      display: none;
    }
  }
`;

export default ProfileImgInput;
