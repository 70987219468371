import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Modal,
  Box,
  TextField,
  Button,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { styled } from "styled-components";
import { color_midnight, color_rose } from "../../util/color";
import { getAccountHolder, getBank, saveAccount } from "../../apis/settlement";

type propType = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  callback: () => void;
};

const AccountInfoModal = ({ open, setOpen, callback }: propType) => {
  const [accountHolder, setAccountHolder] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bank, setBank] = useState("");
  const [agreement, setAgreement] = useState(false); // 체크박스 상태

  const [banks, setBanks] = useState([]);

  const handleClose = () => setOpen(false);

  const handleSubmit = async () => {
    // 체크박스가 체크되지 않았으면 등록을 막습니다.
    if (!agreement) {
      window.alert("계좌등록을 진행하려면 체크박스를 선택해주세요.");
      return;
    }

    const accountHolder = await getAccountHolder(accountNumber, bank).then(
      (res) => res.data.accountHolder
    );

    const result = window.confirm(
      `예금주( ${accountHolder} ) 가 맞는지 확인해주세요.`
    );

    if (result) {
      saveAccount({
        account: accountNumber,
        accountHolder: accountHolder,
        bank: bank,
      })
        .then((res) => {
          window.alert("계좌등록이 완료되었습니다.");
          callback();
          handleClose();
        })
        .catch((e) => {
          window.alert("입력된 정보를 확인해주세요.");
        });
      handleClose();
    }
  };

  useEffect(() => {
    getBank().then((res) => {
      setBanks(res.data.data);
    });
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <ModalBox
        sx={{
          outline: "none",
          border: "0px",
          width: "540px",
          height: "480px",
          padding: "0px 40px",
          boxSizing: "border-box",
        }}
      >
        <h2
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: color_rose.rose9,
            fontWeight: "bold",
            fontSize: "24px",
            margin: "0px",
            marginTop: "32px",
          }}
        >
          현재 등록 되어있는 계좌가 없습니다.
        </h2>
        <span
          style={{
            color: "#181E2D",
            lineHeight: "20px",
            textAlign: "center",
          }}
        >
          정산을 위한 계좌를 등록해주세요.
          <br />
          정산은 차주 수요일마다 정산됩니다.
        </span>
        <TextField
          label="계좌번호"
          value={accountNumber}
          onChange={(e) => setAccountNumber(e.target.value)}
          fullWidth
          margin="normal"
          sx={{
            backgroundColor: color_midnight.midnight2,
          }}
        />
        <TextField
          select
          label="은행"
          value={bank}
          onChange={(e) => setBank(e.target.value)}
          fullWidth
          margin="normal"
          sx={{
            backgroundColor: color_midnight.midnight2,
          }}
        >
          {banks.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        {/* 체크박스 추가 */}
        <FormControlLabel
          sx={{ color: color_midnight.midnight4 }}
          control={
            <Checkbox
              checked={agreement}
              onChange={(e) => setAgreement(e.target.checked)}
              sx={{ color: color_midnight.midnight4 }}
            />
          }
          label="핑프 예약 중개수수료 발생에 동의합니다. "
        />

        <ButtonContainer>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{
              backgroundColor: "#fff",
              border: "0px",
              color: color_midnight.midnight4,
            }}
          >
            다음에 할게요
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ backgroundColor: color_rose.rose9 }}
          >
            계좌 등록하기
          </Button>
        </ButtonContainer>
      </ModalBox>
    </Modal>
  );
};

const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  border: 2px solid #000;
  box-shadow: 24px;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center; /* 가운데 정렬 */
  margin-top: 16px;
  gap: 10px;
`;

export default AccountInfoModal;
