import CardLayout from "../../layout/CardLayout";

declare global {
  interface Window {
    IMP: any;
  }
}

const BillingTest = () => {
  const IMP = window.IMP;
  IMP.init(`imp70703121`);

  const test = () => {
    IMP.request_pay(
      {
        customer_uid: "test_12345", // 카드(빌링키)와 1:1로 대응하는 값
        pg: "kcp_billing.A52LD",
        pay_method: "card", // 'card'만 지원됩니다.
        merchant_uid: "test" + new Date().getTime(), // 상점에서 관리하는 주문 번호
        name: "test",
        amount: 100, // 결제창에 표시될 금액. 실제 승인이 이뤄지지는 않습니다.
        buyer_email: "wjs2282@naver.com",
        buyer_name: "test",
        buyer_tel: "01093797201",
        period: {
          from: "20240130", //YYYYMMDD
          to: "20260130", //YYYYMMDD
        },
      },
      function (rsp: any) {
        window.confirm("테스트" + rsp);
        console.log("11111111111");
        console.log(rsp);
        // callback
        if (rsp.success) {
          // 빌링키 발급 성공
        } else {
          // 빌링키 발급 실패
        }
      }
    );
  };

  return (
    <CardLayout text="BillTest">
      <div onClick={test}>test</div>
    </CardLayout>
  );
};

export default BillingTest;
