const dayMapping: Record<string, string> = {
  monday: "월",
  tuesday: "화",
  wednesday: "수",
  thursday: "목",
  friday: "금",
  saturday: "토",
  sunday: "일",
};

export const transMapping = (
  data: {
    dayOfWeek: string;
    open: string;
    close: string;
  }[]
) => {
  const result: [string, boolean, string, string][] = [
    ["월", false, new Date().toISOString(), new Date().toISOString()],
    ["화", false, new Date().toISOString(), new Date().toISOString()],
    ["수", false, new Date().toISOString(), new Date().toISOString()],
    ["목", false, new Date().toISOString(), new Date().toISOString()],
    ["금", false, new Date().toISOString(), new Date().toISOString()],
    ["토", false, new Date().toISOString(), new Date().toISOString()],
    ["일", false, new Date().toISOString(), new Date().toISOString()],
  ];

  data.forEach((item) => {
    const dayOfWeek = item.dayOfWeek.toLowerCase();

    const dayIndex = result.findIndex(
      (entry) => entry[0] === dayMapping[dayOfWeek]
    );
    if (dayIndex !== -1) {
      result[dayIndex][1] = true;
      result[dayIndex][2] = item.open;
      result[dayIndex][3] = item.close;
    }
  });

  return result;
};

export const transDay = (day: string) => {
  let result = "";

  switch (day) {
    case "월":
      result = "monday";
      break;
    case "화":
      result = "tuesday";
      break;
    case "수":
      result = "wednesday";
      break;
    case "목":
      result = "thursday";
      break;
    case "금":
      result = "friday";
      break;
    case "토":
      result = "saturday";
      break;
    case "일":
      result = "sunday";
      break;
  }

  return result;
};

export const changeCategory = (data: string, option: boolean) => {
  const enList = ["nail", "pedi", "care", "etc", "extension", "removal"];
  const krList = ["네일", "패디", "케어", "기타", "연장", "제거"];

  return option ? enList[krList.indexOf(data)] : krList[enList.indexOf(data)];
};

export const changeMethod = (data: string, option: boolean) => {
  const enList = ["cash", "card", "account", "ticket", "etc", "app"];
  const krList = ["현금", "카드", "계좌이체", "정액권", "기타", "핑프"];

  return option ? enList[krList.indexOf(data)] : krList[enList.indexOf(data)];
};

export const changeManagerCategory = (data: string, option: boolean) => {
  const enList = ["admin", "manager", "designer", "employee"];
  const krList = ["대표", "매니저", "디자이너", "직원"];

  return option ? enList[krList.indexOf(data)] : krList[enList.indexOf(data)];
};

export const handleEmptyValue = (
  value: string | null | undefined | number
): number => {
  if (typeof value === "string" && value.trim() === "") {
    return 0;
  } else if (value === null || value === undefined || isNaN(value as number)) {
    return 0;
  } else {
    return value as number;
  }
};

export const designCategory = [
  "이달의아트",
  "원컬러",
  "시럽",
  "그라데이션",
  "글리터",
  "치크",
  "도트",
  "캐릭터",
  "테라조",
  "마블",
  "프렌치",
  "파우더",
  "체크",
  "파츠",
  "레진",
  "스티커",
  "아트",
  "드로잉",
  "자석",
  "무광",
  "유니크",
];

export const changeDesignCategory = (data: string, option: boolean) => {
  const enList = [
    "monthlyArt",
    "oneColor",
    "syrup",
    "gradation",
    "glitter",
    "cheek",
    "dot",
    "character",
    "terrazzo",
    "marble",
    "french",
    "powder",
    "check",
    "parts",
    "resin",
    "nailSticker",
    "art",
    "drawing",
    "magnet",
    "matte",
    "unique",
  ];
  const krList = [
    "이달의아트",
    "원컬러",
    "시럽",
    "그라데이션",
    "글리터",
    "치크",
    "도트",
    "캐릭터",
    "테라조",
    "마블",
    "프렌치",
    "파우더",
    "체크",
    "파츠",
    "레진",
    "스티커",
    "아트",
    "드로잉",
    "자석",
    "무광",
    "유니크",
  ];

  return option ? enList[krList.indexOf(data)] : krList[enList.indexOf(data)];
};

export const feedColorList = [
  "#F71807", // red
  "#FF9000", // orange
  "#FFCC00", // yellow
  "#1BDAC0", //mint
  "#0CAA73", // green
  "#A4CEE6", // skyblue
  "#3131F4", //blue
  "#303390", // navy
  "#C9A4E6", // 라벤더
  "#9117A3", // purple
  "#F7C6DA", //라이트핑크
  "#F82EA7", // pink
  "#F4D0B5", //베이지
  "#A32517", //브라운
  "#C0C0C0", //실버
  "#ffd700", //골드
  "#FFFFFF", // white
  "#C6C6C6", // gray
  "#1A1A1A", // black
];

export const feedColorList_kor = [
  "레드",
  "오렌지",
  "옐로우",
  "민트",
  "그린",
  "스카이블루",
  "블루",
  "네이비",
  "라벤더",
  "퍼플",
  "라이트핑크",
  "핑크",
  "베이지",
  "브라운",
  "실버",
  "골드",
  "화이트",
  "그레이",
  "블랙",
];

// 제거 -> darknay , apricot
// 추가 -> 블루 , 민트 , 라벤더 , 라이트핑크 , 베이지 , 실버 , 골드
