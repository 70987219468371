import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale";
import styled from "styled-components";
import { color_midnight, color_rose } from "../../util/color";

// DatePicker 전체 스타일 커스터마이징
const CustomDatePicker = styled(DatePicker)`
  width: 300px;
  height: 44px;
  border-radius: 10px;
  padding-left: 20px;
  box-sizing: border-box;
  font-size: 16px;
  border: 0px solid #ddd;
  background-color: ${color_midnight.midnight1};
  color: ${color_midnight.midnight6};

  &:focus {
    border-color: ${color_rose.rose6};
    box-shadow: 0 0 8px rgb(255 99 155 / 50%);
    outline: none;
  }
`;

// DatePicker 내부 캘린더 스타일 커스터마이징
const CustomCalendarContainer = styled.div`
  .react-datepicker {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: "Arial", sans-serif;
  }

  .react-datepicker__header {
    background-color: ${color_rose.rose6};
    color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .react-datepicker__current-month {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .react-datepicker__month {
    margin: 0 10px;
  }

  .react-datepicker__month-text,
  .react-datepicker__quarter-text {
    display: inline-block;
    width: 80px;
    padding: 10px 0;
    margin: 2px;
    font-size: 16px;
    border-radius: 8px;
    background-color: white; /* 월 선택 버튼 배경색 하얀색 */
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }

  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover {
    background-color: ${color_rose.rose6}; /* 호버 시 배경색 */
    color: white;
  }

  .react-datepicker__month--selected,
  .react-datepicker__quarter--selected {
    background-color: ${color_rose.rose6}; /* 선택된 월 배경색 */
    color: white;
  }

  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    top: 8px;
    line-height: 1.7;
    border: none;
    background: none; /* 배경 제거 */
    color: #ffffff;
    cursor: pointer;

    &::before {
      border-color: #ffffff; !important;
    }
  }

  .react-datepicker__navigation--previous:hover,
  .react-datepicker__navigation--next:hover {
    color: white;

    &::before {
      border-color: white; /* 화살표 아이콘 색상을 호버 시 흰색으로 변경 */
    }
  }

  .react-datepicker__navigation-icon::before{
    border-color:#ffffff;
  }
`;

type propType = {
  value: Date;
  setValue: React.Dispatch<React.SetStateAction<Date>>;
};

export default function DatePicker2(props: propType) {
  return (
    <CustomCalendarContainer>
      <CustomDatePicker
        selected={props.value}
        onChange={(date) => props.setValue(date ? date : new Date())}
        dateFormat="yyyy년 M월"
        locale={ko}
        showMonthYearPicker
        popperPlacement="bottom"
      />
    </CustomCalendarContainer>
  );
}
