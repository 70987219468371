import React from "react";
import styled from "styled-components";
import { color_midnight } from "../util/color";

const CardLayout2 = (props: { children: React.ReactNode }) => {
  return (
    <Container>
      <Card>{props.children}</Card>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${color_midnight.lily};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Card = styled.div`
  width: 820px;
  height: 740px;
  background-color: #ffffff;
  box-shadow: 4px 4px 4px 0px #0000001a;
  border-radius: 25px;

  display: flex;
  align-items: center;
  flex-direction: column;

  position: relative;
`;

export default CardLayout2;
