import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Success = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <img src="/image/logo.png" alt="로고이미지" width="216px" />
      <span>회원 탈퇴가 완료되었습니다.</span>
      <span>
        보내주신 피드백을 통해 더 나은 서비스를 만드는 <strong>핑프</strong>가
        되겠습니다.
      </span>
      <img src="/image/Feedback.svg" alt="탈퇴이미지" width="600px" />
      <button onClick={() => navigate("/signin")}>첫 화면으로 돌아가기</button>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  & > span {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.2px;
    color: #6c727f;
  }
  & > span:nth-child(3) {
    color: #5c91ed;
    & > strong {
      color: #ff5d7a;
    }
  }

  & > button {
    width: 300px;
    height: 44px;

    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.2px;
    color: #ffffff;
    border-radius: 8px;
    background-color: #ff5d7a;

    border: none;
  }
`;
export default Success;
