import React, { Dispatch, SetStateAction, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { styled } from "styled-components";
import { BusinessHour } from "../../types";
import { transDay } from "../../util/util";
import DaySetBox from "./DaySetBox";
import TimePickers from "../utility/TimePickers";

type propType = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  list: BusinessHour[];
  setList: Dispatch<SetStateAction<BusinessHour[]>>;
};

const WorkTimeModal = ({ open, setOpen, list, setList }: propType) => {
  const [monday, setMonday] = useState<BusinessHour>(
    list.filter((item) => item.dayOfWeek === transDay("월")).length > 0
      ? list.filter((item) => item.dayOfWeek === transDay("월"))[0]
      : {
          dayOfWeek: "monday",
          open: new Date().toISOString(),
          close: new Date().toISOString(),
        }
  );
  const [tuesday, setTuesday] = useState<BusinessHour>(
    list.filter((item) => item.dayOfWeek === transDay("화")).length > 0
      ? list.filter((item) => item.dayOfWeek === transDay("화"))[0]
      : {
          dayOfWeek: "tuesday",
          open: new Date().toISOString(),
          close: new Date().toISOString(),
        }
  );
  const [wednesday, setWednesday] = useState<BusinessHour>(
    list.filter((item) => item.dayOfWeek === transDay("수")).length > 0
      ? list.filter((item) => item.dayOfWeek === transDay("수"))[0]
      : {
          dayOfWeek: "wednesday",
          open: new Date().toISOString(),
          close: new Date().toISOString(),
        }
  );
  const [thursday, setThursday] = useState<BusinessHour>(
    list.filter((item) => item.dayOfWeek === transDay("목")).length > 0
      ? list.filter((item) => item.dayOfWeek === transDay("목"))[0]
      : {
          dayOfWeek: "thursday",
          open: new Date().toISOString(),
          close: new Date().toISOString(),
        }
  );
  const [friday, setFriday] = useState<BusinessHour>(
    list.filter((item) => item.dayOfWeek === transDay("금")).length > 0
      ? list.filter((item) => item.dayOfWeek === transDay("금"))[0]
      : {
          dayOfWeek: "friday",
          open: new Date().toISOString(),
          close: new Date().toISOString(),
        }
  );
  const [saturday, setSaturday] = useState<BusinessHour>(
    list.filter((item) => item.dayOfWeek === transDay("토")).length > 0
      ? list.filter((item) => item.dayOfWeek === transDay("토"))[0]
      : {
          dayOfWeek: "saturday",
          open: new Date().toISOString(),
          close: new Date().toISOString(),
        }
  );
  const [sunday, setSunday] = useState<BusinessHour>(
    list.filter((item) => item.dayOfWeek === transDay("일")).length > 0
      ? list.filter((item) => item.dayOfWeek === transDay("일"))[0]
      : {
          dayOfWeek: "sunday",
          open: new Date().toISOString(),
          close: new Date().toISOString(),
        }
  );

  const [mode, setMode] = useState(true);

  const [start, setStart] = useState(
    list.length > 1 ? list[0].open : new Date().toISOString()
  );
  const [end, setEnd] = useState(
    list.length > 1 ? list[0].close : new Date().toISOString()
  );

  const [dayList, setDayList] = useState<string[]>(
    list.map((e) => e.dayOfWeek)
  );

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "720px",
            height: mode ? "400px" : "720px",
            bgcolor: "background.paper",
            borderRadius: "16px",
            p: 4,
            boxShadow: "4px 4px 4px 0px #00000040",
            padding: "36px 40px",
            boxSizing: "border-box",
            transition: "height 0.5s ease",
            overflow: "hidden",
          }}
        >
          <Container>
            <Title>샵 영업일 설정</Title>
            <div
              style={{
                width: "100%",
                height: "40px",
                display: "flex",
                gap: "10px",
              }}
            >
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#181e2d",
                  fontSize: "12px",
                  fontWeight: "700",
                  marginRight: "10px",
                }}
              >
                영업 주기
              </label>
              <button
                style={{
                  border: mode ? "1px solid #F14262" : "1px solid #cccccc",
                  backgroundColor: "transparent",
                  color: mode ? "#F14262" : "#cccccc",
                  height: "100%",
                  marginRight: "10px",
                  borderRadius: "10px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
                onClick={() => setMode(true)}
              >
                매일 같은 시간에 영업해요
              </button>
              <button
                style={{
                  border: !mode ? "1px solid #F14262" : "1px solid #cccccc",
                  backgroundColor: "transparent",
                  color: !mode ? "#F14262" : "#cccccc",
                  height: "100%",
                  marginRight: "10px",
                  borderRadius: "10px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
                onClick={() => setMode(false)}
              >
                요일별로 다르게 영업해요
              </button>
            </div>
            {mode ? (
              <>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    width: "100%",
                  }}
                >
                  <label
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#181e2d",
                      fontSize: "12px",
                      fontWeight: "700",
                    }}
                  >
                    영업 시간
                  </label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <span
                      style={{
                        color: "#181e2d",
                        fontSize: "12px",
                        fontWeight: "700",
                      }}
                    >
                      근무시작
                    </span>
                    <TimePickers
                      value={new Date(start)}
                      onChange={(event) => {
                        setStart(new Date(event).toISOString());
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <span
                      style={{
                        color: "#181e2d",
                        fontSize: "12px",
                        fontWeight: "700",
                      }}
                    >
                      근무종료
                    </span>
                    <TimePickers
                      value={new Date(end)}
                      onChange={(event) => {
                        setEnd(new Date(event).toISOString());
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    width: "100%",
                  }}
                >
                  <label
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#181e2d",
                      fontSize: "12px",
                      fontWeight: "700",
                    }}
                  >
                    영업 요일
                  </label>
                  <div style={{ display: "flex", gap: "10px" }}>
                    {["월", "화", "수", "목", "금", "토", "일"].map(
                      (item, idx) => (
                        <div
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "40px",
                            backgroundColor: dayList.includes(transDay(item))
                              ? "#f14262"
                              : "#cccccc",
                            color: "#ffffff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            setDayList((old) =>
                              old.includes(transDay(item))
                                ? dayList.filter((e) => e !== transDay(item))
                                : [...old, transDay(item)]
                            );
                          }}
                        >
                          {item}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    gap: "24px",
                    justifyContent: "flex-end",
                    position: "absolute",
                    bottom: "20px",
                    right: "20px",
                  }}
                >
                  <CancleBtn
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    취소
                  </CancleBtn>
                  <EditBtn
                    onClick={() => {
                      const temp = dayList.map((e) => {
                        return {
                          dayOfWeek: e,
                          open: start,
                          close: end,
                        };
                      });

                      console.log(temp);

                      if (end < start) {
                        window.alert("시간을 다시 한번 확인해주세요.");
                        return;
                      }

                      setList(temp);

                      setOpen(false);
                    }}
                  >
                    저장
                  </EditBtn>
                </div>
              </>
            ) : (
              <>
                <div style={{ display: "flex", flexWrap: "wrap", gap: "24px" }}>
                  <DaySetBox
                    data={monday}
                    setData={setMonday}
                    day={"월"}
                    isChecked={
                      list.filter((item2) => item2.dayOfWeek === transDay("월"))
                        .length < 1
                    }
                    onTogle={() =>
                      setList((old) =>
                        old.filter(
                          (item2) => item2.dayOfWeek === transDay("월")
                        ).length < 1
                          ? [
                              ...old,
                              {
                                dayOfWeek: transDay("월"),
                                open: new Date().toISOString(),
                                close: new Date().toISOString(),
                              },
                            ]
                          : old.filter(
                              (item2) => item2.dayOfWeek !== transDay("월")
                            )
                      )
                    }
                  />
                  <DaySetBox
                    data={tuesday}
                    setData={setTuesday}
                    day={"화"}
                    isChecked={
                      list.filter((item2) => item2.dayOfWeek === transDay("화"))
                        .length < 1
                    }
                    onTogle={() =>
                      setList((old) =>
                        old.filter(
                          (item2) => item2.dayOfWeek === transDay("화")
                        ).length < 1
                          ? [
                              ...old,
                              {
                                dayOfWeek: transDay("화"),
                                open: new Date().toISOString(),
                                close: new Date().toISOString(),
                              },
                            ]
                          : old.filter(
                              (item2) => item2.dayOfWeek !== transDay("화")
                            )
                      )
                    }
                  />
                  <DaySetBox
                    data={wednesday}
                    setData={setWednesday}
                    day={"수"}
                    isChecked={
                      list.filter((item2) => item2.dayOfWeek === transDay("수"))
                        .length < 1
                    }
                    onTogle={() =>
                      setList((old) =>
                        old.filter(
                          (item2) => item2.dayOfWeek === transDay("수")
                        ).length < 1
                          ? [
                              ...old,
                              {
                                dayOfWeek: transDay("수"),
                                open: new Date().toISOString(),
                                close: new Date().toISOString(),
                              },
                            ]
                          : old.filter(
                              (item2) => item2.dayOfWeek !== transDay("수")
                            )
                      )
                    }
                  />
                  <DaySetBox
                    data={thursday}
                    setData={setThursday}
                    day={"목"}
                    isChecked={
                      list.filter((item2) => item2.dayOfWeek === transDay("목"))
                        .length < 1
                    }
                    onTogle={() =>
                      setList((old) =>
                        old.filter(
                          (item2) => item2.dayOfWeek === transDay("목")
                        ).length < 1
                          ? [
                              ...old,
                              {
                                dayOfWeek: transDay("목"),
                                open: new Date().toISOString(),
                                close: new Date().toISOString(),
                              },
                            ]
                          : old.filter(
                              (item2) => item2.dayOfWeek !== transDay("목")
                            )
                      )
                    }
                  />
                  <DaySetBox
                    data={friday}
                    setData={setFriday}
                    day={"금"}
                    isChecked={
                      list.filter((item2) => item2.dayOfWeek === transDay("금"))
                        .length < 1
                    }
                    onTogle={() =>
                      setList((old) =>
                        old.filter(
                          (item2) => item2.dayOfWeek === transDay("금")
                        ).length < 1
                          ? [
                              ...old,
                              {
                                dayOfWeek: transDay("금"),
                                open: new Date().toISOString(),
                                close: new Date().toISOString(),
                              },
                            ]
                          : old.filter(
                              (item2) => item2.dayOfWeek !== transDay("금")
                            )
                      )
                    }
                  />
                  <DaySetBox
                    data={saturday}
                    setData={setSaturday}
                    day={"토"}
                    isChecked={
                      list.filter((item2) => item2.dayOfWeek === transDay("토"))
                        .length < 1
                    }
                    onTogle={() =>
                      setList((old) =>
                        old.filter(
                          (item2) => item2.dayOfWeek === transDay("토")
                        ).length < 1
                          ? [
                              ...old,
                              {
                                dayOfWeek: transDay("토"),
                                open: new Date().toISOString(),
                                close: new Date().toISOString(),
                              },
                            ]
                          : old.filter(
                              (item2) => item2.dayOfWeek !== transDay("토")
                            )
                      )
                    }
                  />
                  <DaySetBox
                    data={sunday}
                    setData={setSunday}
                    day={"일"}
                    isChecked={
                      list.filter((item2) => item2.dayOfWeek === transDay("일"))
                        .length < 1
                    }
                    onTogle={() =>
                      setList((old) =>
                        old.filter(
                          (item2) => item2.dayOfWeek === transDay("일")
                        ).length < 1
                          ? [
                              ...old,
                              {
                                dayOfWeek: transDay("일"),
                                open: new Date().toISOString(),
                                close: new Date().toISOString(),
                              },
                            ]
                          : old.filter(
                              (item2) => item2.dayOfWeek !== transDay("일")
                            )
                      )
                    }
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    gap: "24px",
                    justifyContent: "flex-end",
                  }}
                >
                  <CancleBtn
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    취소
                  </CancleBtn>
                  <EditBtn
                    onClick={() => {
                      const temp = [
                        monday,
                        tuesday,
                        wednesday,
                        thursday,
                        friday,
                        saturday,
                        sunday,
                      ];

                      if (
                        temp.filter((item) => item.close < item.open).length > 0
                      ) {
                        window.alert("시간을 다시 한번 확인해주세요.");
                        return;
                      }

                      setList(
                        temp.filter(
                          (item) =>
                            list.filter(
                              (item2) => item2.dayOfWeek === item.dayOfWeek
                            ).length > 0
                        )
                      );

                      setOpen(false);
                    }}
                  >
                    저장
                  </EditBtn>
                </div>
              </>
            )}
          </Container>
        </Box>
      </Modal>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;

  & > hr {
    width: 100%;
    color: #e5e7eb;
  }
`;

const Title = styled.span`
  color: #181e2d;
  font-size: 20px;
  font-weight: 700;
  line-height: 140%;
`;

const EditBtn = styled.button`
  width: 92px;
  height: 44px;

  border: none;
  border-radius: 8px;

  background-color: #f14262;

  color: #ffffff;
`;
const CancleBtn = styled.button`
  width: 92px;
  height: 44px;

  border: none;
  border-radius: 8px;

  color: #a0a6b1;
  background-color: transparent;
`;

export default WorkTimeModal;
