import axios from "axios";
import { baseUrl, config } from "./api";
import { CreateMangerType, saveMangerType } from "../types";

// shop manager api
export const getManagerList = async (id: string) => {
  return await axios.get(
    baseUrl + `/admin/shop/manager?limit=100&offset=1&shopId=${id}`,
    config()
  );
};

export const createManager = async (data: CreateMangerType) => {
  return await axios.post(baseUrl + "/admin/shop/manager", data, config());
};

export const saveManager = async (data: saveMangerType) => {
  return await axios.put(baseUrl + "/admin/shop/manager", data, config());
};

export const deleteManager = async (data: { managerId: string }) => {
  return await axios.delete(baseUrl + "/admin/shop/manager", {
    headers: config().headers,
    data: data,
  });
};
