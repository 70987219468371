import { styled } from "styled-components";
import MainLayout from "../../layout/MainLayout";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { ShopData } from "../../atoms/atom";
import SideBar from "../../components/customer/SideBar";
import PageNation from "../../components/customer/PageNation";
import SearchIcon from "@mui/icons-material/Search";
import { getCustomerList } from "../../apis/customer";
import MessageListener from "../../components/utility/MessageListener";
import { IoMedical } from "react-icons/io5";

const Customer = () => {
  const [zoom, setZoom] = useState(1.0);

  const shopData = useRecoilValue(ShopData);

  const [active, setActive] = useState(0);
  const [active2, setActive2] = useState(0);

  const [focused, setFocused] = useState(-1);

  const [customerList, setCustomerList] = useState<any[]>([]);
  const [selData, setSelData] = useState<any>({});

  const [customerCount, setCustomerCount] = useState(0);

  const [pageNum, setPageNum] = useState(1);

  const [updateCounter, setUpdateCounter] = useState(0);

  const [searchText, setSearchText] = useState("");

  const getSortColumn = () => {
    switch (active) {
      case 0:
        return "customerName";
      case 1:
        return "customerNumber";
      case 2:
        return "totalPrice";
      case 3:
        return "lastDate";
      case 4:
        return "regular";

      default:
        return "customerName";
    }
  };

  useEffect(() => {
    if (document.body.scrollHeight > window.innerHeight) {
      setZoom(
        1 -
          (document.body.scrollHeight - window.innerHeight) /
            document.body.scrollHeight
      );
    } else {
      setZoom((document.body.scrollHeight - 40) / 885);
    }

    getCustomerList(
      shopData.id,
      getSortColumn(),
      active2 === 0 ? "ASC" : "DESC",
      pageNum
    ).then((res) => {
      //console.log();
      setCustomerCount(res.data.data.customerCount);

      console.log(res.data.data.customerList);
      setCustomerList(res.data.data.customerList);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, active2, pageNum, updateCounter]);

  return (
    <MainLayout active={"customer"}>
      <Container $zoom={zoom}>
        <Main>
          <Contents>
            <Left>
              <Content1>
                <h2>어서오세요! {shopData.name}님</h2>
                <span>핑프와 오늘도 좋은 하루 되세요 🙂</span>
                <SearchBar>
                  <input
                    type="text"
                    placeholder="고객명,휴대폰번호"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDownCapture={(e) => {
                      if (e.key !== "Enter") return;

                      const searchType = searchText
                        ? /\d/.test(searchText)
                          ? "phone"
                          : "name"
                        : "";

                      getCustomerList(
                        shopData.id,
                        getSortColumn(),
                        active2 === 0 ? "ASC" : "DESC",
                        pageNum,
                        searchType,
                        searchText
                      ).then((res) => {
                        setCustomerCount(res.data.data.customerCount);

                        console.log(res.data.data.customerList);
                        setCustomerList(res.data.data.customerList);
                      });
                    }}
                  />
                  <SearchIcon
                    className="searchIcon"
                    style={{ fontSize: "24px", color: "#A0A6B1" }}
                    onClick={() => {
                      const searchType = searchText
                        ? /\d/.test(searchText)
                          ? "phone"
                          : "name"
                        : "";

                      getCustomerList(
                        shopData.id,
                        getSortColumn(),
                        active2 === 0 ? "ASC" : "DESC",
                        pageNum,
                        searchType,
                        searchText
                      ).then((res) => {
                        setCustomerCount(res.data.data.customerCount);

                        console.log(res.data.data.customerList);
                        setCustomerList(res.data.data.customerList);
                      });
                    }}
                  />
                </SearchBar>
              </Content1>
              <Content2
                style={
                  focused !== -1
                    ? { animation: "SlideIn 0.5s forwards" }
                    : { animation: "SlideOut 0.5s forwards" }
                }
              >
                <TopBar>
                  <BtnWrap>
                    <span
                      style={{
                        color: active === 0 ? "#f14262" : "#A0A6B1",
                        cursor: "pointer",
                      }}
                      onClick={() => setActive(0)}
                    >
                      고객명
                    </span>
                    <span
                      style={{
                        color: active === 1 ? "#f14262" : "#A0A6B1",
                        cursor: "pointer",
                      }}
                      onClick={() => setActive(1)}
                    >
                      고객번호
                    </span>
                    <span
                      style={{
                        color: active === 2 ? "#f14262" : "#A0A6B1",
                        cursor: "pointer",
                      }}
                      onClick={() => setActive(2)}
                    >
                      총매출
                    </span>
                    <span
                      style={{
                        color: active === 3 ? "#f14262" : "#A0A6B1",
                        cursor: "pointer",
                      }}
                      onClick={() => setActive(3)}
                    >
                      최근 방문일
                    </span>
                    <span
                      style={{
                        color: active === 4 ? "#f14262" : "#A0A6B1",
                        cursor: "pointer",
                      }}
                      onClick={() => setActive(4)}
                    >
                      단골 고객
                    </span>
                  </BtnWrap>
                  <BtnWrap>
                    <span
                      style={{
                        color: active2 === 0 ? "#f14262" : "#A0A6B1",
                        cursor: "pointer",
                      }}
                      onClick={() => setActive2(0)}
                    >
                      오름차순
                    </span>
                    <span
                      style={{
                        color: active2 === 1 ? "#f14262" : "#A0A6B1",
                        cursor: "pointer",
                      }}
                      onClick={() => setActive2(1)}
                    >
                      내림차순
                    </span>
                  </BtnWrap>
                  <CountBox>{customerCount}개</CountBox>
                </TopBar>
                <Wrap>
                  <TableHeader>
                    <span>고객정보</span>
                    <span>최근방문일</span>
                    <span>최근시술내역</span>
                    <span>방문횟수</span>
                    <span>총 매출액</span>
                  </TableHeader>
                  <div>
                    {customerList.map((item, idx) => (
                      <TableItem
                        key={idx}
                        onClick={() => {
                          setFocused((old) => (old === idx ? -1 : idx));
                          setSelData(item);
                        }}
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            idx === focused ? "#F9E8EC" : "#F9FAFB",
                        }}
                      >
                        <span>
                          <div style={{ width: "100px" }}>
                            <span
                              style={{
                                textOverflow: "ellipsis",
                                width: "100%",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {item.customerNumber}.{item.customerName}
                              {item.isRegular && (
                                <IoMedical
                                  size={"12px"}
                                  color="#FF5D7A"
                                  style={{ marginLeft: "4px" }}
                                />
                              )}
                            </span>
                            <span>{item.customerPhoneNumber}</span>
                          </div>
                        </span>
                        <span>
                          {item.lastDate !== ""
                            ? new Date(item.lastDate)
                                .toLocaleDateString()
                                .slice(0, -1)
                            : "-"}
                        </span>
                        <span>
                          {item.lastMenu !== "" ? item.lastMenu : "없음"}
                        </span>
                        <span>{item.visitCount}</span>
                        <span>
                          {item.totalPrice
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </span>
                      </TableItem>
                    ))}
                  </div>
                </Wrap>
                <PageNation
                  data={Array.from(
                    { length: Math.ceil(customerCount / 10) },
                    (_, index) => index + 1
                  )}
                  pageNum={pageNum}
                  setPageNum={setPageNum}
                />
              </Content2>
            </Left>
            {focused !== -1 && (
              <Right
                style={
                  focused !== -1 ? { animation: "SlideIn2 0.6s forwards" } : {}
                }
              >
                <SideBar
                  data={selData}
                  saveCallback={() => {
                    setFocused(-1);
                    setUpdateCounter((old) => old + 1);
                  }}
                />
              </Right>
            )}
          </Contents>
        </Main>
        <MessageListener />
      </Container>
    </MainLayout>
  );
};

const Container = styled.div<{ $zoom: number }>`
  width: 100%;
  display: flex;

  zoom: ${(props) => props.$zoom};

  .searchIcon {
    border-radius: 50%;
    padding: 4px;
  }

  .searchIcon:hover {
    background-color: #ff5d7a;
    color: #ffffff !important;
  }
`;

const Main = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  min-width: 1200px;
`;

const Contents = styled.div`
  width: 1200px;

  display: flex;
  gap: 50px;

  align-items: center;
`;

const Left = styled.div`
  width: 100%;

  margin-top: 50px;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const Right = styled.div`
  width: 90%;
  height: 840px;
  margin-top: 40px;
  padding-left: 40px;
  box-sizing: border-box;

  display: flex;

  position: relative;
  left: 0px;

  @keyframes SlideIn2 {
    from {
      left: 300px;
    }
    to {
      left: 0px;
    }
  }
`;

const Content1 = styled.div`
  min-width: 100%;
  height: 51px;

  margin-left: 15px;

  position: relative;

  & h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.2px;
  }

  & span {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    color: #a0a6b1;
  }
`;

const Content2 = styled.div`
  background-color: #ffffff;

  display: flex;
  width: 100%;
  height: 720px;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  border-radius: 16px;

  @keyframes SlideIn {
    from {
      width: 180%;
    }
    to {
      width: 100%;
    }
  }

  @keyframes SlideOut {
    from {
      width: 50%;
    }
    to {
      width: 100%;
    }
  }
`;

const TopBar = styled.div`
  display: flex;
  width: 100%;
  height: 44px;
  align-items: flex-start;
  gap: 24px;

  position: relative;
`;

const BtnWrap = styled.div`
  display: flex;
  padding: 11px 10px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background: #f9fafb;
  height: 44px;

  & > span {
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
  }
`;
const CountBox = styled.div`
  display: flex;
  width: 120px;
  padding: 11px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  right: 0px;

  border-radius: 16px;
  background: #f9fafb;
  color: #f14262;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
`;

const Wrap = styled.div`
  width: 100%;
  height: 90%;

  display: flex;
  flex-direction: column;
  gap: 32px;

  & > :nth-child(2) {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`;

const TableHeader = styled.div`
  width: 100%;

  display: flex;
  height: 44px;
  padding: 0px 24px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;

  background: #f9fafb;
  border-radius: 16px;

  & > span {
    display: flex;
    justify-content: center;
    align-items: center;

    color: #394150;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }

  & > :nth-child(1) {
    display: flex;
    width: 200px;
  }
  & > :nth-child(2) {
    display: flex;
    width: 144px;
  }
  & > :nth-child(3) {
    display: flex;
    width: 144px;
  }
  & > :nth-child(4) {
    display: flex;
    width: 96px;
  }
  & > :nth-child(5) {
    display: flex;
    width: 96px;
  }
`;

const TableItem = styled.div`
  width: 100%;

  display: flex;
  height: 44px;
  padding: 0px 24px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;

  background: #f9fafb;
  border-radius: 16px;

  & > span {
    display: flex;
    justify-content: center;
    align-items: center;

    color: #394150;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;

    color: #000000;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
  }

  & > :nth-child(1) {
    display: flex;
    gap: 24px;
    width: 200px;

    & > :first-child {
      display: flex;
      flex-direction: column;

      & > span {
        color: #000000;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
      }
    }
  }
  & > :nth-child(2) {
    display: flex;
    width: 144px;
  }
  & > :nth-child(3) {
    display: flex;
    width: 144px;
  }
  & > :nth-child(4) {
    display: flex;
    width: 96px;
  }
  & > :nth-child(5) {
    display: flex;
    width: 96px;
  }
`;

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  width: 360px;
  height: 48px;
  padding: 16px;
  box-sizing: border-box;
  gap: 8px;
  border-radius: 16px;
  background: #ffffff;

  position: absolute;
  top: 0px;
  right: -32px;

  & > input {
    width: 100%;
    outline: none;
    border: none;
  }
`;

export default Customer;
