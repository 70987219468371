import { styled } from "styled-components";
import MainLayout from "../../layout/MainLayout";
import { useEffect, useState } from "react";
import { getShopData } from "../../apis/shop";
import BasicInfo from "../../components/myshop/BasicInfo";
import { useRecoilState } from "recoil";
import { MyShopIsEdit, ShopData } from "../../atoms/atom";
import { ShopType } from "../../types";
import SurgeryMenu from "../../components/myshop/SurgeryMenu";
import Event from "../../components/myshop/Event";
import Portfolio from "../../components/myshop/Portfolio";
import MessageListener from "../../components/utility/MessageListener";

const Myshop = () => {
  const [zoom, setZoom] = useState(1.0);
  const [tapNum, setTapNum] = useState(1);

  const [shopData, setShopData] = useRecoilState(ShopData);
  const [myShopIsEdit, setMyShopIsEdit] = useRecoilState(MyShopIsEdit);

  useEffect(() => {
    if (document.body.scrollHeight > window.innerHeight) {
      setZoom(
        1 -
          (document.body.scrollHeight - window.innerHeight) /
            document.body.scrollHeight
      );
    } else {
      setZoom((document.body.scrollHeight - 40) / 885);
    }

    getShopData().then((res) => {
      const data = res.data.data.shop as ShopType;
      console.log(data);
      setShopData(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tapComponent = () => {
    switch (tapNum) {
      case 1:
        return <BasicInfo />;
      case 2:
        return <SurgeryMenu />;
      case 3:
        return <Event />;
      case 4:
        return <Portfolio />;
    }
  };

  const changeHandler = (idx: number) => {
    if (myShopIsEdit) {
      const result = window.confirm(
        "수정 사항이 저장되지 않았습니다. 정말 나가시겠습니까?"
      );

      if (result) {
        setTapNum(idx);
        setMyShopIsEdit(false);
      }
    } else {
      setTapNum(idx);
    }
  };

  return (
    <MainLayout active={"myshop"}>
      <Container $zoom={zoom}>
        <Main>
          <Contents>
            <Content1>
              <h2>어서오세요! {shopData.name}님</h2>
              <span>핑프와 오늘도 좋은 하루 되세요 🙂</span>
            </Content1>
            <Content2>
              <MenuBox>
                <MenuItem
                  $active={tapNum === 1}
                  className={tapNum === 1 ? "activeTab" : ""}
                  onClick={() => changeHandler(1)}
                >
                  기본정보
                </MenuItem>
                <MenuItem
                  $active={tapNum === 2}
                  className={tapNum === 2 ? "activeTab" : ""}
                  onClick={() => changeHandler(2)}
                >
                  시술메뉴
                </MenuItem>
                <MenuItem
                  $active={tapNum === 3}
                  className={tapNum === 3 ? "activeTab" : ""}
                  onClick={() => changeHandler(3)}
                >
                  이벤트
                </MenuItem>
                <MenuItem
                  $active={tapNum === 4}
                  className={tapNum === 4 ? "activeTab" : ""}
                  onClick={() => changeHandler(4)}
                >
                  샵포트폴리오
                </MenuItem>
              </MenuBox>
              <Wrap>{tapComponent()}</Wrap>
            </Content2>
          </Contents>
        </Main>
        <MessageListener />
      </Container>
    </MainLayout>
  );
};

const Container = styled.div<{ $zoom: number }>`
  width: 100%;
  display: flex;

  zoom: ${(props) => props.$zoom};
`;

const Main = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  min-width: 1200px;
`;

const Contents = styled.div`
  width: 1200px;
  margin-top: 50px;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Content1 = styled.div`
  min-width: 400px;
  height: 51px;

  margin-left: 15px;

  & h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.2px;
  }

  & span {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    color: #a0a6b1;
  }
`;

const Content2 = styled.div`
  background-color: #ffffff;

  display: flex;
  width: 100%;
  height: 720px;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  border-radius: 16px;
`;

const MenuBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background-color: #f9fafb;
  overflow: hidden;
`;

const MenuItem = styled.button<{ $active: boolean }>`
  cursor: pointer;
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  border: none;
  background-color: ${(props) => (props.$active ? "#f14262" : "transparent")};

  color: ${(props) => (props.$active ? "#ffffff" : "#A0A6B1")};

  font-size: 16px;

  font-weight: 700;
  line-height: 140%;

  width: 116px;
  height: 100%;
`;

const Wrap = styled.div`
  width: 100%;
  height: 90%;
`;

export default Myshop;
