import React, { Dispatch, SetStateAction, useRef } from "react";
import { IoMdClose } from "react-icons/io";
import Box from "@mui/material/Box";
import { styled } from "styled-components";
import { saveBookNoshow } from "../../apis/book";
import { IoImageOutline } from "react-icons/io5";
import { changeCategory } from "../../util/util";
import { color_green } from "../../util/color";
import { CancelReqPayment } from "../../apis/sales";

type propType = {
  setOpen: Dispatch<SetStateAction<boolean>>;
  data: any;
  callback: () => void;
  callback2: () => void;
  setIsBookEditOpen: Dispatch<SetStateAction<boolean>>;
  setIsSalesRegisterOpen: Dispatch<SetStateAction<boolean>>;
  setIsReqPaymentOpen: Dispatch<SetStateAction<boolean>>;
};

const BookDetails = ({
  data,
  setOpen,
  setIsBookEditOpen,
  setIsSalesRegisterOpen,
  setIsReqPaymentOpen,
  callback,
  callback2,
}: propType) => {
  const getStatusComponent = (statusText: string) => {
    switch (statusText) {
      case "bookComplete":
        return <Status $backgroundColor="#FCA2B3">시술예정</Status>;
      case "nailComplete":
        return <Status $backgroundColor="#3A66EC">시술완료</Status>;
      case "bookCanceled":
        return <Status $backgroundColor="#F14262">시술취소</Status>;
      case "noShow":
        return <Status $backgroundColor="#272F3E">노쇼</Status>;
      default:
        return null;
    }
  };

  const translateBookState = (bookState: string): string => {
    const bookStateTranslations: { [key: string]: string } = {
      duplicate: "중복 예약",
      noManager: "담당 디자이너 부재",
      noTime: "일정 불가",
      shop: "샵 사정",
      noRequirement: "요청 사항 불가",
      customer: "고객 취소",
      noApprove: "샵 미승인",
    };
    return bookStateTranslations[bookState] || "";
  };

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInput = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // 높이를 초기화하여 스크롤 높이를 정확히 계산
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // 내용에 맞게 높이 조정
    }
  };

  return (
    <Box
      sx={{
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "660px",
        height:
          data.bookState !== "nailComplete" &&
          data.bookState !== "noShow" &&
          data.bookState !== "bookCanceled"
            ? "880px"
            : "800px",
        borderRadius: "16px",
        boxShadow: "4px 4px 4px 0px #00000040",
        boxSizing: "border-box",
        backgroundColor: "#F9FAFB",
        overflow: "auto",
        zoom: 0.9,
      }}
    >
      <Container>
        <div
          style={{
            width: "100%",
            height: "90px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#ffffff",
          }}
        >
          <h2 style={{ fontSize: "20px", fontWeight: "700" }}>
            예약 상세 정보
          </h2>
          <IoMdClose
            style={{
              position: "absolute",
              right: "32px",
              fontSize: "32px",
              cursor: "pointer",
            }}
            onClick={() => setOpen(false)}
          />
        </div>
        <div
          style={{
            overflow: "auto",
            height: "766px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div style={{ width: "608px", marginTop: "24px" }}>
            <div
              style={{
                width: "608px",
                backgroundColor: "#ffffff",
                height: "76px",
                paddingLeft: "30px",
                alignItems: "center",
                display: "flex",
                boxSizing: "border-box",
                borderRadius: "16px",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                marginBottom: "24px",
              }}
            >
              <Line>
                <Title>예약자</Title>
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    color: "#000000",
                    fontSize: "16px",
                  }}
                >
                  <span>{data.customerName}</span>
                  <span>{data.phoneNumber}</span>
                </div>
              </Line>
            </div>
            <div
              style={{
                width: "608px",
                backgroundColor: "#ffffff",
                height: "163px",
                paddingLeft: "30px",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                boxSizing: "border-box",
                borderRadius: "16px",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                marginBottom: "24px",
              }}
            >
              <Line>
                <Title>예약 상태</Title>
                <span>
                  {data.paymentState === "payRequest" ? (
                    <Status $backgroundColor={color_green.green6}>
                      결제 요청
                    </Status>
                  ) : (
                    getStatusComponent(data.bookState)
                  )}
                </span>
                <span style={{ fontSize: "16px", color: "#000000" }}>
                  {data.bookState === "bookCanceled"
                    ? translateBookState(data.cancelReason)
                    : ""}
                </span>
              </Line>
              <Line>
                <Title>시술일정</Title>
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    color: "#394150",
                    fontSize: "16px",
                  }}
                >
                  <span style={{ fontSize: "16px", color: "#000000" }}>
                    {new Date(data.date).toLocaleDateString()}
                  </span>
                  <span style={{ fontSize: "16px", color: "#000000" }}>
                    {new Date(data.date).toLocaleTimeString("ko-kr", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                </div>
              </Line>
              <Line>
                <Title>담당자</Title>
                <span style={{ fontSize: "16px", color: "#000000" }}>
                  {data.managerName}
                </span>
              </Line>
            </div>
            <div
              style={{
                width: "608px",
                backgroundColor: "#ffffff",
                paddingLeft: "30px",
                padding: "26px 30px",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                boxSizing: "border-box",
                borderRadius: "16px",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                marginBottom: "24px",
              }}
            >
              <Line style={{ minHeight: "122px" }}>
                <Title style={{ height: "100%" }}>시술내용</Title>
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    justifyContent: "space-between",
                    width: "calc(100% - 86px)",
                    paddingRight: "32px",
                    boxSizing: "border-box",
                    color: "#394150",
                    fontSize: "16px",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    {data.menuCategory && (
                      <span
                        style={{
                          fontSize: 16,
                          fontWeight: 700,
                          color: "#000000",
                        }}
                      >
                        {`(${changeCategory(data.menuCategory, false)})`}
                      </span>
                    )}
                    <span
                      style={{
                        fontSize: 16,
                        fontWeight: 700,
                        color: "#000000",
                      }}
                    >
                      -{data.menuName}
                    </span>
                    <span
                      style={{
                        fontSize: 16,
                        fontWeight: 700,
                        color: "#000000",
                      }}
                    >
                      -{data.designName ? data.designName : "없음"}
                    </span>
                    <span
                      style={{
                        fontSize: 16,
                        fontWeight: 700,
                        color: "#000000",
                      }}
                    >
                      -
                      {data.optionName.length > 0
                        ? data.optionName.join(",")
                        : "없음"}
                    </span>
                  </div>
                  {data.imageUrl ? (
                    <img
                      alt=""
                      src={data.imageUrl}
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "16px",
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        backgroundColor: "#D3D6DB",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <IoImageOutline
                        style={{ fontSize: "40px", color: "#A0A6B1" }}
                      />
                    </div>
                  )}
                </div>
              </Line>
              <Line>
                <Title>시술금액</Title>
                <span style={{ color: "#000000", fontSize: "16px" }}>
                  {data.totalPrice
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  원
                </span>
              </Line>
              <Line style={{ paddingTop: "12px", height: "auto" }}>
                <Title style={{ height: "100%" }}>요청사항</Title>
                <span
                  style={{
                    color: "#000000",
                    fontSize: "16px",
                    width: "442px",
                    height: "100%",
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {data.requirement ? data.requirement : "없음"}
                </span>
              </Line>
            </div>
            <div
              style={{
                width: "608px",
                backgroundColor: "#ffffff",
                paddingLeft: "30px",
                padding: "16px 30px",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                boxSizing: "border-box",
                borderRadius: "16px",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                marginBottom: "24px",
              }}
            >
              <Line>
                <Title>예약요청일</Title>
                <span style={{ color: "#000000", fontSize: "16px" }}>
                  {new Date(data.date).toLocaleDateString()}
                </span>
              </Line>
            </div>
            <div
              style={{
                width: "608px",
                backgroundColor: "#ffffff",
                paddingLeft: "30px",
                padding: "16px 30px",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                boxSizing: "border-box",
                borderRadius: "16px",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                gap: "12px",
                marginBottom: "24px",
              }}
            >
              <Line style={{ height: "100%" }}>
                <Title>시술 메모</Title>
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    color: "#000000",
                    fontSize: "16px",
                  }}
                >
                  <span
                    style={{
                      width: "462px",
                      minHeight: "100px",
                      backgroundColor: "#F4F5F7",
                      boxSizing: "border-box",
                      padding: "16px 16px",
                      borderRadius: "10px",
                    }}
                  >
                    <textarea
                      ref={textareaRef}
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#F4F5F7",
                        border: "none",
                        outline: "none",
                        resize: "none",
                      }}
                      placeholder="시술에 관련된 메모를 추가해보세요"
                      onInput={handleInput}
                      value={data.memo}
                      disabled={true}
                    />
                  </span>
                </div>
              </Line>
            </div>
            {data.bookState !== "nailComplete" &&
              data.bookState !== "noShow" &&
              data.bookState !== "bookCanceled" && (
                <div
                  style={{
                    width: "100%",
                    height: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "18px",
                  }}
                >
                  <button
                    style={{
                      height: "44px",
                      width: "140px",
                      borderRadius: "8px",
                      color: "#000000",
                      backgroundColor: "#E5E7EB",
                      cursor: "pointer",
                      boxSizing: "border-box",
                      border: "none",
                    }}
                    onClick={() => {
                      setOpen(false);
                      callback2();
                    }}
                    className="cancelBtn"
                  >
                    예약 취소
                  </button>
                  <button
                    style={{
                      border: "none",
                      height: "44px",
                      width: "140px",
                      borderRadius: "8px",
                      color: "#ffffff",
                      backgroundColor: "#FF5D7A",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setIsBookEditOpen(true);
                    }}
                    className="changeBtn"
                  >
                    예약 변경
                  </button>
                  <button
                    style={{
                      border: "none",
                      height: "44px",
                      width: "140px",
                      borderRadius: "8px",
                      color: "#ffffff",
                      backgroundColor: "#272F3E",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (new Date(data.date) > new Date()) {
                        window.confirm("시술 시간이전입니다.");
                        return;
                      } else {
                        if (window.confirm("노쇼 처리하시겠습니까?")) {
                          saveBookNoshow(data.bookId).then((res) => {
                            setOpen(false);
                            callback();
                          });
                        }
                      }
                    }}
                    className="noShowBtn"
                  >
                    노쇼
                  </button>
                  {!data.paymentState ? (
                    <button
                      style={{
                        border: "none",
                        height: "44px",
                        width: "140px",
                        borderRadius: "8px",
                        color: "#ffffff",
                        backgroundColor: "#6688F0",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (new Date(data.date) < new Date()) {
                          setIsSalesRegisterOpen(true);
                        } else {
                          window.alert(
                            "시술 시간전에는 매출등록이 불가합니다."
                          );
                        }
                      }}
                      className="submitBtn"
                    >
                      매출 등록
                    </button>
                  ) : (
                    <button
                      style={{
                        border: "none",
                        height: "44px",
                        width: "140px",
                        borderRadius: "8px",
                        color: "#ffffff",
                        backgroundColor: "#6688F0",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (new Date(data.date) < new Date()) {
                          setIsReqPaymentOpen(true);
                        } else {
                          window.alert(
                            "시술 시간전에는 매출등록이 불가합니다."
                          );
                        }
                      }}
                      className="submitBtn"
                    >
                      결제 요청
                    </button>
                  )}
                </div>
              )}
            {data.paymentState === "payRequest" && (
              <div
                style={{
                  width: "100%",
                  height: "60px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "18px",
                }}
              >
                <button
                  style={{
                    border: "none",
                    height: "44px",
                    width: "140px",
                    borderRadius: "8px",
                    color: "#ffffff",
                    backgroundColor: "#6688F0",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    CancelReqPayment(data.bookId).then((res) => {
                      setOpen(false);
                      callback();
                    });
                  }}
                  className="submitBtn"
                >
                  요청 취소
                </button>
              </div>
            )}
            <div
              style={{
                paddingBottom:
                  data.bookState !== "nailComplete" &&
                  data.bookState !== "noShow" &&
                  data.bookState !== "bookCanceled"
                    ? "20px"
                    : "80px",
              }}
            ></div>
          </div>
        </div>
      </Container>
    </Box>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;

  align-items: center;

  overflow: hidden;

  & .cancelBtn:hover {
    background-color: #d3d6db !important;
  }

  & .changeBtn:hover {
    background-color: #fd7f96 !important;
  }

  & .noShowBtn:hover {
    background-color: #394150 !important;
  }

  & .submitBtn:hover {
    background-color: #86a1f2 !important;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 44px;

  display: flex;
  align-items: center;

  box-sizing: border-box;

  gap: 12px;
`;

const Title = styled.span`
  width: 86px;
  font-size: 16px;
  font-weight: 700;
  color: #4d5562;
`;

const Status = styled.div<{ $backgroundColor: string }>`
  width: 53px;
  height: 22px;
  background-color: ${(props) => props.$backgroundColor};
  color: #ffffff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
`;

export default BookDetails;
