import React from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import {
  Element4,
  Calendar,
  Profile2User,
  Messages1,
  Chart21,
  Home2,
  Setting2,
  Wallet,
  User,
  Book1,
} from "iconsax-react";
import { MyShopIsEdit, UnReadMessageCount } from "../../atoms/atom";
import { useRecoilState, useRecoilValue } from "recoil";
import { color_midnight, color_rose } from "../../util/color";

type propType = {
  name: string;
  active?: boolean;
  to: string;
};

const MenuItem = (props: propType) => {
  const [myShopIsEdit, setMyShopIsEdit] = useRecoilState(MyShopIsEdit);

  const unReadMessage = useRecoilValue(UnReadMessageCount);

  const getIcon = () => {
    switch (props.name) {
      case "홈":
        return (
          <Element4
            size="20"
            color={props.active ? "#ffffff" : color_midnight.midnight5}
            variant="Bold"
          />
        );
      case "일정관리":
        return (
          <Calendar
            size="20"
            color={props.active ? "#ffffff" : color_midnight.midnight5}
            variant="Bold"
          />
        );
      case "고객관리":
        return (
          <Profile2User
            size="20"
            color={props.active ? "#ffffff" : color_midnight.midnight5}
            variant="Bold"
          />
        );
      case "매출관리":
        return (
          <Wallet
            size="20"
            color={props.active ? "#ffffff" : color_midnight.midnight5}
            variant="Bold"
          />
        );

      case "직원관리":
        return (
          <User
            size="20"
            color={props.active ? "#ffffff" : color_midnight.midnight5}
            variant="Bold"
          />
        );
      case "핑프톡":
        return (
          <Messages1
            size="20"
            color={props.active ? "#ffffff" : color_midnight.midnight5}
            variant="Bold"
          />
        );
      case "통계":
        return (
          <Chart21
            size="20"
            color={props.active ? "#ffffff" : color_midnight.midnight5}
            variant="Bold"
          />
        );

      case "정산":
        return (
          <Book1
            size="20"
            color={props.active ? "#ffffff" : color_midnight.midnight5}
            variant="Bold"
          />
        );
      case "내샵관리":
        return (
          <Home2
            size="20"
            color={props.active ? "#ffffff" : color_midnight.midnight5}
            variant="Bold"
          />
        );
      case "설정":
        return (
          <Setting2
            size="20"
            color={props.active ? "#ffffff" : color_midnight.midnight5}
            variant="Bold"
          />
        );
    }
  };

  return (
    <LinkBtn
      to={props.to}
      onClickCapture={(event) => {
        event.preventDefault(); // 이벤트의 기본 동작을 막음

        if (myShopIsEdit) {
          const result = window.confirm(
            "수정 사항이 저장되지 않았습니다. 정말 나가시겠습니까?"
          );

          if (result) {
            setMyShopIsEdit(false);
            window.location.href = props.to;
          }
        } else {
          window.location.href = props.to;
        }
      }}
    >
      <Container $active={props.active ? true : false}>
        <IconWrap>{getIcon()}</IconWrap>
        <ItemName $active={props.active ? true : false}>{props.name}</ItemName>
        {!props.active && props.name === "핑프톡" && unReadMessage > 0 && (
          <div
            style={{
              position: "absolute",
              right: 12,
              width: 24,
              height: 24,
              backgroundColor: color_rose.rose8,
              color: "#ffffff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 20,
            }}
          >
            {unReadMessage}
          </div>
        )}
      </Container>
    </LinkBtn>
  );
};

const Container = styled.div<{ $active: boolean }>`
  width: 184px;
  height: 49px;
  border-radius: 8px;
  background-color: ${(props) =>
    props.$active ? color_rose.rose8 : "#ffffff"};
  box-shadow: ${(props) =>
    props.$active ? "0px 4px 4px 0px #00000040" : "none"};

  display: flex;
  align-items: center;

  position: relative;
`;

const IconWrap = styled.div`
  width: 49px;
  height: 49px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemName = styled.span<{ $active: boolean }>`
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  color: ${(props) => (props.$active ? "#ffffff" : color_midnight.midnight5)};
`;

const LinkBtn = styled(Link)`
  text-decoration: none;
`;

export default MenuItem;
