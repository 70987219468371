import { styled } from "styled-components";
import MoneyIcon from "@mui/icons-material/Money";
import { ManagerType } from "../../types";
import { changeManagerCategory } from "../../util/util";

type propType = {
  focused: boolean;
  onClick: () => void;
  isEditBtn?: boolean;
  onEditClick?: () => void;
  data: ManagerType;
};

const ManagerItem = ({
  focused,
  onClick,
  isEditBtn,
  onEditClick,
  data,
}: propType) => {
  console.log(data);
  return (
    <Container $focused={focused} onClick={onClick}>
      <div>
        <div>
          {data.imageUrl !== "" && (
            <img
              alt=""
              src={data.imageUrl}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          )}
          <div
            style={{
              width: "16px",
              height: "16px",
              borderRadius: "50%",
              backgroundColor: data.color,
              position: "absolute",
              bottom: "0px",
              right: "0px",
            }}
          ></div>
        </div>
        {isEditBtn && (
          <div
            style={{
              width: "44px",
              height: "24px",
              borderRadius: "16px",
              backgroundColor: "#E5E7EB",
              fontSize: "10px",
              position: "absolute",
              top: "0px",
              right: "0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={onEditClick}
          >
            수정
          </div>
        )}
        <div>
          <div>
            <span>{changeManagerCategory(data.category, false)}</span>
            <span>{data.name}</span>
          </div>
          <span>
            {data.phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")}
          </span>
        </div>
      </div>
      <div>
        <div>
          <MoneyIcon />
          <div>
            <span>
              {data.totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              원
            </span>
            <span>
              {new Date()
                .toLocaleDateString(undefined, {
                  year: "numeric",
                  month: "2-digit",
                })
                .slice(0, -1)}{" "}
              매출
            </span>
          </div>
        </div>
        <div>
          <MoneyIcon />
          <div>
            <span>{data.salesCount}건</span>
            <span>
              {new Date()
                .toLocaleDateString(undefined, {
                  year: "numeric",
                  month: "2-digit",
                })
                .slice(0, -1)}{" "}
              시술
            </span>
          </div>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div<{ $focused: boolean }>`
  cursor: pointer;
  display: flex;
  width: 278px;
  height: 238px;
  padding: 16px 24px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background: ${(props) => (props.$focused ? "#F9E8EC" : "#f9fafb")};

  & > div:first-child {
    position: relative;
    width: 100%;
    height: 134px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    & > div:first-child {
      width: 56px;
      height: 56px;
      border-radius: 100px;
      background-color: #cccccc;

      position: relative;
    }

    & > div:last-child {
      display: flex;
      flex-direction: column;
      gap: 8px;

      & > div {
        display: flex;
        gap: 8px;
        & > span:first-child {
          display: flex;
          height: 24px;
          padding: 0px 8px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 8px;
          background: #f9e8ec;
          color: #f14262;
          font-size: 10px;
          font-weight: 500;
          line-height: 140%;
        }
        & > span:last-child {
          color: #000000;
          font-size: 16px;
          font-weight: 700;
          line-height: 140%;
        }
      }
      & > span:last-child {
        color: #000000;
        font-size: 16px;
        font-weight: 700;
        line-height: 140%;
      }
    }
  }
  & > div:last-child {
    width: 100%;
    height: 56px;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;

    & > div {
      display: flex;
      height: 56px;
      padding: 8px;
      align-items: center;
      gap: 8px;
      border-radius: 16px;
      background: #ffffff;
      box-sizing: border-box;

      & > :first-child {
        display: flex;
        padding: 6px;
        align-items: flex-start;
        gap: 10px;
        box-sizing: border-box;
        border-radius: 6px;
        background: #f9e8ec;
        font-size: 24px;
        color: #f14262;
      }

      & > div:last-child {
        display: flex;
        flex-direction: column;
        gap: 4px;
        & > span:first-child {
          color: #000000;
          font-size: 12px;
          font-weight: 700;
          line-height: 140%;
        }
        & > span:last-child {
          color: #a0a6b1;
          font-size: 10px;
          font-weight: 500;
          line-height: 140%;
        }
      }
    }
  }
`;

export default ManagerItem;
