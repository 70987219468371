import { useRecoilState } from "recoil";
import MainLayout from "../../layout/MainLayout";
import { ShopData } from "../../atoms/atom";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { getShopData } from "../../apis/shop";
import { ShopType } from "../../types";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TotalSales from "../../components/statistics/TotalSales";
import Manager from "../../components/statistics/Manager";
import Visitors from "../../components/statistics/Visitors";

const Statistics = () => {
  const [zoom, setZoom] = useState(1.0);
  const [shopData, setShopData] = useRecoilState(ShopData);

  const [tapNum, setTapNum] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    if (document.body.scrollHeight > window.innerHeight) {
      setZoom(
        1 -
          (document.body.scrollHeight - window.innerHeight) /
            document.body.scrollHeight
      );
    }

    getShopData()
      .then((res) => {
        const data = res.data.data.shop as ShopType;
        setShopData(data);
      })
      .catch((e) => {
        console.log(e, "Date Loading Fail , please login again!");
        navigate("/");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStatistics = () => {
    switch (tapNum) {
      case 0:
        return <TotalSales />;
      case 1:
        return <Manager />;
      case 2:
        return <Visitors />;
    }
  };

  return (
    <MainLayout active={"statistics"}>
      <Container $zoom={zoom}>
        <Main>
          <Contents>
            <Content1>
              <h2>어서오세요! {shopData.name}님</h2>
              <span>보트르네일과 좋은 하루 되세요 🙂</span>
            </Content1>
            <Content2>
              <ArrowBackIosNewIcon
                sx={{
                  position: "absolute",
                  top: "32%",
                  left: "-50px",
                  fontSize: "50px",
                  color: "#A0A6B1",
                }}
                onClick={() => setTapNum((old) => (old === 0 ? 0 : old - 1))}
              />
              <div>
                <div className="TapHeader">
                  {["총매출액", "담당자별", "방문자수"].map((item, idx) => (
                    <button
                      className={tapNum === idx ? "active" : ""}
                      onClick={() => setTapNum(idx)}
                    >
                      {item}
                    </button>
                  ))}
                </div>
                {getStatistics()}
              </div>
              <ArrowForwardIosIcon
                sx={{
                  position: "absolute",
                  top: "32%",
                  right: "-50px",
                  fontSize: "50px",
                  color: "#A0A6B1",
                }}
                onClick={() => setTapNum((old) => (old === 2 ? 2 : old + 1))}
              />
            </Content2>
          </Contents>
        </Main>
      </Container>
    </MainLayout>
  );
};

const Container = styled.div<{ $zoom: number }>`
  width: 100%;
  display: flex;

  zoom: ${(props) => props.$zoom};
`;

const Main = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  min-width: 1200px;
`;

const Contents = styled.div`
  width: 1200px;
  margin-top: 50px;

  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Content1 = styled.div`
  width: 400px;
  height: 51px;

  margin-left: 15px;

  & h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.2px;
  }

  & span {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    color: #a0a6b1;
  }
`;

const Content2 = styled.div`
  width: 100%;
  height: 740px;

  position: relative;

  & .TapHeader {
    width: 100%;
    height: 52px;

    display: flex;
    justify-content: center;
    align-items: center;

    & button {
      border: none;
      background-color: transparent;
      width: 160px;
      height: 52px;

      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: -0.2px;

      color: #a0a6b1;

      &.active {
        color: #ff5d7a;
      }
    }
  }
`;

export default Statistics;
