import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import "dayjs/locale/ko";
import dayjs, { Dayjs } from "dayjs";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import Badge from "@mui/material/Badge";
import { color_rose } from "../../util/color";
import { useEffect, useState } from "react";
import { getSalesList } from "../../apis/sales";
import { useRecoilValue } from "recoil";
import { ShopData } from "../../atoms/atom";

type propType = {
  zoom?: number;
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  bookList: any[];
  managerId: string;
};

const CustomDay = (
  props: PickersDayProps<Dayjs> & { highlightedDays?: string[] }
) => {
  const { highlightedDays, day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !outsideCurrentMonth &&
    highlightedDays &&
    highlightedDays.includes(day.toDate().toLocaleDateString());

  return (
    <Badge
      key={day.toString()}
      overlap="circular"
      variant="dot"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        ".MuiBadge-badge": {
          scale: isSelected ? "0.72" : "0",
          bottom: "18%",
          left: "50%",
          backgroundColor: color_rose.rose3,
        },
      }}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  );
};

export default function Calendar3({
  zoom,
  date,
  setDate,
  bookList,
  managerId,
}: propType) {
  const [salesList, setSalesList] = useState<any[]>([]);
  const shopData = useRecoilValue(ShopData);

  useEffect(() => {
    getSalesList(
      1,
      "date",
      "DESC",
      shopData.id,
      "monthly",
      date.toISOString(),
      managerId
    ).then((res) => {
      console.log(res.data.data.salesList);
      setSalesList(res.data.data.salesList);
    });
  }, [date, managerId, shopData.id]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
      <DateCalendar
        disableHighlightToday
        value={dayjs(date)}
        onMonthChange={(value) => {
          setDate(value!.toDate());
        }}
        onChange={(value) => {
          console.log(value?.toDate());
          setDate(value!.toDate());
        }}
        slots={{
          day: CustomDay,
        }}
        slotProps={{
          calendarHeader: {
            sx: {
              padding: "0",
              ".MuiPickersCalendarHeader-label": {
                fontSize: "20px",
                fontWeight: "700",
                marginLeft: "10px",
                fontFamily: "Noto Sans KR",
              },
            },
          },
          day: {
            highlightedDays: salesList.map((item) =>
              new Date(item.date).toLocaleDateString()
            ),
            sx: {
              fontFamily: "Noto Sans KR",
              "&.MuiPickersDay-today": {
                backgroundColor: "#FF5D7A",
                color: "#ffffff",
                border: "none",
              },
              "&:hover": {
                backgroundColor: "#FF5D7A",
                color: "#ffffff",
              },
              "&.Mui-selected,&.Mui-selected:focus": {
                backgroundColor: "#FF5D7A",
              },
              alignItems: "start",
              paddingTop: "4px",
            },
          } as any,
        }}
        sx={{
          scale: zoom ? zoom.toString() : "0.95",
          height: "285px",
          margin: 0,
          ".MuiDayCalendar-weekDayLabel": {
            fontSize: "13px",
            fontWeight: "700",
            color: "#000000",
          },
          ".MuiDayCalendar-weekDayLabel[aria-label='일요일']": {
            fontSize: "13px",
            fontWeight: "700",
            color: "#FF5D7A",
          },
          ".MuiDayCalendar-weekContainer,.MuiDayCalendar-header": {
            justifyContent: "space-between",
          },
        }}
        views={["day"]}
      />
    </LocalizationProvider>
  );
}
