import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Reset } from "styled-reset";
import { createGlobalStyle } from "styled-components";
import Findid from "./pages/findid";
import { RecoilRoot } from "recoil";
import {
  Signin,
  Signup,
  Store,
  Home,
  Schedule,
  Customer,
  Statistics,
  Myshop,
  Setting,
} from "./pages";
import Talk from "./pages/talk";
import { CookiesProvider } from "react-cookie";
import Success from "./pages/setting/Success";
import SecondPassword from "./pages/secondPassword";
import SecondPwSucces from "./pages/secondPwSucces";
import Manager from "./pages/manager";
import Revenue from "./pages/revenue";
import BillingTest from "./pages/billingTest";
import Billing from "./pages/billing";
import { Suspense } from "react";
import Expired from "./pages/expired";
import ServerMaintenancePage from "./pages/ServerMaintenancePage";

import "@sendbird/uikit-react/dist/index.css";
import SSOSignIn from "./pages/sso_signin";
import Revenue2 from "./pages/revenue2";
import Adjustment from "./pages/adjustment";

function App() {
  return (
    <CookiesProvider>
      <BrowserRouter>
        <RecoilRoot>
          <Suspense fallback={<div>Loading...</div>}>
            <Reset />
            <GlobalStyle />
            <Routes>
              <Route path="/update" element={<ServerMaintenancePage />} />
              <Route path="/" element={<Signin />} />
              <Route path="/signin" element={<Signin />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/secondPassword" element={<SecondPassword />} />
              <Route path="/secondPwSucces" element={<SecondPwSucces />} />
              <Route path="/store" element={<Store />} />
              <Route path="/findid" element={<Findid />} />
              <Route path="/home" element={<Home />} />
              <Route path="/schedule" element={<Schedule />} />
              <Route path="/customer" element={<Customer />} />
              <Route path="/talk" element={<Talk />} />
              <Route path="/statistics" element={<Statistics />} />
              <Route path="/myshop" element={<Myshop />} />
              <Route path="/setting" element={<Setting />} />
              <Route path="/setting/success" element={<Success />} />
              <Route path="/manager" element={<Manager />} />
              <Route path="/revenue" element={<Revenue />} />
              <Route path="/revenue2" element={<Revenue2 />} />
              <Route path="/expired" element={<Expired />} />
              <Route path="/billing" element={<Billing />} />
              <Route path="/billingTest" element={<BillingTest />} />
              <Route path="/sso_signin" element={<SSOSignIn />} />
              <Route path="/adjustment" element={<Adjustment />} />
            </Routes>
          </Suspense>
        </RecoilRoot>
      </BrowserRouter>
    </CookiesProvider>
  );
}

const GlobalStyle = createGlobalStyle`
  body{
    font-family: 'Noto Sans KR', sans-serif;
  }
  *{
    user-select: none;
  }

  .Mui-selected{
    background-color:#FF5D7A !important;
  }

  img {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
`;

export default App;
