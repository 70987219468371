import { Box, Modal } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CropIcon from "@mui/icons-material/Crop";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import CropImg2 from "../CropImg2";
import { UploadImg2 } from "../../apis/api";
import { FaArrowRotateLeft } from "react-icons/fa6";
import { FaArrowRotateRight } from "react-icons/fa6";
import { ShopData } from "../../atoms/atom";
import { useRecoilState } from "recoil";

type propType = {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  data: string[];
  setData: Dispatch<SetStateAction<string[]>>;
  titleImg: string;
  setTitleImg: Dispatch<SetStateAction<string>>;
  setOpenWaiting: Dispatch<SetStateAction<boolean>>;
};

const GallayModal = ({
  open,
  setOpen,
  data,
  setData,
  titleImg,
  setTitleImg,
  setOpenWaiting,
}: propType) => {
  const [active, setActive] = useState(0);
  const [imgData, setImgData] = useState(data);
  const [isCrop, setIsCrop] = useState(false);
  const [cropImg, setCropImg] = useState("");

  const [rotate, setRotate] = useState(0);

  const [shopData, setShopData] = useRecoilState(ShopData);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const temp = Array.from(e.target.files);
      setImgData((old) => [
        ...temp.map((item, i) => URL.createObjectURL(item)),
        ...imgData,
      ]);
    }
  };

  const deleteHandler = (num: number) => {
    setImgData((old) => old.filter((item, i) => i !== num));
  };

  useEffect(() => {
    if (cropImg !== "") {
      let temp = Array.from(imgData);
      temp[active] = cropImg;

      setImgData(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropImg]);

  useEffect(() => {
    setActive((old) => (imgData.length < old + 1 ? old - 1 : old));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgData]);

  useEffect(() => {
    setImgData((old) => data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const convertURLtoFile = async (url: string) => {
    const response = await fetch(url);
    const data = await response.blob();
    const ext = url.split(".").pop(); // url 구조에 맞게 수정할 것
    const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
    const metadata = { type: `image/${ext}` };
    return new File([data], filename!, metadata);
  };

  const saveHandler = async () => {
    setOpenWaiting(true);

    const temp: string[] = [];

    console.log(imgData);

    for (let i = 0; i < imgData.length; i++) {
      if (imgData[i].indexOf("storage.finger-princess") !== -1) {
        temp.push(imgData[i]);
      } else {
        const res = await convertURLtoFile(imgData[i]);
        const res2 = await UploadImg2(res, shopData.id, "shop");

        if (titleImg === imgData[i]) {
          setTitleImg(res2.data.url);
        }

        temp.push(res2.data.url);
      }
    }

    setImgData(temp);
    setData(temp);
    window.alert("저장되었습니다.");
    setOpenWaiting(false);
  };

  return (
    <>
      <Modal open={open}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 860,
            height: 540,
            backgroundColor: "#ffffff",
            outline: "none",
            borderRadius: "16px",
            padding: "0px 0px",
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Left>
            <span>
              <AddIcon
                sx={{ color: "#E5E7EB", fontSize: "36px" }}
                style={{ cursor: "pointer" }}
              />
              <input
                type="file"
                onChange={changeHandler}
                multiple
                style={{ cursor: "pointer" }}
              />
            </span>
            {imgData.map((item, i) => (
              <ImageFrame
                key={i}
                $bgUrl={item}
                onClick={() => {
                  setIsCrop(false);
                  setActive(i);
                }}
                className={active === i ? "active" : ""}
              >
                <CloseIcon
                  sx={{
                    color: "#ffffff",
                    position: "absolute",
                    top: "4px",
                    right: "4px",
                    fontSize: "18px",
                  }}
                  onClick={() => deleteHandler(i)}
                />
              </ImageFrame>
            ))}
          </Left>
          <Right>
            <Middle $bgUrl={imgData.length >= 1 ? imgData[active] : ""}>
              <div>
                <KeyboardArrowLeftIcon
                  sx={{ fontSize: "42px", color: "#A0A6B1", cursor: "pointer" }}
                  onClick={() =>
                    setActive((old) => {
                      if (old - 1 < 0) return 0;
                      return old - 1;
                    })
                  }
                />
              </div>
              {isCrop ? (
                <CropImg2
                  imgsrc={imgData[active]}
                  setCropData={setCropImg}
                  setSave={setIsCrop}
                  rotate={rotate}
                />
              ) : (
                <div>
                  {imgData.length >= 1
                    ? imgData[active]
                      ? ""
                      : "샵 이미지를 추가해보세요"
                    : "샵 이미지를 추가해보세요"}
                </div>
              )}
              <div>
                <KeyboardArrowRightIcon
                  sx={{ fontSize: "42px", color: "#A0A6B1", cursor: "pointer" }}
                  onClick={() =>
                    setActive((old) => {
                      if (imgData.length <= old + 1) return imgData.length - 1;
                      return old + 1;
                    })
                  }
                />
              </div>
            </Middle>
            <Bottom>
              {isCrop && (
                <FaArrowRotateLeft
                  style={{
                    width: "16px",
                    height: "16px",
                    color: "#F14262",
                    cursor: "pointer",
                  }}
                  onClick={() => setRotate((old) => old - 10)}
                />
              )}
              <DeleteForeverIcon
                sx={{
                  width: "24px",
                  height: "24px",
                  color: "#F14262",
                  cursor: "pointer",
                }}
                onClick={() => deleteHandler(active)}
              />
              <CropIcon
                sx={{
                  width: "24px",
                  height: "24px",
                  color: "#F14262",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setIsCrop(true);
                }}
              />
              {isCrop && (
                <FaArrowRotateRight
                  style={{
                    width: "16px",
                    height: "16px",
                    color: "#F14262",
                    cursor: "pointer",
                  }}
                  onClick={() => setRotate((old) => old + 10)}
                />
              )}
              {!isCrop && (
                <button onClick={saveHandler} style={{ cursor: "pointer" }}>
                  저장
                </button>
              )}
            </Bottom>
          </Right>
          <CloseIcon
            onClick={() => setOpen(false)}
            sx={{
              fontSize: "32px",
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
            }}
          />
        </Box>
      </Modal>
    </>
  );
};

const Left = styled.div`
  width: 160px;
  height: 500px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  overflow-y: scroll;

  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
  &::-webkit-scrollbar {
    display: none;
  }

  & > span {
    flex: 0 0 auto;
  }

  & > span:first-child {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 120px;
    height: 120px;
    background-color: #f9fafb;
    border-radius: 8px;

    margin: 0;
    position: relative;
    border: 1px solid #cccccc;

    & > input {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      color: transparent;

      &::file-selector-button {
        display: none;
      }
    }
  }
`;

const Right = styled.div`
  width: 700px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 30px;
`;

const Middle = styled.div<{ $bgUrl: string }>`
  margin-top: 20px;

  width: 700px;
  height: 375px;

  display: flex;

  & > div:nth-child(1),
  & > div:nth-child(3) {
    width: 100px;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > div:nth-child(2) {
    width: 500px;
    height: 100%;

    background-color: #f9fafb;

    background-image: url(${(props) => props.$bgUrl});
    background-size: cover;
    background-position: center;
    border-radius: 16px;

    color: #a0a6b1;
    font-size: 16px;
    font-weight: 700;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Bottom = styled.div`
  width: 700px;
  height: 44px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 28px;

  position: relative;

  & > button {
    position: absolute;
    right: 40px;

    width: 92px;
    height: 44px;
    background-color: #ff5d7a;
    color: #ffffff;
    border: none;
    border-radius: 8px;
  }
`;

const ImageFrame = styled.span<{ $bgUrl: string }>`
  cursor: pointer;
  width: 120px;
  height: 120px;
  background-color: #cccccc;
  border-radius: 8px;

  border: 1px solid #cccccc;
  box-sizing: border-box;

  display: flex;

  position: relative;

  background-image: url(${(props) => props.$bgUrl});
  background-size: cover;

  &.active {
    border: 4px solid #ff5d7a;
  }
`;

export default GallayModal;
