import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { styled } from "styled-components";
import { changeCategory, changeMethod } from "../../util/util";
import { IoMdClose } from "react-icons/io";
import EditSales from "./EditSales";

type propType = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  data: any;
  callback?: () => void;
};

const SalesHistoryModal = ({ open, setOpen, data, callback }: propType) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInput = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // 높이를 초기화하여 스크롤 높이를 정확히 계산
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // 내용에 맞게 높이 조정
    }
  };

  const [isSalesEdit, setIsSalesEdit] = useState(false);

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isSalesEdit ? (
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "660px",
              height: "880px",
              borderRadius: "16px",
              boxShadow: "4px 4px 4px 0px #00000040",
              boxSizing: "border-box",
              backgroundColor: "#F9FAFB",
              overflow: "auto",
              zoom: 0.9,
            }}
          >
            <Container>
              <div
                style={{
                  width: "100%",
                  height: "90px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#ffffff",
                }}
              >
                <h2 style={{ fontSize: "20px", fontWeight: "700" }}>
                  매출 등록 내역
                </h2>
                <IoMdClose
                  style={{
                    position: "absolute",
                    right: "32px",
                    fontSize: "32px",
                    cursor: "pointer",
                  }}
                  onClick={() => setOpen(false)}
                />
              </div>
              <div
                style={{
                  overflow: "auto",
                  height: "766px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ width: "608px", marginTop: "24px" }}>
                  <div
                    style={{
                      width: "608px",
                      backgroundColor: "#ffffff",
                      height: "76px",
                      paddingLeft: "30px",
                      alignItems: "center",
                      display: "flex",
                      boxSizing: "border-box",
                      borderRadius: "16px",
                      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                      marginBottom: "24px",
                    }}
                  >
                    <Line>
                      <Title>예약자</Title>
                      <div
                        style={{
                          display: "flex",
                          gap: "12px",
                          color: "#000000",
                          fontSize: "16px",
                        }}
                      >
                        <span>{data.customerName}</span>
                        <span>{data.customerPhoneNumber}</span>
                      </div>
                    </Line>
                  </div>
                  <div
                    style={{
                      width: "608px",
                      backgroundColor: "#ffffff",
                      paddingLeft: "30px",
                      padding: "26px 30px",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      boxSizing: "border-box",
                      borderRadius: "16px",
                      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                      marginBottom: "24px",
                    }}
                  >
                    <Line>
                      <Title>시술 일정</Title>
                      <span>
                        {new Date(data.date)
                          .toLocaleDateString("ko-KR")
                          .slice(0, -1)}
                      </span>
                      <span>
                        {new Date(data.date).toLocaleTimeString("ko-KR", {
                          hour12: true,
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </span>
                    </Line>
                    <Line style={{ minHeight: "122px", paddingTop: "10px" }}>
                      <Title style={{ height: "100%" }}>시술내용</Title>
                      <div
                        style={{
                          display: "flex",
                          gap: "12px",
                          justifyContent: "space-between",
                          width: "calc(100% - 86px)",
                          paddingRight: "32px",
                          boxSizing: "border-box",
                          color: "#394150",
                          fontSize: "16px",
                          height: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                          }}
                        >
                          {data.menuCategory && (
                            <span
                              style={{
                                fontSize: 16,
                                fontWeight: 700,
                                color: "#000000",
                              }}
                            >
                              {`(${changeCategory(data.menuCategory, false)})`}
                            </span>
                          )}
                          <span
                            style={{
                              fontSize: 16,
                              fontWeight: 700,
                              color: "#000000",
                            }}
                          >
                            -{data.menuName}
                          </span>
                          <span
                            style={{
                              fontSize: 16,
                              fontWeight: 700,
                              color: "#000000",
                            }}
                          >
                            -{data.designName ? data.designName : "없음"}
                          </span>
                          <span
                            style={{
                              fontSize: 16,
                              fontWeight: 700,
                              color: "#000000",
                            }}
                          >
                            -
                            {data.optionName.length > 0
                              ? data.optionName.join(",")
                              : "없음"}
                          </span>
                        </div>
                      </div>
                    </Line>
                    <Line>
                      <Title>담당자</Title>
                      <span style={{ color: "#000000", fontSize: "16px" }}>
                        {data.managerName}
                      </span>
                    </Line>
                    <Line>
                      <Title>시술 금액</Title>
                      <span style={{ color: "#000000", fontSize: "16px" }}>
                        {data.price
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        원
                      </span>
                    </Line>
                    <Line>
                      <Title>결제 방법</Title>
                      <span style={{ color: "#000000", fontSize: "16px" }}>
                        {changeMethod(data.paymentMethod, false)}
                      </span>
                    </Line>
                  </div>
                  <div
                    style={{
                      width: "608px",
                      backgroundColor: "#ffffff",
                      paddingLeft: "30px",
                      padding: "16px 30px",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      boxSizing: "border-box",
                      borderRadius: "16px",
                      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                      marginBottom: "24px",
                    }}
                  >
                    <Line>
                      <Title>매출등록일</Title>
                      <span style={{ color: "#000000", fontSize: "16px" }}>
                        {new Date(data.createdAt).toLocaleDateString()}
                      </span>
                    </Line>
                  </div>
                  <div
                    style={{
                      width: "608px",
                      backgroundColor: "#ffffff",
                      paddingLeft: "30px",
                      padding: "16px 30px",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      boxSizing: "border-box",
                      borderRadius: "16px",
                      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                      gap: "12px",
                      marginBottom: "24px",
                    }}
                  >
                    <Line style={{ height: "100%" }}>
                      <Title>매출 메모</Title>
                      <div
                        style={{
                          display: "flex",
                          gap: "12px",
                          color: "#000000",
                          fontSize: "16px",
                        }}
                      >
                        <span
                          style={{
                            width: "462px",
                            minHeight: "100px",
                            backgroundColor: "#F4F5F7",
                            boxSizing: "border-box",
                            padding: "16px 16px",
                            borderRadius: "10px",
                          }}
                        >
                          <textarea
                            ref={textareaRef}
                            style={{
                              width: "100%",
                              height: "100%",
                              backgroundColor: "#F4F5F7",
                              border: "none",
                              outline: "none",
                              resize: "none",
                            }}
                            placeholder="매출에 관련된 메모를 추가해보세요"
                            onInput={handleInput}
                            value={data.memo}
                            disabled={true}
                          />
                        </span>
                      </div>
                    </Line>
                  </div>
                  {false && (
                    <div
                      style={{
                        width: "100%",
                        height: "44px",
                        display: "flex",
                        justifyContent: "center",
                        paddingBottom: "32px",
                      }}
                    >
                      <span
                        style={{
                          width: "184px",
                          height: "44px",
                          backgroundColor: "#272F3E",
                          color: "#ffffff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "8px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setIsSalesEdit(true);
                        }}
                      >
                        수정
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </Container>
          </Box>
        ) : (
          <EditSales
            setOpen={setOpen}
            data={data}
            callback={() => {
              setOpen(false);

              if (callback) {
                callback();
              }
            }}
          />
        )}
      </Modal>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;

  & > hr {
    width: 90%;
    color: #e5e7eb;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 44px;

  display: flex;
  align-items: center;

  box-sizing: border-box;

  gap: 12px;
`;

const Title = styled.span`
  width: 86px;
  font-size: 16px;
  font-weight: 700;
  color: #4d5562;
`;
export default SalesHistoryModal;
