import {
  Calendar2,
  Shop,
  Card,
  Location,
  Call,
  Tag,
  Clock,
} from "iconsax-react";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import ErrorIcon from "@mui/icons-material/Error";
import InputBox from "../utility/InputBox";
import { Box, Modal, Switch } from "@mui/material";
import DaumPostcode from "react-daum-postcode";
import CloseIcon from "@mui/icons-material/Close";
import LocalSelModal from "./LocalSelModal";
import SubwaySelModal from "./SubwaySelModal";
import GallayModal from "../utility/GallayModal";
import ProfileImgInput from "../utility/ProfileImgInput";
import ImageCropModal from "../utility/ImageCropModal";
import WorkTimeModal from "./WorkTimeModal";
import { useRecoilState } from "recoil";
import { MyShopIsEdit, ShopData } from "../../atoms/atom";
import { getShopData, saveShopData } from "../../apis/shop";
import WaitingModal from "../utility/WaitingModal";
import { ShopType } from "../../types";
import { UploadImg } from "../../apis/api";
import { BusinessHour } from "../../types";
import { transDay } from "../../util/util";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { FaDog } from "react-icons/fa";
import { FaWifi } from "react-icons/fa";

const BasicInfo = () => {
  const [shopData, setShopData] = useRecoilState(ShopData);

  const [isEdit, setIsEdit] = useState(false);

  const [name, setName] = useState("");
  const [engName, setEngName] = useState("");
  const [deposit, setDeposit] = useState("");
  const [phone, setPhone] = useState("");
  const [announcement, setAnnouncement] = useState(shopData.announcement);

  const [isAutoBooking, setIsAutoBooking] = useState(false);

  const [isBooking, setIsBooking] = useState(false);
  const [isParking, setIsParking] = useState(false);

  const [isPet, setIsPet] = useState(false);
  const [isWifi, setIsWifi] = useState(false);

  const [basicAdd, setBasicAdd] = useState("");
  const [detailAdd, setDetailAdd] = useState("");

  const [openPostcode, setOpenPostcode] = useState(false);

  const [isOpenLocalSelModal, setIsOpenLocalSelModal] = useState(false);
  const [isOpenSubwaySelModal, setIsOpenSubwaySelModal] = useState(false);
  const [isOpenWorkTimeModal, setIsOpenWorkTimeModal] = useState(false);

  const [openImageSetting, setOpenImageSetting] = useState(false);
  const [imgList, setImageList] = useState<string[]>([]);

  const [profile, setProfile] = useState("");
  const [image, setImage] = useState<File>();
  const [openCrop, setOpenCrop] = useState(false);

  const [openWaiting, setOpenWaiting] = useState(false);

  const [localTagList, setLocalTagList] = useState<string[]>([]);
  const [subwayTagList, setSubwayTagList] = useState<string[]>([]);

  const [businessHourList, setBusinessHourList] = useState<BusinessHour[]>([]);

  const [titleImg, setTitleImg] = useState("");

  const [, setMyShopIsEdit] = useRecoilState(MyShopIsEdit);

  const [kakaoLink, setKakaoLink] = useState("");
  const [isOnlyKakao, setIsOnlyKakao] = useState(false);

  const handle = {
    // 버튼 클릭 이벤트
    clickButton: () => {
      setOpenPostcode(true);
    },

    // 주소 선택 이벤트
    selectAddress: (data: any) => {
      setBasicAdd(data.address);
      setOpenPostcode(false);
    },
  };

  const saveData = (url: string) => {
    console.log(businessHourList);
    saveShopData({
      shopUserId: shopData.shopUserId,
      id: shopData.id,
      imageUrlList: url ? [url, ...imgList] : ["", ...imgList],
      name: name,
      engName: engName,
      phoneNumber: phone,
      basicAddress: basicAdd,
      detailAddress: detailAdd,
      coordinate: shopData.coordinate,
      announcement: announcement,
      info: {
        isBooking: isBooking,
        isParking: isParking,
        isPet: isPet,
        isWifi: isWifi,
      },
      businessHourList: businessHourList,
      deposit: deposit,
      bookingOption: isAutoBooking,
      localTag: localTagList.join(","),
      subwayTag: subwayTagList.join(","),
      instagramId: shopData.instagramId,
      instagramRefreshToken: shopData.instagramRefreshToken,
      titleMenuId: shopData.titleMenuId,
      titleImageUrl: titleImg,
      kakaoLink: kakaoLink,
      isOnlyKakao: kakaoLink === "" ? false : isOnlyKakao,
    }).then((res) => {
      getShopData().then((res) => {
        const data = res.data.data.shop as ShopType;
        setShopData(data);
        setOpenWaiting(false);

        setMyShopIsEdit(false);
        setIsEdit(false);
      });
    });
  };

  const saveHandle = () => {
    setOpenWaiting(true);

    if (image) {
      UploadImg(image!, shopData.id, "shop").then((res) => {
        saveData(res.data.url);
      });
    } else {
      saveData(shopData.imageUrlList[0]);
    }
  };

  useEffect(() => {
    if (profile) {
      if (!profile.startsWith("http") && profile) {
        fetch(profile)
          .then((response) => response.blob())
          .then((blob) => {
            const file = new File([blob], "cropImg.png", { type: blob.type });
            setImage(file);
          });
      }
    }
  }, [profile]);

  useEffect(() => {
    setName(shopData.name);
    setEngName(shopData.engName);
    setAnnouncement(shopData.announcement);
    setIsBooking(shopData.info.isBooking);
    setIsParking(shopData.info.isParking);
    setIsPet(shopData.info.isPet);
    setIsWifi(shopData.info.isWifi);
    setBasicAdd(shopData.basicAddress);
    setDetailAdd(shopData.detailAddress);
    setProfile(shopData.imageUrlList[0]);
    setIsAutoBooking(shopData.bookingOption);
    setDeposit(shopData.deposit);
    setPhone(shopData.phoneNumber);
    setImageList(shopData.imageUrlList.filter((item, i) => i !== 0));
    setLocalTagList(
      shopData.localTag !== "" ? shopData.localTag.split(",") : []
    );
    setSubwayTagList(
      shopData.subwayTag !== "" ? shopData.subwayTag.split(",") : []
    );
    setBusinessHourList(shopData.businessHourList);
    setTitleImg(shopData.titleImageUrl ? shopData.titleImageUrl : "");
    setKakaoLink(shopData.kakaoLink);
    setIsOnlyKakao(shopData.isOnlyKakao);
  }, [shopData]);

  return (
    <Container>
      {isEdit ? (
        <>
          <EditBox>
            <EditLeft>
              <ProfileImgInput
                profile={profile}
                onChange={(e) => {
                  if (e.target.files?.length) {
                    setImage(e.target.files[0]);
                    setOpenCrop(true);
                  }
                }}
              />
              <DataBox>
                <DataTitle>샵 명(한글)</DataTitle>
                <InputBox
                  type={"text"}
                  setValue={setName}
                  value={name}
                  placeholder={"샵 명을 입력해주세요."}
                  width={300}
                />
              </DataBox>
              <DataBox>
                <DataTitle>샵 명(영어)</DataTitle>
                <InputBox
                  type={"text"}
                  setValue={setEngName}
                  value={engName}
                  placeholder={"샵 명을 입력해주세요."}
                  width={300}
                />
              </DataBox>
              <DataBox>
                <DataTitle>샵 연락처</DataTitle>
                <InputBox
                  type={"phone"}
                  setValue={setPhone}
                  value={phone}
                  placeholder={"샵 연락처를 입력해주세요."}
                  width={300}
                />
              </DataBox>
              {false && (
                <DataBox>
                  <DataTitle>예약금</DataTitle>
                  <InputBox
                    type={"money"}
                    setValue={setDeposit}
                    value={deposit}
                    placeholder={"Ex. 20,000"}
                    width={300}
                  />
                </DataBox>
              )}
              <DataBox>
                <DataTitle>예약 설정</DataTitle>
                <TogleWrap>
                  <span>예약 자동 승인</span>
                  <Switch
                    checked={isAutoBooking}
                    onClick={() => setIsAutoBooking((old) => !old)}
                  />
                </TogleWrap>
              </DataBox>
              <DataBox>
                <DataTitle>편의 정보</DataTitle>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <TogleWrap>
                    <span>예약 필수</span>
                    <Switch
                      checked={isBooking}
                      onClick={() => setIsBooking((old) => !old)}
                    />
                  </TogleWrap>
                  <TogleWrap>
                    <span>주차 가능</span>
                    <Switch
                      checked={isParking}
                      onClick={() => setIsParking((old) => !old)}
                    />
                  </TogleWrap>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <TogleWrap>
                    <span>무선인터넷</span>
                    <Switch
                      checked={isWifi}
                      onClick={() => setIsWifi((old) => !old)}
                    />
                  </TogleWrap>
                  <TogleWrap>
                    <span>반려동물동반</span>
                    <Switch
                      checked={isPet}
                      onClick={() => setIsPet((old) => !old)}
                    />
                  </TogleWrap>
                </div>
              </DataBox>
            </EditLeft>
            <EditRight>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <DataTitle>샵 주소</DataTitle>
                  <div
                    style={{
                      display: "flex",
                      gap: "12px",
                      position: "relative",
                    }}
                  >
                    <InputBox
                      placeholder=""
                      type="text"
                      width={300}
                      setValue={setBasicAdd}
                      value={basicAdd}
                      noReset={true}
                      disabled={true}
                    />
                    <InputBox
                      placeholder=""
                      type="text"
                      width={300}
                      setValue={setDetailAdd}
                      value={detailAdd}
                    />

                    <ClickWrap onClick={handle.clickButton}></ClickWrap>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <DataTitle>샵 위치 ( 지역 ) *최대 5개 추가 가능</DataTitle>
                  <div
                    style={{
                      display: "flex",
                      gap: "12px",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        width: "48px",
                        height: "36px",
                        backgroundColor: "#F9FAFB",
                        borderRadius: "25px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => setIsOpenLocalSelModal(true)}
                    >
                      +
                    </div>
                    {[...localTagList].map((item, idx) => {
                      return (
                        <div
                          key={idx}
                          style={{
                            height: "36px",
                            backgroundColor: "#D8F5F5",
                            borderRadius: "25px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "14px",
                            gap: "4px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                          }}
                        >
                          {item}
                          <CloseIcon
                            style={{ fontSize: "14px", cursor: "pointer" }}
                            onClick={() =>
                              setLocalTagList(
                                localTagList.filter((item2) => item !== item2)
                              )
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <DataTitle>샵 위치 ( 지하철 )</DataTitle>
                  <div
                    style={{
                      display: "flex",
                      gap: "12px",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        width: "48px",
                        height: "36px",
                        backgroundColor: "#F9FAFB",
                        borderRadius: "25px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => setIsOpenSubwaySelModal(true)}
                    >
                      +
                    </div>
                    {[...subwayTagList].map((item, idx) => (
                      <div
                        key={idx}
                        style={{
                          height: "36px",
                          backgroundColor: "#D8F5F5",
                          borderRadius: "25px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "14px",
                          gap: "4px",
                          paddingLeft: "16px",
                          paddingRight: "16px",
                        }}
                      >
                        {item}
                        <CloseIcon
                          style={{ fontSize: "14px", cursor: "pointer" }}
                          onClick={() =>
                            setSubwayTagList((old) =>
                              old.filter((item2) => item2 !== item)
                            )
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <DataTitle>샵 공지사항</DataTitle>
                  <div
                    style={{
                      width: "560px",
                      padding: "10px",
                      backgroundColor: "#f4f5f7",
                      borderRadius: "16px",
                    }}
                  >
                    <textarea
                      placeholder="샵 공지사항을 입력해주세요."
                      style={{
                        width: "540px",
                        height: "120px",
                        backgroundColor: "#f4f5f7",
                        border: "none",
                      }}
                      value={announcement}
                      onChange={(e) => {
                        if (e.target.value.length <= 300) {
                          setAnnouncement(e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                  }}
                >
                  <DataTitle>샵 영업일 및 영업시간</DataTitle>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "44px",
                      height: "24px",
                      backgroundColor: "#E5E7EB",
                      fontSize: "10px",
                      borderRadius: "16px",
                      cursor: "pointer",
                    }}
                    onClick={() => setIsOpenWorkTimeModal(true)}
                  >
                    수정
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "12px",
                    }}
                  >
                    <DataTitle>
                      샵 이미지 ( 2장 이상의 사진을 올려야 앱에서 볼 때 예뻐요💖
                      )
                    </DataTitle>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "48px",
                        height: "24px",
                        backgroundColor: "#E5E7EB",
                        fontSize: "10px",
                        borderRadius: "16px",
                        cursor: "pointer",
                      }}
                      onClick={() => setOpenImageSetting(true)}
                    >
                      업로드
                    </div>
                  </div>
                  {imgList.length < 1 ? (
                    <div
                      style={{
                        display: "flex",
                        width: "216px",
                        height: "144px",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "10px",
                        backgroundColor: "#F9FAFB",
                        color: "#A0A6B1",
                        fontSize: "14px",
                      }}
                    >
                      샵 이미지를 추가해보세요.
                    </div>
                  ) : (
                    <div style={{ display: "flex", gap: "10px" }}>
                      {imgList
                        .filter((e, i) => i <= 2)
                        .map((e, i) => (
                          <div
                            key={i}
                            style={{
                              display: "flex",
                              width: "216px",
                              height: "144px",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "10px",
                              backgroundColor: "#F9FAFB",
                              color: "#A0A6B1",
                              fontSize: "14px",
                              backgroundImage: `url(${imgList[i]})`,
                              backgroundSize: "cover",
                            }}
                          ></div>
                        ))}
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <DataTitle>카카오 채널</DataTitle>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "160px",
                      }}
                    >
                      <span style={{ color: "#FD91A5", fontSize: "10px" }}>
                        카카오 채널로만 예약을 받으시려면 상태를 ON으로
                        변경해주세요
                      </span>
                      <Switch
                        disabled={kakaoLink === ""}
                        checked={isOnlyKakao}
                        onClick={() => setIsOnlyKakao((old) => !old)}
                      />
                    </div>
                  </div>
                  <InputBox
                    placeholder={"카카오 채널링크를 입력하세요."}
                    type={"text"}
                    width={580}
                    setValue={setKakaoLink}
                    value={kakaoLink}
                  />
                </div>
              </div>
            </EditRight>
          </EditBox>
          <div style={{ display: "flex", gap: "24px" }}>
            <CancleBtn
              onClick={() => {
                setMyShopIsEdit(false);
                setIsEdit(false);
              }}
            >
              취소
            </CancleBtn>
            <EditBtn
              onClick={() => {
                saveHandle();
              }}
            >
              저장
            </EditBtn>
          </div>
        </>
      ) : (
        <>
          <InfoBox>
            <Left>
              <div>
                {profile ? (
                  <img
                    alt=""
                    src={profile}
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <ProfileImg>
                    <Shop size="80" color="#F0EFF2" variant="Bold" />
                  </ProfileImg>
                )}
                <div>
                  <div>
                    <OptionBox>
                      {shopData.info.isBooking ? (
                        <>
                          <Calendar2
                            size="20"
                            color="#F14262"
                            variant="Outline"
                          />
                          <span>예약 필수</span>
                        </>
                      ) : (
                        <>
                          <DirectionsWalkIcon
                            style={{ color: "#F14262", fontSize: "20px" }}
                          />
                          <span>바로 방문</span>
                        </>
                      )}
                    </OptionBox>
                    <OptionBox>
                      {shopData.info.isParking ? (
                        <>
                          <DirectionsCarIcon
                            style={{ color: "#F14262", fontSize: "20px" }}
                          />
                          <span>주차 가능</span>
                        </>
                      ) : (
                        <>
                          <DirectionsCarIcon
                            style={{ color: "#FBC5D0", fontSize: "20px" }}
                          />
                          <span style={{ color: "#FBC5D0" }}>주차 불가</span>
                        </>
                      )}
                    </OptionBox>
                    <OptionBox>
                      {shopData.info.isWifi ? (
                        <>
                          <FaWifi
                            style={{
                              color: "#F14262",
                              fontSize: "20px",
                            }}
                          />
                          <span style={{ textAlign: "center" }}>
                            무선인터넷
                          </span>
                        </>
                      ) : (
                        <>
                          <FaWifi
                            style={{ color: "#FBC5D0", fontSize: "20px" }}
                          />
                          <span
                            style={{ color: "#FBC5D0", textAlign: "center" }}
                          >
                            무선인터넷
                          </span>
                        </>
                      )}
                    </OptionBox>
                    <OptionBox>
                      {shopData.info.isPet ? (
                        <>
                          <FaDog
                            style={{ color: "#F14262", fontSize: "20px" }}
                          />
                          <span>반려동물</span>
                        </>
                      ) : (
                        <>
                          <FaDog
                            style={{ color: "#FBC5D0", fontSize: "20px" }}
                          />
                          <span style={{ color: "#FBC5D0" }}>반려동물</span>
                        </>
                      )}
                    </OptionBox>
                  </div>
                  <DepositBox>
                    <IconBox>
                      <Card size="16" color="#F14262" variant="Outline" />
                    </IconBox>
                    <div>
                      <span>
                        {deposit
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        원
                      </span>
                      <span>예약금</span>
                    </div>
                  </DepositBox>
                </div>
              </div>
              <div>
                <IconBox>
                  <Shop size="16" color="#F14262" variant="Bold" />
                </IconBox>
                <Title>샵 명</Title>
                <Text>
                  {name} {`( ${engName} )`}
                </Text>
              </div>
              <div>
                <IconBox>
                  <Location size="16" color="#F14262" variant="Bold" />
                </IconBox>
                <Title>주 소</Title>
                <Text>
                  {basicAdd} <br />
                  {detailAdd}
                </Text>
              </div>
              <div>
                <IconBox>
                  <Call size="16" color="#F14262" variant="Bold" />
                </IconBox>
                <Title>전화번호</Title>
                <Text>{phone}</Text>
              </div>
              <div>
                <IconBox>
                  <Tag size="16" color="#F14262" variant="Bold" />
                </IconBox>
                <Title>위치태그</Title>
                <TagBox>
                  {[...localTagList, ...subwayTagList].map((item, idx) => (
                    <TagItem key={idx}>{item}</TagItem>
                  ))}
                </TagBox>
              </div>
              <div>
                <IconBox>
                  <ErrorIcon style={{ fontSize: 16, color: "#F14262" }} />
                </IconBox>
                <Title>공지사항</Title>
                <pre
                  style={{
                    height: "36px",
                    color: "#272f3e",
                    fontSize: "12px",
                    fontWeight: 500,
                    width: "350px",
                    overflowY: "scroll",
                  }}
                >
                  {announcement ? announcement : "등록된 내용이 없습니다."}
                </pre>
              </div>
            </Left>
            <Right>
              <RunningTime>
                <div>
                  <div>
                    <IconBox>
                      <Clock size="16" color="#F14262" variant="Bold" />
                    </IconBox>
                    <Title>영업시간</Title>
                  </div>
                  <div>
                    {["월", "화", "수", "목", "금", "토", "일"].map(
                      (item, idx) => (
                        <RunDateBox key={idx}>
                          <div>{item}</div>
                          <span>
                            {businessHourList.filter(
                              (item2) => item2.dayOfWeek === transDay(item)
                            ).length < 1
                              ? "휴무"
                              : `${new Date(
                                  businessHourList.filter(
                                    (item2) =>
                                      item2.dayOfWeek === transDay(item)
                                  )[0].open
                                ).toLocaleTimeString("ko-KR", {
                                  timeZone: "Asia/Seoul",
                                  hour12: false,
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}~${new Date(
                                  businessHourList.filter(
                                    (item2) =>
                                      item2.dayOfWeek === transDay(item)
                                  )[0].close
                                ).toLocaleTimeString("ko-KR", {
                                  timeZone: "Asia/Seoul",
                                  hour12: false,
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}`}
                          </span>
                        </RunDateBox>
                      )
                    )}
                  </div>
                </div>
              </RunningTime>
              <ShopImage>
                <div>
                  <div>
                    <IconBox>
                      <Clock size="16" color="#F14262" variant="Bold" />
                    </IconBox>
                    <Title>샵이미지</Title>
                  </div>
                  <div>
                    <Image>
                      {shopData.imageUrlList[1] ? (
                        <img
                          alt=""
                          src={shopData.imageUrlList[1]}
                          width={"100%"}
                          height={"100%"}
                          style={{ objectFit: "cover" }}
                        />
                      ) : (
                        <img
                          alt=""
                          src={
                            "https://storage.finger-princess.com/25117f69-5480-491b-b170-a1707545113b"
                          }
                          width={"100%"}
                          height={"100%"}
                          style={{
                            objectFit: "contain",
                            backgroundColor: "#D9D9D9",
                          }}
                        />
                      )}
                    </Image>
                  </div>
                </div>
              </ShopImage>
            </Right>
          </InfoBox>
          <EditBtn
            onClick={() => {
              setMyShopIsEdit(true);
              setIsEdit(true);
            }}
          >
            수정
          </EditBtn>
        </>
      )}
      <Modal open={openPostcode} onClose={() => setOpenPostcode(false)}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            height: 500,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <DaumPostcode
            onComplete={handle.selectAddress}
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
      </Modal>
      {isOpenLocalSelModal && (
        <LocalSelModal
          open={isOpenLocalSelModal}
          setOpen={setIsOpenLocalSelModal}
          list={localTagList}
          setList={setLocalTagList}
        />
      )}
      {isOpenSubwaySelModal && (
        <SubwaySelModal
          open={isOpenSubwaySelModal}
          setOpen={setIsOpenSubwaySelModal}
          list={subwayTagList}
          setList={setSubwayTagList}
        />
      )}
      {openImageSetting && (
        <GallayModal
          open={openImageSetting}
          setOpen={setOpenImageSetting}
          data={imgList}
          setData={setImageList}
          titleImg={titleImg}
          setTitleImg={setTitleImg}
          setOpenWaiting={setOpenWaiting}
        />
      )}
      {openCrop && (
        <ImageCropModal
          image={image!}
          open={openCrop}
          setOpen={setOpenCrop}
          setData={setProfile}
        />
      )}
      {isOpenWorkTimeModal && (
        <WorkTimeModal
          open={isOpenWorkTimeModal}
          setOpen={setIsOpenWorkTimeModal}
          list={businessHourList}
          setList={setBusinessHourList}
        />
      )}
      <WaitingModal open={openWaiting} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  align-items: flex-end;
`;

const InfoBox = styled.div`
  width: 100%;
  height: 93%;

  display: flex;
  justify-content: space-between;
`;

const Left = styled.div`
  width: 49%;
  height: 92%;

  background-color: #f9fafb;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  box-sizing: border-box;

  justify-content: center;

  & > :nth-child(1) {
    padding-left: 20px;
    width: 100%;
    height: 136px;
    border-radius: 16px;

    display: flex;
    gap: 40px;
    align-items: center;

    & > div:last-child {
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;

      gap: 16px;

      & > div:first-child {
        display: flex;
        gap: 16px;
      }
    }
  }
  & > :nth-child(2) {
    width: 100%;
    height: 56px;
    background-color: #ffffff;
    border-radius: 16px;

    display: flex;
    padding: 16px 24px;
    box-sizing: border-box;
    align-items: center;
    gap: 24px;
  }
  & > :nth-child(3) {
    width: 100%;
    height: 56px;
    background-color: #ffffff;
    border-radius: 16px;

    display: flex;
    padding: 16px 24px;
    box-sizing: border-box;
    align-items: center;
    gap: 24px;
  }
  & > :nth-child(4) {
    width: 100%;
    height: 56px;
    background-color: #ffffff;
    border-radius: 16px;

    display: flex;
    padding: 16px 24px;
    box-sizing: border-box;
    align-items: center;
    gap: 24px;
  }
  & > :nth-child(5) {
    width: 100%;
    height: 80px;
    background-color: #ffffff;
    border-radius: 16px;

    display: flex;
    padding: 16px 24px;
    align-items: center;
    box-sizing: border-box;
    gap: 24px;
  }
  & > :nth-child(6) {
    width: 100%;
    height: 56px;
    background-color: #ffffff;
    border-radius: 16px;

    display: flex;
    padding: 16px 24px;
    box-sizing: border-box;
    align-items: center;
    gap: 24px;
  }
`;
const Right = styled.div`
  width: 49%;
  height: 92%;

  display: flex;
  flex-direction: column;

  justify-content: space-between;
`;

const RunningTime = styled.div`
  width: 100%;
  height: 48%;

  background-color: #f9fafb;
  border-radius: 16px;

  padding: 16px 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    background-color: #ffffff;
    padding: 16px 48px;
    display: flex;
    align-items: center;
    gap: 24px;
    border-radius: 16px;

    width: 520px;
    height: 208px;

    & > div:first-child {
      height: 100%;
      display: flex;
      align-items: center;
      gap: 16px;
    }

    & > div:last-child {
      display: flex;
      align-items: flex-start;
      gap: 16px;

      flex-wrap: wrap;
      width: 300px;
    }
  }
`;

const ShopImage = styled.div`
  width: 100%;
  height: 48%;

  background-color: #f9fafb;
  border-radius: 16px;

  padding: 16px 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    background-color: #ffffff;
    padding: 16px 48px;
    display: flex;
    align-items: center;
    gap: 24px;
    border-radius: 16px;

    width: 520px;
    height: 208px;

    & > div:first-child {
      height: 100%;
      display: flex;
      align-items: center;
      gap: 16px;
    }

    & > div:last-child {
      display: flex;
      align-items: flex-start;
      gap: 16px;

      flex-wrap: wrap;
      width: 300px;
    }
  }
`;

const EditBtn = styled.button`
  cursor: pointer;
  width: 92px;
  height: 44px;

  border: none;
  border-radius: 8px;

  background-color: #f14262;

  color: #ffffff;

  &:hover {
    background-color: #fd7f96;
  }
`;
const CancleBtn = styled.button`
  cursor: pointer;
  width: 92px;
  height: 44px;

  border: none;
  border-radius: 8px;

  color: #a0a6b1;
  background-color: transparent;
`;

const ProfileImg = styled.div`
  width: 120px;
  height: 120px;
  background-color: #c3c5c9;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const OptionBox = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 8px;
  background-color: #f9e8ec;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;

  & > span {
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    color: #f14262;
  }
`;

const DepositBox = styled.div`
  display: flex;
  width: 144px;
  height: 56px;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background: #fff;

  box-sizing: border-box;

  & > div:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    & > span:first-child {
      color: #000;
      font-size: 12px;
      font-weight: 700;
      line-height: 140%;
    }
    & > span:last-child {
      color: #a0a6b1;
      font-size: 10px;
      font-weight: 500;
      line-height: 140%;
    }
  }
`;

const IconBox = styled.div`
  display: flex;
  padding: 6px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 6px;
  background: #f9e8ec;
`;

const Title = styled.h2`
  color: #272f3e;
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
  width: 62px;
`;

const Text = styled.span`
  color: #272f3e;
  font-size: 12px;
  font-weight: 500;
  line-height: 140%;
  max-width: 350px;
  overflow: hidden;
`;

const TagBox = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-wrap: wrap;
  width: 360px;
`;

const TagItem = styled.div`
  display: flex;
  height: 24px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 25px;
  background: #d8f5f5;

  color: #181e2d;
  font-size: 12px;
  font-weight: 500;
  line-height: 140%;

  box-sizing: border-box;
`;

const RunDateBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & > div:first-child {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;

    border-radius: 16px;
    background: #d8e0f8;

    color: #000000;
    font-size: 12px;
    font-weight: 700;
    line-height: 140%;
  }
  & > span:last-child {
    width: 100px;
  }
`;

const Image = styled.div`
  width: 282px;
  height: 188px;
  border-radius: 16px;
  background-color: #cccccc;
  overflow: hidden;
`;

const EditBox = styled.div`
  width: 100%;
  height: 94%;
  display: flex;

  padding: 0px 24px;
  box-sizing: border-box;
  gap: 40px;

  margin-bottom: 16px;
`;

const EditLeft = styled.div`
  width: 300px;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  & > div:nth-child(1) {
    width: 100px;
    height: 100px;
    background-color: #cccccc;
    border-radius: 50%;
  }
`;
const EditRight = styled.div`
  padding-left: 40px;
  border-left: 1px solid black;
  width: 100%;
  height: 100%;
  overflow: hidden;

  & > div:first-child {
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    & > * {
      margin-bottom: 16px;
    }
  }

  .Mui-checked + .MuiSwitch-track {
    background-color: red !important;
  }
  .Mui-checked {
    color: #ff5d7a !important;
  }
`;

const DataBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  width: 100%;
`;

const DataTitle = styled.span`
  color: #fd91a5;
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
`;

const TogleWrap = styled.div`
  width: 136px;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > span {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    color: #a0a6b1;
  }

  .Mui-checked + .MuiSwitch-track {
    background-color: red !important;
  }
  .Mui-checked {
    color: #ff5d7a !important;
  }
`;

const ClickWrap = styled.div`
  width: 300px;
  height: 100%;

  position: absolute;
  top: 0px;
  left: 0px;
`;
export default BasicInfo;
