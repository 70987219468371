import { styled } from "styled-components";
import CardLayout from "../../layout/CardLayout";
import { useState } from "react";
import { color_iris, color_midnight, color_rose } from "../../util/color";
import InputBox from "../../components/utility/InputBox";
import { Link } from "react-router-dom";
import {
  MessageCertification,
  ResetPw,
  getAuthMessage,
  getTempToken,
} from "../../apis/auth";
import MessageModal from "../../components/utility/MessageModal";

const AUTH_BTN_WIDTH = 104;
const INPUT_BOX_WIDTH = 300;
const MAX_TIMEOUT = 1000;
const AUTH_LENGTH = 4;

const Findid = () => {
  const [id, setId] = useState("");
  const [authNum, setAuthNum] = useState("");
  const [isSend, setIsSend] = useState(false);
  const [token, setToken] = useState("");
  const [showFailMessage, setShowFailMessage] = useState(false);
  const [step, setStep] = useState(1);
  const [pw, setPw] = useState("");
  const [pw2, setPw2] = useState("");

  const handleAuthClick = async () => {
    try {
      const tempToken = await getTempToken().then((res) => res.data.tempToken);
      setToken(tempToken);

      await getAuthMessage(id, tempToken);
      setIsSend(true);
      setShowFailMessage(true);
      setTimeout(() => setShowFailMessage(false), MAX_TIMEOUT);
    } catch (error) {
      console.error("인증 메시지 전송 오류:", error);
    }
  };

  const handleNextClick = async () => {
    try {
      await MessageCertification(authNum, token);
      setStep(2);
    } catch (error) {
      console.error("인증 오류:", error);
    }
  };

  const handleResetClick = async () => {
    try {
      await ResetPw(id, pw, token);
      setStep(3);
    } catch (error) {
      console.error("비밀번호 재설정 오류:", error);
    }
  };

  return (
    <CardLayout text={step !== 3 ? "비밀번호 재설정" : "비밀번호 변경 완료!"}>
      {step === 1 && (
        <>
          <InputContainer>
            <Label>ID</Label>
            <InputRow>
              <InputBox
                placeholder="아이디를 입력해주세요."
                type="text"
                width={180}
                setValue={setId}
                value={id}
                noReset={true}
                disabled={isSend}
              />
              <AuthBtn
                disabled={id.length <= 0 || isSend}
                onClick={handleAuthClick}
              >
                인증번호 받기
              </AuthBtn>
            </InputRow>
            <HelperText>
              계정에 입력되어있는 휴대폰 번호로 인증번호가 발송됩니다.
            </HelperText>
          </InputContainer>
          <InputContainer>
            <Label>인증번호</Label>
            <InputBox
              placeholder="인증번호를 입력해주세요."
              type="number-only"
              width={INPUT_BOX_WIDTH}
              setValue={setAuthNum}
              value={authNum}
              maxLength={AUTH_LENGTH}
            />
            <HelperList>
              <HelperListItem>
                인증번호 발송까지 최대 1분까지 소요됩니다.
              </HelperListItem>
              <HelperListItem>
                인증번호를 받지 못하셨다면 고객센터로 문의 바랍니다.
              </HelperListItem>
            </HelperList>
          </InputContainer>
          <NextBtn
            onClick={handleNextClick}
            disabled={!isSend}
            style={{ marginTop: "20px" }}
          >
            다음
          </NextBtn>
          <Etc>
            <CsText to={"https://pf.kakao.com/_KRBrG"}>고객센터</CsText>
          </Etc>
          <MessageModal
            showFailMessage={showFailMessage}
            text="인증번호를 발송하였습니다."
          />
        </>
      )}
      {step === 2 && (
        <>
          <InputContainer>
            <Label>새 비밀번호 입력 </Label>
            <InputBox
              placeholder="비밀번호를 입력해주세요."
              type="password"
              width={INPUT_BOX_WIDTH}
              setValue={setPw}
              value={pw}
            />
          </InputContainer>
          <InputContainer>
            <Label>새 비밀번호 확인</Label>
            <InputBox
              placeholder="비밀번호를 입력해주세요."
              type="password"
              width={INPUT_BOX_WIDTH}
              setValue={setPw2}
              value={pw2}
            />
          </InputContainer>
          <NextBtn
            onClick={handleResetClick}
            disabled={!(pw && pw2 && pw === pw2)}
            style={{ marginTop: "20px" }}
          >
            확인
          </NextBtn>
          <Etc>
            <CsText to={"https://pf.kakao.com/_KRBrG"}>고객센터</CsText>
          </Etc>
          <MessageModal
            showFailMessage={showFailMessage}
            text="인증번호를 발송하였습니다."
          />
        </>
      )}
      {step === 3 && (
        <>
          <CompletionText>다시 로그인해주세요.</CompletionText>
          <img src="./image/pwreset.png" alt="" style={{ width: "200px" }} />
          <NextBtn
            onClick={() => (window.location.href = "/signin")}
            disabled={false}
            style={{ marginTop: "20px" }}
          >
            확인
          </NextBtn>
          <Etc>
            <CsText to={"https://pf.kakao.com/_KRBrG"}>고객센터</CsText>
          </Etc>
        </>
      )}
    </CardLayout>
  );
};

// Styled Components
const InputContainer = styled.div`
  width: 300px;
  min-height: 82px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Label = styled.span`
  color: ${color_midnight.midnight4};
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.2px;
  font-weight: 700;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AuthBtn = styled.button`
  border: none;
  width: ${AUTH_BTN_WIDTH}px;
  height: 44px;
  border-radius: 8px;
  color: #f9fafb;
  background-color: ${(props) =>
    !props.disabled ? color_iris.iris9 : color_midnight.midnight3};
  font-family: "Noto Sans KR", sans-serif;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
`;

const NextBtn = styled.button`
  cursor: pointer;
  width: 300px;
  height: 44px;
  background-color: ${(props) =>
    !props.disabled ? color_rose.rose8 : color_midnight.midnight3};
  border: none;
  border-radius: 8px;
  color: ${color_midnight.lily};
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: -0.2px;
  &:hover {
    background-color: ${color_rose.rose6};
  }
`;

const HelperText = styled.div`
  color: ${color_midnight.midnight5};
  font-size: 12px;
`;

const HelperList = styled.ul`
  color: ${color_midnight.midnight5};
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: inside;
`;

const HelperListItem = styled.li`
  font-size: 12px;
`;

const CompletionText = styled.div`
  color: ${color_midnight.midnight4};
`;

const Etc = styled.div`
  width: 300px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const CsText = styled(Link)`
  color: ${color_rose.rose4};
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
`;

export default Findid;
