import { styled } from "styled-components";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { RegisterDataAtom, registerStepAtom } from "../../atoms/atom";
import { Link } from "react-router-dom";

const RegisterStep8 = () => {
  const setStep = useSetRecoilState(registerStepAtom);
  const data = useRecoilValue(RegisterDataAtom);

  return (
    <>
      <ResultContainer>
        <div></div>
        <div>
          <span>{data.shopname}</span>
          <div>
            <span>{data.name}</span>
            <div>대표</div>
          </div>
        </div>
        <div>[핑프메이커]에 오신걸 환영합니다♥︎</div>
      </ResultContainer>
      <Link to="/signin" onClick={() => setStep(1)}>
        <OkBtn>로그인 하러 가기</OkBtn>
      </Link>
    </>
  );
};

const ResultContainer = styled.div`
  width: 300px;
  height: 240px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  & > div:nth-child(1) {
    width: 96px;
    height: 96px;
    background-color: #d9d9d9;
    border-radius: 48px;
  }
  & > div:nth-child(2) {
    width: 100%;
    height: 76px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    & > span {
      font-size: 28px;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: -0.3px;
    }
    & > div {
      display: flex;
      align-items: center;
      gap: 8px;

      & > span {
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: -0.2px;
      }
      & > div {
        width: 35px;
        height: 22px;
        background-color: #fada07;
        border-radius: 4px;
        color: #ffffff;

        font-size: 10px;
        font-weight: 500;
        line-height: 14px;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  & > div:nth-child(3) {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: #181e2d;
  }
`;

const OkBtn = styled.button`
  border: none;
  width: 300px;
  height: 44px;
  border-radius: 8px;
  color: #f9fafb;
  background-color: #ff5d7a;

  font-family: "Noto Sans KR", sans-serif;

  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: -0.2px;
`;

export default RegisterStep8;
