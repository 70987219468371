import { useRecoilValue } from "recoil";
import CardLayout from "../../layout/CardLayout";
import { registerStepAtom } from "../../atoms/atom";
import RegisterStep1 from "../../components/register/RegisterStep1";
import RegisterStep2 from "../../components/register/RegisterStep2";
import RegisterStep3 from "../../components/register/RegisterStep3";
import RegisterStep4 from "../../components/register/RegisterStep4";
import RegisterStep5 from "../../components/register/RegisterStep5";
import RegisterStep6 from "../../components/register/RegisterStep6";
import RegisterStep7 from "../../components/register/RegisterStep7";
import RegisterStep8 from "../../components/register/RegisterStep8";

const Signup = () => {
  const step = useRecoilValue(registerStepAtom);

  const registerStep = () => {
    switch (step) {
      case 1:
        return <RegisterStep1 />;
      case 2:
        return <RegisterStep2 />;
      case 3:
        return <RegisterStep3 />;
      case 4:
        return <RegisterStep4 />;
      case 5:
        return <RegisterStep5 />;
      case 6:
        return <RegisterStep6 />;
      case 7:
        return <RegisterStep7 />;
      case 8:
        return <RegisterStep8 />;
    }
  };

  return <CardLayout text="회원 가입">{registerStep()}</CardLayout>;
};

export default Signup;
