import React, { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import { color_midnight, color_rose } from "../../util/color";
import InputBox from "../utility/InputBox";
import { saveTicketDate } from "../../apis/customer";

type propType = {
  updateCallback: () => void;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  data: any;
};

const TickeDateModal = ({ updateCallback, setIsModalOpen, data }: propType) => {
  const [ticketValidity, setTicketValidity] = useState<string>(data.endDate);

  return (
    <ModalOverlay>
      <ModalContent>
        <div
          style={{
            width: "100%",
            height: "56px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#ffffff",
          }}
        >
          <h3 style={{ margin: "0px" }}>유효기간 수정</h3>
        </div>
        <div
          style={{
            width: "100%",
            padding: "24px",
            boxSizing: "border-box",
            backgroundColor: color_midnight.lily,
          }}
        >
          <div
            style={{
              width: "100%",
              background: "#ffffff",
              padding: "24px",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              borderRadius: "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <span style={{ fontWeight: "700", fontSize: "14px" }}>
                유효기간
              </span>
              <InputBox
                placeholder={"YYYY-MM-DD"}
                type={"date"}
                width={300}
                setValue={setTicketValidity}
                value={ticketValidity}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              paddingTop: "24px",
            }}
          >
            <button
              onClick={() => setIsModalOpen(false)}
              style={{
                backgroundColor: color_midnight.midnight2,
                border: "none",
                borderRadius: "8px",
                color: "#000",
                cursor: "pointer",
                width: "160px",
                height: "44px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              취소
            </button>
            <button
              onClick={() => {
                saveTicketDate({
                  ticketId: data.ticketId,
                  startDate: data.startDate,
                  endDate: new Date(ticketValidity).toISOString(),
                  title: "유효기간 수정",
                })
                  .then((res) => {
                    updateCallback();
                  })
                  .finally(() => {
                    setIsModalOpen(false);
                  });
              }}
              style={{
                backgroundColor: color_rose.rose8,
                border: "none",
                borderRadius: "8px",
                color: "#fff",
                cursor: "pointer",
                width: "160px",
                height: "44px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              확인
            </button>
          </div>
        </div>
      </ModalContent>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #ffffff;
  padding: 0;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Header와 Body의 배경색 차이로 overflow 숨김 */
`;

export default TickeDateModal;
