import React, { useState } from "react";
import styled from "styled-components";
import Calendar from "../utility/Calendar";
import CountChart3 from "../utility/CountChart3";
import { Calendar2, Profile2User, Chart21 } from "iconsax-react";

const Visitors = () => {
  const [activeBtn, setActiveBtn] = useState(1);

  return (
    <Container>
      <div>
        <Content3Header $ActiveBtn={activeBtn}>
          <div className="btn_wrap">
            <button onClick={() => setActiveBtn(1)}>기간 별</button>
            <button onClick={() => setActiveBtn(2)}>연도 별</button>
          </div>
        </Content3Header>
        <div>
          <Calendar />
          <div>
            <h2>방문 연령대</h2>
            <CountChart3 dataType={activeBtn} width={300} height={320} />
          </div>
          <div>
            <div>
              <h3>2023-01-01</h3>
              <span>기간</span>
              <Calendar2
                size="40"
                color={"#EB6077"}
                variant="Bold"
                style={{ position: "absolute", right: "30px", top: "40px" }}
              />
              <div
                className="pointCircle"
                style={{ backgroundColor: "#EB6077" }}
              ></div>
            </div>
            <div>
              <h3>49,832</h3>
              <span>방문자수</span>
              <Profile2User
                size="40"
                color={"#779CE6"}
                variant="Bold"
                style={{ position: "absolute", right: "30px", top: "40px" }}
              />
              <div
                className="pointCircle"
                style={{ backgroundColor: "#779CE6" }}
              ></div>
            </div>
            <div>
              <h3>120,000원</h3>
              <span>매출액</span>
              <Chart21
                size="40"
                color={"#72BFB2"}
                variant="Bold"
                style={{ position: "absolute", right: "30px", top: "40px" }}
              />
              <div
                className="pointCircle"
                style={{ backgroundColor: "#72BFB2" }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 10px;

  box-sizing: border-box;

  & > div:first-child {
    background-color: #ffffff;
    width: 100%;
    height: 460px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border-radius: 8px;

    padding: 40px;
    padding-top: 20px;
    box-sizing: border-box;

    & > div:last-child {
      display: flex;
      justify-content: space-between;

      & > div:first-child {
        margin-left: 20px;
        background-color: #f9fafb;
        padding: 20px;
        border-radius: 8px;
      }
      & > div:nth-child(2) {
        width: 300px;
        height: 100%;

        & > h2 {
          font-size: 20px;
          font-weight: 700;
          line-height: 28px;
          letter-spacing: -0.2px;
          margin-left: 20px;
        }
      }
      & > div:last-child {
        width: 300px;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & > div {
          width: 100%;
          height: 100px;
          border-radius: 8px;

          overflow: hidden;
          position: relative;

          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-left: 24px;
          box-sizing: border-box;

          & > h3 {
            font-size: 24px;
            font-weight: 700;
            line-height: 33px;
            color: #000000;
          }
          & > span {
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            color: #202020;
          }

          & .pointCircle {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            background-color: #eb6077;

            position: absolute;
            right: -30px;
            bottom: 0px;
            opacity: 0.5;
          }
        }
      }
    }
  }
`;

const Content3Header = styled.div<{ $ActiveBtn: number }>`
  display: flex;
  height: 42px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  & .btn_wrap {
    position: absolute;
    left: 0px;
    top: 10px;

    width: 96px;
    height: 24px;

    border: 1px solid #f4f5f7;
    border-radius: 4px;
    overflow: hidden;

    & > button {
      width: 48px;
      height: 24px;
      border: none;
      background-color: #ffffff;
      color: #4d5562;
      font-size: 10px;
      font-weight: 500;
      line-height: 14px;
    }

    & :nth-child(${(props) => props.$ActiveBtn}) {
      background-color: #f9e8ec;
      color: #fd91a5;
    }
  }
`;

export default Visitors;
