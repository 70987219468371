import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MessageModal from "../utility/MessageModal";
import EventItem from "./EventItem";
import { Box, Modal, Switch } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useRecoilState, useRecoilValue } from "recoil";
import { MyShopIsEdit, ShopData } from "../../atoms/atom";
import { createEvent, getEventList, saveEvent } from "../../apis/event";
import { ShopEventType } from "../../types";
import InputBox from "../utility/InputBox";
import DatePicker from "../utility/DatePicker";
import TimePickers from "../utility/TimePickers";
import ImageCropModal from "../utility/ImageCropModal";
import { UploadImg } from "../../apis/api";
import WaitingModal from "../utility/WaitingModal";

const Event = () => {
  const [eventList, setEventList] = useState<ShopEventType[]>([]);
  const [showFailMessage, setShowFailMessage] = useState(false);

  const [openInfoModal, setOpenInfoModal] = useState(false);

  const shopData = useRecoilValue(ShopData);
  const [, setMyShopIsEdit] = useRecoilState(MyShopIsEdit);

  const [data, setData] = useState<ShopEventType>({
    name: "",
    open: "",
    close: "",
    id: "",
    description: "",
    imageUrl: "",
    shopId: "",
    isEnable: false,
  });

  const [isEdit, setIsEdit] = useState(false);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isEnable, setIsEnable] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [EndDate, setEndDate] = useState(new Date());

  const [profile, setProfile] = useState("");
  const [image, setImage] = useState<File>();
  const [openCrop, setOpenCrop] = useState(false);

  const [openWaiting, setOpenWaiting] = useState(false);

  const [isAdd, setIsAdd] = useState(false);

  const isTimeBetween = (start: string, end: string) => {
    const startTime: number = new Date(start).getTime();
    const endTime: number = new Date(end).getTime();
    const currentTime: number = new Date().getTime();

    return startTime <= currentTime && currentTime <= endTime;
  };

  const CreateHandle = () => {
    setOpenWaiting(true);
    if (image) {
      UploadImg(image!, shopData.id, "shop").then((res) => {
        createData(res.data.url);
      });
    } else {
      createData(data.imageUrl);
    }
  };

  const createData = (url: string) => {
    createEvent({
      open: startDate.toISOString(),
      close: EndDate.toISOString(),
      imageUrl: url,
      name: name,
      description: description,
      shopId: shopData.id,
      isEnable: isEnable,
    }).then((res) => {
      getEventList(shopData.id).then((res) => {
        setEventList(res.data.data.eventList);
        setOpenWaiting(false);

        setMyShopIsEdit(false);
        setIsAdd(false);
      });
    });
  };

  const saveHandle = () => {
    setOpenWaiting(true);
    if (image) {
      UploadImg(image!, shopData.id, "shop").then((res) => {
        saveData(res.data.url);
      });
    } else {
      saveData(data.imageUrl);
    }
  };

  const saveData = (url: string) => {
    saveEvent({
      open: startDate.toISOString(),
      close: EndDate.toISOString(),
      eventId: data.id,
      imageUrl: url,
      name: name,
      description: description,
      shopId: data.shopId,
      isEnable: isEnable,
    }).then((res) => {
      getEventList(shopData.id).then((res) => {
        setEventList(res.data.data.eventList);
        setOpenWaiting(false);

        setMyShopIsEdit(false);
        setIsEdit(false);
      });
    });
  };

  useEffect(() => {
    getEventList(shopData.id).then((res) => {
      console.log(res.data.data.eventList);
      setEventList(res.data.data.eventList);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profile) {
      if (!profile.startsWith("http") && profile) {
        fetch(profile)
          .then((response) => response.blob())
          .then((blob) => {
            const file = new File([blob], "cropImg.png", { type: blob.type });
            setImage(file);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    setName(data.name);
    setStartDate(new Date(data.open));
    setEndDate(new Date(data.close));
    setDescription(data.description);
    setIsEnable(data.isEnable);
    setProfile(data.imageUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const formatISOToCustom = (isoString: string) => {
    const date = new Date(isoString);

    const formattedDate = date.toLocaleDateString("ko-KR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const formattedTime = date.toLocaleTimeString("ko-KR", {
      timeZone: "Asia/Seoul",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <Container>
      <EventAddBtn
        onClick={() => {
          setData({
            name: "",
            open: new Date().toISOString(),
            close: new Date().toISOString(),
            id: "",
            description: "",
            imageUrl: "",
            shopId: "",
            isEnable: false,
          });

          setMyShopIsEdit(true);
          setIsAdd(true);
          setIsEdit(false);

          setStartDate(new Date());
          setEndDate(new Date());
        }}
      >
        이벤트 +
      </EventAddBtn>
      {isAdd ? (
        <>
          <div
            style={{
              width: "100%",
              height: "100%",
              padding: "0px 40px",
              boxSizing: "border-box",
              display: "flex",
            }}
          >
            <div
              style={{
                width: "50%",
                height: "100%",
                paddingRight: "40px",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "24px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "24px",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontSize: "16px",
                    color: "#FD91A5",
                    fontWeight: "700",
                  }}
                >
                  게시 상태
                </span>
                <span
                  style={{
                    display: "flex",
                    padding: "7px 16px",
                    borderRadius: "50px",
                    backgroundColor: "#F14262",
                    color: "#ffffff",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                >
                  게시중
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "4px",
                  backgroundColor: "#F14262",
                  padding: "8px 8px",
                  borderRadius: "4px",
                  width: "300px",
                }}
              >
                <span style={{ fontSize: "18px", color: "#ffffff" }}>
                  {name}
                </span>
                <span
                  style={{ fontSize: "14px", color: "#ffffff" }}
                >{`${formatISOToCustom(
                  startDate.toISOString()
                )} ~ ${formatISOToCustom(EndDate.toISOString())}`}</span>
              </div>
              {profile ? (
                <img
                  alt=""
                  src={profile}
                  style={{
                    width: "400px",
                    height: "400px",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <div
                  style={{
                    width: "400px",
                    height: "400px",
                    backgroundColor: "#F4F5F7",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#A0A6B1",
                  }}
                >
                  이미지 없음
                </div>
              )}
              <span
                style={{
                  width: "400px",
                  height: "88px",
                  backgroundColor: "#F9FAFB",
                  padding: "20px 20px",
                  boxSizing: "border-box",
                  borderRadius: "8px",
                  fontSize: "16px",
                  color: "#6C727F",
                  display: "inline-block",
                  overflow: "hidden",
                  wordWrap: "break-word",
                }}
              >
                {description}
              </span>
            </div>
            <div
              style={{
                width: "50%",
                height: "100%",
                borderLeft: "1px solid #E5E7EB",
                paddingLeft: "40px",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                paddingTop: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                }}
              >
                <span
                  style={{
                    color: "#FD91A5",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  이벤트명 (30자 이내 작성)
                </span>
                <InputBox
                  placeholder={""}
                  type={"text"}
                  width={400}
                  setValue={setName}
                  value={name}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                }}
              >
                <span
                  style={{
                    color: "#FD91A5",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  이벤트 기간
                </span>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "12px" }}
                >
                  <span
                    style={{
                      color: "#A0A6B1",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    시작 시간
                  </span>
                  <DatePicker value={startDate} setValue={setStartDate} />
                  <TimePickers value={startDate} setValue={setStartDate} />
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "12px" }}
                >
                  <span
                    style={{
                      color: "#A0A6B1",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    종료 시간
                  </span>
                  <DatePicker value={EndDate} setValue={setEndDate} />
                  <TimePickers value={EndDate} setValue={setEndDate} />
                </div>
                <div style={{ display: "flex", gap: "40px" }}>
                  <span
                    style={{
                      color: "#FD91A5",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    이미지
                  </span>
                  <span
                    style={{
                      fontSize: "10px",
                      color: "#272F3E",
                      width: "48px",
                      height: "24px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "16px",
                      backgroundColor: "#E5E7EB",
                      position: "relative",
                      cursor: "pointer",
                    }}
                  >
                    <FileInput
                      type="file"
                      onChange={(e) => {
                        if (e.target.files?.length) {
                          setImage(e.target.files[0]);
                          setOpenCrop(true);
                        }
                      }}
                    />
                    업로드
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                  }}
                >
                  <span
                    style={{
                      color: "#FD91A5",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    이벤트 내용
                  </span>
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    style={{
                      height: "96px",
                      borderRadius: "8px",
                      backgroundColor: "#F4F5F7",
                      outline: "none",
                      padding: "20px",
                      boxSizing: "border-box",
                      border: "none",
                    }}
                  />
                </div>
                <div style={{ display: "flex", gap: "40px" }}>
                  <span
                    style={{
                      color: "#FD91A5",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    게시하기
                  </span>
                  <Switch
                    checked={isEnable}
                    onClick={() => setIsEnable((old) => !old)}
                    sx={{
                      ".css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
                        { backgroundColor: "red" },
                      ".css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked":
                        { color: "#FF5D7A" },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", gap: "24px", alignSelf: "end" }}>
            <span
              style={{
                width: "88px",
                height: "44px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                color: "#A0A6B1",
                fontSize: "14px",
                fontWeight: "500",
                cursor: "pointer",
              }}
              onClick={() => {
                setMyShopIsEdit(false);
                setIsAdd(false);
              }}
            >
              취소
            </span>
            <span
              style={{
                width: "88px",
                height: "44px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                backgroundColor: "#F14262",
                color: "#ffffff",
                fontSize: "14px",
                fontWeight: "500",
                cursor: "pointer",
              }}
              onClick={CreateHandle}
            >
              저장
            </span>
          </div>
        </>
      ) : isEdit ? (
        <>
          <div
            style={{
              width: "100%",
              height: "100%",
              padding: "0px 40px",
              boxSizing: "border-box",
              display: "flex",
            }}
          >
            <div
              style={{
                width: "50%",
                height: "100%",
                paddingRight: "40px",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "24px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "24px",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontSize: "16px",
                    color: "#FD91A5",
                    fontWeight: "700",
                  }}
                >
                  게시 상태
                </span>
                <span
                  style={{
                    display: "flex",
                    padding: "7px 16px",
                    borderRadius: "50px",
                    backgroundColor: "#F14262",
                    color: "#ffffff",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                >
                  게시중
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "4px",
                  backgroundColor: "#F14262",
                  padding: "8px 8px",
                  borderRadius: "4px",
                  width: "300px",
                }}
              >
                <span style={{ fontSize: "18px", color: "#ffffff" }}>
                  {name}
                </span>
                <span
                  style={{ fontSize: "14px", color: "#ffffff" }}
                >{`${formatISOToCustom(data.open)} ~ ${formatISOToCustom(
                  data.close
                )}`}</span>
              </div>
              <img
                alt=""
                src={profile}
                style={{ width: "400px", height: "400px", objectFit: "cover" }}
              />
              <span
                style={{
                  width: "400px",
                  height: "88px",
                  backgroundColor: "#F9FAFB",
                  padding: "20px 20px",
                  boxSizing: "border-box",
                  borderRadius: "8px",
                  fontSize: "16px",
                  color: "#6C727F",
                }}
              >
                {description}
              </span>
            </div>
            <div
              style={{
                width: "50%",
                height: "100%",
                borderLeft: "1px solid #E5E7EB",
                paddingLeft: "40px",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                paddingTop: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                }}
              >
                <span
                  style={{
                    color: "#FD91A5",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  이벤트명 (30자 이내 작성)
                </span>
                <InputBox
                  placeholder={""}
                  type={"text"}
                  width={400}
                  setValue={setName}
                  value={name}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                }}
              >
                <span
                  style={{
                    color: "#FD91A5",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  이벤트 기간
                </span>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "12px" }}
                >
                  <span
                    style={{
                      color: "#A0A6B1",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    시작 시간
                  </span>
                  <DatePicker value={startDate} setValue={setStartDate} />
                  <TimePickers value={startDate} setValue={setStartDate} />
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "12px" }}
                >
                  <span
                    style={{
                      color: "#A0A6B1",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    종료 시간
                  </span>
                  <DatePicker value={EndDate} setValue={setEndDate} />
                  <TimePickers value={EndDate} setValue={setEndDate} />
                </div>
                <div style={{ display: "flex", gap: "40px" }}>
                  <span
                    style={{
                      color: "#FD91A5",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    이미지
                  </span>
                  <span
                    style={{
                      fontSize: "10px",
                      color: "#272F3E",
                      width: "48px",
                      height: "24px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "16px",
                      backgroundColor: "#E5E7EB",
                      position: "relative",
                    }}
                  >
                    <FileInput
                      type="file"
                      onChange={(e) => {
                        if (e.target.files?.length) {
                          setImage(e.target.files[0]);
                          setOpenCrop(true);
                        }
                      }}
                    />
                    업로드
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                  }}
                >
                  <span
                    style={{
                      color: "#FD91A5",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    이벤트 내용
                  </span>
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    style={{
                      height: "96px",
                      borderRadius: "8px",
                      backgroundColor: "#F4F5F7",
                      outline: "none",
                      padding: "20px",
                      boxSizing: "border-box",
                      border: "none",
                    }}
                  />
                </div>
                <div style={{ display: "flex", gap: "40px" }}>
                  <span
                    style={{
                      color: "#FD91A5",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    게시하기
                  </span>
                  <Switch
                    checked={isEnable}
                    onClick={() => setIsEnable((old) => !old)}
                    sx={{
                      ".css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
                        { backgroundColor: "red" },
                      ".css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked":
                        { color: "#FF5D7A" },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", gap: "24px", alignSelf: "end" }}>
            <span
              style={{
                width: "88px",
                height: "44px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                color: "#A0A6B1",
                fontSize: "14px",
                fontWeight: "500",
              }}
              onClick={() => {
                setMyShopIsEdit(false);
                setIsEdit(false);
              }}
            >
              취소
            </span>
            <span
              style={{
                width: "88px",
                height: "44px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                backgroundColor: "#F14262",
                color: "#ffffff",
                fontSize: "14px",
                fontWeight: "500",
              }}
              onClick={saveHandle}
            >
              저장
            </span>
          </div>
        </>
      ) : (
        <>
          {eventList.length === 0 ? (
            <NothingText>샵에서 진행 중인 이벤트를 추가해주세요.</NothingText>
          ) : (
            <EvnetListBox>
              {eventList.map((item, idx) => (
                <EventItem
                  data={item}
                  setData={setData}
                  key={idx}
                  setShowFailMessage={setShowFailMessage}
                  isShow={item.isEnable && isTimeBetween(item.open, item.close)}
                  open={openInfoModal}
                  setOpen={setOpenInfoModal}
                  deleteCallback={() => {
                    getEventList(shopData.id).then((res) => {
                      setEventList(res.data.data.eventList);
                    });
                  }}
                  setIsEdit={setIsEdit}
                />
              ))}
            </EvnetListBox>
          )}
        </>
      )}
      <MessageModal
        showFailMessage={showFailMessage}
        text="이벤트가 삭제되었습니다."
        left="438px"
        bottom="-20px"
      />
      <Modal open={openInfoModal}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            height: 700,
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            backgroundColor: "#ffffff",
            display: "flex",
            gap: "16px",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "16px",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              position: "relative",
              height: "44px",
              color: "#181E2D",
              fontSize: "20px",
              fontWeight: "700",
            }}
          >
            이벤트 확인
            <CloseIcon
              style={{ position: "absolute", right: "0px" }}
              onClick={() => setOpenInfoModal(false)}
            />
          </div>
          <img
            alt=""
            src={data.imageUrl}
            style={{
              width: "360px",
              height: "360px",
              objectFit: "cover",
              borderRadius: "16px",
            }}
          />
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "44px",
              padding: "0px 40px",
              boxSizing: "border-box",
              alignItems: "center",
              gap: "24px",
            }}
          >
            <span
              style={{ fontSize: "16px", fontWeight: "700", width: "80px" }}
            >
              이벤트명
            </span>
            <span style={{ fontSize: "16px", fontWeight: "500" }}>
              {data.name}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "44px",
              padding: "0px 40px",
              boxSizing: "border-box",
              alignItems: "center",
              gap: "24px",
            }}
          >
            <span
              style={{ fontSize: "16px", fontWeight: "700", width: "80px" }}
            >
              이벤트기간
            </span>
            <span style={{ fontSize: "16px", fontWeight: "500" }}>
              {`${formatISOToCustom(data.open)} ~ ${formatISOToCustom(
                data.close
              )}`}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "92px",
              padding: "0px 40px",
              boxSizing: "border-box",
              alignItems: "center",
              gap: "24px",
            }}
          >
            <span
              style={{ fontSize: "16px", fontWeight: "700", width: "80px" }}
            >
              이벤트내용
            </span>
            <span
              style={{
                fontSize: "14px",
                fontWeight: "500",
                width: "400px",
                padding: "16px 24px",
                backgroundColor: "#F9FAFB",
                borderRadius: "16px",
              }}
            >
              {data.description}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "44px",
              padding: "0px 40px",
              boxSizing: "border-box",
              alignItems: "center",
              gap: "24px",
            }}
          >
            <span
              style={{ fontSize: "16px", fontWeight: "700", width: "80px" }}
            >
              게시상태
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "71px",
                height: "34px",
                backgroundColor: "#F14262",
                borderRadius: "17px",
                color: "#ffffff",
                fontSize: "14px",
              }}
            >
              게시중
            </div>
          </div>
        </Box>
      </Modal>
      {openCrop && (
        <ImageCropModal
          image={image!}
          open={openCrop}
          setOpen={setOpenCrop}
          setData={setProfile}
        />
      )}
      <WaitingModal open={openWaiting} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
  height: 100%;

  position: relative;

  justify-content: center;
`;

const NothingText = styled.span`
  color: #a0a6b1;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
`;

const EventAddBtn = styled.div`
  cursor: pointer;
  display: flex;
  width: 160px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: #f14262;
  position: absolute;
  right: 0px;

  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;

  position: absolute;
  right: 0px;
  top: -64px;
`;

const EvnetListBox = styled.div`
  display: flex;
  gap: 24px;
  width: 86%;
  height: 100%;
  flex-wrap: wrap;

  overflow-y: scroll;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const FileInput = styled.input`
  cursor: pointer;
  width: 100%;
  height: 100%;
  color: transparent;

  position: absolute;

  &::file-selector-button {
    display: none;
  }
`;

export default Event;
