import { atom, selector } from "recoil";
import { ShopType } from "../types";
import { getShopData } from "../apis/shop";

export const findIdStepAtom = atom<number>({
  key: "findIdStep",
  default: 1,
});

export const findPwStepAtom = atom<number>({
  key: "findPwStep",
  default: 1,
});

export const registerStepAtom = atom<number>({
  key: "registerStep",
  default: 1,
});

export const findPwDataAtom = atom<{
  email: string;
  phone: string;
  passwd: string;
  isAdmin: boolean;
  token: string;
}>({
  key: "findPwData",
  default: { email: "", phone: "", passwd: "", isAdmin: true, token: "" },
});

export const findIdDataAtom = atom<{
  phone: string;
  isAdmin: boolean;
  token: string;
}>({
  key: "findIdData",
  default: { phone: "", isAdmin: true, token: "" },
});

export const RegisterDataAtom = atom<{
  email: string;
  passwd: string;
  phone: string;
  name: string;
  isAdmin: boolean;
  tos: {
    votrenaeil: boolean;
    collection: boolean;
    offer: boolean;
    event: boolean;
    push: boolean;
  };
  token: string;
  shopname: string;
  address: string;
}>({
  key: "registerData",
  default: {
    email: "",
    passwd: "",
    phone: "",
    name: "",
    isAdmin: true,
    tos: {
      votrenaeil: true,
      collection: true,
      offer: true,
      event: true,
      push: true,
    },
    token: "",
    shopname: "",
    address: "",
  },
});

export const SCCustomr = atom({
  key: "SCCustomr",
  default: true,
});

export const shopDataSelector = selector({
  key: "ShopDataSelector",
  get: async () => {
    try {
      // Fetch data from the API
      const res = await getShopData();
      return res.data.data.shop as ShopType;
    } catch (error) {
      console.error("Error fetching shop data:", error);
      throw error; // Propagate the error if necessary
    }
  },
});

export const ShopData = atom<ShopType>({
  key: "ShopData",
  default: shopDataSelector,
});

export const SideContentItem = atom<string>({
  key: "sideContentItem",
  default: "",
});

export const RefrashCounter = atom<number>({
  key: "refrashCounter",
  default: 0,
});

export const RefrashCounter2 = atom<number>({
  key: "refrashCounter2",
  default: 0,
});

export const CalanderView = atom<string>({
  key: "calanderView",
  default: "month",
});

export const CalanderActiveDate = atom<Date>({
  key: "calanderActiveDate",
  default: new Date(),
});

export const RefrashCounterFcm = atom<number>({
  key: "refrashCounterFcm",
  default: 0,
});

export const MyShopIsEdit = atom<boolean>({
  key: "myShopIsEdit",
  default: false,
});

export const UnReadMessageCount = atom<number>({
  key: "UnReadMessageCount",
  default: 0,
});
