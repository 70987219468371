import { styled } from "styled-components";
import { useState } from "react";
import { RegisterDataAtom, registerStepAtom } from "../../atoms/atom";
import { useSetRecoilState } from "recoil";
import { Link } from "react-router-dom";

const RegisterStep1 = () => {
  const setStep = useSetRecoilState(registerStepAtom);
  const setData = useSetRecoilState(RegisterDataAtom);

  const [checkedAll, setCheckedAll] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);

  const AllCheckHandler = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    setCheckedAll((checkedAll) => {
      setChecked1(!checkedAll);
      setChecked2(!checkedAll);
      setChecked3(!checkedAll);
      setChecked4(!checkedAll);

      return !checkedAll;
    });
  };

  const NextHandler = () => {
    setData((old) => ({
      email: old.email,
      passwd: old.passwd,
      name: old.name,
      phone: old.phone,
      isAdmin: old.isAdmin,
      tos: {
        votrenaeil: checked1,
        collection: checked2,
        offer: checked3,
        event: checked4,
        push: true,
      },
      token: old.token,
      shopname: old.shopname,
      address: old.address,
    }));
    setStep(2);
  };

  return (
    <>
      <Container>
        <h2>회원가입을 위한 약관에 동의해주세요.</h2>
        <div>
          <input
            type="checkbox"
            checked={checkedAll}
            onClick={(e) => AllCheckHandler(e)}
          />
          <span className="header">이용자 약관 전체 동의</span>
        </div>
        <hr />
        <div>
          <input
            type="checkbox"
            checked={checked1}
            onClick={(e) => setChecked1((checked) => !checked)}
          />
          <span>[필수] 보트르네일 이용 약관 동의</span>
          <button>
            <CustemLink
              to="https://storage.finger-princess.com/TermsofUse.html"
              target="_blank"
            >
              [더보기]{" "}
            </CustemLink>
          </button>
        </div>
        <div>
          <input
            type="checkbox"
            checked={checked2}
            onClick={(e) => setChecked2((checked) => !checked)}
          />
          <span>[필수] 개인정보 수집 및 이용 약관 동의</span>
          <button>[더보기]</button>
        </div>
        <div>
          <input
            type="checkbox"
            checked={checked3}
            onClick={(e) => setChecked3((checked) => !checked)}
          />
          <span>[필수] 개인정보 제3자 제공 동의</span>
          <button>[더보기]</button>
        </div>
        <div>
          <input
            type="checkbox"
            checked={checked4}
            onClick={(e) => setChecked4((checked) => !checked)}
          />
          <span>[선택] 마케팅 이벤트 제공 동의</span>
          <button>[더보기]</button>
        </div>
      </Container>
      <NextBtn
        disabled={!(checked1 && checked2 && checked3)}
        onClick={NextHandler}
      >
        다음
      </NextBtn>
    </>
  );
};

const Container = styled.div`
  width: 300px;
  height: 240px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  position: relative;

  & > div {
    width: 100%;

    display: flex;
    align-items: center;

    gap: 10px;

    & > span {
      font-size: 12px;
      font-weight: 700;
      line-height: 20px;
    }

    position: relative;
  }

  & h2 {
    position: absolute;
    left: 0px;
    top: -35px;

    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #ade4e3;

    width: 100%;
    display: flex;
    justify-content: center;
  }

  & input {
    appearance: none;
    border: 2px solid #ff5d7a;
    border-radius: 3px;
    width: 18px;
    height: 18px;

    &:checked {
      background-image: url(/image/checked.png);
      background-size: contain;
    }
  }

  & hr {
    width: 100%;
    margin: 0;
    color: #d3d6db;
    border: 1px solid #d3d6db;
  }

  & button {
    border: none;
    padding: 0;
    background-color: transparent;

    font-size: 12px;
    font-weight: 700;
    line-height: 18px;

    color: #a0a6b1;
    font-family: Noto Sans KR;

    position: absolute;
    right: 0;
  }

  & .header {
    font-size: 14px;
  }
`;

const NextBtn = styled.button<{ disabled: boolean }>`
  border: none;
  width: 300px;
  height: 44px;
  border-radius: 8px;
  color: #f9fafb;
  background-color: ${(props) => (!props.disabled ? "#ff5d7a" : "#d3d6db")};

  font-family: "Noto Sans KR", sans-serif;

  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: -0.2px;
`;

const CustemLink = styled(Link)`
  text-decoration: none;
  color: #a0a6b1;
`;

export default RegisterStep1;
