import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SettingsIcon from "@mui/icons-material/Settings";
import SearchIcon from "@mui/icons-material/Search";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { talkUserData } from "../../dumyData/data";
import { TalkUserDataType } from "../../types";
import { useRecoilValue } from "recoil";
import { ShopData } from "../../atoms/atom";
//import { socket } from "../../apis/socket";
//import { useRecoilValue } from "recoil";
//import { ShopData } from "../../atoms/atom";

type propType = {
  onChange: () => void;
};

const MessageBox = ({ onChange }: propType) => {
  const [text, setText] = useState("");
  const [history, setHistory] = useState<
    { isShop: boolean; message: string }[]
  >([]);
  const [active, setActive] = useState(0);

  const [userData] = useState<TalkUserDataType[]>(talkUserData);

  const shopData = useRecoilValue(ShopData);

  const messageEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    /*socket.on("chat message", (data) =>
      setHistory((old) => {
        const temp: string[] = old;
        return [...temp, data];
      })
    );*/

    setHistory((old) => [
      ...old,
      { isShop: false, message: "안녕하세요 , 네일 관련 문의 드립니다." },
    ]);
    setInterval(() => {
      setHistory((old) => [
        ...old,
        { isShop: false, message: "안녕하세요 , 네일 관련 문의 드립니다." },
      ]);
    }, 30000);
  }, []);

  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollTop = messageEndRef.current.scrollHeight;
    }
  }, [history]);

  return (
    <Container>
      <div>
        <div>
          <div
            style={{
              backgroundImage: `url(${userData[active].imgUrl})`,
              backgroundSize: "cover",
            }}
          ></div>
          <div>
            <span>{userData[active].name}</span>
            <span>{userData[active].phone}</span>
          </div>
        </div>
        <div>
          <div ref={messageEndRef}>
            {history.map((item, i) =>
              item.isShop ? (
                <ShopMessage key={i} $img={shopData.imageUrlList[0]}>
                  <div>
                    <div>{item.message}</div>
                    <span>08:33</span>
                  </div>
                  <div></div>
                </ShopMessage>
              ) : (
                <UserMessage key={i} $img={userData[active].imgUrl}>
                  <div></div>
                  <div>
                    <div>{item.message}</div>
                    <span>08:33</span>
                  </div>
                </UserMessage>
              )
            )}
          </div>
          <div>
            <input
              type="text"
              placeholder="메시지를 입력해주세요..."
              value={text}
              onChange={(e) => setText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  //socket.emit("chat message", shopData.name + ":" + text);
                  setText("");
                  if (text !== "") {
                    setHistory((old) => [
                      ...old,
                      { isShop: true, message: text },
                    ]);
                  }
                }
              }}
            />
            <AttachFileIcon sx={{ fontSize: 18, padding: "0px 20px" }} />
          </div>
        </div>
      </div>
      <div>
        <div>
          <SettingsIcon
            sx={{ fontSize: 21, color: "#A0A6B1" }}
            onClick={() => onChange()}
          />
        </div>
        <div>
          <SearchIcon sx={{ fontSize: 28, color: "#A0A6B1" }} />
          <input type="text" />
        </div>
        <div>
          {userData.map((item, i) => (
            <TalkRoom
              key={i}
              onClick={() => setActive(i)}
              className={i === active ? "active" : ""}
            >
              <div
                style={{
                  backgroundImage: `url(${item.imgUrl})`,
                  backgroundSize: "cover",
                }}
              ></div>
              <div>
                <span>{item.name}</span>
                <span>{item.resentMessage.text}</span>
              </div>
              <span>{item.resentMessage.time}</span>
            </TalkRoom>
          ))}
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 740px;

  background-color: #ffffff;

  border-radius: 8px;

  display: flex;
  justify-content: space-between;

  padding-right: 40px;
  box-sizing: border-box;

  & > div:first-child {
    width: 792px;
    height: 700px;

    display: flex;
    flex-direction: column;
    align-items: center;

    & > div:first-child {
      width: 100%;
      height: 104px;
      border-bottom: 1px solid #e5e7eb;

      display: flex;
      padding: 24px 40px;
      box-sizing: border-box;

      & > div:first-child {
        background-color: #cccccc;
        border-radius: 50%;
        width: 54px;
        height: 54px;

        margin-right: 24px;
      }

      & > div:last-child {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        gap: 5px;

        & > span:first-child {
          font-size: 16px;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: -0.2px;
          color: #000000;
        }
        & > span:last-child {
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          color: #a0a6b1;
        }
      }
    }

    & > div:last-child {
      width: 720px;
      height: 600px;

      & > div:first-child {
        width: 100%;
        height: 520px;

        overflow-y: scroll;
        -ms-overflow-style: none; /* 인터넷 익스플로러 */
        scrollbar-width: none; /* 파이어폭스 */
        &::-webkit-scrollbar {
          display: none;
        }
      }
      & > div:last-child {
        width: 100%;
        height: 80px;

        background-color: #f4f5f7;
        border-radius: 18px;

        display: flex;
        align-items: center;

        & > input {
          width: 100%;
          height: 44px;

          border: none;

          margin-left: 20px;
          background-color: transparent;
          outline: none;

          font-family: Noto Sans KR;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.2px;
        }
      }
    }
  }

  & > div:last-child {
    width: 392px;
    height: 700px;

    box-sizing: border-box;
    padding: 42px;
    padding-bottom: 0px;
    padding-right: 0px;

    display: flex;
    flex-direction: column;
    gap: 16px;

    & > div:nth-child(1) {
      width: 100%;
      height: 22px;

      display: flex;
      justify-content: end;
    }

    & > div:nth-child(2) {
      width: 100%;
      height: 44px;

      border: 2px solid #f4f5f7;
      background-color: #f4f5f7;
      border-radius: 10px;

      padding: 8px 16px;
      box-sizing: border-box;

      display: flex;

      & > input {
        width: 100%;
        height: 28px;
        padding: 0;
        border: none;
        background-color: transparent;
        outline: none;

        font-family: Noto Sans KR;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #000000;

        margin-left: 10px;
      }
    }

    & > div:nth-child(3) {
      width: 108%;
      height: 560px;

      overflow-y: scroll;
    }
  }
`;

const TalkRoom = styled.div`
  width: 352px;
  height: 88px;
  border-radius: 10px;

  position: relative;

  background-color: #f4f5f7;

  margin-bottom: 8px;

  display: flex;
  align-items: center;
  gap: 8px;

  &.active {
    background-color: #f9e8ec;
  }

  & > div:nth-child(1) {
    width: 54px;
    height: 54px;

    border-radius: 50%;
    background-color: #cccccc;

    margin-left: 20px;
  }

  & > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    gap: 5px;

    & > span:first-child {
      font-family: Noto Sans KR;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.2px;
      color: #000000;
    }

    & > span:last-child {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #a0a6b1;
    }
  }

  & > span {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: #a0a6b1;

    position: absolute;

    top: 16px;
    right: 20px;
  }
`;

const UserMessage = styled.div<{ $img: string }>`
  width: 100%;
  height: 100px;

  display: flex;
  align-items: center;
  gap: 20px;

  box-sizing: border-box;
  padding-right: 20px;

  &>div: last-child {
    display: flex;
    flex-direction: column;
    align-items: end;

    &>div: first-child {
      background-color: #f4f5f7;
      max-width: 450px;
      min-width: 250px;
      min-height: 45px;
      color: #000000;

      padding: 15px;
      box-sizing: border-box;
      border-radius: 18px;

      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
    }

    &>span: last-child {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      color: #b6b6b6;
      margin-top: 5px;
    }
  }

  &>div: first-child {
    background-image: url(${(props) => props.$img});
    background-size: cover;
    width: 56px;
    height: 56px;

    border-radius: 50%;
  }
`;

const ShopMessage = styled.div<{ $img: string }>`
  width: 100%;
  height: 100px;

  display: flex;
  justify-content: end;
  align-items: center;
  gap: 20px;

  box-sizing: border-box;
  padding-right: 20px;

  &>div: first-child {
    display: flex;
    flex-direction: column;
    align-items: end;

    &>div: first-child {
      background-color: #fd91a5;
      max-width: 450px;
      min-width: 250px;
      min-height: 45px;
      color: #ffffff;

      padding: 15px;
      box-sizing: border-box;
      border-radius: 18px;

      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
    }

    &>span: last-child {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      color: #b6b6b6;
      margin-top: 5px;
    }
  }

  &>div: last-child {
    background-image: url(${(props) => props.$img});
    background-size: cover;
    width: 56px;
    height: 56px;

    border-radius: 50%;
  }
`;

export default MessageBox;
