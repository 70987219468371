import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import "dayjs/locale/ko";
import dayjs from "dayjs";

type propType = {
  zoom?: number;
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
};

export default function Calendar2({ zoom, date, setDate }: propType) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
      <DateCalendar
        disableHighlightToday
        value={dayjs(date)}
        onChange={(value) => {
          console.log(value?.toDate());
          setDate(value!.toDate());
        }}
        focusedView={"month"}
        slotProps={{
          calendarHeader: {
            sx: {
              padding: "0",
              ".MuiPickersCalendarHeader-label": {
                fontSize: "20px",
                fontWeight: "700",
                marginLeft: "10px",
                fontFamily: "Noto Sans KR",
              },
            },
          },
          day: {
            sx: {
              fontFamily: "Noto Sans KR",
              "&.MuiPickersDay-today": {
                backgroundColor: "#FF5D7A",
                color: "#ffffff",
                border: "none",
              },
              "&:hover": {
                backgroundColor: "#FF5D7A",
                color: "#ffffff",
              },
              "&.Mui-selected,&.Mui-selected:focus": {
                backgroundColor: "#FF5D7A",
              },
            },
          },
        }}
        sx={{
          scale: zoom ? zoom.toString() : "0.95",
          height: "285px",
          margin: 0,
          ".MuiDayCalendar-weekDayLabel": {
            fontSize: "13px",
            fontWeight: "700",
            color: "#000000",
          },
          ".MuiDayCalendar-weekDayLabel[aria-label='일요일']": {
            fontSize: "13px",
            fontWeight: "700",
            color: "#FF5D7A",
          },
          ".MuiDayCalendar-weekContainer,.MuiDayCalendar-header": {
            justifyContent: "space-between",
          },
          "&.MuiPickersMonth-monthButton": {
            backgroundColor: "#FF5D7A",
          },
          ".Mui-selected, .Mui-selected:focus": {
            backgroundColor: "#FF5D7A",
          },
        }}
        views={["month"]}
      />
    </LocalizationProvider>
  );
}
