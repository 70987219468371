import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import MenuItem from "./MenuItem";

const SideNav = (props: { active: string; zoom: number }) => {
  const navigate = useNavigate();

  return (
    <NavContainer zoom={props.zoom}>
      <LogoBox>
        <Logo src="/image/logo.png" onClick={() => navigate("/home")} />
      </LogoBox>
      <MenuList>
        <MenuItem name="홈" active={props.active === "home"} to="/home" />
        <MenuItem
          name="일정관리"
          active={props.active === "schedule"}
          to="/schedule"
        />
        <MenuItem
          name="고객관리"
          active={props.active === "customer"}
          to="/customer"
        />
        <MenuItem
          name="직원관리"
          active={props.active === "manager"}
          to="/manager"
        />
        <MenuItem
          name="매출관리"
          active={props.active === "revenue"}
          to="/revenue2"
        />
        <MenuItem name="핑프톡" active={props.active === "talk"} to="/talk" />
        <MenuItem
          name="정산"
          active={props.active === "adjustment"}
          to="/adjustment"
        />
        <MenuItem
          name="내샵관리"
          active={props.active === "myshop"}
          to="/myshop"
        />
        <MenuItem
          name="설정"
          active={props.active === "setting"}
          to="/setting"
        />
      </MenuList>
      <CompanyInfo>
        <p>
          상호 : (주)보트르내일 <br />
          대표 : 나태주 <br />
          사업자등록번호 : 136-86-03033 <br />
          주소 : 서울특별시 강남구 테헤란로 69길 14 삼성빌딩 <br />
          이메일 : info@votre.kr
          <br />
          대표번호 : 02-508-5591 <br />
          광고, 제휴 문의 : 02-508-5591 <br />
          <br />
          <br />
          (주)보트르내일은 통신판매중개사로서 통신판매의 당사자가 아닙니다.
          (주)보트르내일은 예약 및 구매관련 통신판매업자가 제공하는 상품,
          거래정보 및 거래 등에 대하여 책임을 지지 않습니다.
        </p>
      </CompanyInfo>
    </NavContainer>
  );
};

const NavContainer = styled.div<{ zoom: number }>`
  width: 264px;
  background-color: #ffffff;
  min-height: 900px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  zoom: ${(props) => props.zoom};
`;

const LogoBox = styled.div`
  width: 264px;
  height: 203px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  cursor: pointer;
  width: 184px;
  height: 60px;
`;

const MenuList = styled.ul`
  width: 184px;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

const CompanyInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  p {
    width: 184px;
    font-size: 10px;
    color: rgb(222, 222, 222);
  }
`;

export default SideNav;
