import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import Box from "@mui/material/Box";
import { styled } from "styled-components";
import Select2 from "./Select2";
import { ReqPayment } from "../../apis/sales";
import InputBox from "./InputBox";
import { changeCategory, handleEmptyValue } from "../../util/util";
import MenuSelection from "./MenuSelection";
import { IoMdClose } from "react-icons/io";
import { useRecoilValue } from "recoil";
import { ShopData } from "../../atoms/atom";

type propType = {
  setOpen: Dispatch<SetStateAction<boolean>>;
  data: any;
  date: Date;
  time: Date;
  manager: any;
  setManager: Dispatch<SetStateAction<any>>;
  managerList: any[];
  selMenu: any;
  setSelMenu: Dispatch<SetStateAction<any>>;
  selDesign: any;
  setSelDesign: Dispatch<SetStateAction<any>>;
  selOptionList: any[];
  setSelOptionList: Dispatch<SetStateAction<any[]>>;
  setIsReqPaymentOpen: Dispatch<SetStateAction<boolean>>;
  isSelMenuOpen: boolean;
  setIsSelMenuOpen: Dispatch<SetStateAction<boolean>>;
  setAmount: any;
  amount: any;
  method: string;
  setMethod: Dispatch<SetStateAction<string>>;
  callback: () => void;
  isSalesModal?: boolean;
};

const RequestPayment = ({
  setOpen,
  data,
  date,
  time,
  manager,
  setManager,
  managerList,
  selMenu,
  setSelMenu,
  selDesign,
  setSelDesign,
  selOptionList,
  setSelOptionList,
  setIsReqPaymentOpen,
  isSelMenuOpen,
  setIsSelMenuOpen,
  amount,
  setAmount,
  method,
  setMethod,
  callback,
  isSalesModal,
}: propType) => {
  const shopData = useRecoilValue(ShopData);

  const [selCategory, setSelCategory] = useState("네일");
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [memo, setMemo] = useState("");

  console.log("123:", data);

  const calDuration = () => {
    let menu_runningTime = handleEmptyValue(
      selMenu
        ? parseInt(selMenu.runningTime !== "" ? selMenu.runningTime : 0)
        : 0
    );
    let design_runningTime = handleEmptyValue(
      selDesign
        ? parseInt(selDesign.runningTime !== "" ? selDesign.runningTime : 0)
        : 0
    );

    let total_option_runningTime = 0;

    selOptionList.forEach((e) => {
      total_option_runningTime += parseInt(e.runningTime);
    });

    return menu_runningTime + design_runningTime + total_option_runningTime;
  };

  const handleInput = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // 높이를 초기화하여 스크롤 높이를 정확히 계산
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // 내용에 맞게 높이 조정
    }
  };

  useEffect(() => {
    handleInput(); // 초기화 (예: textarea에 기본값이 있을 때)
  }, []);

  return (
    <Box
      sx={{
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: isSelMenuOpen ? "1280px" : "680px",
        height: "880px",
        borderRadius: "16px",
        p: 4,
        boxShadow: "4px 4px 4px 0px #00000040",
        boxSizing: "border-box",
        backgroundColor: "#F9FAFB",
        zoom: 0.9,
        padding: 0,
        overflow: "hidden",
        display: "flex",
      }}
    >
      <Container>
        <div
          style={{
            width: "100%",
            maxWidth: "680px",
            height: "90px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#ffffff",
            position: "relative",
          }}
        >
          <h2 style={{ fontSize: "20px", fontWeight: "700" }}>결제 요청</h2>
          <IoMdClose
            style={{
              position: "absolute",
              right: "32px",
              fontSize: "32px",
              cursor: "pointer",
            }}
            onClick={() => setOpen(false)}
          />
        </div>
        <div
          style={{
            overflow: "auto",
            height: "766px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div style={{ width: "608px", marginTop: "24px" }}>
            <div
              style={{
                width: "608px",
                backgroundColor: "#ffffff",
                height: "76px",
                paddingLeft: "30px",
                alignItems: "center",
                display: "flex",
                boxSizing: "border-box",
                borderRadius: "16px",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                marginBottom: "24px",
              }}
            >
              <Line>
                <Title>예약자</Title>
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    color: "#000000",
                    fontSize: "16px",
                  }}
                >
                  <span>{data.customerName}</span>
                  <span>{data.phoneNumber}</span>
                </div>
              </Line>
            </div>
            <div
              style={{
                width: "608px",
                backgroundColor: "#ffffff",
                paddingLeft: "30px",
                padding: "16px 30px",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                boxSizing: "border-box",
                borderRadius: "16px",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                gap: "12px",
                marginBottom: "24px",
              }}
            >
              <Line>
                <Title>시술 일정</Title>
                <div
                  style={{
                    width: "84%",
                    height: "44px",
                    borderRadius: "8px",
                    display: "flex",
                    gap: "16px",
                    alignItems: "center",
                  }}
                >
                  <span>{date.toLocaleDateString("ko-KR").slice(0, -1)}</span>
                  <span>
                    {time.toLocaleTimeString("ko-KR", {
                      hour12: true,
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                </div>
              </Line>
              <div
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h2
                  style={{
                    fontWeight: "700",
                    height: "128px",
                    paddingTop: "24px",
                    boxSizing: "border-box",
                  }}
                >
                  시술 내용
                </h2>
                <div
                  style={{
                    width: "84%",
                    height: "128px",
                    backgroundColor: "#F4F5F7",
                    borderRadius: "8px",
                    boxSizing: "border-box",
                    padding: "14px",
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsSelMenuOpen((old) => !old)}
                  className="menuSelBox"
                >
                  {selMenu.id === "" ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                          boxSizing: "border-box",
                          padding: "10px",
                        }}
                      >
                        {data.menuCategory && (
                          <span
                            style={{
                              fontSize: 16,
                              fontWeight: 700,
                              color: "#000000",
                            }}
                          >
                            {`(${changeCategory(data.menuCategory, false)})`}
                          </span>
                        )}
                        <span>- {data.menuName}</span>
                        {data.designName && <span>- {data.designName}</span>}
                        {data.optionName && (
                          <span>- {data.optionName.join(",")}</span>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                          boxSizing: "border-box",
                          padding: "10px",
                        }}
                      >
                        {selCategory && (
                          <span
                            style={{
                              fontSize: 16,
                              fontWeight: 700,
                              color: "#000000",
                            }}
                          >
                            {`(${selCategory})`}
                          </span>
                        )}
                        <span>- {selMenu.name}</span>
                        {selDesign.id !== "" && <span>- {selDesign.name}</span>}
                        {selOptionList.length > 0 && (
                          <span>
                            - {selOptionList.map((e) => e.name).join(",")}
                          </span>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Title>담당자</Title>
                <div
                  style={{
                    width: "84%",
                    height: "44px",
                    borderRadius: "8px",
                    display: "flex",
                    gap: "16px",
                  }}
                >
                  <Select2
                    option={managerList}
                    defaultValue={data.managerName}
                    setResult={setManager}
                  />
                </div>
              </div>
              <Line>
                <Title style={{ fontWeight: "700" }}>시술 금액</Title>
                <InputBox
                  placeholder={"시술 금액"}
                  type={"money"}
                  width={180}
                  setValue={setAmount}
                  value={amount}
                />
              </Line>
            </div>
            <div
              style={{
                width: "608px",
                backgroundColor: "#ffffff",
                paddingLeft: "30px",
                padding: "16px 30px",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                boxSizing: "border-box",
                borderRadius: "16px",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                marginBottom: "24px",
              }}
            >
              <Line>
                <Title>매출등록일</Title>
                <span style={{ color: "#000000", fontSize: "16px" }}>
                  {new Date().toLocaleDateString()}
                </span>
              </Line>
            </div>
            <div
              style={{
                width: "608px",
                backgroundColor: "#ffffff",
                paddingLeft: "30px",
                padding: "16px 30px",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                boxSizing: "border-box",
                borderRadius: "16px",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                gap: "12px",
                marginBottom: "24px",
              }}
            >
              <Line style={{ height: "100%" }}>
                <Title>매출 메모</Title>
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    color: "#000000",
                    fontSize: "16px",
                  }}
                >
                  <span
                    style={{
                      width: "462px",
                      minHeight: "100px",
                      backgroundColor: "#F4F5F7",
                      boxSizing: "border-box",
                      padding: "16px 16px",
                      borderRadius: "10px",
                    }}
                  >
                    <textarea
                      ref={textareaRef}
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#F4F5F7",
                        border: "none",
                        outline: "none",
                        resize: "none",
                      }}
                      placeholder="매출 등록 시, 필요한 내용을 메모하세요."
                      onInput={handleInput}
                      value={memo}
                      onChange={(event) => {
                        setMemo(event.target.value);
                      }}
                    />
                  </span>
                </div>
              </Line>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "24px",
                paddingBottom: "40px",
              }}
            >
              <div
                style={{
                  width: "184px",
                  height: "44px",
                  backgroundColor: "#FF5D7A",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (amount <= 100) {
                    window.alert(
                      "결제 요청 금액은 , 100원 보다 작을 수 없습니다."
                    );
                  }

                  if (
                    !window.confirm(
                      "결제 요청시, 즉시 예약금이 반환된 후, 결제가 요청됩니다.\n결제를 요청하시겠습니까?"
                    )
                  ) {
                    return;
                  }

                  ReqPayment({
                    bookId: data.bookId,
                    menuName: selMenu.id ? selMenu.name : data.menuName,
                    designName: selDesign ? selDesign.name : "",
                    optionName: selMenu.id
                      ? selOptionList.map((e) => e.name)
                      : data.optionName,
                    duration: selMenu.id
                      ? calDuration().toString()
                      : data.duration,
                    managerId: manager.id,
                    imageUrl: selDesign ? selDesign.imageUrl : "",
                    menuCategory: selMenu.id
                      ? changeCategory(selCategory, true)
                      : data.menuCategory,
                    memo: memo,
                    price: amount,
                  }).then((res) => {
                    callback();
                    setOpen(false);
                  });
                }}
                className="submitBtn"
              >
                요청하기
              </div>
            </div>
          </div>
        </div>
      </Container>
      {isSelMenuOpen && (
        <MenuSelection
          selMenu={selMenu}
          setSelMenu={setSelMenu}
          selDesign={selDesign}
          setSelDesign={setSelDesign}
          selOptionList={selOptionList}
          setSelOptionList={setSelOptionList}
          selCategory={selCategory}
          setSelCategory={setSelCategory}
        />
      )}
    </Box>
  );
};

const Container = styled.div`
  align-items: center;
  width: 100%;
  height: 100%;

  overflow: hidden;

  & .menuSelBox:hover {
    background-color: #f9e8ec !important;
  }

  & .selectBox:hover {
    background-color: #f9e8ec !important;
  }

  & .noshowBtn:hover {
    background-color: #4a5165 !important;
  }

  & .cancelBtn:hover {
    background-color: #d3d6db !important;
  }

  & .submitBtn:hover {
    background-color: #fd7f96 !important;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

const Title = styled.span`
  width: 86px;
  font-size: 16px;
  font-weight: 700;
  color: #4d5562;
`;

export default RequestPayment;
