import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import CardLayout from "../../layout/CardLayout";
import { useState } from "react";
import InputBox from "../../components/utility/InputBox";
import { saveSecondPw } from "../../apis/auth";

const SecondPassword = () => {
  const [pw, setPw] = useState("");
  const navigate = useNavigate();

  return (
    <CardLayout text="2차 비밀번호를 설정해주세요.">
      <InputBox
        placeholder={"2차 비밀번호를 입력해주세요."}
        type={"password"}
        width={300}
        setValue={setPw}
        value={pw}
        disabled={true}
      />
      <BtnWrap>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, "삭제", 0, "확인"].map((item, idx) =>
          item === "삭제" ? (
            <KeypadSpecialBtn
              key={idx}
              onClick={() => setPw((old) => old.slice(0, -1))}
            >
              {item}
            </KeypadSpecialBtn>
          ) : item === "확인" ? (
            <KeypadSpecialBtn
              onClick={() => {
                if (pw.length === 4) {
                  saveSecondPw(pw)
                    .then((res) => navigate("/secondPwSucces"))
                    .catch((e) => console.log(e));
                }
              }}
            >
              {item}
            </KeypadSpecialBtn>
          ) : (
            <KeypadBtn
              onClick={() =>
                setPw((old) => (old.length < 4 ? old + item : old))
              }
            >
              {item}
            </KeypadBtn>
          )
        )}
      </BtnWrap>
    </CardLayout>
  );
};

const BtnWrap = styled.div`
  width: 300px;
  height: 300px;

  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  padding-top: 20px;
`;

const KeypadBtn = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #f4f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  font-weight: 700;
  color: #a0a6b1;
`;

const KeypadSpecialBtn = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #f14262;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
`;

export default SecondPassword;
