import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { IoImageOutline } from "react-icons/io5";
import { changeCategory } from "../../util/util";
import { SurgeryMenuType, SurgeryOptionType } from "../../types";
import { getMenuList, getOptionList } from "../../apis/menu";
import { ShopData } from "../../atoms/atom";
import { useRecoilValue } from "recoil";

type propType = {
  selMenu: any;
  setSelMenu: Dispatch<SetStateAction<any>>;
  selDesign: any;
  setSelDesign: Dispatch<SetStateAction<any>>;
  selOptionList: any[];
  setSelOptionList: Dispatch<SetStateAction<any[]>>;

  selCategory: string;
  setSelCategory: Dispatch<SetStateAction<string>>;
};

const MenuSelection = ({
  selMenu,
  setSelMenu,
  selDesign,
  setSelDesign,
  selOptionList,
  setSelOptionList,
  selCategory,
  setSelCategory,
}: propType) => {
  const shopData = useRecoilValue(ShopData);

  const [selCategory2, setSelCategory2] = useState("네일");

  const [menuAll, setMenuAll] = useState<SurgeryMenuType[]>([]);
  const [optionAll, setOptionAll] = useState<SurgeryOptionType[]>([]);

  useEffect(() => {
    getMenuList(shopData.id).then((res) => {
      setMenuAll(res.data.data.menuList);
    });

    getOptionList(shopData.id).then((res) => {
      setOptionAll(res.data.data.optionList);
    });
  }, [shopData.id]);

  return (
    <div
      style={{
        width: "600px",
        height: "100%",
        paddingRight: "3%",
        paddingLeft: "3%",
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "600px",
          height: "800px",
          backgroundColor: "#ffffff",
          border: "1px solid #cccccc",
          borderRadius: "8px",
          boxSizing: "border-box",
          padding: "24px",
          gap: "16px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h2 style={{ fontWeight: "700" }}>시술 카테고리</h2>
        <div style={{ width: "100%", display: "flex", gap: "12px" }}>
          {["네일", "패디", "기타"].map((item, idx) => (
            <div
              key={idx}
              style={{
                width: "67px",
                height: "36px",
                borderRadius: "19px",
                backgroundColor: selCategory2 === item ? "#FF5D7A" : "#E5E7EB",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                color: selCategory2 === item ? "#ffffff" : "#000000",
                fontSize: "14px",
              }}
              onClick={() => setSelCategory2(item)}
            >
              {item}
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 16,
            height: "280px",
            overflowY: "auto",
          }}
        >
          {menuAll
            .filter((e) => e.category === changeCategory(selCategory2, true))
            .map((item, idx) => (
              <div
                key={idx}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 8,
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: 44,
                    borderRadius: 8,
                    borderWidth: item.id === selMenu.id ? 0 : 1,
                    borderStyle: "solid",
                    borderColor: "#E5E7EB",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "0px 16px",
                    justifyContent: "space-between",
                    backgroundColor:
                      item.id === selMenu.id ? "#FF5D7A" : "#ffffff",
                    display: "flex",
                    boxSizing: "border-box",
                  }}
                  onClick={() => {
                    if (item.id === selMenu.id) return;

                    setSelCategory(selCategory2);

                    setSelDesign({ id: "" });
                    setSelMenu(item);
                  }}
                >
                  <div
                    style={{
                      flexDirection: "row",
                      gap: 4,
                      display: "flex",
                    }}
                  >
                    <span
                      style={{
                        color: item.id === selMenu.id ? "#ffffff" : "#000000",
                        display: "flex",
                        flexDirection: "column",
                        fontSize: "14px",
                      }}
                    >
                      {item.name}
                    </span>
                    <span
                      style={{
                        color: item.id === selMenu.id ? "#ffffff" : "#A0A6B1",
                        display: "flex",
                        flexDirection: "column",
                        fontSize: "12px",
                      }}
                    >
                      ({item.runningTime}분)
                    </span>
                  </div>
                  <span
                    style={{
                      color: item.id === selMenu.id ? "#ffffff" : "#000000",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      fontSize: "14px",
                    }}
                  >
                    {(!item.eventPrice || item.eventPrice !== "-1") && (
                      <span
                        style={{
                          width: "48px",
                          height: "21px",
                          backgroundColor: "#FADA07",
                          borderRadius: "8px",
                          color: "#ffffff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "10px",
                        }}
                      >
                        Event
                      </span>
                    )}
                    {item.eventPrice && item.eventPrice === "-1"
                      ? item.price
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : item.eventPrice
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    원
                  </span>
                </div>
                {item.id === selMenu.id &&
                  item.designList.map((item2, idx) => (
                    <div
                      key={idx}
                      style={{
                        width: "100%",
                        height: 44,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0px 16px",
                        display: "flex",
                        boxSizing: "border-box",
                      }}
                      onClick={() => setSelDesign(item2)}
                    >
                      <div
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 12,
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            width: 15,
                            height: 15,
                            borderRadius: 8,
                            borderWidth: "1px",
                            borderColor:
                              item2.id === selDesign.id ? "#FF5D7A" : "#D9D9D9",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                            borderStyle: "solid",
                          }}
                        >
                          <div
                            style={{
                              width: 9,
                              height: 9,
                              borderRadius: 8,
                              backgroundColor:
                                item2.id === selDesign.id
                                  ? "#FF5D7A"
                                  : "#ffffff",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          />
                        </div>
                        {item2.imageUrl ? (
                          <img
                            alt=""
                            src={item2.imageUrl}
                            style={{
                              width: 44,
                              height: 44,
                              borderRadius: 8,
                              backgroundColor: "#cccccc",
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              width: 44,
                              height: 44,
                              borderRadius: 8,
                              backgroundColor: "#D3D6DB",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <IoImageOutline
                              style={{
                                fontSize: "20px",
                                color: "#A0A6B1",
                              }}
                            />
                          </div>
                        )}
                        <span
                          style={{
                            fontSize: 14,
                            color: "#000000",
                            display: "flex",
                            flexDirection: "column",
                            gap: "6px",
                          }}
                        >
                          {item2.name}{" "}
                          <span
                            style={{
                              fontSize: 12,
                              color: "#A0A6B1",
                            }}
                          >
                            {item2.runningTime
                              ? `( +${item2.runningTime}분)`
                              : ""}
                          </span>
                        </span>
                      </div>
                      <span>
                        +{" "}
                        {item2.price
                          ? item2.price
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : "0"}
                        원
                      </span>
                    </div>
                  ))}
              </div>
            ))}
        </div>
        <hr
          style={{
            width: "100%",
            border: "none",
            height: "1px",
            backgroundColor: "#cccccc",
          }}
        />
        <h2 style={{ fontWeight: "700" }}>
          옵션 선택{" "}
          <strong style={{ color: "#A0A6B1" }}>(중복 선택 가능)</strong>
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
          }}
        >
          {optionAll.map((item, idx) => (
            <div
              key={idx}
              style={{
                width: "100%",
                height: 44,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 4,
                display: "flex",
                cursor: "pointer",
              }}
              onClick={() => {
                if (!selOptionList.find((value: any) => value.id === item.id)) {
                  setSelOptionList((old) => [...old, item]);
                } else {
                  setSelOptionList((old) =>
                    old.filter((e) => e.id !== item.id)
                  );
                }
              }}
            >
              <div
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 12,
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: 15,
                    height: 15,
                    borderRadius: 8,
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: selOptionList.find(
                      (value: any) => value.id === item.id
                    )
                      ? "#FF5D7A"
                      : "#D9D9D9",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      width: 9,
                      height: 9,
                      borderRadius: 8,
                      backgroundColor: selOptionList.find(
                        (value: any) => value.id === item.id
                      )
                        ? "#FF5D7A"
                        : "#ffffff",
                    }}
                  />
                </div>
                <span style={{ fontSize: 14, color: "#000000" }}>
                  {item.name}{" "}
                  <span style={{ fontSize: 12, color: "#A0A6B1" }}>
                    {item.runningTime ? `( +${item.runningTime}분)` : ""}
                  </span>
                </span>
              </div>
              <span
                style={{
                  color: item.id === selMenu.id ? "#ffffff" : "#000000",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                {(!item.eventPrice || item.eventPrice !== "-1") && (
                  <span
                    style={{
                      width: "48px",
                      height: "21px",
                      backgroundColor: "#FADA07",
                      borderRadius: "8px",
                      color: "#ffffff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "10px",
                    }}
                  >
                    Event
                  </span>
                )}
                {item.price
                  ? item.eventPrice !== "-1"
                    ? item.eventPrice
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : item.price
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "0"}
                원
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MenuSelection;
