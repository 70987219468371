import React, { useEffect, useState } from "react";
import { getVotreNoti } from "../../apis/notification";
import { Announcement } from "../../types";
import NotiItem from "./NotiItem";

const NotificationList = () => {
  const [notiList, setNotiList] = useState<Announcement[]>([]);

  useEffect(() => {
    getVotreNoti().then((res) => {
      setNotiList(res.data.data.announcement);
    });
  }, []);

  return (
    <div style={{ width: "100%", height: "100%", overflowY: "auto" }}>
      {notiList.length === 0 && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "18px",
            color: "#A0A6B1",
          }}
        >
          등록된 공지사항이 없습니다.
        </div>
      )}
      {notiList.map((item, idx) => (
        <NotiItem data={item} key={idx} />
      ))}
    </div>
  );
};

export default NotificationList;
