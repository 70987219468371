import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { saveNewAccessToken } from "./auth";
import Resizer from "react-image-file-resizer";
// 배포용 url
export const baseUrl = "https://api.finger-princess.com";

// 개발용 url
//export const baseUrl = "https://dev-api.finger-princess.com";

axios.defaults.withCredentials = true;

// 요청 인터셉터
axios.interceptors.request.use(
  (config) => config,
  (error) => {
    console.log("res error intercept");
    return Promise.reject(error);
  }
);

// 응답 인터셉터
axios.interceptors.response.use(
  (config) => config,
  (error) => {
    console.log("res error intercept");

    try {
      if (error.response.status === 403) {
        // 토큰 만료
        //navigateToScreen("LoginSelect");
        saveNewAccessToken();
        return;
      } else if (error.response.status === 412) {
        window.location.href = "./expired";
      }
    } catch (e) {
      console.log(e);
    }

    return Promise.reject(error);
  }
);

// config setting 함수
export const config = (accessToken?: string, params?: any) => {
  if (accessToken) {
    return {
      params: params,
      headers: {
        withCredentials: true,
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
  }

  const temp: AxiosRequestConfig = {
    params: params,
    headers: {
      withCredentials: true,
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  };
  return temp;
};

export const imgConfig = () => {
  const accessToken = localStorage.getItem("accessToken");

  return {
    headers: {
      withCredentials: true,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

export const img_config: AxiosRequestConfig = {
  headers: {
    withCredentials: true,
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  },
};

const base64ToBlob = (base64Data: string, contentType = "") => {
  const byteCharacters = atob(base64Data.split(",")[1]);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
};

//  util api

export const UploadImg = async (
  file: File,
  shopId: string,
  uploadType: string
) => {
  // 새로운 프로미스 생성
  return new Promise<AxiosResponse<any, any>>((resolve, reject) => {
    Resizer.imageFileResizer(
      file,
      1080,
      1080,
      "JPEG",
      100,
      0,
      async (uri) => {
        console.log(uri);

        // 리사이징된 이미지를 Blob으로 변환
        const blobImage = base64ToBlob(uri as string, "image/jpeg");

        // Blob 객체를 파일로 변환
        const resizedFile = new File([blobImage], "resized_image.jpeg");

        console.log(resizedFile.size);
        console.log(new Date().getTime());

        // FormData에 리사이징된 이미지 추가
        const formData = new FormData();

        formData.append("file", resizedFile);
        formData.append("shopId", shopId);
        formData.append("uploadType", uploadType);

        try {
          // 서버에 리사이징된 이미지 업로드
          const response = await axios.post(
            baseUrl + "/upload/admin",
            formData,
            imgConfig()
          );
          resolve(response); // 성공 시 resolve 호출
        } catch (error) {
          reject(error); // 실패 시 reject 호출
        }
      },
      "base64"
    );
  });
};

export const UploadImg2 = async (
  file: File,
  shopId: string,
  uploadType: string
) => {
  // 새로운 프로미스 생성
  return new Promise<AxiosResponse<any, any>>((resolve, reject) => {
    Resizer.imageFileResizer(
      file,
      1080,
      720,
      "JPEG",
      100,
      0,
      async (uri) => {
        console.log(uri);

        // 리사이징된 이미지를 Blob으로 변환
        const blobImage = base64ToBlob(uri as string, "image/jpeg");

        // Blob 객체를 파일로 변환
        const resizedFile = new File([blobImage], "resized_image.jpeg");

        console.log(resizedFile.size);
        console.log(new Date().getTime());

        // FormData에 리사이징된 이미지 추가
        const formData = new FormData();

        formData.append("file", resizedFile);
        formData.append("shopId", shopId);
        formData.append("uploadType", uploadType);

        try {
          // 서버에 리사이징된 이미지 업로드
          const response = await axios.post(
            baseUrl + "/upload/admin",
            formData,
            imgConfig()
          );
          resolve(response); // 성공 시 resolve 호출
        } catch (error) {
          reject(error); // 실패 시 reject 호출
        }
      },
      "base64"
    );
  });
};
