import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import RemoveIcon from "@mui/icons-material/Remove";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  CreateDesignType,
  DesignType,
  ShopType,
  SurgeryMenuType,
} from "../../types";
import CheckIcon from "@mui/icons-material/Check";
import InputBox from "../utility/InputBox";
import { createDesign, saveDesign, saveMenu } from "../../apis/menu";
import DesignAdd from "../utility/DesignAdd";
import DesignEdit from "../utility/DesignEdit";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";
import { getShopData, saveShopData } from "../../apis/shop";
import { useRecoilState } from "recoil";
import { ShopData } from "../../atoms/atom";
import { IoMenu } from "react-icons/io5";
import { color_midnight } from "../../util/color";

type propType = {
  isReordering: boolean;
  data: SurgeryMenuType;
  deleteCallback: () => void;
  submitCallback?: () => void;
  presubmitCallback?: () => void;
  editKey: string;
  key: number;
  setEditKey: Dispatch<SetStateAction<string>>;
};

const MenuItem = ({
  isReordering,
  data,
  deleteCallback,
  submitCallback,
  presubmitCallback,
  editKey,
  setEditKey,
}: propType) => {
  const [shopData, setShopData] = useRecoilState(ShopData);

  const [name, setName] = useState(data.name);
  const [engName, setEngName] = useState(data.engName);
  const [description, setDescription] = useState(data.description);
  const [engDescription, setEngDescription] = useState(data.engDescription);
  const [price, setPrice] = useState(data.price);
  const [eventPrice, setEventPrice] = useState(
    data.eventPrice === "-1" ? "0" : data.eventPrice
  );
  const [runningTime, setRunningTime] = useState(data.runningTime);

  const [isEvent, setIsEvent] = useState(data.eventPrice !== "-1");

  const [AddDesignList, setAddDesignList] = useState<CreateDesignType[]>([]);
  const [EditDesignList, setEditDesignList] = useState<DesignType[]>(
    data.designList
  );

  useEffect(() => {
    setIsEvent(data.eventPrice !== "-1");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {editKey === data.id ? (
        <Container2>
          <div>
            <RemoveIcon
              onClick={() => deleteCallback()}
              sx={{
                backgroundColor: "#F71807",
                color: "#ffffff",
                borderRadius: "50%",
                marginTop: "18px",
                width: "20px",
                height: "20px",
                position: "absolute",
                top: "-4px",
                left: "-40px",
              }}
            />
            <div>
              <Box>
                <span>*메뉴명(한글)</span>
                <InputBox
                  placeholder={""}
                  type={"text"}
                  width={300}
                  setValue={setName}
                  value={name}
                  maxLength={12}
                />
              </Box>
              <Box>
                <span>*메뉴명(영어)</span>
                <InputBox
                  placeholder={""}
                  type={"text"}
                  width={300}
                  setValue={setEngName}
                  value={engName}
                  maxLength={30}
                />
              </Box>
            </div>
            <div>
              <Box>
                <span>설명(한글)</span>
                <InputBox
                  placeholder={
                    "메뉴에 대한 설명을 입력해주세요. ex. 보트르내일만의 이달의 아트"
                  }
                  type={"text"}
                  width={800}
                  setValue={setDescription}
                  value={description}
                />
              </Box>
            </div>
            <div>
              <Box>
                <span>설명(영어)</span>
                <InputBox
                  placeholder={
                    "메뉴에 대한 설명을 입력해주세요. ex. 보트르내일만의 이달의 아트"
                  }
                  type={"text"}
                  width={800}
                  setValue={setEngDescription}
                  value={engDescription}
                />
              </Box>
            </div>
            <div style={{ gap: "36px" }}>
              <Box>
                <span>*가격</span>
                <InputBox
                  placeholder={"ex.50,000"}
                  type={"money"}
                  width={184}
                  setValue={setPrice}
                  value={price}
                />
              </Box>
              <EventBox>
                <div
                  onClick={() => setIsEvent((old) => !old)}
                  style={{ cursor: "pointer" }}
                >
                  <CheckIcon
                    style={{ color: isEvent ? "#62D4D2" : "#ffffff" }}
                  />
                </div>
                <span>Event</span>
                <span>이벤트가</span>
                <InputBox
                  placeholder={"ex.50,000"}
                  type={"money"}
                  width={184}
                  setValue={setEventPrice}
                  value={eventPrice}
                />
              </EventBox>
              <Box>
                <span>*소요시간</span>
                <InputBox
                  placeholder={"00 ( ex : 단위 분  50분 -> 50)"}
                  type={"minute"}
                  width={184}
                  setValue={setRunningTime}
                  value={runningTime}
                />
              </Box>
            </div>
          </div>
          <span
            style={{
              color: "#62D4D2",
              fontSize: "12px",
              fontWeight: "700",
              marginLeft: "24px",
            }}
          >
            이벤트 가격 설정 시 정상가를 입력해주세요.
          </span>
          <div style={{ display: "flex", gap: "24px" }}>
            <span
              style={{ fontSize: "16px", color: "#F14262", fontWeight: "700" }}
            >
              디자인
            </span>
            <span
              style={{
                width: "20px",
                height: "20px",
                backgroundColor: "#F14262",
                borderRadius: "50%",
                color: "#ffffff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() =>
                setAddDesignList((old) => {
                  let temp = [...old];
                  temp.push({
                    name: "",
                    price: "",
                    runningTime: "",
                    imageUrl: "",
                  });
                  return temp;
                })
              }
            >
              +
            </span>
          </div>
          <div>
            {AddDesignList.map((item, i) => (
              <DesignAdd
                key={i}
                setValue={setAddDesignList}
                index={i}
                value={AddDesignList[i]}
              />
            ))}
            {EditDesignList.map((item, i) => (
              <DesignEdit
                key={i}
                setValue={setEditDesignList}
                index={i}
                value={EditDesignList[i]}
              />
            ))}
          </div>
          <div>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setEditKey("");
                //editCallback();
                //setIsEdit(false);
              }}
            >
              취소
            </span>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (presubmitCallback) presubmitCallback();
                saveMenu({
                  menuId: data.id,
                  shopId: data.shopId,
                  name: name,
                  engName: engName,
                  description: description,
                  engDescription: engDescription,
                  category: data.category,
                  runningTime: runningTime ? runningTime : "0",
                  price: price,
                  eventPrice: isEvent ? eventPrice : "-1",
                })
                  .then((res) => {
                    AddDesignList.forEach(async (item) => {
                      if (item.name !== "") {
                        await createDesign({
                          name: item.name,
                          imageUrl: item.imageUrl,
                          runningTime: item.runningTime
                            ? item.runningTime
                            : "0",
                          price: item.price,
                          menuId: data.id,
                        });
                      }
                    });
                    EditDesignList.forEach(async (item) => {
                      if (item.name !== "") {
                        await saveDesign({
                          designId: item.id,
                          menuId: item.menuId,
                          name: item.name,
                          imageUrl: item.imageUrl,
                          runningTime: item.runningTime
                            ? item.runningTime
                            : "0",
                          price: item.price,
                        });
                      }
                    });

                    if (submitCallback) submitCallback();

                    setEditKey("");
                    //editCallback();
                    //setIsEdit(false);
                  })
                  .catch((e) => console.log(e));
              }}
            >
              저장
            </span>
          </div>
        </Container2>
      ) : (
        <Container>
          {isReordering ? (
            <div style={{ flexShrink: 0 }}>
              <IoMenu
                style={{
                  color: color_midnight.midnight9,
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
              />
            </div>
          ) : (
            <div style={{ flexShrink: 0 }}>
              {shopData.titleMenuId !== data.id ? (
                <StarOutlineIcon
                  onClick={() => {
                    if (
                      window.confirm(`${name}을 대표메뉴로 설정하시겠습니까?`)
                    ) {
                      saveShopData({
                        shopUserId: shopData.shopUserId,
                        id: shopData.id,
                        imageUrlList: shopData.imageUrlList,
                        name: shopData.name,
                        engName: shopData.engName,
                        phoneNumber: shopData.phoneNumber,
                        basicAddress: shopData.basicAddress,
                        detailAddress: shopData.detailAddress,
                        coordinate: shopData.coordinate,
                        announcement: shopData.announcement,
                        info: shopData.info,
                        businessHourList: shopData.businessHourList,
                        deposit: shopData.deposit,
                        bookingOption: shopData.bookingOption,
                        localTag: shopData.localTag,
                        subwayTag: shopData.subwayTag,
                        instagramId: shopData.instagramId,
                        instagramRefreshToken: shopData.instagramRefreshToken,
                        titleMenuId: data.id,
                        titleImageUrl: shopData.titleImageUrl,
                        isOnlyKakao: shopData.isOnlyKakao,
                        kakaoLink: shopData.kakaoLink,
                      }).then((res) => {
                        getShopData().then((res) => {
                          const data = res.data.data.shop as ShopType;
                          setShopData(data);
                        });
                      });
                    }
                  }}
                  sx={{
                    color: "#F14262",
                    borderRadius: "50%",
                    width: "20px",
                    height: "20px",
                    cursor: "pointer",
                  }}
                />
              ) : (
                <StarIcon
                  sx={{
                    color: "#F14262",
                    borderRadius: "50%",
                    width: "20px",
                    height: "20px",
                  }}
                />
              )}
            </div>
          )}
          <div style={{ flexGrow: 1 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                height: "auto",
              }}
            >
              <h3>
                {data.name} {data.engName ? `( ${data.engName} )` : ""}
              </h3>
              <span>{data.description}</span>
              {engDescription && <span>{data.engDescription}</span>}
            </div>
            {data.eventPrice !== "-1" ? (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  fontSize: isEvent ? "14px" : "16px",
                  fontWeight: isEvent ? "500" : "700",
                }}
              >
                {isEvent && (
                  <span
                    style={{
                      color: "#F14262",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {eventPrice
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    원
                  </span>
                )}
                {isEvent
                  ? `(기존${price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원)`
                  : `${data.price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원`}
                <span>
                  {runningTime
                    ? `(${String(
                        Math.floor(parseInt(runningTime) / 60)
                      ).padStart(2, "0")}:${String(
                        parseInt(runningTime) % 60
                      ).padStart(2, "0")})`
                    : ""}
                </span>
              </div>
            ) : (
              <div>
                {data.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원{" "}
                <span>
                  {runningTime
                    ? `(${String(
                        Math.floor(parseInt(runningTime) / 60)
                      ).padStart(2, "0")}:${String(
                        parseInt(runningTime) % 60
                      ).padStart(2, "0")})`
                    : ""}
                </span>
              </div>
            )}

            <div>
              {data.designList.map((item, i) => (
                <div key={i}>
                  <div
                    style={{
                      backgroundImage: `url(${item.imageUrl})`,
                      backgroundSize: "cover",
                    }}
                  ></div>
                  <h4>{item.name}</h4>
                  <span>
                    <span>
                      {item.price
                        ? "+" +
                          item.price
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                          "원"
                        : ""}
                    </span>
                    <span>
                      {item.runningTime
                        ? "+(" +
                          parseInt(
                            (parseInt(item.runningTime) / 60).toString()
                          ) +
                          ":" +
                          (parseInt(item.runningTime) % 60) +
                          ")"
                        : ""}
                    </span>
                  </span>
                </div>
              ))}
            </div>
          </div>
          {!isReordering && (
            <div style={{ flexShrink: 0 }}>
              <SettingsIcon
                sx={{
                  color: "#E5E7EB",
                  width: "22px",
                  height: "22px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setEditKey(data.id);
                  //setIsEdit(true);
                }}
              />
            </div>
          )}
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  width: 100%;
  min-height: 100px;
  border: 2px solid #e5e7eb;
  box-sizing: border-box;

  margin-bottom: 32px;

  border-radius: 8px;

  display: flex;

  background-color: #fff;

  padding: 20px;

  & > div:nth-child(1) {
    margin-right: 10px;
  }

  & > div:nth-child(2) {
    min-height: 100px;
    height: 100%;
    box-sizing: border-box;

    & > div:nth-child(1) {
      width: 100%;
      height: 22px;

      display: flex;

      & > h3 {
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: -0.2px;
      }
      & > span {
        color: #a0a6b1;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: -0.2px;
      }
    }
    & > div:nth-child(2) {
      margin-top: 16px;
      margin-bottom: 8px;

      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: -0.2px;

      & > span {
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: -0.2px;
        color: #a0a6b1;
      }
    }
    & > div:nth-child(3) {
      & > div {
        height: 44px;

        display: flex;
        align-items: center;

        margin-bottom: 8px;

        & > h4 {
          font-size: 16px;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: -0.2px;
        }
        & > span {
          font-size: 16px;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: -0.2px;

          & > span {
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;

            color: #fd91a5;
            margin-right: 24px;
          }
        }
        & > div {
          width: 44px;
          height: 44px;
          border-radius: 8px;
          background-color: #f4f5f7;
          margin-right: 24px;
        }
      }
    }
  }
  & > div:nth-child(3) {
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Container2 = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 2px solid #f14262;
  box-sizing: border-box;
  margin-bottom: 32px;

  display: flex;
  padding: 16px 32px;
  padding-left: 64px;
  flex-direction: column;
  justify-content: flex-end;
  gap: 16px;

  & > :first-child {
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 24px;

    & > div {
      display: flex;
      align-items: center;
      gap: 24px;
    }
  }

  & > :last-child {
    display: flex;
    align-self: flex-end;
    & > :first-child {
      display: flex;
      width: 92px;
      height: 44px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;

      color: #000000;
      font-size: 14px;
      font-weight: 500;
    }
    & > :last-child {
      display: flex;
      width: 92px;
      height: 44px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: #f14262;

      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
    }
  }
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  & > :first-child {
    color: #f14262;
    font-size: 16px;
    font-weight: 700;
  }
`;

const EventBox = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  & > :nth-child(1) {
    width: 24px;
    height: 24px;
    border: 2px solid #62d4d2;
    box-sizing: border-box;

    border-radius: 4px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > :nth-child(2) {
    padding: 4px 7px;
    border-radius: 4px;
    background: #fada07;

    color: #f9fafb;
    font-size: 12px;
    font-weight: 700;
  }

  & > :nth-child(3) {
    color: #62d4d2;
    font-size: 14px;
    font-weight: 700;
  }
`;

export default MenuItem;
