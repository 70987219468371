import axios from "axios";
import { baseUrl, config } from "./api";
import {
  CreateSalesDataType,
  CreateSalesDataTypeV2,
  Payment,
  SaveSalesDataType,
  SaveSalesDataTypeV2,
} from "../types";

export const ReqPayment = async (data: any) => {
  return await axios.post(
    baseUrl + "/admin/shop/sales/v2/payment/request",
    data,
    config()
  );
};

export const CancelReqPayment = async (bookId: string) => {
  return await axios.post(
    baseUrl + "/admin/shop/sales/v2/payment/request/cancel",
    { bookId: bookId },
    config()
  );
};

export const createSales = async (data: CreateSalesDataType) => {
  return await axios.post(baseUrl + "/admin/shop/sales", data, config());
};

export const createSalesV2 = async (data: CreateSalesDataTypeV2) => {
  return await axios.post(baseUrl + "/admin/shop/sales/v2", data, config());
};

export const saveSales = async (data: SaveSalesDataType) => {
  return await axios.put(baseUrl + "/admin/shop/sales", data, config());
};

export const saveSalesV2 = async (data: SaveSalesDataTypeV2) => {
  return await axios.put(baseUrl + "/admin/shop/sales/v2", data, config());
};

export const saveSalesPaymentV2 = async (data: Payment[]) => {
  return await axios.put(
    baseUrl + "/admin/shop/sales/v2/payment",
    data,
    config()
  );
};

export const getSalesList = async (
  offset: number,
  sortColumn: string,
  sort: string,
  shopId: string,
  dateType: string,
  date: string,
  managerId: string
) => {
  return await axios.get(
    baseUrl + `/admin/shop/sales`,
    config(undefined, {
      limit: 7,
      offset: offset,
      sortColumn: sortColumn,
      sort: sort,
      shopId: shopId,
      dateType: dateType,
      date: date,
      managerId: managerId ? managerId : null,
    })
  );
};

export const getSalesListV2 = async (
  offset: number,
  sortColumn: string,
  sort: string,
  shopId: string,
  dateType: string,
  startDate: string,
  endDate: string,
  managerId: string
) => {
  return await axios.get(
    baseUrl + `/admin/shop/sales/v2`,
    config(undefined, {
      limit: 100,
      offset: offset,
      sortColumn: sortColumn,
      sort: sort,
      shopId: shopId,
      dateType: dateType,
      startDate: startDate,
      endDate: endDate,
      managerId: managerId ? managerId : null,
    })
  );
};

export const getSalesStatistic = async (shopId: string, date: string) => {
  return await axios.get(
    baseUrl + `/admin/shop/sales/statistic?shopId=${shopId}&date=${date}`,
    config()
  );
};

export const getSalesStatisticV2 = async (shopId: string, date: string) => {
  return await axios.get(
    baseUrl + `/admin/shop/sales/v2/statistic?shopId=${shopId}&date=${date}`,
    config()
  );
};

export const getSalesCumstomerList = async (
  offset: number,
  customerId: string
) => {
  return await axios.get(
    baseUrl + `/admin/shop/sales/customer`,
    config(undefined, {
      limit: 8,
      offset: offset,
      customerId: customerId,
    })
  );
};
