import React, { Dispatch, SetStateAction } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { styled } from "styled-components";
import { IoMdClose } from "react-icons/io";
import { ReqApprove } from "../../apis/book";
import { IoImageOutline } from "react-icons/io5";
import { changeCategory } from "../../util/util";
import useSendbirdStateContext from "@sendbird/uikit-react/useSendbirdStateContext";
import sendbirdSelectors, {
  getCreateGroupChannel,
} from "@sendbird/uikit-react/sendbirdSelectors";
import { ShopData } from "../../atoms/atom";
import { useRecoilState } from "recoil";
import { getCustomerOne } from "../../apis/customer";

type propType = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  data: any;
  callback: () => void;
  callback2: (bookId: string) => void;
};

const BookRequestModal = ({
  open,
  setOpen,
  data,
  callback,
  callback2,
}: propType) => {
  console.log("BookRequestModal data:", data);
  const [shopData] = useRecoilState(ShopData);
  const getStatusComponent = (statusText: string) => {
    switch (statusText) {
      case "bookRequest":
        return <Status $backgroundColor="#A0A6B1">확정대기</Status>;
      case "bookComplete":
        return <Status $backgroundColor="#FCA2B3">시술예정</Status>;
      case "nailComplete":
        return <Status $backgroundColor="#3A66EC">시술완료</Status>;
      case "bookCanceled":
        return <Status $backgroundColor="#F14262">시술취소</Status>;
      case "noShow":
        return <Status $backgroundColor="#272F3E">노쇼</Status>;
      default:
        return null;
    }
  };

  const translateBookState = (bookState: string): string => {
    const bookStateTranslations: { [key: string]: string } = {
      duplicate: "중복 예약",
      noManager: "담당 디자이너 부재",
      noTime: "일정 불가",
      shop: "샵 사정",
      noRequirement: "요청 사항 불가",
      customer: "고객 취소",
      noApprove: "샵 미승인",
    };
    return bookStateTranslations[bookState] || "";
  };

  const globalStore = useSendbirdStateContext();
  sendbirdSelectors.getSdk(globalStore);

  const createChannel = getCreateGroupChannel(globalStore);

  const createChannelAndSendMessage = async () => {
    const user = await getCustomerOne(data.customerId).then(
      (res) => res.data.data
    );

    try {
      const params = {
        invitedUserIds: [user.userId, shopData.id],
        name: shopData.name,
        coverUrl:
          "https://storage.finger-princess.com/5071c892-2053-4ac7-b7a8-e7726989f433",
        isDistinct: true,
      };
      const channel = await createChannel(params);
      const messageText = `${data.customerName}님의 ${new Date(
        data.date
      ).toLocaleString("ko-KR", {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour12: true,
        hour: "2-digit",
        minute: "2-digit",
      })} 예약이 승인되었습니다💅\n예약 시간에 맞춰 늦지 않게 와주세요 :)`;

      channel.sendUserMessage({ message: messageText });
    } catch (error) {
      console.error("Error creating channel or sending message:", error);
    }
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "680px",
            height: "860px",
            borderRadius: "16px",
            boxShadow: "4px 4px 4px 0px #00000040",
            boxSizing: "border-box",
            outline: "none",
            backgroundColor: "#F9FAFB",
            zoom: 0.9,
            padding: 0,
            overflow: "hidden",
            display: "flex",
          }}
        >
          <Container>
            <div
              style={{
                width: "100%",
                height: "90px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#ffffff",
              }}
            >
              <h2 style={{ fontSize: "20px", fontWeight: "700" }}>
                신규 예약 요청
              </h2>
              <IoMdClose
                style={{
                  position: "absolute",
                  right: "32px",
                  fontSize: "32px",
                  cursor: "pointer",
                }}
                onClick={() => setOpen(false)}
              />
            </div>
            <div
              style={{
                width: "608px",
                backgroundColor: "#ffffff",
                height: "76px",
                paddingLeft: "30px",
                alignItems: "center",
                display: "flex",
                boxSizing: "border-box",
                borderRadius: "16px",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
              }}
            >
              <Line>
                <Title>예약자</Title>
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    color: "#000000",
                    fontSize: "16px",
                  }}
                >
                  <span>{data.customerName}</span>
                  <span>{data.phoneNumber}</span>
                </div>
              </Line>
            </div>
            <div
              style={{
                width: "608px",
                backgroundColor: "#ffffff",
                height: "163px",
                paddingLeft: "30px",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                boxSizing: "border-box",
                borderRadius: "16px",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
              }}
            >
              <Line>
                <Title>예약 상태</Title>
                <span>{getStatusComponent(data.bookState)}</span>
                <span style={{ fontSize: "16px", color: "#000000" }}>
                  {data.bookState === "bookCanceled"
                    ? translateBookState(data.cancelReason)
                    : ""}
                </span>
              </Line>
              <Line>
                <Title>시술일정</Title>
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    color: "#394150",
                    fontSize: "16px",
                  }}
                >
                  <span style={{ fontSize: "16px", color: "#000000" }}>
                    {new Date(data.date).toLocaleDateString()}
                  </span>
                  <span style={{ fontSize: "16px", color: "#000000" }}>
                    {new Date(data.date).toLocaleTimeString("ko-kr", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                </div>
              </Line>
              <Line>
                <Title>담당자</Title>
                <span style={{ fontSize: "16px", color: "#000000" }}>
                  {data.managerName}
                </span>
              </Line>
            </div>
            <div
              style={{
                width: "608px",
                backgroundColor: "#ffffff",
                paddingLeft: "30px",
                padding: "26px 30px",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                boxSizing: "border-box",
                borderRadius: "16px",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
              }}
            >
              <Line style={{ minHeight: "122px" }}>
                <Title style={{ height: "100%" }}>시술내용</Title>
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    justifyContent: "space-between",
                    width: "calc(100% - 86px)",
                    paddingRight: "32px",
                    boxSizing: "border-box",
                    color: "#394150",
                    fontSize: "16px",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    {data.menuCategory && (
                      <span
                        style={{
                          fontSize: 16,
                          fontWeight: 700,
                          color: "#000000",
                        }}
                      >
                        {`(${changeCategory(data.menuCategory, false)})`}
                      </span>
                    )}
                    <span
                      style={{
                        fontSize: 16,
                        fontWeight: 700,
                        color: "#000000",
                      }}
                    >
                      -{data.menuName}
                    </span>
                    <span
                      style={{
                        fontSize: 16,
                        fontWeight: 700,
                        color: "#000000",
                      }}
                    >
                      -{data.designName ? data.designName : "없음"}
                    </span>
                    <span
                      style={{
                        fontSize: 16,
                        fontWeight: 700,
                        color: "#000000",
                      }}
                    >
                      -
                      {data.optionName.length > 0
                        ? data.optionName.join(",")
                        : "없음"}
                    </span>
                  </div>
                  {data.imageUrl ? (
                    <img
                      alt=""
                      src={data.imageUrl}
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "16px",
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        backgroundColor: "#D3D6DB",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <IoImageOutline
                        style={{ fontSize: "40px", color: "#A0A6B1" }}
                      />
                    </div>
                  )}
                </div>
              </Line>
              <Line>
                <Title>시술금액</Title>
                <span style={{ color: "#000000", fontSize: "16px" }}>
                  {data.totalPrice
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  원
                </span>
              </Line>
              <Line style={{ paddingTop: "12px", height: "auto" }}>
                <Title style={{ height: "100%" }}>요청사항</Title>
                <span
                  style={{
                    color: "#000000",
                    fontSize: "16px",
                    width: "442px",
                    height: "100%",
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {data.requirement ? data.requirement : "없음"}
                </span>
              </Line>
            </div>
            <div
              style={{
                width: "608px",
                backgroundColor: "#ffffff",
                paddingLeft: "30px",
                padding: "16px 30px",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                boxSizing: "border-box",
                borderRadius: "16px",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
              }}
            >
              <Line>
                <Title>예약요청일</Title>
                <span style={{ color: "#000000", fontSize: "16px" }}>
                  {new Date(data.date).toLocaleDateString()}
                </span>
              </Line>
            </div>
            <div
              style={{
                width: "100%",
                height: "44px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "24px",
              }}
            >
              <button
                style={{
                  border: "none",
                  height: "100%",
                  width: "184px",
                  borderRadius: "8px",
                  color: "#A0A6B1",
                  backgroundColor: "#E5E7EB",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpen(false);
                  callback2(data.bookId);
                }}
                className="cancelBtn"
              >
                거절
              </button>
              <button
                style={{
                  border: "none",
                  height: "100%",
                  width: "184px",
                  borderRadius: "8px",
                  color: "#ffffff",
                  backgroundColor: "#FF5D7A",
                  cursor: "pointer",
                }}
                onClick={() => {
                  ReqApprove(data.bookId).then(() => {
                    callback();
                    setOpen(false);

                    createChannelAndSendMessage();
                  });
                }}
                className="submitBtn"
              >
                승인
              </button>
            </div>
          </Container>
        </Box>
      </Modal>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 24px;

  align-items: center;

  & .cancelBtn:hover {
    background-color: #d3d6db !important;
  }

  & .submitBtn:hover {
    background-color: #fd7f96 !important;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 44px;

  padding-left: 24px;
  display: flex;
  align-items: center;

  box-sizing: border-box;

  gap: 12px;
`;

const Title = styled.span`
  width: 86px;
  font-size: 16px;
  font-weight: 700;
`;

const Status = styled.div<{ $backgroundColor: string }>`
  width: 53px;
  height: 22px;
  background-color: ${(props) => props.$backgroundColor};
  color: #ffffff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
`;

export default BookRequestModal;
