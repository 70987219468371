import React, { useState } from "react";
import { color_green, color_midnight, color_rose } from "../../util/color";
import { IoChevronDownOutline } from "react-icons/io5";
import { IoChevronUpOutline } from "react-icons/io5";
import styled from "styled-components";
import TicketMoneyModal from "./TicketMoneyModal";
import TickeDateModal from "./TickeDateModal";

type propType = {
  callback: () => void;
  updateCallback: () => void;
  isShow: boolean;
  data: any;
};

const Ticket = ({ callback, isShow, data, updateCallback }: propType) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#F9FAFB",
        display: isShow ? "flex" : "none",
        flexDirection: "column",
        padding: "16px 24px",
        boxSizing: "border-box",
        borderRadius: "10px",
        marginBottom: isOpen ? "0px" : "24px",
        height: isOpen ? "100%" : "180px",
        transition: "height 0.3s ease",
        gap: "16px",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100px",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              fontSize: "18px",
              fontWeight: "900",
            }}
          >
            {data.name}
          </span>
          <span
            style={{
              fontWeight: "700",
              display: "flex",
              gap: "8px",
              alignItems: "center",
            }}
          >
            {data.balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} 원
          </span>
        </div>
        <div
          style={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <span style={{ fontWeight: "700", fontSize: "14px" }}>유효기간</span>
          <span
            style={{
              fontSize: "14px",
              color: color_rose.rose8,
              display: "flex",
              alignItems: "center",
              gap: "4px",
              cursor: "pointer",
            }}
            onClick={() => setIsModalOpen2(true)}
          >
            {new Date(data.startDate)
              .toLocaleDateString("ko-KR", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              .slice(0, -1)}
            {" ~ "}
            {new Date(data.endDate)
              .toLocaleDateString("ko-KR", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              .slice(0, -1)}
          </span>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "700", fontSize: "14px" }}>
              사용 내역 보기
            </span>
            {isOpen ? (
              <IoChevronUpOutline
                onClick={() => {
                  callback();
                  setIsOpen(false);
                }}
              />
            ) : (
              <IoChevronDownOutline
                onClick={() => {
                  callback();
                  setIsOpen(true);
                }}
              />
            )}
          </div>
        </div>
      </div>
      {isOpen && (
        <>
          <HistoryBox>
            {data.ticketHistory.map(
              (item: {
                charge: number;
                balance: number;
                title: string;
                salesId: string | undefined;
                createdAt: string;
              }) => (
                <div
                  style={{
                    width: "100%",
                    height: "60px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <div
                      style={{
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        backgroundColor: color_rose.rose8,
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <span style={{ fontSize: "14px" }}>
                        {new Date(item.createdAt)
                          .toLocaleDateString("ko-KR", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          })
                          .slice(0, -1)}
                      </span>
                      <span style={{ fontSize: "14px" }}>{item.title}</span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      gap: "8px",
                    }}
                  >
                    <span style={{ fontSize: "16px", fontWeight: "700" }}>
                      {item.charge > 0 ? "+" : ""}
                      {item.charge
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      원
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        color: color_midnight.midnight5,
                      }}
                    >
                      {item.balance
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      원
                    </span>
                  </div>
                </div>
              )
            )}
          </HistoryBox>
        </>
      )}
      {isModalOpen && (
        <TicketMoneyModal
          updateCallback={updateCallback}
          setIsModalOpen={setIsModalOpen}
          data={data}
        />
      )}
      {isModalOpen2 && (
        <TickeDateModal
          updateCallback={updateCallback}
          setIsModalOpen={setIsModalOpen2}
          data={data}
        />
      )}
      <button
        style={{
          border: "none",
          backgroundColor: color_green.green6,
          width: "100%",
          height: "36px",
          borderRadius: "8px",
          padding: "0px",
          color: "#ffffff",
          fontSize: "14px",
        }}
        onClick={() => setIsModalOpen(true)}
      >
        수정
      </button>
    </div>
  );
};

const HistoryBox = styled.div`
  width: 100%;
  height: 100%;

  max-height: 520px;

  overflow-y: scroll;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default Ticket;
