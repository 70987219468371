import axios from "axios";
import {
  EmailOverlapType,
  FindIdType,
  InitPwType,
  LoginType,
  OtpAuthType,
  OtpType,
} from "../types";
import { baseUrl, config } from "./api";

axios.defaults.withCredentials = true;

// Login & auth api

// 로그인
export const login = async (data: LoginType) => {
  return await axios.post(baseUrl + "/member/token/shop", data, config());
};

// 2차 비번 체크 로그인
export const checkSecondPw = async (secondPasswd: string) => {
  return await axios.post(
    baseUrl + "/member/user/shop/secondPasswd/check",
    { secondPasswd: secondPasswd },
    config()
  );
};

export const saveSecondPw = async (secondPasswd: string) => {
  return await axios.put(
    baseUrl + "/member/user/shop/secondPasswd",
    { secondPasswd: secondPasswd },
    config()
  );
};

// 임시 토큰 발급
export const getTempToken = async () => {
  return await axios.post(
    baseUrl + "/member/token/temp",
    { deviceId: "votreNaeil" + Math.random().toString(36).substr(2, 8) },
    config()
  );
};

// 임시 토큰 발급
export const getAuthMessage = async (id: string, tempToken: string) => {
  return await axios.post(
    baseUrl + "/member/user/shop/message",
    {
      id: id,
      token: tempToken,
    },
    config()
  );
};

export const MessageCertification = async (
  number: string,
  tempToken: string
) => {
  return await axios.post(
    baseUrl + "/member/user/shop/message/certification",
    {
      number: number,
      token: tempToken,
    },
    config()
  );
};

export const ResetPw = async (
  id: string,
  passwd: string,
  tempToken: string
) => {
  return await axios.post(
    baseUrl + "/member/user/shop/reset/passwd",
    {
      id: id,
      passwd: passwd,
      token: tempToken,
    },
    config()
  );
};

// 이메일 중복 체크
export const isEmailOverlap = async (data: EmailOverlapType) => {
  return await axios.post(baseUrl + "/member/user/overlap", data, config());
};

// otp 생성 요청
export const getOTP = async (data: OtpType) => {
  return await axios.post(baseUrl + "/member/message", data, config());
};

// otp 인증 요청
export const OtpCertification = async (data: OtpAuthType) => {
  return await axios.post(
    baseUrl + "/member/message/certification",
    data,
    config()
  );
};

// 비밀번호 초기화
export const InitPassword = async (data: InitPwType) => {
  return await axios.post(baseUrl + "/member/user/passwd", data, config());
};

// id 찾기
export const FindId = async (data: FindIdType) => {
  return await axios.post(baseUrl + "/member/user/email", data, config());
};

// 억세스 토큰 요청
export const getAccessToken = async (refreshToken: string) => {
  return await axios.post(
    baseUrl + "/member/token/access",
    { refreshToken: refreshToken },
    config()
  );
};

export const getUserData = async () => {
  const id = localStorage.getItem("userId");
  return await axios.get(baseUrl + `/member/user/shop?id=${id}`, config());
};

export const checkPw = async (pw: string) => {
  return await axios.post(
    baseUrl + "/member/user/shop/passwd/check",
    { passwd: pw },
    config()
  );
};

export const changePw = async (pw: string) => {
  return await axios.put(
    baseUrl + "/member/user/shop/passwd",
    { passwd: pw },
    config()
  );
};

export const instaAuth = async () => {
  return await axios.get(baseUrl + "/member/instagram/authorize", config());
};

export const getNewAccessToken = async (refreshToken: string) => {
  return await axios.post(
    baseUrl + "/member/token/shop/access",
    { refreshToken: refreshToken, loginType: "web" },
    config()
  );
};

export const saveNewAccessToken = () => {
  const refreshToken = localStorage.getItem("refreshToken");
  console.log(refreshToken);

  getNewAccessToken(refreshToken!)
    .then((res) => {
      console.log(res.data.accessToken);
      localStorage.setItem("accessToken", res.data.accessToken);
      window.location.reload();
    })
    .catch((e) => {
      console.log("refresh_Token issue");

      window.location.href = "/";
    });
};

export const refreshInstaToken = async () => {
  return await axios.get(
    baseUrl + "/member/instagram/refreshAccessToken",
    config()
  );
};

export const resetInsta = async (shopId: string) => {
  return await axios.put(
    baseUrl + "/member/instagram/reset",
    { shopId: shopId },
    config()
  );
};

export const oauthLogin = async (
  client_id: string,
  state: string,
  redirect_uri: string
) => {
  return await axios.get(
    baseUrl +
      `/oauth/authorize?client_id=${client_id}&state=${state}&redirect_uri=${redirect_uri}`,
    config()
  );
};
