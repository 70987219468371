import React, { Dispatch, SetStateAction, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { styled } from "styled-components";
import { IoMdClose } from "react-icons/io";

type propType = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  list: string[];
  setList: Dispatch<SetStateAction<string[]>>;
};

const localTable = {
  서울: [
    "강남",
    "강동",
    "강서",
    "건대",
    "관악",
    "구로",
    "노원",
    "동작",
    "마포",
    "서초",
    "성수",
    "성북",
    "송파",
    "왕십리",
    "용산",
    "이태원",
    "잠실",
    "종로",
    "중구",
    "중랑",
    "합정",
    "한남",
    "홍대",
    "영등포",
    "여의도",
  ],
  경기: [
    "가평",
    "고양",
    "과천",
    "광명",
    "광주",
    "구리",
    "군포",
    "김포",
    "남양주",
    "동두천",
    "부천",
    "성남",
    "수원",
    "시흥",
    "안산",
    "안성",
    "안양",
    "양주",
    "양평",
    "여주",
    "오산",
    "용인",
    "의왕",
    "의정부",
    "이천",
    "파주",
    "평택",
    "포천",
    "하남",
    "화성",
  ],
  인천: [
    "중구",
    "동구",
    "미추홀구",
    "연수구",
    "남동구",
    "부평구",
    "계양구",
    "서구",
    "강화군",
    "웅진군",
  ],
  대전: ["중구", "서구", "동구", "대덕구", "유성구"],
  대구: [
    "중구",
    "동구",
    "서구",
    "남구",
    "북구",
    "수성구",
    "달서구",
    "달성군",
    "군위군",
  ],
  울산: ["중구", "남구", "동구", "북구", "울주군"],
  부산: [
    "강서구",
    "북구",
    "사상구",
    "사하구",
    "금정구",
    "동래구",
    "연제구",
    "부산진구",
    "해운대구",
    "수영구",
    "남구",
    "동구",
    "중구",
    "서구",
    "영도구",
    "기장군",
  ],
  광주: ["광산구", "동구", "서부", "북구", "남구"],
  충북: ["청주", "충주", "제천/단양", "진천/음성/증평", "보은/옥천/괴산/영동"],
  "충남/세종": [
    "천안",
    "공주/보령/부여/서천/청양",
    "아산",
    "서산/홍성/예산/태안",
    "논산/계룡/금산",
    "당진",
    "세종",
  ],
  전남: [
    "여수",
    "순천/광양",
    "목포",
    "무안/영암/신안",
    "나주/함평/영광/장성",
    "담양/곡성/화순/구례",
    "해남/완도/진도/강진/장흥/보성/고흥",
  ],
  경북: [
    "포항/울릉도",
    "경주",
    "김천/칠곡/성주/고령",
    "구미",
    "안동/의성",
    "문경/상주/영주/예천/봉화",
    "경산",
    "울진/영덕/청송/영양",
    "영천/청도",
  ],
  경남: [
    "창원",
    "김해",
    "양산/밀양",
    "진주",
    "거제/통영/고성",
    "사천/남해",
    "거창/함안/창녕/합천/의령",
    "하동/산청/함양",
  ],
  제주: [],
  강원: [
    "춘천",
    "원주",
    "강릉",
    "동해/삼척/태백",
    "속초/고성/양양",
    "홍천/횡성/평창/영월/정선",
    "화천/철원/인제/양구",
  ],
  전북: [
    "전주/완주",
    "익산",
    "군산",
    "정읍/부안/김제/고창",
    "남원/임실/순창/무주/진안/장수",
  ],
};

const LocalSelModal = ({ open, setOpen, list, setList }: propType) => {
  const [selBigLocal, setSelBigLocal] =
    useState<keyof typeof localTable>("서울");
  const [selectedLocals, setSelectedLocals] = useState<string[]>(list);

  const handleLocalClick = (item: string) => {
    const formattedItem =
      selBigLocal === "서울" || selBigLocal === "경기"
        ? item
        : `${item}>${selBigLocal}`;

    if (selectedLocals.includes(formattedItem)) {
      setSelectedLocals(
        selectedLocals.filter((local) => local !== formattedItem)
      );
    } else if (selectedLocals.length < 5) {
      setSelectedLocals([...selectedLocals, formattedItem]);
    }
  };

  const handleSave = () => {
    setList(selectedLocals);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "660px",
          height: "520px",
          borderRadius: "16px",
          boxShadow: "4px 4px 4px 0px #00000040",
          boxSizing: "border-box",
          backgroundColor: "#F9FAFB",
          overflow: "auto",
          zoom: 0.9,
        }}
      >
        <Container>
          <div
            style={{
              width: "100%",
              height: "90px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#ffffff",
            }}
          >
            <h2 style={{ fontSize: "20px", fontWeight: "700" }}>지역 추가</h2>
            <IoMdClose
              style={{
                position: "absolute",
                right: "32px",
                fontSize: "32px",
                cursor: "pointer",
              }}
              onClick={() => setOpen(false)}
            />
            <MiniNoti style={{ position: "absolute", top: "60px" }}>
              지역 최대 5개 추가 가능
            </MiniNoti>
          </div>
          <MenuBox>
            <FilterContainer>
              <Label>
                지역 선택
                <select
                  value={selBigLocal}
                  onChange={(e) =>
                    setSelBigLocal(e.target.value as keyof typeof localTable)
                  }
                  style={{
                    padding: "8px 16px",
                    borderRadius: "25px",
                    border: "1px solid #ccc",
                    marginLeft: "8px",
                  }}
                >
                  {Object.keys(localTable).map((item, idx) => (
                    <option key={idx} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </Label>
            </FilterContainer>
          </MenuBox>
          <LocalBox>
            {localTable[selBigLocal].map((item, idx) => (
              <SmallLocalBtn
                key={idx}
                onClick={() => handleLocalClick(item)}
                style={{
                  backgroundColor: selectedLocals.includes(
                    selBigLocal === "서울" || selBigLocal === "경기"
                      ? item
                      : `${item}>${selBigLocal}`
                  )
                    ? "#D8F5F5"
                    : "#F9FAFB",
                }}
              >
                {item}
              </SmallLocalBtn>
            ))}
          </LocalBox>
          <div
            style={{
              width: "100%",
              display: "flex",
              gap: "24px",
              justifyContent: "center",
              paddingBottom: "10px",
            }}
          >
            <EditBtn onClick={handleSave}>저장</EditBtn>
          </div>
        </Container>
      </Box>
    </Modal>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;

const MiniNoti = styled.span`
  color: #fd91a5;
  font-size: 12px;
  font-weight: 700;
  line-height: 140%;

  align-self: flex-end;
`;

const MenuBox = styled.div`
  width: 90%;
  gap: 32px;

  display: flex;
  flex-direction: column;

  background-color: #ffffff;
  border-radius: 16px;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #181e2d;
`;

const LocalBox = styled.div`
  width: 90%;
  height: 300px;
  background-color: #ffffff;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 16px;

  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f14262;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #e5e7eb;
    border-radius: 8px;
  }

  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-content: flex-start;
`;

const SmallLocalBtn = styled.div`
  display: flex;
  height: 36px;
  padding: 10px 16px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;

  border-radius: 25px;
  background: #f9fafb;

  color: #181e2d;
  font-size: 14px;
  font-weight: 500;
`;

const EditBtn = styled.button`
  width: 92px;
  height: 44px;

  border: none;
  border-radius: 8px;

  background-color: #f14262;

  color: #ffffff;
`;

export default LocalSelModal;
