import { css, keyframes, styled } from "styled-components";

type propType = {
  showFailMessage: boolean;
  text: string;
  left?: string;
  bottom?: string;
};

const MessageModal = (props: propType) => {
  console.log(props.bottom);
  return (
    <Message
      $visible={props.showFailMessage}
      $left={props.left}
      $bottom={props.bottom}
    >
      {props.text}
    </Message>
  );
};

const Message = styled.div<{
  $visible: boolean;
  $left?: string;
  $bottom?: string;
}>`
  background-color: #62d4d2;
  width: 322px;
  height: 44px;
  border-radius: 25px;

  position: absolute;
  left: ${(props) => (props.$left ? props.$left : "39px")};
  bottom: ${(props) => (props.$bottom ? props.$bottom : "-64px")};

  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  color: #ffffff;

  box-shadow: 0px 4px 4px 0px #00000040;

  transition: all 0.3s ease-out;

  ${(props) => messageAni(props.$visible)}
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const messageAni = (visible: boolean) => css`
  visibility: ${visible ? "visible" : "hidden"};
  z-index: 15;
  animation: ${visible ? fadeIn : fadeOut} 0.15s ease-out;
  transition: visibility 0.15s ease-out;
`;

export default MessageModal;
