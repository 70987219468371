import { Modal } from "@mui/material";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

type propType = {
  open: boolean;
};

const WaitingModal = ({ open }: propType) => {
  return (
    <>
      <Modal
        open={open}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <CircularProgress />
      </Modal>
    </>
  );
};

export default WaitingModal;
