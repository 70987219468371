import { styled } from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import { RegisterDataAtom, registerStepAtom } from "../../atoms/atom";
import InputBox from "../utility/InputBox";
import { useState } from "react";
import MessageModal from "../utility/MessageModal";
import { isEmailOverlap } from "../../apis/auth";

const RegisterStep4 = () => {
  const [email, setEmail] = useState("");
  const [check, setCheck] = useState(false);
  const setStep = useSetRecoilState(registerStepAtom);
  const [data, setData] = useRecoilState(RegisterDataAtom);

  const [showFailMessage, setShowFailMessage] = useState(false);

  const nextHandler = () => {
    setStep(5);
  };

  const dupleCheck = () => {
    isEmailOverlap({ email: email, token: data.token })
      .then((res) => {
        if (res.data.isOverlap) {
          setShowFailMessage(true);
          setTimeout(() => setShowFailMessage(false), 1000);
        } else {
          setData((old) => ({
            email: email,
            passwd: old.passwd,
            name: old.name,
            phone: old.phone,
            isAdmin: old.isAdmin,
            tos: old.tos,
            token: old.token,
            shopname: old.shopname,
            address: old.address,
          }));

          setCheck(true);
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <ResultContainer>
        <InputContainer>
          <Label>E-mail</Label>
          <EmailAuth>
            <InputBox
              placeholder="이메일을 입력해주세요."
              type="text"
              width={184}
              setValue={setEmail}
              value={email}
              disabled={check}
            />
            <AuthBtn disabled={check ? true : false} onClick={dupleCheck}>
              중복확인
            </AuthBtn>
          </EmailAuth>
        </InputContainer>
      </ResultContainer>
      <OkBtn disabled={!check ? true : false} onClick={() => nextHandler()}>
        다음
      </OkBtn>
      <MessageModal
        showFailMessage={showFailMessage}
        text="입력하신 이메일은 이미 존재합니다."
      />
    </>
  );
};

const InputContainer = styled.div`
  width: 300px;
  height: 82px;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const ResultContainer = styled.div`
  width: 300px;
  height: 300px;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 40px;
  box-sizing: border-box;
`;

const Label = styled.span`
  color: #a0a6b1;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.2px;
  font-weight: 700;
`;

const OkBtn = styled.button<{ disabled: boolean }>`
  border: none;
  width: 300px;
  height: 44px;
  border-radius: 8px;
  color: #f9fafb;
  background-color: ${(props) => (!props.disabled ? "#ff5d7a" : "#d3d6db")};

  font-family: "Noto Sans KR", sans-serif;

  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: -0.2px;
`;
const AuthBtn = styled.button<{ disabled: boolean }>`
  border: none;
  width: 104px;
  height: 44px;
  border-radius: 8px;
  color: #f9fafb;
  background-color: ${(props) => (!props.disabled ? "#ff5d7a" : "#d3d6db")};

  font-family: "Noto Sans KR", sans-serif;

  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
`;

const EmailAuth = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default RegisterStep4;
