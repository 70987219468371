import React from "react";
import Chart from "react-apexcharts";
import { useEffect, useState } from "react";
import { styled } from "styled-components";
import {
  ChartDataDay,
  ChartDataMonth,
  ChartDataWeek,
} from "../../dumyData/data";

type propType = {
  dataType: number;
  width?: number;
  height?: number;
};

const CountChart2 = ({ dataType, width, height }: propType) => {
  const [selecedIndex, setSelecedIndex] = useState<number>(-1);
  useEffect(() => {}, [selecedIndex]);

  const data =
    dataType === 1
      ? ChartDataDay
      : dataType === 2
      ? ChartDataWeek
      : ChartDataMonth;

  return (
    <Container>
      <Chart
        options={{
          stroke: {
            curve: "smooth",
            width: 3,
          },
          chart: {
            id: "date",
            toolbar: {
              show: false,
            },
            background: "#ffffff",
            events: {
              mouseMove: (e, chart, options) =>
                setSelecedIndex(options.dataPointIndex),
            },
            selection: {
              enabled: false,
            },
            zoom: {
              enabled: false,
            },
            brush: {
              enabled: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: data.xData,
          },
          colors: ["#F14262", "#5856CE", "#FADA07"],
          grid: {
            show: true,
          },
          yaxis: {
            show: true,
            min: 0,
            max: Math.max(...data.yData) * 2,
          },
          annotations: {
            xaxis: [
              {
                x: data.xData[selecedIndex],
                x2: data.xData[selecedIndex + 1],
                fillColor: "#FD91A5",
                opacity: 0.4,
                offsetX: -37,
              },
            ],
          },
        }}
        series={[
          {
            name: "담당자1",
            data: data.yData,
          },
          {
            name: "담당자2",
            data: data.yData.map((i) => i + 10),
          },
          {
            name: "담당자3",
            data: data.yData.map((i) => (i * 3) / 2),
          },
        ]}
        type="line"
        width={width ? `${width}` : "760px"}
        height={height ? `${height}` : "206px"}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: 5px;
  border: 1px solid black;
`;

export default CountChart2;
