import React, { Dispatch, SetStateAction, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { styled } from "styled-components";
import { IoMdClose } from "react-icons/io";
import * as hangul from "hangul-js";
import { color_midnight } from "../../util/color";

type propType = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  list: string[];
  setList: Dispatch<SetStateAction<string[]>>;
};

const subwayData = {
  "1호선": [
    "가능",
    "가산디지털단지",
    "간석",
    "개봉",
    "관악",
    "광명",
    "광운대",
    "구로",
    "구일",
    "군포",
    "금정",
    "금천구청",
    "남영",
    "노량진",
    "녹양",
    "녹천",
    "당정",
    "대방",
    "덕계",
    "덕정",
    "도봉",
    "도봉산",
    "도원",
    "도화",
    "독산",
    "동대문",
    "동두천",
    "동두천중앙",
    "동묘앞",
    "동암",
    "동인천",
    "두정",
    "망월사",
    "명학",
    "방학",
    "배방",
    "백운",
    "병점",
    "보산",
    "봉명",
    "부개",
    "부천",
    "부평",
    "서동탄",
    "서울역",
    "서정리",
    "석계",
    "석수",
    "성균관대",
    "성환",
    "세류",
    "세마",
    "소사",
    "소요산",
    "송내",
    "송탄",
    "수원",
    "시청",
    "신길",
    "신도림",
    "신설동",
    "신이문",
    "신창",
    "쌍용",
    "아산",
    "안양",
    "양주",
    "역곡",
    "영등포",
    "오류동",
    "오산",
    "오산대",
    "온수",
    "온양온천",
    "외대앞",
    "용산",
    "월계",
    "의왕",
    "의정부",
    "인천",
    "제기동",
    "제물포",
    "종각",
    "종로3가",
    "종로5가",
    "주안",
    "중동",
    "지행",
    "직산",
    "진위",
    "창동",
    "천안",
    "청량리",
    "탕정",
    "평택",
    "평택지제",
    "화서",
    "회기",
    "회룡",
  ],
  "2호선": [
    "한양대",
    "신도림",
    "아현",
    "봉천",
    "충정로",
    "용답",
    "문래",
    "잠실새내",
    "신림",
    "종합운동장",
    "삼성",
    "신대방",
    "영등포구청",
    "양천구청",
    "을지로4가",
    "뚝섬",
    "신답",
    "을지로입구",
    "사당",
    "을지로3가",
    "성수",
    "건대입구",
    "당산",
    "신정네거리",
    "구의",
    "합정",
    "용두",
    "구로디지털단지",
    "홍대입구",
    "신촌",
    "이대",
    "신설동",
    "도림천",
    "까치산",
    "시청",
    "동대문역사문화공원",
    "신당",
    "상왕십리",
    "강변",
    "잠실나루",
    "잠실",
    "선릉",
    "역삼",
    "강남",
    "교대",
    "서초",
    "방배",
    "낙성대",
    "서울대입구",
    "왕십리",
    "대림",
  ],
  "3호선": [
    "안국",
    "충무로",
    "매봉",
    "동대입구",
    "대치",
    "학여울",
    "옥수",
    "압구정",
    "종로3가",
    "신사",
    "경찰병원",
    "잠원",
    "대청",
    "오금",
    "고속터미널",
    "교대",
    "남부터미널",
    "일원",
    "수서",
    "가락시장",
    "지축",
    "구파발",
    "연신내",
    "불광",
    "녹번",
    "홍제",
    "무악재",
    "독립문",
    "경복궁",
    "을지로3가",
    "금호",
    "약수",
    "도곡",
    "양재",
    "원흥",
    "삼송",
    "화정",
    "마두",
    "대화",
    "대곡",
    "정발산",
    "원당",
    "주엽",
    "백석",
  ],
  "4호선": [
    "명동",
    "회현",
    "수유",
    "노원",
    "동작",
    "총신대입구",
    "미아",
    "숙대입구",
    "혜화",
    "동대문",
    "서울역",
    "사당",
    "남태령",
    "창동",
    "동대문역사문화공원",
    "미아사거리",
    "충무로",
    "삼각지",
    "신용산",
    "진접",
    "오남",
    "별내별가람",
    "당고개",
    "상계",
    "쌍문",
    "길음",
    "성신여대입구",
    "한성대입구",
    "이촌",
    "선바위",
    "경마공원",
    "대공원",
    "과천",
    "정부과천청사",
    "인덕원",
    "평촌",
    "금정",
    "산본",
    "대야미",
    "반월",
    "상록수",
    "한대앞",
    "중앙",
    "고잔",
    "초지",
    "안산",
    "신길온천",
    "정왕",
    "범계",
    "오이도",
    "수리산",
  ],
  "5호선": [
    "방화",
    "개화산",
    "김포공항",
    "송정",
    "마곡",
    "발산",
    "우장산",
    "화곡",
    "까치산",
    "신정",
    "목동",
    "오목교",
    "양평",
    "영등포구청",
    "영등포시장",
    "신길",
    "여의도",
    "여의나루",
    "마포",
    "공덕",
    "충정로",
    "서대문",
    "광화문",
    "종로3가",
    "을지로4가",
    "동대문역사문화공원",
    "청구",
    "신금호",
    "행당",
    "왕십리",
    "마장",
    "장한평",
    "군자",
    "광나루",
    "천호",
    "강동",
    "굽은다리",
    "명일",
    "고덕",
    "둔촌동",
    "올림픽공원",
    "아차산",
    "길동",
    "방이",
    "개롱",
    "거여",
    "마천",
    "하남시청",
    "하남검단산",
    "답십리",
    "하남풍산",
    "애오개",
    "상일동",
    "오금",
    "강일",
    "미사",
  ],
  "6호선": [
    "응암",
    "역촌",
    "불광",
    "독바위",
    "연신내",
    "구산",
    "새절",
    "증산",
    "디지털미디어시티",
    "월드컵경기장",
    "마포구청",
    "망원",
    "합정",
    "상수",
    "광흥창",
    "대흥",
    "공덕",
    "효창공원앞",
    "삼각지",
    "녹사평",
    "이태원",
    "한강진",
    "버티고개",
    "약수",
    "신당",
    "동묘앞",
    "창신",
    "보문",
    "안암",
    "고려대",
    "월곡",
    "상월곡",
    "돌곶이",
    "석계",
    "태릉입구",
    "봉화산",
    "신내",
    "화랑대",
    "청구",
  ],
  "7호선": [
    "장암",
    "도봉산",
    "수락산",
    "마들",
    "노원",
    "중계",
    "하계",
    "공릉",
    "태릉입구",
    "먹골",
    "중화",
    "상봉",
    "면목",
    "중곡",
    "군자",
    "어린이대공원",
    "건대입구",
    "뚝섬유원지",
    "청담",
    "강남구청",
    "논현",
    "고속터미널",
    "내방",
    "이수",
    "남성",
    "숭실대입구",
    "상도",
    "장승배기",
    "신대방삼거리",
    "보라매",
    "신풍",
    "대림",
    "용마산",
    "남구로",
    "가산디지털단지",
    "광명사거리",
    "천왕",
    "온수",
    "사가정",
    "반포",
    "학동",
    "철산",
    "까치울",
    "부천종합운동장",
    "춘의",
    "신중동",
    "부천시청",
    "상동",
    "삼산체육관",
    "굴포천",
    "부평구청",
    "석남",
    "산곡",
  ],
  "8호선": [
    "암사",
    "천호",
    "강동구청",
    "몽촌토성",
    "잠실",
    "석촌",
    "송파",
    "문정",
    "장지",
    "복정",
    "산성",
    "남한산성입구",
    "단대오거리",
    "신흥",
    "수진",
    "모란",
    "남위례",
    "가락시장",
    "암사역사공원",
    "장자호수공원",
    "구리",
    "동구릉",
    "다산",
    "별내",
  ],
  "9호선": [
    "김포공항",
    "공항시장",
    "신방화",
    "마곡나루",
    "양천향교",
    "가양",
    "증미",
    "등촌",
    "염창",
    "신목동",
    "선유도",
    "국회의사당",
    "여의도",
    "샛강",
    "노량진",
    "흑석",
    "동작",
    "구반포",
    "신반포",
    "사평",
    "신논현",
    "언주",
    "선정릉",
    "삼성중앙",
    "종합운동장",
    "올림픽공원",
    "둔촌오륜",
    "중앙보훈병원",
    "개화",
    "당산",
    "노들",
    "봉은사",
    "석촌고분",
    "송파나루",
    "한성백제",
    "고속터미널",
    "석촌",
    "삼전",
  ],
  경강선: [
    "이매",
    "삼동",
    "경기광주",
    "초월",
    "곤지암",
    "신둔도예촌",
    "세종대왕릉",
    "여주",
    "부발",
    "판교",
    "이천",
  ],
  경의선: [
    "곡산",
    "청량리",
    "이촌",
    "한남",
    "옥수",
    "응봉",
    "회기",
    "중랑",
    "상봉",
    "망우",
    "양원",
    "구리",
    "도농",
    "양정",
    "덕소",
    "도심",
    "팔당",
    "운길산",
    "양수",
    "국수",
    "아신",
    "오빈",
    "양평",
    "원덕",
    "용문",
    "서울역",
    "신촌",
    "효창공원앞",
    "서강대",
    "홍대입구",
    "가좌",
    "디지털미디어시티",
    "강매",
    "백마",
    "풍산",
    "신원",
    "지평",
    "행신",
    "일산",
    "탄현",
    "야당",
    "운정",
    "금릉",
    "금촌",
    "월롱",
    "파주",
    "문산",
    "임진강",
    "운천",
    "왕십리",
    "수색",
    "서빙고",
    "공덕",
    "한국항공대",
    "용산",
    "능곡",
    "대곡",
  ],
  경춘선: [
    "광운대",
    "청량리",
    "회기",
    "중랑",
    "망우",
    "신내",
    "갈매",
    "별내",
    "사릉",
    "금곡",
    "평내호평",
    "대성리",
    "청평",
    "상천",
    "가평",
    "백양리",
    "김유정",
    "남춘천",
    "상봉",
    "천마산",
    "굴봉산",
    "퇴계원",
    "마석",
    "춘천",
    "강촌",
  ],
  공항철도: [
    "청라국제도시",
    "공항화물청사",
    "서울역",
    "계양",
    "검암",
    "운서",
    "공덕",
    "홍대입구",
    "디지털미디어시티",
    "김포공항",
    "인천공항1터미널",
    "인천공항2터미널",
    "영종",
    "마곡나루",
  ],
  김포도시철도: [
    "풍무",
    "고촌",
    "김포공항",
    "양촌",
    "구래",
    "마산",
    "장기",
    "운양",
    "걸포북변",
    "사우",
  ],
  서해선: [
    "곡산",
    "풍산",
    "백마",
    "일산",
    "능곡",
    "대곡",
    "김포공항",
    "부천종합운동장",
    "원종",
    "소사",
    "소새울",
    "시흥대야",
    "신천",
    "신현",
    "시흥시청",
    "시흥능곡",
    "달미",
    "선부",
    "초지",
    "시우",
    "원시",
  ],
  수인분당선: [
    "도곡",
    "구룡",
    "수서",
    "개포동",
    "선릉",
    "왕십리",
    "복정",
    "대모산입구",
    "한티",
    "오이도",
    "한대앞",
    "고잔",
    "초지",
    "안산",
    "신길온천",
    "정왕",
    "청량리",
    "중앙",
    "수원",
    "서울숲",
    "압구정로데오",
    "강남구청",
    "선정릉",
    "가천대",
    "모란",
    "서현",
    "수내",
    "정자",
    "미금",
    "오리",
    "이매",
    "보정",
    "죽전",
    "구성",
    "신갈",
    "상갈",
    "청명",
    "망포",
    "매탄권선",
    "수원시청",
    "매교",
    "고색",
    "어천",
    "야목",
    "달월",
    "월곶",
    "태평",
    "기흥",
    "오목천",
    "소래포구",
    "인천논현",
    "호구포",
    "원인재",
    "연수",
    "송도",
    "신포",
    "인천",
    "야탑",
    "남동인더스파크",
    "인하대",
    "숭의",
    "영통",
    "사리",
  ],
  신림선: [
    "보라매공원",
    "보라매병원",
    "신림",
    "샛강",
    "보라매",
    "서원",
    "대방",
    "서울지방병무청",
    "서울대벤처타운",
    "관악산",
    "당곡",
  ],
  신분당선: [
    "판교",
    "정자",
    "강남",
    "동천",
    "신사",
    "신논현",
    "미금",
    "수지구청",
    "논현",
    "양재",
    "양재시민의숲",
    "청계산입구",
    "성복",
    "상현",
    "광교중앙",
    "광교",
  ],
  용인경전철: [
    "어정",
    "동백",
    "초당",
    "삼가",
    "고진",
    "강남대",
    "지석",
    "시청.용인대",
    "명지대",
    "김량장",
    "기흥",
    "운동장.송담대",
    "보평",
    "둔전",
    "전대.에버랜드",
  ],
  우이신설경전철: [
    "솔밭공원",
    "화계",
    "삼양",
    "솔샘",
    "북한산우이",
    "4.19민주묘지",
    "가오리",
    "삼양사거리",
    "북한산보국문",
    "정릉",
    "성신여대입구",
    "보문",
    "신설동",
  ],
  인천선: [
    "계양",
    "귤현",
    "박촌",
    "임학",
    "계산",
    "경인교대입구",
    "작전",
    "갈산",
    "부평구청",
    "부평시장",
    "부평",
    "동수",
    "부평삼거리",
    "간석오거리",
    "인천시청",
    "예술회관",
    "인천터미널",
    "문학경기장",
    "선학",
    "신연수",
    "동춘",
    "캠퍼스타운",
    "지식정보단지",
    "인천대입구",
    "센트럴파크",
    "국제업무지구",
    "원인재",
    "동막",
    "송도달빛축제공원",
    "테크노파크",
  ],
  인천2호선: [
    "계양",
    "귤현",
    "박촌",
    "임학",
    "계산",
    "경인교대입구",
    "작전",
    "갈산",
    "부평구청",
    "부평시장",
    "부평",
    "동수",
    "부평삼거리",
    "간석오거리",
    "인천시청",
    "예술회관",
    "인천터미널",
    "문학경기장",
    "선학",
    "신연수",
    "동춘",
    "캠퍼스타운",
    "지식정보단지",
    "인천대입구",
    "센트럴파크",
    "국제업무지구",
    "검단사거리",
    "마전",
    "완정",
    "독정",
    "검암",
    "검바위",
    "아시아드경기장",
    "가정",
    "가정중앙시장",
    "석남",
    "서부여성회관",
    "인천가좌",
    "원인재",
    "동막",
    "송도달빛축제공원",
    "가재울",
    "주안국가산단",
    "시민공원",
    "석바위시장",
    "인천시청",
    "석천사거리",
    "모래내시장",
    "남동구청",
    "인천대공원",
    "운연",
    "테크노파크",
    "왕길",
    "서구청",
    "주안",
    "만수",
    "검단오류",
  ],
  "대구 도시철도 1호선": [
    "대곡",
    "각산",
    "교대",
    "대구역",
    "대구은행",
    "명덕",
    "동촌",
    "동대구역",
    "두류",
    "명덕",
    "명곡",
    "반야월",
    "반월당",
    "방촌",
    "범어",
    "설화명곡",
    "성서산업단지",
    "송현",
    "신기",
    "신천",
    "아양교",
    "안심",
    "안지랑",
    "어린이회관",
    "연호",
    "영대병원",
    "월배",
    "월촌",
    "율하",
    "진천",
    "중앙로",
    "칠성시장",
    "현충로",
    "화원",
    "해안",
  ],
  "대구 도시철도 2호선": [
    "담티",
    "경대병원",
    "계명대",
    "강창",
    "대명",
    "대실",
    "문양",
    "용산",
    "사월",
    "성서산업단지",
    "수성구청",
    "반고개",
    "죽전",
    "만촌",
    "이곡",
    "용계",
    "용산",
    "임당",
    "청라언덕",
    "대구은행",
    "대구역",
    "반야월",
    "반월당",
    "방촌",
    "서문시장",
    "연호",
    "영남대",
    "정평",
    "신매",
  ],
  "대구 도시철도 3호선": [
    "달성공원",
    "북구청",
    "건들바위",
    "공단",
    "구암",
    "칠곡경대병원",
    "용지",
    "팔달",
    "팔달시장",
    "지산",
    "청라언덕",
    "칠곡운암",
    "대봉교",
    "대공원",
    "만평",
    "매천",
    "매천시장",
    "명덕",
    "문양",
    "팔거",
    "어린이회관",
    "원대",
    "태전",
    "학정",
    "황금",
  ],
  "광주도시철도 1호선": [
    "공항",
    "광주송정역",
    "금남로4가",
    "금남로5가",
    "김대중컨벤션센터(마륵)",
    "남광주",
    "녹동",
    "농성",
    "도산",
    "돌고개",
    "문화전당(구도청)",
    "상무",
    "소태",
    "송정공원",
    "쌍촌",
    "양동시장",
    "운천",
    "평동",
    "학동증심사입구",
    "화정",
  ],
  "대전 도시철도 1호선": [
    "지족",
    "오룡",
    "갈마",
    "갑천",
    "구암",
    "노은",
    "대동",
    "대전",
    "반석",
    "서대전네거리",
    "시청",
    "신흥",
    "용문",
    "월드컵경기장",
    "월평",
    "유성온천",
    "정부청사",
    "중구청",
    "중앙로",
    "탄방",
    "판암",
    "현충원",
  ],
  "부산 경량도시철도 4호선": [
    "미남",
    "동래",
    "수안",
    "낙민",
    "충렬사",
    "명장",
    "서동",
    "금사",
    "반여농산물시장",
    "석대",
    "영산대",
    "윗반송",
    "고촌",
    "안평",
  ],
  "부산 도시철도 1호선": [
    "다대포해수욕장",
    "다대포항",
    "낫개",
    "신장림",
    "장림",
    "동매",
    "교대",
    "구서",
    "괴정",
    "범일",
    "범어사",
    "서대신",
    "동대신",
    "토성",
    "자갈치",
    "남포",
    "중앙",
    "부산역",
    "초량",
    "부산진",
    "좌천",
    "서면",
    "부전",
    "양정",
    "시청",
    "연산",
    "동래",
    "명륜",
    "온천장",
    "장전",
    "두실",
    "남산",
    "노포",
    "신평",
    "당리",
    "사하",
    "대티",
  ],
  "부산 도시철도 2호선": [
    "부산대양산캠퍼스",
    "부암",
    "사상",
    "서면",
    "수영",
    "양산",
    "연산",
    "광안",
    "남산정",
    "남양산",
    "냉정",
    "모덕",
    "모라",
    "부산원동역",
    "부산역",
    "금련산",
    "국제금융센터·부산은행",
    "개금",
    "감전",
    "경성대·부경대",
    "금곡",
    "구남",
    "구서",
    "국제금융센터",
    "덕천",
    "동의대",
    "망미",
    "물만골",
    "민락",
    "벡스코",
    "센텀시티",
    "수정",
    "수영",
    "시청",
    "양산",
    "연산",
    "연지공원",
    "장산",
    "중동",
    "지게골",
    "전포",
    "주례",
    "호포",
    "해운대",
    "동백",
    "센텀시티",
    "모덕",
    "모라",
    "덕천",
    "동의대",
    "사직",
  ],
  "부산 도시철도 3호선": [
    "거제",
    "대저",
    "덕천",
    "만덕",
    "물만골",
    "망미",
    "사직",
    "서부산유통지구",
    "숙등",
    "수영",
    "체육공원",
    "종합운동장",
    "대저",
    "남산정",
    "연산",
  ],
  부산김해경전철: [
    "김해대학",
    "김해시청",
    "괘법르네시떼",
    "대사",
    "대저",
    "덕두",
    "등구",
    "봉황",
    "부산김해경전철",
    "사상",
    "서부산유통지구",
    "수로왕릉",
    "연지공원",
    "장신대",
    "지내",
    "평강",
  ],
  동해선: [
    "부전역",
    "거제해맞이역",
    "거제역",
    "교대역",
    "동래역",
    "안락역",
    "부산원동역",
    "재송역",
    "센텀역",
    "벡스코역",
    "신해운대역",
    "송정역",
    "오시리아역",
    "기장역",
    "일광역",
    "좌천역",
    "월내역",
    "서생역",
    "남창역",
    "망양역",
    "덕하역",
    "개운포역",
    "태화강역",
  ],
};

const SubwaySelModal = ({ open, setOpen, list, setList }: propType) => {
  const [line, setLine] = useState("");
  const [startWith, setStartWith] = useState("");
  const [selectedSubways, setSelectedSubways] = useState<string[]>(list);

  const handleSubwayClick = (item: string) => {
    if (selectedSubways.includes(item)) {
      setSelectedSubways(selectedSubways.filter((subway) => subway !== item));
    } else if (selectedSubways.length < 5) {
      setSelectedSubways([...selectedSubways, item]);
    }
  };

  const handleSave = () => {
    setList(selectedSubways);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "660px",
          height: "800px",
          borderRadius: "16px",
          boxShadow: "4px 4px 4px 0px #00000040",
          boxSizing: "border-box",
          backgroundColor: "#F9FAFB",
          overflow: "auto",
          zoom: 0.9,
        }}
      >
        <Container>
          <div
            style={{
              width: "100%",
              height: "90px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#ffffff",
            }}
          >
            <h2 style={{ fontSize: "20px", fontWeight: "700" }}>
              지하철 역 추가
            </h2>
            <IoMdClose
              style={{
                position: "absolute",
                right: "32px",
                fontSize: "32px",
                cursor: "pointer",
              }}
              onClick={() => setOpen(false)}
            />

            <MiniNoti style={{ position: "absolute", top: "60px" }}>
              지하철 역 최대 5개 추가 가능
            </MiniNoti>
          </div>
          <MenuBox>
            <FilterContainer>
              <Label>
                호선 선택
                <select
                  value={line}
                  onChange={(e) => setLine(e.target.value)}
                  style={{
                    padding: "8px 16px",
                    borderRadius: "25px",
                    border: "1px solid #ccc",
                    marginLeft: "8px",
                  }}
                >
                  <option value="">전체</option>
                  {Object.keys(subwayData).map((item, idx) => (
                    <option key={idx} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </Label>

              <Label>
                초성 선택
                <select
                  value={startWith}
                  onChange={(e) => setStartWith(e.target.value)}
                  style={{
                    padding: "8px 16px",
                    borderRadius: "25px",
                    border: "1px solid #ccc",
                    marginLeft: "8px",
                  }}
                >
                  <option value="">전체</option>
                  {[
                    "ㄱ",
                    "ㄴ",
                    "ㄷ",
                    "ㄹ",
                    "ㅁ",
                    "ㅂ",
                    "ㅅ",
                    "ㅇ",
                    "ㅈ",
                    "ㅊ",
                    "ㅋ",
                    "ㅌ",
                    "ㅍ",
                    "ㅎ",
                  ].map((item, idx) => (
                    <option key={idx} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </Label>
            </FilterContainer>
          </MenuBox>
          <SubwayBox>
            {line !== "" && startWith !== ""
              ? subwayData[line as keyof typeof subwayData]
                  .filter(
                    (e) => hangul.disassemble(e.charAt(0))[0] === startWith
                  )
                  .map((item, idx) => (
                    <SmallLocalBtn
                      key={idx}
                      onClick={() => handleSubwayClick(item)}
                      style={{
                        backgroundColor: selectedSubways.includes(item)
                          ? "#D8F5F5"
                          : "#F9FAFB",
                      }}
                    >
                      {item}
                    </SmallLocalBtn>
                  ))
              : line !== ""
              ? subwayData[line as keyof typeof subwayData].map((item, idx) => (
                  <SmallLocalBtn
                    key={idx}
                    onClick={() => handleSubwayClick(item)}
                    style={{
                      backgroundColor: selectedSubways.includes(item)
                        ? "#D8F5F5"
                        : "#F9FAFB",
                    }}
                  >
                    {item}
                  </SmallLocalBtn>
                ))
              : startWith !== ""
              ? Object.values(subwayData)
                  .flat()
                  .filter(
                    (e) => hangul.disassemble(e.charAt(0))[0] === startWith
                  )
                  .reduce<string[]>((unique, item) => {
                    if (!unique.includes(item)) {
                      unique.push(item);
                    }
                    return unique;
                  }, [])
                  .map((item, idx) => (
                    <SmallLocalBtn
                      key={idx}
                      onClick={() => handleSubwayClick(item)}
                      style={{
                        backgroundColor: selectedSubways.includes(item)
                          ? "#D8F5F5"
                          : "#F9FAFB",
                      }}
                    >
                      {item}
                    </SmallLocalBtn>
                  ))
              : Object.values(subwayData)
                  .flat()
                  .reduce<string[]>((unique, item) => {
                    if (!unique.includes(item)) {
                      unique.push(item);
                    }
                    return unique;
                  }, [])
                  .map((item, idx) => (
                    <SmallLocalBtn
                      key={idx}
                      onClick={() => handleSubwayClick(item)}
                      style={{
                        backgroundColor: selectedSubways.includes(item)
                          ? "#D8F5F5"
                          : color_midnight.midnight1,
                      }}
                    >
                      {item}
                    </SmallLocalBtn>
                  ))}
          </SubwayBox>
          <div
            style={{
              width: "100%",
              display: "flex",
              gap: "24px",
              justifyContent: "center",
              paddingBottom: "10px",
            }}
          >
            <EditBtn onClick={handleSave}>저장</EditBtn>
          </div>
        </Container>
      </Box>
    </Modal>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;

  & > hr {
    width: 100%;
    color: #e5e7eb;
  }
`;
const MiniNoti = styled.span`
  color: #fd91a5;
  font-size: 12px;
  font-weight: 700;
  line-height: 140%;

  align-self: flex-end;
`;

const MenuBox = styled.div`
  width: 90%;
  gap: 32px;

  display: flex;
  flex-direction: column;

  background-color: #ffffff;
  border-radius: 16px;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #181e2d;
`;

const SmallLocalBtn = styled.div`
  display: flex;
  height: 36px;
  padding: 10px 16px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;

  border-radius: 25px;
  background: #f9fafb;

  color: #181e2d;
  font-size: 14px;
  font-weight: 500;
`;

const SubwayBox = styled.div`
  width: 90%;
  height: 540px;
  background-color: #ffffff;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 16px;

  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f14262;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #e5e7eb;
    border-radius: 8px;
  }

  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-content: flex-start;
`;

const EditBtn = styled.button`
  width: 92px;
  height: 44px;

  border: none;
  border-radius: 8px;

  background-color: #f14262;

  color: #ffffff;
`;

export default SubwaySelModal;
