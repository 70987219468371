import * as React from "react";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

type propType = {
  value?: Date;
  disabled?: boolean;
  setValue?: React.Dispatch<React.SetStateAction<Date>>;
  onChange?: (event: any) => void;
  width?: string;
  height?: string;
};

export default function TimePickers(props: propType) {
  const [open, setOpen] = React.useState(false);

  const [value, setValue] = React.useState<Date>();

  const chageHandler = (event: any) => {
    if (props.setValue) {
      props.setValue(event.toDate());
    }

    if (props.onChange) {
      props.onChange(event);
    }
  };

  React.useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
      <TimePicker
        ampm={false}
        minutesStep={30}
        skipDisabled={true}
        disabled={props.disabled}
        value={value ? dayjs(value) : dayjs(Date.now())}
        onChange={(e) => chageHandler(e)}
        onOpen={() => {
          setOpen(true);
          //const temp = document.getElementsByClassName("MuiPopper-root")[0];
          //console.log(temp);
          //temp.setAttribute("style", "transform: translate3d(0px,0px, 0px)");
        }}
        onClose={() => setOpen(false)}
        sx={{
          width: props.width ? props.width : "184px",
          height: props.height ? props.height : "44px",
          backgroundColor: "#f4f5f7",
          borderRadius: "8px",
          "& >div": { height: "44px" },
          "& fieldset": { border: "none" },
          "& input ": {
            color: open ? "#7FDBD9" : "rgba(0, 0, 0, 0.54)",
            fontFamily: "Noto Sans KR",
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "22px",
            letterSpacing: "-0.2px",
          },
          "& svg ": {
            color: open ? "#7FDBD9" : "rgba(0, 0, 0, 0.54)",
          },
        }}
        slotProps={{
          desktopPaper: {
            sx: {
              "& ul": {
                msOverflowStyle: "none",
                scrollbarWidth: 0,
                overflowY: "scroll",
                "::-webkit-scrollbar": { display: "none" },
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
}
