import { styled } from "styled-components";
import MainLayout from "../../layout/MainLayout";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { RefrashCounterFcm, ShopData } from "../../atoms/atom";
import SideBar from "../../components/home/SideBar";
import PeopleIcon from "@mui/icons-material/People";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MessageIcon from "@mui/icons-material/Message";
import CancelIcon from "@mui/icons-material/Cancel";
import ScheduleItem from "../../components/home/ScheduleItem";
import { getShopData, saveShopData } from "../../apis/shop";
import {
  ReqCancel,
  getBookReqList,
  getVisitorCounts,
  getVisitorCountsWeekly,
} from "../../apis/book";
import NotiModal from "../../components/utility/NotiModal";
import BookRequestModal from "../../components/utility/BookRequestModal";
import MessageListener from "../../components/utility/MessageListener";
import { BookData, VisitorData, VisitorDayData } from "../../types";
import BookCancelModal from "../../components/utility/BookCancelModal";
import WaitingModal from "../../components/utility/WaitingModal";
import {
  color_green,
  color_iris,
  color_midnight,
  color_rose,
} from "../../util/color";
import { getSalesListV2 } from "../../apis/sales";
import SalesHistoryModal from "../../components/utility/SalesHistoryModal";
import BookApprovalBox from "../../components/home/BookApprovalBox";

const Home = () => {
  const [zoom, setZoom] = useState(1.0);

  const [shopData, setShopData] = useRecoilState(ShopData);
  const refrashCounterFcm = useRecoilValue(RefrashCounterFcm);

  const [bookList, setBookList] = useState<BookData[]>([]);
  const [reqList, setReqList] = useState<BookData[]>([]);

  const [openWaiting, setOpenWaiting] = useState(false);
  const [isSaleOpen, setIsSaleOpen] = useState(false);
  const [isNotiOpen, setIsNotiOpen] = useState(false);
  const [isRequestOpen, setIsRequestOpen] = useState(false);

  const [saleData, setSaleData] = useState<BookData>();
  const [approvalData, setApprovalData] = useState<BookData>();

  const [announcement, setAnnouncement] = useState(shopData.announcement);

  const [updateCounter, setUpdateCounter] = useState(0);

  const [visitorData, setVisitorData] = useState<VisitorData>();

  const [visitorData2, setVisitorData2] = useState<VisitorDayData[]>([]);

  const [cancelId, setCancelId] = useState("");

  useEffect(() => {
    if (document.body.scrollHeight > window.innerHeight) {
      setZoom(
        1 -
          (document.body.scrollHeight - window.innerHeight) /
            document.body.scrollHeight
      );
    } else {
      setZoom((document.body.scrollHeight - 40) / 885);
    }

    getSalesListV2(
      1,
      "date",
      "DESC",
      shopData.id,
      "daily",
      new Date().toISOString(),
      new Date().toISOString(),
      ""
    ).then((res) => {
      setBookList(res.data.data.salesList);
    });

    getBookReqList().then((res) => {
      console.log(res.data.data.bookList);
      setReqList(res.data.data.bookList);
    });

    getVisitorCounts(new Date().toISOString()).then((res) => {
      console.log(res.data.data);
      setVisitorData(res.data.data);
    });

    getVisitorCountsWeekly(new Date().toISOString()).then((res) => {
      const day = ["일", "월", "화", "수", "목", "금", "토"];

      setVisitorData2(
        res.data.data.visitorList.map(
          (e: { date: string; visitor: string; day: string }) => {
            return {
              date: e.date,
              visitor: parseInt(e.visitor),
              day: day[new Date(e.date).getDay()],
            };
          }
        )
      );
    });

    getShopData().then((res) => {
      setShopData(res.data.data.shop);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCounter, refrashCounterFcm]);

  return (
    <MainLayout active={"home"}>
      <Container $zoom={zoom}>
        <Main>
          <Contents>
            <Left>
              <Content1>
                <h2>어서오세요! {shopData.name}님</h2>
                <span>핑프와 오늘도 좋은 하루 되세요 🙂</span>
              </Content1>
              <Content2>
                <TopBox>
                  <StatusBox>
                    <StatusCard>
                      <span>
                        {visitorData?.totalVisitor.length === 1
                          ? "0" + visitorData.totalVisitor
                          : visitorData?.totalVisitor}
                      </span>
                      <span>
                        총 예약{" "}
                        <strong style={{ color: color_iris.iris10 }}>
                          {visitorData && parseInt(visitorData.difference) !== 0
                            ? parseInt(visitorData.difference) < 0
                              ? visitorData.difference
                              : visitorData.difference
                            : ""}
                        </strong>
                      </span>
                      <div style={{ backgroundColor: "#EB607777" }}></div>
                      <PeopleIcon
                        style={{ fontSize: "42px", color: "#EB6077" }}
                      />
                    </StatusCard>
                    <StatusCard>
                      <span>
                        {visitorData?.nailComplete.length === 1
                          ? "0" + visitorData.nailComplete
                          : visitorData?.nailComplete}
                      </span>
                      <span>방문완료</span>
                      <div style={{ backgroundColor: "#7685C977" }}></div>
                      <CheckCircleIcon
                        style={{ fontSize: "42px", color: "#6275C7" }}
                      />
                    </StatusCard>
                    <StatusCard>
                      <span>
                        {visitorData?.bookComplete.length === 1
                          ? "0" + visitorData.bookComplete
                          : visitorData?.bookComplete}
                      </span>
                      <span>방문예정</span>
                      <div style={{ backgroundColor: "#63BF6A77" }}></div>
                      <MessageIcon
                        style={{ fontSize: "42px", color: "#63BF6A" }}
                      />
                    </StatusCard>
                    <StatusCard>
                      <span>
                        {visitorData?.noShow.length === 1
                          ? "0" + visitorData.noShow
                          : visitorData?.noShow}
                      </span>
                      <span>노쇼</span>
                      <div style={{ backgroundColor: "#272F3E77" }}></div>
                      <CancelIcon
                        style={{
                          fontSize: "42px",
                          color: color_midnight.midnight8,
                        }}
                      />
                    </StatusCard>
                  </StatusBox>
                  <StatisticsBox>
                    <div>
                      <span>
                        주간 통계{" "}
                        <strong style={{ color: color_rose.rose9 }}>
                          Total{" "}
                          {visitorData2.reduce((acc, cur, idx) => {
                            return (acc += cur.visitor);
                          }, 0)}
                        </strong>
                      </span>
                      <span>
                        {new Date(
                          visitorData2[0] ? visitorData2[0].date : ""
                        ).toLocaleDateString()}{" "}
                        ~
                        {new Date(
                          visitorData2[6] ? visitorData2[6].date : ""
                        ).toLocaleDateString()}
                      </span>
                    </div>
                    <div>
                      {visitorData2.map((item: any, idx: number) => (
                        <div
                          style={{
                            width: "22px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            minHeight: "180px",
                          }}
                          key={idx}
                        >
                          <span
                            style={{
                              marginBottom: "4px",
                              color:
                                item.day === "토" || item.day === "일"
                                  ? color_midnight.midnight5
                                  : color_rose.rose9,
                              fontSize: "12px",
                            }}
                          >
                            {item.visitor}
                          </span>
                          <div
                            style={{
                              width: "8px",
                              height: 5 * (item.visitor as number) + "px",
                              backgroundColor:
                                item.day === "토" || item.day === "일"
                                  ? color_midnight.midnight5
                                  : color_rose.rose9,
                              marginBottom: "12px",
                              borderTopLeftRadius: "4px",
                              borderTopRightRadius: "4px",
                            }}
                          ></div>
                          <span
                            style={{
                              fontSize: "12px",
                              fontWeight: "700",
                              marginBottom: "4px",
                            }}
                          >
                            {item.day}
                          </span>
                          <span
                            style={{
                              color: color_midnight.midnight5,
                              fontSize: "12px",
                            }}
                          >
                            {6 + idx}
                          </span>
                        </div>
                      ))}
                    </div>
                  </StatisticsBox>
                </TopBox>
                <MiddleBox>
                  <div>
                    <span>샵 공지사항</span>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setIsNotiOpen(true);
                      }}
                    >
                      수정
                    </div>
                  </div>
                  <div>
                    <pre
                      style={{
                        width: "100%",
                        maxWidth: "740px",
                        height: "44px",
                        overflowY: "scroll",
                        wordWrap: "break-word",
                      }}
                    >
                      {announcement}
                    </pre>
                  </div>
                </MiddleBox>
                <BottomBox>
                  <SalesRegisterBox>
                    <div>
                      <span>오늘 매출된 등록</span>
                      <span
                        style={{
                          display: bookList.length === 0 ? "none" : "flex",
                        }}
                      >
                        {bookList.length}
                      </span>
                    </div>
                    <div>
                      {bookList.length === 0 ? (
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            gap: "16px",
                          }}
                        >
                          <img src={"./image/icon2.png"} alt="이모티콘2" />
                          <span style={{ color: color_midnight.midnight4 }}>
                            매출 등록된 매출이 없습니다.
                          </span>
                        </div>
                      ) : (
                        bookList.map((item, idx) => (
                          <ScheduleItem
                            key={idx}
                            data={{
                              name: item.customerName,
                              time: new Date(item.date).toLocaleTimeString(
                                "ko-KR",
                                { timeStyle: "short" }
                              ),
                              contens: item.menuName,
                              managerName: item.managerName,
                            }}
                            onClick={() => {
                              setSaleData(item);
                              setIsSaleOpen(true);
                            }}
                            width="100%"
                          />
                        ))
                      )}
                    </div>
                  </SalesRegisterBox>
                  <NewBookBox>
                    <div>
                      <span>새로운 예약</span>
                      <span
                        style={{
                          display: reqList.length === 0 ? "none" : "flex",
                        }}
                      >
                        {reqList.length}
                      </span>
                    </div>
                    <div>
                      {shopData.bookingOption ? (
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            gap: "16px",
                          }}
                        >
                          <img src={"./image/icon3.png"} alt="이모티콘3" />
                          <span style={{ color: color_midnight.midnight4 }}>
                            자동 예약 기능을 사용하고 있습니다.
                          </span>
                        </div>
                      ) : reqList.length === 0 ? (
                        <span
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            gap: "16px",
                          }}
                        >
                          <img src={"./image/icon1.png"} alt="이모티콘1" />
                          <span style={{ color: color_midnight.midnight4 }}>
                            새로운 예약이 없습니다.
                          </span>
                        </span>
                      ) : (
                        reqList.map((item, idx) => (
                          <BookApprovalBox
                            key={idx}
                            data={item}
                            customerName={item.customerName}
                            date={new Date(item.date).toLocaleString()}
                            menuName={item.menuName}
                            callback={() => {
                              setUpdateCounter((old) => old + 1);
                            }}
                          />
                        ))
                      )}
                    </div>
                  </NewBookBox>
                </BottomBox>
              </Content2>
            </Left>
            <Right>
              <SideBar />
            </Right>
          </Contents>
        </Main>
        {isSaleOpen && (
          <SalesHistoryModal
            open={isSaleOpen}
            setOpen={setIsSaleOpen}
            data={saleData}
            callback={() => {
              setUpdateCounter((old) => old + 1);
            }}
          />
        )}
        {isNotiOpen && (
          <NotiModal
            open={isNotiOpen}
            setOpen={setIsNotiOpen}
            data={announcement}
            setData={setAnnouncement}
            callback={() => {
              saveShopData({
                shopUserId: shopData.shopUserId,
                id: shopData.id,
                imageUrlList: shopData.imageUrlList,
                name: shopData.name,
                engName: shopData.engName,
                phoneNumber: shopData.phoneNumber,
                basicAddress: shopData.basicAddress,
                detailAddress: shopData.detailAddress,
                coordinate: shopData.coordinate,
                announcement: announcement,
                info: shopData.info,
                businessHourList: shopData.businessHourList,
                deposit: shopData.deposit,
                bookingOption: shopData.bookingOption,
                localTag: shopData.localTag,
                subwayTag: shopData.subwayTag,
                instagramId: shopData.instagramId,
                instagramRefreshToken: shopData.instagramRefreshToken,
                titleMenuId: shopData.titleMenuId,
                titleImageUrl: shopData.titleImageUrl,
                isOnlyKakao: shopData.isOnlyKakao,
                kakaoLink: shopData.kakaoLink,
              }).then((res) => {
                setUpdateCounter((old) => old + 1);
                setIsNotiOpen(false);
              });
            }}
          />
        )}
        <MessageListener />
        <WaitingModal open={openWaiting} />
      </Container>
    </MainLayout>
  );
};

const Container = styled.div<{ $zoom: number }>`
  width: 100%;
  display: flex;

  zoom: ${(props) => props.$zoom};
`;

const Main = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  min-width: 1200px;
`;

const Contents = styled.div`
  width: 1200px;

  display: flex;
  gap: 50px;

  align-items: center;
`;

const Left = styled.div`
  width: 100%;

  margin-top: 50px;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const Right = styled.div`
  width: 42%;
  height: 840px;
  margin-top: 40px;
  box-sizing: border-box;

  display: flex;

  position: relative;
  left: 0px;
`;

const Content1 = styled.div`
  min-width: 400px;
  height: 51px;

  margin-left: 15px;

  & h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.2px;
  }

  & span {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    color: ${color_midnight.midnight4};
  }
`;

const Content2 = styled.div`
  display: flex;
  width: 100%;
  height: 720px;
  padding: 24px 0px;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  border-radius: 16px;
`;

const TopBox = styled.div`
  width: 100%;
  height: 256px;
  display: flex;

  justify-content: space-between;
`;

const MiddleBox = styled.div`
  display: flex;
  width: 100%;
  height: 160px;
  padding: 16px 24px;
  box-sizing: border-box;
  flex-direction: column;
  gap: 16px;
  border-radius: 16px;
  background: #ffffff;

  & > div:first-child {
    display: flex;
    width: 100%;
    justify-content: space-between;

    & > span {
      color: ${color_midnight.midnight8};
      font-size: 20px;
      font-weight: 700;
      line-height: 140%;
    }

    & > div {
      display: flex;
      width: 44px;
      height: 24px;
      padding: 0px 8px;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;

      color: #ffffff;
      font-size: 10px;
      font-weight: 500;
      line-height: 140%;

      border-radius: 16px;
      background: ${color_midnight.midnight8};
    }
  }

  & > div:last-child {
    display: flex;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    padding: 10px 24px;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    background: ${color_midnight.midnight1};

    color: var(--Black, #000);

    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
  }
`;

const BottomBox = styled.div`
  display: flex;
  width: 100%;
  height: 320px;
  box-sizing: border-box;

  gap: 16px;
`;

const StatusBox = styled.div`
  width: 504px;
  height: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  gap: 16px;
`;

const StatusCard = styled.div`
  width: 240px;
  height: 120px;
  border-radius: 16px;
  background: #ffffff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 24px;
  box-sizing: border-box;

  overflow: hidden;

  position: relative;

  & > span:nth-child(1) {
    color: #272f3e;
    font-size: 24px;
    font-weight: 700;
    line-height: 140%;
  }
  & > span:nth-child(2) {
    color: #6c727f;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
  }

  & > div:nth-child(3) {
    width: 96px;
    height: 96px;
    background: #eb607777;
    border-radius: 50%;
    position: absolute;
    top: -20px;
    right: -20px;
  }

  & > :nth-child(4) {
    position: absolute;
    top: 16px;
    right: 16px;
  }
`;

const StatisticsBox = styled.div`
  width: 272px;
  height: 100%;
  background: #ffffff;
  border-radius: 16px;

  padding: 16px 32px;
  box-sizing: border-box;

  & > div:first-child {
    display: flex;
    flex-direction: column;
    gap: 4px;

    & > :first-child {
      color: ${color_midnight.midnight5};
      font-size: 16px;
      font-weight: 500;
      line-height: 140%;
    }
    & > :last-child {
      color: ${color_rose.rose9};
      font-size: 12px;
      font-weight: 700;
      line-height: 140%;
    }
  }

  & > div:last-child {
    display: flex;
    justify-content: space-between;
  }
`;

const SalesRegisterBox = styled.div`
  width: 50%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 16px 24px;
  box-sizing: border-box;

  & > div:first-child {
    width: 100%;
    height: 28px;
    display: flex;
    gap: 8px;
    align-items: center;

    & > span:first-child {
      color: ${color_midnight.midnight8};
      font-size: 20px;
      font-weight: 700;
      line-height: 140%;
    }
    & > span:last-child {
      display: flex;
      width: 33px;
      height: 24px;
      justify-content: center;
      align-items: center;
      background-color: #f14262;
      border-radius: 50px;

      color: #ffffff;
      font-size: 14px;
      font-weight: 700;
      line-height: 140%;
    }
  }

  & > div:last-child {
    width: 100%;
    height: 100%;

    overflow-y: scroll;
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
    &::-webkit-scrollbar {
      display: none;
    }

    padding: 0px 10px;
    box-sizing: border-box;

    & > li:hover {
      background-color: ${color_green.green2};
    }
  }
`;
const NewBookBox = styled.div`
  width: 50%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 16px 24px;
  box-sizing: border-box;

  & > div:first-child {
    width: 100%;
    height: 28px;
    display: flex;
    gap: 8px;
    align-items: center;

    & > span:first-child {
      color: ${color_midnight.midnight8};
      font-size: 20px;
      font-weight: 700;
      line-height: 140%;
    }
    & > span:last-child {
      display: flex;
      width: 33px;
      height: 24px;
      justify-content: center;
      align-items: center;
      background-color: ${color_rose.rose9};
      border-radius: 50px;

      color: #ffffff;
      font-size: 14px;
      font-weight: 700;
      line-height: 140%;
    }
  }

  & > div:last-child {
    width: 100%;
    height: 100%;

    overflow-y: scroll;
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
    &::-webkit-scrollbar {
      display: none;
    }

    padding: 0px 10px;
    box-sizing: border-box;

    & > div:hover {
      background-color: ${color_rose.rose0};
    }
  }
`;

export default Home;
