import { TalkUserDataType } from "../types";

export const dumyScheduleData = [
  {
    name: "박새연",
    time: "10:00 - 11:00",
    contens: "손젤",
    managerName: "홍길동",
  },
  {
    name: "윤어진",
    time: "11:00 - 12:00",
    contens: "이달의 아트",
    managerName: "홍길동",
  },
  {
    name: "박새윤",
    time: "12:00 - 13:00",
    contens: "손젤",
    managerName: "홍길동",
  },
  {
    name: "김민영",
    time: "13:00 - 14:00",
    contens: "이달의 아트",
    managerName: "홍길동",
  },
  {
    name: "김미연",
    time: "14:00 - 15:00",
    contens: "손젤",
    managerName: "홍길동",
  },
  {
    name: "최시연",
    time: "15:00 - 16:00",
    contens: "이달의 아트",
    managerName: "홍길동",
  },
  {
    name: "알렉산더",
    time: "16:00 - 17:00",
    contens: "손젤",
    managerName: "홍길동",
  },
  {
    name: "욤",
    time: "17:00 - 18:00",
    contens: "이달의 아트",
    managerName: "홍길동",
  },
];

export const ChartDataDay = {
  xData: [
    "10일",
    "11일",
    "12일",
    "13일",
    "14일",
    "15일",
    "16일",
    "17일",
    "18일",
    "19일",
  ],
  yData: [5, 10, 7, 8, 14, 10, 12, 3, 5, 12],
};

export const ChartDataWeek = {
  xData: [
    "7.1주",
    "7.2주",
    "7.3주",
    "7.4주",
    "8.1주",
    "8.2주",
    "8.3주",
    "8.4주",
    "9.1주",
    "9.2주",
  ],
  yData: [20, 40, 28, 32, 14, 20, 16, 10, 30, 40],
};

export const ChartDataMonth = {
  xData: [
    "2월",
    "3월",
    "4월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월",
  ],
  yData: [50, 100, 70, 80, 140, 100, 120, 30, 50, 120],
};

export const eventData = [
  {
    title: "박새연_type1",
    start: new Date("2023-10-15"),
    end: new Date(
      new Date("2023-10-15").setHours(new Date("2023-10-15").getHours() + 2)
    ),
    indexNum: 0,
    AmountNum: 10,
    color: "",
  },
  {
    title: "박새연_type1",
    start: new Date("2023-10-15"),
    end: new Date(
      new Date("2023-10-15").setHours(new Date("2023-10-15").getHours() + 2)
    ),
    indexNum: 1,
    AmountNum: 10,
    color: "",
  },
  {
    title: "박새연_type2",
    start: new Date("2023-10-15"),
    end: new Date(
      new Date("2023-10-15").setHours(new Date("2023-10-15").getHours() + 2)
    ),
    indexNum: 1,
    AmountNum: 10,
    color: "",
  },
  {
    title: "박새연_type3",
    start: new Date("2023-10-15"),
    end: new Date(
      new Date("2023-10-15").setHours(new Date("2023-10-15").getHours() + 2)
    ),
    indexNum: 1,
    AmountNum: 10,
    color: "",
  },
  {
    title: "박새연_type1",
    start: new Date("2023-10-28T11:00:00"),
    end: new Date("2023-10-28T12:00:00"),
    indexNum: 1,
    AmountNum: 10,
    color: "",
  },
  {
    title: "박새연_type2",
    start: new Date("2023-10-28T12:00:00"),
    end: new Date("2023-10-28T14:00:00"),
    indexNum: 0,
    AmountNum: 10,
    color: "",
  },
  {
    title: "박새연_type3",
    start: new Date("2024-01-17T08:57:09"),
    end: new Date("2024-01-17T10:57:09"),
    indexNum: 0,
    AmountNum: 10,
    color: "",
  },
];

export const talkUserData: TalkUserDataType[] = [
  {
    name: "전준오",
    resentMessage: {
      text: "새로운 채팅을 시작합니다.",
      time: "16:08",
    },
    phone: "010-9999-8888",
    imgUrl:
      "https://storage.finger-princess.com/62cec158-4bf2-4cac-8313-e72957c5e6bf",
  },
  {
    name: "홍길동",
    resentMessage: { text: "안녕하세요", time: "20:08" },
    phone: "010-1234-5678",
    imgUrl:
      "https://storage.finger-princess.com/69cb3b81-3d4b-4eaa-be8d-2726a94b7823",
  },
];
