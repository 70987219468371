import axios from "axios";
import { CreateShopType, ShopType } from "../types";
import { baseUrl, config } from "./api";

// shop api
export const getShopData = async (accessToken?: string) => {
  return await axios.get(baseUrl + "/admin/shop", config(accessToken));
};

export const createShopData = async (
  data: CreateShopType,
  accessToken?: string
) => {
  return await axios.post(baseUrl + "/admin/shop", data, config(accessToken));
};

export const saveShopData = async (data: ShopType) => {
  return await axios.put(baseUrl + "/admin/shop", data, config());
};

export const saveFcmToken = async (fcmToken: string) => {
  return await axios.post(
    baseUrl + "/admin/shop/fcm",
    { fcmToken: fcmToken },
    config()
  );
};

export const paymentTicket = async (
  paymentUid: string,
  period: string,
  amount: number
) => {
  return await axios.post(
    baseUrl + "/admin/shop/payment",
    {
      paymentUid: paymentUid,
      period: period,
      amount: amount,
    },
    config()
  );
};
