import React, { useEffect, useState } from "react";
import styled from "styled-components";
import InputBox from "../utility/InputBox";
import {
  changePw,
  checkPw,
  checkSecondPw,
  getUserData,
  saveSecondPw,
} from "../../apis/auth";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";

const UserInfoChange = () => {
  const navigate = useNavigate();
  const [pw, setPw] = useState("");

  const [isAuthed, setIsAuthed] = useState(false);

  const [pageNum, setPageNum] = useState(0);

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [businessNum, setBusinessNum] = useState("");
  const [phone, setPhone] = useState("");

  const [oldPw, setOldPw] = useState("");

  const [newPw, setNewPw] = useState("");
  const [newPw2, setNewPw2] = useState("");

  useEffect(() => {
    getUserData().then((res) => {
      console.log(res.data.user);

      setId(res.data.user.id);
      setName(res.data.user.name);
      setBusinessNum(res.data.user.businessNumber);
      setPhone(res.data.user.phoneNumber);
    });
  }, []);

  const getPage = () => {
    switch (pageNum) {
      case 0:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "100%",
              height: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "44px",
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 18px",
                boxSizing: "border-box",
                borderRadius: "16px",
                backgroundColor: "#F9FAFB",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setPageNum(1)}
            >
              <span style={{ color: "#A0A6B1", fontSize: "16px" }}>
                대표자 정보 및 사업자등록증
              </span>
              <ArrowForwardIosIcon
                style={{ color: "#A0A6B1", fontSize: "16px" }}
              />
            </div>
            <div
              style={{
                width: "100%",
                height: "44px",
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 18px",
                boxSizing: "border-box",
                borderRadius: "16px",
                backgroundColor: "#F9FAFB",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setPageNum(2)}
            >
              <span style={{ color: "#A0A6B1", fontSize: "16px" }}>
                비밀번호 변경
              </span>
              <ArrowForwardIosIcon
                style={{ color: "#A0A6B1", fontSize: "16px" }}
              />
            </div>
            <div
              style={{
                width: "100%",
                height: "44px",
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 18px",
                boxSizing: "border-box",
                borderRadius: "16px",
                backgroundColor: "#F9FAFB",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setPageNum(3)}
            >
              <span style={{ color: "#A0A6B1", fontSize: "16px" }}>
                2차 비밀번호 변경
              </span>
              <ArrowForwardIosIcon
                style={{ color: "#A0A6B1", fontSize: "16px" }}
              />
            </div>
          </div>
        );
      case 1:
        return (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px 20px",
              boxSizing: "border-box",
              gap: "40px",
            }}
          >
            <span
              style={{ color: "#272F3E", fontWeight: "700", fontSize: "20px" }}
            >
              대표자 정보 및 사업자등록증
            </span>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "24px",
                justifyContent: "center",
              }}
            >
              <InfoBox>
                <span>대표자 성함</span>
                <div>{name}</div>
              </InfoBox>
              <InfoBox>
                <span>대표자 연락처</span>
                <div>{phone}</div>
              </InfoBox>
              <InfoBox>
                <span>아이디</span>
                <div>{id}</div>
              </InfoBox>
              <InfoBox>
                <span>사업자등록번호</span>
                <div>{businessNum}</div>
              </InfoBox>
            </div>
          </div>
        );
      case 2:
        return (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px 20px",
              boxSizing: "border-box",
              gap: "40px",
              position: "relative",
            }}
          >
            <span
              style={{ color: "#272F3E", fontWeight: "700", fontSize: "20px" }}
            >
              비밀번호변경
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <span style={{ fontSize: "14px", color: "#6C727F" }}>
                고객님의 소중한 개인정보를 안전하게 보호하기 위해
              </span>
              <span style={{ fontSize: "14px", color: "#6C727F" }}>
                비밀번호를 한 번 더 입력해 주세요.
              </span>
            </div>
            <InputBox
              placeholder={"비밀번호를 입력해주세요."}
              type={"password"}
              width={300}
              setValue={setOldPw}
              value={oldPw}
            />
            <div
              style={{
                position: "absolute",
                bottom: "40px",
                right: "40px",
                display: "flex",
                gap: "24px",
              }}
            >
              <span
                style={{
                  width: "88px",
                  height: "44px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#A0A6B1",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() => setPageNum(0)}
              >
                취소
              </span>
              <span
                style={{
                  width: "88px",
                  height: "44px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  fontSize: "14px",
                  borderRadius: "8px",
                  backgroundColor: oldPw.length > 1 ? "#F14262" : "#E5E7EB",
                  cursor: "pointer",
                }}
                onClick={() => {
                  checkPw(oldPw).then((res) => {
                    if (res.data.checkResult) {
                      setPageNum(4);
                    } else {
                      window.alert("비밀번호가 일치하지 않습니다");
                    }
                  });
                }}
              >
                다음
              </span>
            </div>
          </div>
        );
      case 3:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
            }}
          >
            <span
              style={{
                color: "#272F3E",
                fontWeight: "700",
                fontSize: "20px",
                marginBottom: "24px",
              }}
            >
              2차 비밀번호 변경
            </span>
            <Image
              src="image/PasswordCode.png"
              style={{
                width: "160px",
                height: "160px",
                objectFit: "cover",
                marginTop: "0px",
              }}
            />
            <span
              style={{
                margin: "16px 0px",
                textAlign: "center",
                lineHeight: "20px",
                fontSize: "16px",
                color: "#F14262",
                fontWeight: "700",
              }}
            >
              새로운 2차 비밀번호를 입력해주세요.
            </span>
            <InputBox
              placeholder={"2차 비밀번호를 입력해주세요."}
              type={"password"}
              width={300}
              setValue={setNewPw}
              value={newPw}
              disabled={true}
            />
            <BtnWrap>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, "삭제", 0, "확인"].map((item, idx) =>
                item === "삭제" ? (
                  <KeypadSpecialBtn
                    key={idx}
                    onClick={() => setNewPw((old) => old.slice(0, -1))}
                  >
                    {item}
                  </KeypadSpecialBtn>
                ) : item === "확인" ? (
                  <KeypadSpecialBtn
                    key={idx}
                    onClick={() => {
                      if (newPw.length === 4) {
                        setPageNum(6);
                      }
                    }}
                  >
                    {item}
                  </KeypadSpecialBtn>
                ) : (
                  <KeypadBtn
                    key={idx}
                    onClick={() =>
                      setNewPw((old) => (old.length < 4 ? old + item : old))
                    }
                  >
                    {item}
                  </KeypadBtn>
                )
              )}
            </BtnWrap>
          </div>
        );
      case 4:
        return (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px 20px",
              boxSizing: "border-box",
              gap: "40px",
              position: "relative",
            }}
          >
            <span
              style={{ color: "#272F3E", fontWeight: "700", fontSize: "20px" }}
            >
              비밀번호변경
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <span style={{ fontSize: "14px", color: "#6C727F" }}>
                새로운 비밀번호를 입력해주세요.
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <InputBox
                placeholder={"비밀번호를 입력해주세요."}
                type={"password"}
                width={300}
                setValue={setNewPw}
                value={newPw}
              />
              <InputBox
                placeholder={"비밀번호를 재입력해주세요."}
                type={"password"}
                width={300}
                setValue={setNewPw2}
                value={newPw2}
              />
              <span
                style={{ color: "#62D4D2", fontSize: "12px", width: "300px" }}
              >
                비밀번호는 8자리 이상 20자리 이내로 입력해 주세요.
              </span>
              {newPw !== newPw2 && (
                <span
                  style={{ color: "#F14262", fontSize: "12px", width: "300px" }}
                >
                  비밀번호가 일치하지 않습니다.
                </span>
              )}
            </div>
            <div
              style={{
                position: "absolute",
                bottom: "40px",
                right: "40px",
                display: "flex",
                gap: "24px",
              }}
            >
              <span
                style={{
                  width: "88px",
                  height: "44px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#A0A6B1",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() => setPageNum(0)}
              >
                취소
              </span>
              <span
                style={{
                  width: "88px",
                  height: "44px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  fontSize: "14px",
                  borderRadius: "8px",
                  backgroundColor: oldPw.length > 1 ? "#F14262" : "#E5E7EB",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (newPw === newPw2) {
                    changePw(newPw).then((res) => {
                      setPageNum(5);
                    });
                  } else {
                    window.alert("비밀번호를 확인해주세요");
                  }
                }}
              >
                다음
              </span>
            </div>
          </div>
        );
      case 5:
        return (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px 20px",
              boxSizing: "border-box",
              gap: "40px",
              position: "relative",
            }}
          >
            <span
              style={{ color: "#272F3E", fontWeight: "700", fontSize: "20px" }}
            >
              비밀번호변경
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <span style={{ fontSize: "14px", color: "#6C727F" }}>
                비밀번호 변경이 완료되었습니다.
              </span>
            </div>
            <div
              style={{
                marginTop: "80px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Image src="image/PasswordCode.png" />
              <span
                style={{
                  color: "#272F3E",
                  fontWeight: "700",
                  fontSize: "12px",
                }}
              >
                로그인 페이지에서 다시 로그인해주세요.
              </span>
            </div>
            <div
              style={{
                position: "absolute",
                bottom: "40px",
                right: "40px",
                display: "flex",
                gap: "24px",
              }}
            >
              <span
                style={{
                  width: "88px",
                  height: "44px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  fontSize: "14px",
                  borderRadius: "8px",
                  backgroundColor: oldPw.length > 1 ? "#F14262" : "#E5E7EB",
                  cursor: "pointer",
                }}
                onClick={() => {
                  localStorage.removeItem("refreshToken");
                  localStorage.removeItem("accessToken");

                  // 자동 로그인 옵션 삭제
                  localStorage.removeItem("autoLogin");

                  //로그인 화면 이동
                  navigate("/signin");
                }}
              >
                확인
              </span>
            </div>
          </div>
        );
      case 6:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
            }}
          >
            <span
              style={{
                color: "#272F3E",
                fontWeight: "700",
                fontSize: "20px",
                marginBottom: "24px",
              }}
            >
              2차 비밀번호 변경
            </span>
            <Image
              src="image/PasswordCode.png"
              style={{
                width: "160px",
                height: "160px",
                objectFit: "cover",
                marginTop: "0px",
              }}
            />
            <span
              style={{
                margin: "16px 0px",
                textAlign: "center",
                lineHeight: "20px",
                fontSize: "16px",
                color: "#F14262",
                fontWeight: "700",
              }}
            >
              다시 입력해주세요.
              <br />
              {newPw.length !== 4 && "(숫자 4자리)"}
            </span>
            <InputBox
              placeholder={"2차 비밀번호를 입력해주세요."}
              type={"password"}
              width={300}
              setValue={setNewPw2}
              value={newPw2}
              disabled={true}
            />
            <BtnWrap>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, "삭제", 0, "확인"].map((item, idx) =>
                item === "삭제" ? (
                  <KeypadSpecialBtn
                    key={idx}
                    onClick={() => setNewPw2((old) => old.slice(0, -1))}
                  >
                    {item}
                  </KeypadSpecialBtn>
                ) : item === "확인" ? (
                  <KeypadSpecialBtn
                    key={idx}
                    onClick={() => {
                      if (newPw2.length === 4) {
                        if (newPw === newPw2) {
                          saveSecondPw(newPw).then((res) => {
                            setPageNum(7);
                          });
                        } else {
                          window.alert("비밀번호가 일치하지 않습니다.");
                        }
                      }
                    }}
                  >
                    {item}
                  </KeypadSpecialBtn>
                ) : (
                  <KeypadBtn
                    onClick={() =>
                      setNewPw2((old) => (old.length < 4 ? old + item : old))
                    }
                  >
                    {item}
                  </KeypadBtn>
                )
              )}
            </BtnWrap>
          </div>
        );
      case 7:
        return (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px 20px",
              boxSizing: "border-box",
              gap: "40px",
              position: "relative",
            }}
          >
            <span
              style={{ color: "#272F3E", fontWeight: "700", fontSize: "20px" }}
            >
              2차 비밀번호 변경
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <span style={{ fontSize: "14px", color: "#6C727F" }}>
                2차 비밀번호 변경이 완료되었습니다.
              </span>
            </div>
            <div
              style={{
                marginTop: "80px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Image src="image/PasswordCode.png" />
              <span
                style={{
                  color: "#272F3E",
                  fontWeight: "700",
                  fontSize: "12px",
                }}
              >
                2차 비밀번호는 계정대표자의 인증키로
                <br />
                비밀번호가 노출되지 않게 주의해주세요.
              </span>
            </div>
            <div
              style={{
                position: "absolute",
                bottom: "40px",
                right: "40px",
                display: "flex",
                gap: "24px",
              }}
            >
              <span
                style={{
                  width: "88px",
                  height: "44px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  fontSize: "14px",
                  borderRadius: "8px",
                  backgroundColor: "#F14262",
                }}
                onClick={() => {
                  setNewPw("");
                  setNewPw2("");
                  setOldPw("");
                  setPw("");

                  setPageNum(0);
                }}
              >
                확인
              </span>
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <Container>
      {!isAuthed ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Image src="image/PasswordCode.png" />
          <span
            style={{
              margin: "16px 0px",
              textAlign: "center",
              lineHeight: "20px",
              fontSize: "16px",
              color: "#F14262",
              fontWeight: "700",
            }}
          >
            2차 비밀번호를 입력해주세요.
            <br />
            {pw.length !== 4 && "(숫자 4자리)"}
          </span>
          <InputBox
            placeholder={"2차 비밀번호를 입력해주세요."}
            type={"password"}
            width={300}
            setValue={setPw}
            value={pw}
            disabled={true}
          />
          <BtnWrap>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, "삭제", 0, "확인"].map((item, idx) =>
              item === "삭제" ? (
                <KeypadSpecialBtn
                  key={idx}
                  onClick={() => setPw((old) => old.slice(0, -1))}
                >
                  {item}
                </KeypadSpecialBtn>
              ) : item === "확인" ? (
                <KeypadSpecialBtn
                  onClick={() => {
                    if (pw.length === 4) {
                      checkSecondPw(pw).then((res) => {
                        if (res.data.checkResult) {
                          setIsAuthed(true);
                        } else {
                          window.alert("2차 비밀번호가 틀렸습니다.");
                        }
                      });
                    }
                  }}
                >
                  {item}
                </KeypadSpecialBtn>
              ) : (
                <KeypadBtn
                  onClick={() =>
                    setPw((old) => (old.length < 4 ? old + item : old))
                  }
                >
                  {item}
                </KeypadBtn>
              )
            )}
          </BtnWrap>
        </div>
      ) : (
        getPage()
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  & > iframe {
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Image = styled.img`
  margin-top: -40px;
`;

const BtnWrap = styled.div`
  width: 300px;
  height: 300px;

  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  padding-top: 20px;
`;

const KeypadBtn = styled.div`
  cursor: pointer;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #f4f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  font-weight: 700;
  color: #a0a6b1;
`;

const KeypadSpecialBtn = styled.div`
  cursor: pointer;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #f14262;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
`;

const InfoBox = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > :first-child {
    color: #fd91a5;
    font-size: 16px;
    font-weight: 700;
  }
  & > :last-child {
    width: 100%;
    height: 44px;
    background-color: #e5e7eb;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding-left: 18px;
    box-sizing: border-box;

    color: #a0a6b1;
    font-size: 16px;
    font-weight: 500;
  }
`;

export default UserInfoChange;
