import React, { useEffect, useState } from "react";
import { saveFeed } from "../../apis/feed";
import { useRecoilState } from "recoil";
import { ShopData } from "../../atoms/atom";
import { FeedType } from "../../types";
import { Checkbox, Switch } from "@mui/material";
import InputBox from "../utility/InputBox";
import CheckIcon from "@mui/icons-material/Check";
import {
  changeDesignCategory,
  designCategory,
  feedColorList,
  feedColorList_kor,
} from "../../util/util";
import DatePicker2 from "../utility/DatePicker2";
import { color_midnight } from "../../util/color";

type propType = {
  data: FeedType;
  cancleCallback: () => void;
  editCallback: () => void;
};

const EditFeed = ({ data, cancleCallback, editCallback }: propType) => {
  const [shopData] = useRecoilState(ShopData);

  const [isPinned, setIsPinned] = useState(data.isPinned);
  const [name, setName] = useState(data.name);
  const [price, setPrice] = useState(data.price);
  const [runningTime, setRunninTime] = useState(data.runningTime);
  const [categoryList, setCategoryList] = useState<string[]>(
    data.category.split(",")
  );
  const [feedTagList, setFeedTagList] = useState(
    data.feedTagList
      .map((e: { id: string; name: string }) => "#" + e.name)
      .join(" ")
  );

  const [colorList, setColorList] = useState(data.color.split(","));
  const [imageUrl] = useState(data.imageUrl);

  const [isMonthlyArt, setIsMonthlyArt] = useState(false);
  const [monthlyArt, setMonthlyArt] = useState(new Date());

  useEffect(() => {
    if (feedTagList.includes("이달의아트")) {
      setIsMonthlyArt(true);

      const year = feedTagList.split("이달의아트")[1].split(",")[0].slice(0, 4);
      const month = feedTagList.split("이달의아트")[1].split(",")[0].slice(4);

      const tempDate = new Date();
      tempDate.setFullYear(parseInt(year));
      tempDate.setMonth(parseInt(month) - 1);

      setMonthlyArt(tempDate);
    }
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          gap: "24px",
          alignItems: "center",
        }}
      >
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "248px",
                height: "248px",
                background: "#E5E7EB",
                borderRadius: "16px",
              }}
            >
              <img
                alt=""
                src={imageUrl}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
            <div
              style={{
                width: "344px",
                padding: "16px 24px",
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                boxSizing: "border-box",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#FD91A5",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    디자인명
                  </span>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        color: "#A0A6B1",
                        fontSize: "12px",
                        fontWeight: "700",
                      }}
                    >
                      디자인 고정
                    </span>
                    <Switch
                      checked={isPinned}
                      onClick={() => setIsPinned((old) => !old)}
                      sx={{
                        ".css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
                          { backgroundColor: "red" },
                        ".css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked":
                          { color: "#FF5D7A" },
                      }}
                    />
                  </div>
                </div>
                <InputBox
                  placeholder={""}
                  type={"text"}
                  width={300}
                  setValue={setName}
                  value={name}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    color: "#FD91A5",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                >
                  가격
                </span>
                <InputBox
                  placeholder={"ex.50,000"}
                  type={"money"}
                  width={184}
                  setValue={setPrice}
                  value={price}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    color: "#FD91A5",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                >
                  소요시간
                </span>
                <InputBox
                  placeholder={"00 ( ex : 단위 분  50분 -> 50)"}
                  type={"minute"}
                  width={184}
                  setValue={setRunninTime}
                  value={runningTime}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    checked={isMonthlyArt}
                    onChange={() => {
                      if (!isMonthlyArt) {
                        if (
                          !categoryList.includes(
                            changeDesignCategory("이달의아트", true)
                          )
                        ) {
                          setCategoryList((old) => [
                            ...old,
                            changeDesignCategory("이달의아트", true),
                          ]);
                        }
                      } else {
                        setCategoryList((old) =>
                          old.filter(
                            (item2) =>
                              item2 !== changeDesignCategory("이달의아트", true)
                          )
                        );
                      }

                      setIsMonthlyArt(!isMonthlyArt);
                    }}
                    sx={{
                      color: "#ddd", // 기본 체크박스 색상
                      "&.Mui-checked": {
                        color: "#FD91A5", // 체크 시 색상
                      },
                    }}
                  />
                  <span
                    style={{
                      color: "#FD91A5",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    이달의 아트 설정
                  </span>
                </div>
                <DatePicker2 value={monthlyArt} setValue={setMonthlyArt} />
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: "190px",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <span
              style={{ fontWeight: "700", fontSize: "14px", color: "#FD91A5" }}
            >
              해시태그
            </span>
            <div style={{ display: "flex", gap: "16px" }}>
              <textarea
                style={{
                  width: "100%",
                  height: "160px",
                  outline: "none",
                  border: "none",
                  backgroundColor: color_midnight.midnight1,
                  borderRadius: "8px",
                  padding: "20px",
                  boxSizing: "border-box",
                }}
                placeholder="#을 이용해 해쉬태그를 입력해보세요.(띄어쓰기x)"
                value={feedTagList}
                onChange={(e) => setFeedTagList(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            width: "467px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            paddingTop: "32px",
            boxSizing: "border-box",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <span
              style={{
                color: "#FD91A5",
                fontSize: "14px",
                fontWeight: "700",
              }}
            >
              카테코리 선택
            </span>
            <span
              style={{
                color: "#A0A6B1",
                fontSize: "12px",
                fontWeight: "700",
              }}
            >
              카테고리 미선택시 핑프 앱 내 카테고리 필터에서 검색 노출이 되지
              않습니다. (중복 선택 가능)
            </span>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
            {designCategory.map((item, idx) => (
              <span
                style={{
                  padding: "10px 16px",
                  borderRadius: "25px",
                  backgroundColor:
                    categoryList.filter(
                      (item2) => item2 === changeDesignCategory(item, true)
                    ).length > 0
                      ? "#D8F5F5"
                      : "#F9FAFB",
                  color: "#181E2D",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
                onClick={() => {
                  console.log(categoryList);
                  if (categoryList.includes(changeDesignCategory(item, true))) {
                    setCategoryList((old) =>
                      old.filter(
                        (item2) => item2 !== changeDesignCategory(item, true)
                      )
                    );
                  } else {
                    setCategoryList((old) => [
                      ...old,
                      changeDesignCategory(item, true),
                    ]);
                  }
                }}
              >
                {item}
              </span>
            ))}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              padding: "16px 0px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <span
                style={{
                  color: "#FD91A5",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                대표 색상 선택
              </span>
              <span
                style={{
                  color: "#A0A6B1",
                  fontSize: "12px",
                  fontWeight: "700",
                }}
              >
                디자인을 표현할 수 있는 색상을 선택해주세요. (중복 선택 가능)
              </span>
            </div>
            <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
              {feedColorList.map((item, idx) => (
                <div
                  style={{
                    display: "flex",
                    padding: "6px 16px",
                    backgroundColor: color_midnight.lily,
                    borderRadius: "20px",
                    alignItems: "center",
                    gap: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (
                      colorList.filter((item2) => item2 === item).length > 0
                    ) {
                      setColorList((old) =>
                        old.filter((item2) => item2 !== item)
                      );
                    } else {
                      setColorList((old) => [...old, item]);
                    }
                  }}
                >
                  <span
                    key={idx}
                    style={{
                      width: "24px",
                      height: "24px",
                      borderRadius: "50%",
                      backgroundColor: item,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      border:
                        item === "#FFFFFF"
                          ? "2px solid #cccccc"
                          : "0px solid black",
                      boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    {colorList.filter((item2) => item2 === item).length > 0 && (
                      <CheckIcon
                        sx={{
                          color: item === "#FFFFFF" ? "#000000" : "#ffffff",
                          fontSize: 16,
                        }}
                      />
                    )}
                  </span>
                  <span style={{ fontSize: "12px" }}>
                    {feedColorList_kor[idx]}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", gap: "24px", alignSelf: "flex-end" }}>
        <span
          style={{
            width: "88px",
            height: "44px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#A0A6B1",
            fontSize: "14px",
          }}
          onClick={cancleCallback}
        >
          취소
        </span>
        <span
          style={{
            width: "88px",
            height: "44px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
            backgroundColor: "#F14262",
            color: "#ffffff",
            fontSize: "14px",
          }}
          onClick={() => {
            let cleanedTags = feedTagList;

            if (!isMonthlyArt) {
              const monthlyArtTag = `#이달의아트${monthlyArt.getFullYear()}${String(
                monthlyArt.getMonth() + 1
              )}`;
              const regex = new RegExp(`${monthlyArtTag}`, "g");
              cleanedTags = cleanedTags.replace(regex, "").trim();
              setFeedTagList(cleanedTags); // 상태 업데이트
            }

            const finalTags = [
              ...new Set(
                (
                  cleanedTags +
                  (isMonthlyArt
                    ? `#이달의아트${monthlyArt.getFullYear()}${String(
                        monthlyArt.getMonth() + 1
                      )}`
                    : "")
                )
                  .split("#")
                  .map((e) => e.replaceAll("\n", "").replaceAll(" ", ""))
                  .filter((e) => e !== "")
              ),
            ];

            saveFeed({
              shopId: shopData.id,
              imageUrl: imageUrl,
              name: name,
              category: categoryList.join(","),
              runningTime: runningTime,
              price: price,
              color: colorList.join(","),
              feedId: data.id,
              isPinned: isPinned,
              feedTagList: finalTags,
            }).then((res) => {
              editCallback();
            });
          }}
        >
          저장
        </span>
      </div>
    </div>
  );
};

export default EditFeed;
