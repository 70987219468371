import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import Box from "@mui/material/Box";
import { IoMdClose } from "react-icons/io";
import { styled } from "styled-components";
import DatePicker from "../utility/DatePicker";
import TimePickers from "../utility/TimePickers";
import Select2 from "./Select2";
import { saveBook } from "../../apis/book";
import { IoImageOutline } from "react-icons/io5";
import { changeCategory, handleEmptyValue } from "../../util/util";
import MenuSelection from "./MenuSelection";

type propType = {
  setOpen: Dispatch<SetStateAction<boolean>>;
  data: any;
  callback: () => void;
  date: Date;
  setDate: Dispatch<SetStateAction<Date>>;
  time: Date;
  setTime: Dispatch<SetStateAction<Date>>;
  manager: any;
  setManager: Dispatch<SetStateAction<any>>;
  managerList: any[];
  selMenu: any;
  setSelMenu: Dispatch<SetStateAction<any>>;
  selDesign: any;
  setSelDesign: Dispatch<SetStateAction<any>>;
  selOptionList: any[];
  setSelOptionList: Dispatch<SetStateAction<any[]>>;
  setIsSelMenuOpen: Dispatch<SetStateAction<boolean>>;
  isSelMenuOpen: boolean;
};

const EditBooking = ({
  setOpen,
  data,
  date,
  setDate,
  time,
  setTime,
  manager,
  setManager,
  managerList,
  selMenu,
  setSelMenu,
  selDesign,
  setSelDesign,
  selOptionList,
  setSelOptionList,
  setIsSelMenuOpen,
  isSelMenuOpen,
  callback,
}: propType) => {
  const calPrice = () => {
    let menu_price = handleEmptyValue(
      selMenu ? parseInt(selMenu.price !== "" ? selMenu.price : 0) : 0
    );
    let design_price = handleEmptyValue(
      selDesign ? parseInt(selDesign.price !== "" ? selDesign.price : 0) : 0
    );

    if (selMenu && selMenu.eventPrice && selMenu.eventPrice !== "-1") {
      menu_price = parseInt(selMenu.eventPrice);
    }

    if (selDesign && selDesign.eventPrice && selDesign.eventPrice !== "-1") {
      design_price = parseInt(selDesign.eventPrice);
    }

    let total_option_price = 0;

    selOptionList.forEach((e) => {
      if (e.eventPrice === "-1") {
        total_option_price += parseInt(e.price !== "" ? e.price : 0);
      } else {
        total_option_price += parseInt(e.eventPrice !== "" ? e.eventPrice : 0);
      }
    });

    return menu_price + design_price + total_option_price;
  };

  const calDuration = () => {
    let menu_runningTime = handleEmptyValue(
      selMenu
        ? parseInt(selMenu.runningTime !== "" ? selMenu.runningTime : 0)
        : 0
    );
    let design_runningTime = handleEmptyValue(
      selDesign
        ? parseInt(selDesign.runningTime !== "" ? selDesign.runningTime : 0)
        : 0
    );

    let total_option_runningTime = 0;

    selOptionList.forEach((e) => {
      total_option_runningTime += parseInt(e.runningTime);
    });

    return menu_runningTime + design_runningTime + total_option_runningTime;
  };

  const [selCategory, setSelCategory] = useState("네일");
  const [memo, setMemo] = useState(data.memo);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInput = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // 높이를 초기화하여 스크롤 높이를 정확히 계산
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // 내용에 맞게 높이 조정
    }
  };

  useEffect(() => {
    handleInput(); // 초기화 (예: textarea에 기본값이 있을 때)
  }, []);

  return (
    <Box
      sx={{
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: isSelMenuOpen ? "1280px" : "680px",
        height: "880px",
        borderRadius: "16px",
        p: 4,
        boxShadow: "4px 4px 4px 0px #00000040",
        boxSizing: "border-box",
        backgroundColor: "#F9FAFB",
        zoom: 0.9,
        padding: 0,
        overflow: "hidden",
        display: "flex",
      }}
    >
      <Container>
        <div
          style={{
            width: "100%",
            maxWidth: "680px",
            height: "90px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#ffffff",
            position: "relative",
            marginBottom: "24px",
          }}
        >
          <h2 style={{ fontSize: "20px", fontWeight: "700" }}>예약 변경</h2>
          <IoMdClose
            style={{
              position: "absolute",
              right: "32px",
              fontSize: "32px",
              cursor: "pointer",
            }}
            onClick={() => setOpen(false)}
          />
        </div>
        <div
          style={{
            overflow: "auto",
            height: "766px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div style={{ width: "608px" }}>
            <div
              style={{
                width: "608px",
                backgroundColor: "#ffffff",
                height: "76px",
                paddingLeft: "30px",
                alignItems: "center",
                display: "flex",
                boxSizing: "border-box",
                borderRadius: "16px",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                marginBottom: "24px",
              }}
            >
              <Line>
                <Title>예약자</Title>
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    color: "#000000",
                    fontSize: "16px",
                  }}
                >
                  <span>{data.customerName}</span>
                  <span>{data.phoneNumber}</span>
                </div>
              </Line>
            </div>
            <div
              style={{
                width: "608px",
                backgroundColor: "#ffffff",
                paddingLeft: "30px",
                padding: "16px 30px",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                boxSizing: "border-box",
                borderRadius: "16px",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                gap: "12px",
                marginBottom: "24px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Title>시술 일정</Title>
                <div
                  style={{
                    width: "84%",
                    height: "44px",
                    borderRadius: "8px",
                    display: "flex",
                    gap: "16px",
                  }}
                >
                  <DatePicker value={date} setValue={setDate} />
                  <TimePickers value={time} setValue={setTime} />
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Title>담당자</Title>
                <div
                  style={{
                    width: "84%",
                    height: "44px",
                    borderRadius: "8px",
                    display: "flex",
                    gap: "16px",
                  }}
                >
                  <Select2
                    option={managerList}
                    defaultValue={data.managerName}
                    setResult={setManager}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h2
                  style={{
                    fontWeight: "700",
                    height: "128px",
                    paddingTop: "24px",
                    boxSizing: "border-box",
                  }}
                >
                  시술 내용
                </h2>
                <div
                  style={{
                    width: "84%",
                    height: "128px",
                    backgroundColor: "#F4F5F7",
                    borderRadius: "8px",
                    boxSizing: "border-box",
                    padding: "14px",
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsSelMenuOpen((old) => !old)}
                  className="menuSelBox"
                >
                  {selMenu.id === "" ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                          boxSizing: "border-box",
                          padding: "10px",
                        }}
                      >
                        {data.menuCategory && (
                          <span
                            style={{
                              fontSize: 16,
                              fontWeight: 700,
                              color: "#000000",
                            }}
                          >
                            {`(${changeCategory(data.menuCategory, false)})`}
                          </span>
                        )}
                        <span>- {data.menuName}</span>
                        {data.designName && <span>- {data.designName}</span>}
                        {data.optionName && (
                          <span>- {data.optionName.join(",")}</span>
                        )}
                      </div>
                      {data.imageUrl ? (
                        <img
                          alt=""
                          src={data.imageUrl}
                          style={{
                            width: "100px",
                            height: "100px",
                            backgroundColor: "red",
                            borderRadius: "8px",
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            width: "100px",
                            height: "100px",
                            backgroundColor: "#D3D6DB",
                            borderRadius: "8px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <IoImageOutline
                            style={{ fontSize: "40px", color: "#A0A6B1" }}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                          boxSizing: "border-box",
                          padding: "10px",
                        }}
                      >
                        {selCategory && (
                          <span
                            style={{
                              fontSize: 16,
                              fontWeight: 700,
                              color: "#000000",
                            }}
                          >
                            {`(${selCategory})`}
                          </span>
                        )}
                        <span>- {selMenu.name}</span>
                        {selDesign.id !== "" && <span>- {selDesign.name}</span>}
                        {selOptionList.length > 0 && (
                          <span>
                            - {selOptionList.map((e) => e.name).join(",")}
                          </span>
                        )}
                      </div>
                      {selDesign.id !== "" && selDesign.imageUrl !== "" ? (
                        <img
                          alt=""
                          src={selDesign.imageUrl}
                          style={{
                            width: "100px",
                            height: "100px",
                            backgroundColor: "#cccccc",
                            borderRadius: "8px",
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            width: "100px",
                            height: "100px",
                            backgroundColor: "#D3D6DB",
                            borderRadius: "8px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <IoImageOutline
                            style={{ fontSize: "40px", color: "#A0A6B1" }}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                width: "608px",
                backgroundColor: "#ffffff",
                paddingLeft: "30px",
                padding: "16px 30px",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                boxSizing: "border-box",
                borderRadius: "16px",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                gap: "12px",
                marginBottom: "24px",
              }}
            >
              <Line>
                <Title>소요 시간</Title>
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    color: "#000000",
                    fontSize: "16px",
                  }}
                >
                  <span>
                    {selMenu.id === ""
                      ? `${data.duration}분`
                      : `${calDuration()}분`}
                  </span>
                </div>
              </Line>
              <Line>
                <Title>시술 금액</Title>
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    color: "#000000",
                    fontSize: "16px",
                  }}
                >
                  <span>
                    {selMenu.id === ""
                      ? data.totalPrice
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : calPrice()
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    원
                  </span>
                </div>
              </Line>
            </div>
            <div
              style={{
                width: "608px",
                backgroundColor: "#ffffff",
                paddingLeft: "30px",
                padding: "16px 30px",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                boxSizing: "border-box",
                borderRadius: "16px",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                gap: "12px",
                marginBottom: "24px",
              }}
            >
              <Line style={{ height: "100%" }}>
                <Title>시술 메모</Title>
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    color: "#000000",
                    fontSize: "16px",
                  }}
                >
                  <span
                    style={{
                      width: "462px",
                      minHeight: "100px",
                      backgroundColor: "#F4F5F7",
                      boxSizing: "border-box",
                      padding: "16px 16px",
                      borderRadius: "10px",
                    }}
                  >
                    <textarea
                      ref={textareaRef}
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#F4F5F7",
                        border: "none",
                        outline: "none",
                        resize: "none",
                      }}
                      placeholder="메모"
                      onInput={handleInput}
                      value={memo}
                      onChange={(event) => {
                        setMemo(event.target.value);
                      }}
                    />
                  </span>
                </div>
              </Line>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "24px",
                paddingBottom: "40px",
              }}
            >
              <div
                style={{
                  width: "184px",
                  height: "44px",
                  backgroundColor: "#E5E7EB",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setOpen(false)}
                className="cancelBtn"
              >
                취소
              </div>
              <div
                style={{
                  width: "184px",
                  height: "44px",
                  backgroundColor: "#FF5D7A",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (selMenu.id === "") {
                    console.log(changeCategory(selCategory, true));

                    saveBook({
                      date: new Date(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate(),
                        time.getHours(),
                        time.getMinutes()
                      ).toISOString(),
                      duration: data.duration,
                      menuName: data.menuName,
                      designName: data.designName,
                      optionName: data.optionName,
                      managerId: manager.id,
                      totalPrice: data.totalPrice,
                      bookId: data.bookId,
                      imageUrl: selDesign ? selDesign.imageUrl : "",
                      menuCategory: data.menuCategory,
                      memo: memo,
                    }).then((res) => {
                      callback();
                      setOpen(false);
                    });
                  } else {
                    saveBook({
                      date: new Date(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate(),
                        time.getHours(),
                        time.getMinutes()
                      ).toISOString(),
                      duration: calDuration().toString(),
                      menuName: selMenu.name,
                      designName: selDesign ? selDesign.name : "",
                      optionName: selOptionList.map((e) => e.name),
                      managerId: manager.id,
                      totalPrice: calPrice().toString(),
                      bookId: data.bookId,
                      imageUrl: selDesign ? selDesign.imageUrl : "",
                      menuCategory: changeCategory(selCategory, true),
                      memo: memo,
                    }).then((res) => {
                      callback();
                      setOpen(false);
                    });
                  }
                }}
                className="submitBtn"
              >
                변경하기
              </div>
            </div>
          </div>
        </div>
      </Container>
      {isSelMenuOpen && (
        <MenuSelection
          selMenu={selMenu}
          setSelMenu={setSelMenu}
          selDesign={selDesign}
          setSelDesign={setSelDesign}
          selOptionList={selOptionList}
          setSelOptionList={setSelOptionList}
          selCategory={selCategory}
          setSelCategory={setSelCategory}
        />
      )}
    </Box>
  );
};

const Container = styled.div`
  align-items: center;
  width: 100%;
  height: 100%;

  overflow: hidden;

  & .menuSelBox:hover {
    background-color: #f9e8ec !important;
  }

  & .selectBox:hover {
    background-color: #f9e8ec !important;
  }

  & .cancelBtn:hover {
    background-color: #d3d6db !important;
  }

  & .submitBtn:hover {
    background-color: #fd7f96 !important;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  gap: 12px;
`;

const Title = styled.span`
  width: 86px;
  font-size: 16px;
  font-weight: 700;
  color: #4d5562;
`;

export default EditBooking;
