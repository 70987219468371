import { styled } from "styled-components";
import MainLayout from "../../layout/MainLayout";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { CalanderView, RefrashCounterFcm, ShopData } from "../../atoms/atom";
import BigCalendar from "../../components/utility/BigCalendar";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import Calendar from "../../components/utility/Calendar";
import { Switch } from "@mui/material";
import BookRegisterModal from "../../components/shceldule/BookRegisterModal";
import { getManagerList } from "../../apis/manager";
import PageNation from "../../components/customer/PageNation";
import Select from "../../components/utility/Select";
import { useLocation } from "react-router-dom";
import { getTotalBookList } from "../../apis/book";
import { deleteNoti, getNotiList } from "../../apis/notification";
import DatePicker from "../../components/utility/DatePicker";
import MessageListener from "../../components/utility/MessageListener";
import BookItem from "../../components/shceldule/BookItem";

const Schedule = () => {
  const location = useLocation();

  const [zoom, setZoom] = useState(1.0);

  const shopData = useRecoilValue(ShopData);
  const refrashCounterFcm = useRecoilValue(RefrashCounterFcm);

  const [view, setView] = useRecoilState(CalanderView);
  const [notShowList, setNotShowList] = useState<string[]>([]);

  const [openModal, setOpenModal] = useState(false);

  const [managerList, setManagerList] = useState<any>([]);

  const [bookManaging, setBookManaging] = useState(false);
  const [pageNum, setPageNum] = useState(1);

  const [active, setActive] = useState(0);
  const [active2, setActive2] = useState(0);

  const [state, setState] = useState("");

  const [bookList, setBookList] = useState<any[]>([]);
  const [bookCount, setBookCount] = useState(0);

  const [updateCounter, setUpdateCounter] = useState(0);
  const [notiList, setNotiList] = useState<any[]>([]);

  const [date1, setDate1] = useState(
    new Date(new Date().getTime() - 60000 * 60 * 24 * 30)
  );
  const [date2, setDate2] = useState(new Date());

  const getSortColumn = () => {
    switch (active) {
      case 0:
        return "date";
      case 1:
        return "customerNumber";

      default:
        return "date";
    }
  };

  useEffect(() => {
    if (document.body.scrollHeight > window.innerHeight) {
      setZoom(
        1 -
          (document.body.scrollHeight - window.innerHeight) /
            document.body.scrollHeight
      );
    } else {
      setZoom((document.body.scrollHeight - 40) / 885);
    }

    getManagerList(shopData.id).then((res) => {
      console.log(res.data.data.managerList);
      setManagerList(res.data.data.managerList);

      if (location.state) {
        setView("day");

        setNotShowList(
          res.data.data.managerList
            .filter((e: any) => e.id !== location.state.managerId)
            .map((e: any) => e.id)
        );
      } else {
        setView("month");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refrashCounterFcm]);

  useEffect(() => {
    getTotalBookList(
      getSortColumn(),
      active2 === 0 ? "ASC" : "DESC",
      pageNum,
      state,
      date1.toISOString(),
      date2.toISOString()
    ).then((res) => {
      setBookList(res.data.data.bookList);
      setBookCount(res.data.data.count);
    });

    getNotiList().then((res) => {
      console.log("NotiList:", res.data.data);
      setNotiList(res.data.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    active,
    active2,
    pageNum,
    state,
    updateCounter,
    refrashCounterFcm,
    date1,
    date2,
  ]);

  return (
    <MainLayout active={"schedule"}>
      <Container $zoom={zoom}>
        <Main>
          <Contents>
            <Content1
              style={{
                width: view === "month" ? "100%" : "70%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <h2>어서오세요! {shopData.name}님</h2>
                <span>핑프와 오늘도 좋은 하루 되세요 🙂</span>
              </div>
              <div
                style={{
                  width: "111px",
                  height: "56px",
                  backgroundColor: "#ffffff",
                  borderRadius: "16px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: bookManaging ? "#F14262" : "#A0A6B1",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setView("month");
                  setBookManaging((old) => !old);
                }}
              >
                예약 관리
              </div>
            </Content1>
            {bookManaging ? (
              <Content2>
                <div
                  style={{
                    backgroundColor: "#ffffff",
                    width: "100%",
                    height: "100%",
                    padding: "24px 42px",
                    borderRadius: "16px",
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <TopBar>
                    <CountBox>{bookCount}개</CountBox>
                    <BtnWrap>
                      <span
                        style={{
                          color: active === 0 ? "#f14262" : "#A0A6B1",
                          cursor: "pointer",
                        }}
                        onClick={() => setActive(0)}
                      >
                        예약일정
                      </span>
                      <span
                        style={{
                          color: active === 1 ? "#f14262" : "#A0A6B1",
                          cursor: "pointer",
                        }}
                        onClick={() => setActive(1)}
                      >
                        고객번호
                      </span>
                    </BtnWrap>
                    <BtnWrap>
                      <span
                        style={{
                          color: active2 === 0 ? "#f14262" : "#A0A6B1",
                          cursor: "pointer",
                        }}
                        onClick={() => setActive2(0)}
                      >
                        오름차순
                      </span>
                      <span
                        style={{
                          color: active2 === 1 ? "#f14262" : "#A0A6B1",
                          cursor: "pointer",
                        }}
                        onClick={() => setActive2(1)}
                      >
                        내림차순
                      </span>
                    </BtnWrap>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <DatePicker value={date1} setValue={setDate1} />
                      <DatePicker value={date2} setValue={setDate2} />
                    </div>
                    <Select
                      option={[0, 1, 2, 3, 4, 5]}
                      isTypeTag={true}
                      setResult={setState}
                      defaultValue={0}
                    />
                  </TopBar>
                  <Wrap>
                    <TableHeader>
                      <span>고객정보</span>
                      <span>예약일정</span>
                      <span>시술내용</span>
                      <span>가격</span>
                      <span>예약 상태</span>
                    </TableHeader>
                    <div>
                      {bookList.map((item, idx) => (
                        <BookItem
                          data={item}
                          callback={() => {
                            setUpdateCounter((old) => old + 1);
                          }}
                        />
                      ))}
                    </div>
                  </Wrap>
                  <PageNation
                    data={Array.from(
                      { length: Math.ceil(bookCount / 10) },
                      (_, index) => index + 1
                    )}
                    pageNum={pageNum}
                    setPageNum={setPageNum}
                  />
                </div>
              </Content2>
            ) : (
              <Content2>
                <BigCalendar
                  notShowList={notShowList}
                  style={
                    view !== "month"
                      ? {
                          width: "100%",
                          height: "100%",
                          animation: "SlideIn 0.5s forwards",
                        }
                      : {
                          width: "100%",
                          height: "100%",
                          animation: "SlideOut 0.5s forwards",
                        }
                  }
                />
                {view !== "month" && (
                  <Side
                    style={
                      view !== "month"
                        ? {
                            animation: "SlideIn2 0.5s forwards",
                          }
                        : {}
                    }
                  >
                    <Top>
                      <div>
                        <span>새 알림</span>
                        <span>{notiList.length}</span>
                      </div>
                      {notiList.length === 0 ? (
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            gap: "16px",
                          }}
                        >
                          <img src={"./image/icon5.png"} alt="이모티콘1" />
                          <span style={{ color: "#A0A6B1" }}>
                            새 알림이 없습니다.
                          </span>
                        </div>
                      ) : (
                        <div>
                          {notiList.map((item, idx) => (
                            <NotiBox key={idx}>
                              <div>
                                <span>{item.title}</span>
                                <span>{item.message}</span>
                              </div>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  deleteNoti(item.id).then((res) => {
                                    setUpdateCounter((old) => old + 1);
                                  });
                                }}
                              >
                                확인
                              </div>
                            </NotiBox>
                          ))}
                        </div>
                      )}
                    </Top>
                    <Middle>
                      <Calendar />
                    </Middle>
                    <Bottom>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "700",
                            color: "#181E2D",
                          }}
                        >
                          담당자
                        </span>
                        <Header
                          style={{
                            display: "flex",
                            gap: "24px",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              color: "#051635",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            전체
                          </span>
                          <Switch
                            checked={notShowList.length === 0}
                            onClick={() => setNotShowList([])}
                            sx={{
                              ".css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
                                { backgroundColor: "red" },
                              ".css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked":
                                { color: "#FF5D7A" },
                            }}
                          />
                        </Header>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        {managerList.map((item: any, idx: number) => (
                          <SwitchItem
                            key={idx}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "50%",
                              height: "24px",
                              justifyContent: "space-between",
                            }}
                            $color={item.color}
                          >
                            <div
                              style={{
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <div
                                style={{
                                  width: "4px",
                                  height: "100%",
                                  backgroundColor: item.color,
                                  borderRadius: "16px",
                                }}
                              ></div>
                              <span>{item.name}</span>
                            </div>
                            <Switch
                              checked={!notShowList.includes(item.id)}
                              onClick={() =>
                                setNotShowList((old) =>
                                  old.includes(item.id)
                                    ? old.filter((e) => e !== item.id)
                                    : [...old, item.id]
                                )
                              }
                            />
                          </SwitchItem>
                        ))}
                      </div>
                    </Bottom>
                  </Side>
                )}
              </Content2>
            )}
          </Contents>
          <BookAddBox
            onClickCapture={() => setOpenModal(true)}
            style={{ cursor: "pointer" }}
          >
            <EditCalendarIcon style={{ fontSize: "28px", color: "#ffffff" }} />
            <span
              style={{
                color: "#ffffff",
                fontSize: "10px",
                fontWeight: "700",
              }}
            >
              예약 추가
            </span>
          </BookAddBox>
        </Main>
        <MessageListener />
      </Container>
      {openModal && (
        <BookRegisterModal
          open={openModal}
          setOpen={setOpenModal}
          managerList={managerList}
          callback={() => {
            window.location.reload();
          }}
        />
      )}
    </MainLayout>
  );
};

const Container = styled.div<{ $zoom: number }>`
  width: 100%;
  display: flex;

  zoom: ${(props) => props.$zoom};
`;

const Main = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  min-width: 1200px;
`;

const Contents = styled.div`
  width: 1200px;
  margin-top: 50px;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Content1 = styled.div`
  min-width: 400px;
  height: 51px;

  margin-left: 15px;

  & h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.2px;
  }

  & span {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    color: #a0a6b1;
  }

  & > div:last-child:hover {
    color: #f14262 !important;
  }
`;

const Content2 = styled.div`
  display: flex;
  width: 100%;
  height: 720px;
  padding: 24px;
  align-items: center;
  gap: 50px;

  border-radius: 16px;
`;

const Side = styled.div`
  width: 40%;
  height: 115%;

  position: relative;
  bottom: 50px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  @keyframes SlideIn2 {
    from {
      left: 300px;
    }
    to {
      left: 0px;
    }
  }
`;

const BookAddBox = styled.div`
  width: 72px;
  height: 72px;
  background-color: #f14262;
  border-radius: 50%;

  position: absolute;
  right: 60px;
  bottom: 40px;

  z-index: 20;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const Top = styled.div`
  display: flex;
  width: 100%;
  height: 212px;
  padding: 16px 24px 24px 24px;
  box-sizing: border-box;
  flex-direction: column;
  gap: 24px;
  border-radius: 16px;
  background: #ffffff;

  & > :first-child {
    display: flex;
    align-items: center;
    gap: 8px;

    & > :first-child {
      color: #272f3e;
      font-size: 20px;
      font-weight: 700;
      line-height: 140%;
    }
    & > :last-child {
      display: flex;
      height: 24px;
      padding: 10px 12px;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 50px;
      background: #f14262;

      color: #ffffff;
      font-size: 14px;
      font-weight: 700;
      line-height: 140%;
    }
  }
  & > :last-child {
    display: flex;
    flex-direction: column;
    gap: 12px;

    overflow-y: scroll;
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
    &::-webkit-scrollbar {
      display: none;
    }

    padding: 0px 10px;
    box-sizing: border-box;

    & > li:hover {
      background-color: #f9e8ec;
    }
  }
`;

const NotiBox = styled.div`
  background-color: #f4f5f7;
  border-radius: 16px;

  width: 100%;
  padding: 16px 24px;
  box-sizing: border-box;

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  & > div:first-child {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-bottom: 20px;

    & > span:first-child {
      color: #272f3e;
      font-size: 12px;
      font-weight: 700;
      line-height: 140%;
    }

    & > span:last-child {
      color: #a0a6b1;
      font-size: 10px;
      font-weight: 500;
      line-height: 140%;
    }
  }
  & > div:last-child {
    width: 100%;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #272f3e;
    font-size: 12px;
    font-weight: 700;
    line-height: 140%;
  }
`;

const Middle = styled.div`
  display: flex;
  width: 100%;
  height: 300px;
  border-radius: 16px;
  background: #ffffff;

  justify-content: center;
  align-items: center;
`;

const Bottom = styled.div`
  display: flex;
  width: 100%;
  height: 276px;
  padding: 16px 24px 24px 24px;
  box-sizing: border-box;
  flex-direction: column;
  gap: 16px;

  border-radius: 16px;
  background: #ffffff;
`;

const TopBar = styled.div`
  display: flex;
  width: 100%;
  height: 44px;
  align-items: flex-start;
  gap: 24px;

  position: relative;

  & > div:last-child {
    position: absolute;
    right: 0px;
  }
`;

const BtnWrap = styled.div`
  display: flex;
  padding: 11px 10px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background: #f9fafb;
  height: 44px;

  & > span {
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
  }
`;
const CountBox = styled.div`
  display: flex;
  width: 120px;
  padding: 11px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 16px;
  background: #f9fafb;
  color: #f14262;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
`;

const Wrap = styled.div`
  width: 100%;
  height: 90%;

  min-height: 560px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  & > :nth-child(2) {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;

    & .bookTableItem:hover {
      background-color: #fad7de;
    }
  }
`;

const TableHeader = styled.div`
  width: 100%;

  display: flex;
  height: 44px;
  padding: 0px 24px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;

  background: #f9fafb;
  border-radius: 16px;

  & > span {
    display: flex;
    justify-content: center;
    align-items: center;

    color: #394150;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }

  & > :nth-child(1) {
    display: flex;
    width: 200px;
  }
  & > :nth-child(2) {
    display: flex;
    width: 144px;
  }
  & > :nth-child(3) {
    display: flex;
    width: 144px;
  }
  & > :nth-child(4) {
    display: flex;
    width: 96px;
  }
  & > :nth-child(5) {
    display: flex;
    width: 96px;
  }
`;

const Header = styled.div`
  .Mui-checked + .MuiSwitch-track {
    background-color: red !important;
  }
  .Mui-checked {
    color: #ff5d7a !important;
  }
`;

const SwitchItem = styled.div<{ $color: string }>`
  .Mui-checked + .MuiSwitch-track {
    background-color: ${(props) => props.$color} !important;
  }
  .Mui-checked {
    color: ${(props) => props.$color} !important;
  }
`;

export default Schedule;
