import { styled } from "styled-components";
import MainLayout from "../../layout/MainLayout";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { ShopData } from "../../atoms/atom";
import { getSalesListV2, getSalesStatisticV2 } from "../../apis/sales";
import { changeMethod } from "../../util/util";
import { getBookList } from "../../apis/book";
import SalesHistoryModal3 from "../../components/utility/SalesHistoryModal3";
import MessageListener from "../../components/utility/MessageListener";
import { color_iris, color_midnight, color_rose } from "../../util/color";
import DatePicker from "../../components/utility/DatePicker";
import SalesChart from "../../components/revenue/SalesChart";
import Select2 from "../../components/utility/Select2";
import { getManagerList } from "../../apis/manager";
import SalesRegisterModal from "../../components/revenue/SalesRegisterModal";
import { Wallet } from "iconsax-react";
import SalesHistoryModal2 from "../../components/revenue/SalesHistoryModal2";
import Calendar3 from "../../components/revenue/Calendar3";

const Revenue2 = () => {
  const [zoom, setZoom] = useState(1.0);

  const shopData = useRecoilValue(ShopData);

  const [active, setActive] = useState(0);
  const [active2, setActive2] = useState(0);

  const [isMonth, setIsMonth] = useState(false);

  const [salesList, setSalesList] = useState<any[]>([]);

  const [pageNum, setPageNum] = useState(1);

  const [salesCount, setSalesCount] = useState(0);

  const [bookList, setBookList] = useState<any[]>([]);

  const [isSaleOpen, setIsSaleOpen] = useState(false);

  const [updateCounter, setUpdateCounter] = useState(0);

  const [date, setDate] = useState(new Date());

  const [selData2, setSelData2] = useState<any>({ id: "" });
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [managerList, setManagerList] = useState<any>([]);
  const [manager, setManager] = useState<any>({
    name: "전체",
    id: "",
  });

  const [dailyData, setDailyData] = useState<any[]>([]);
  const [monthlyData, setMonthlyData] = useState<any[]>([]);

  const [isStaticsMonthly, setIsStaticsMonthly] = useState(false);

  const getSortColumn = () => {
    switch (active) {
      case 0:
        return "date";
      case 1:
        return "price";
      case 2:
        return "managerName";

      default:
        return "date";
    }
  };

  useEffect(() => {
    if (isMonth) {
      setStartDate(
        new Date(new Date(date).getFullYear(), new Date(date).getMonth(), 1)
      );
      setEndDate(
        new Date(
          new Date(date).getFullYear(),
          new Date(date).getMonth(),
          new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
        )
      );
    } else {
      setStartDate(date);
      setEndDate(date);
    }
  }, [date]);

  useEffect(() => {
    if (isMonth) {
      setStartDate(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      );
    } else {
      setStartDate(date);
    }
  }, [isMonth]);

  useEffect(() => {
    if (document.body.scrollHeight > window.innerHeight) {
      setZoom(
        1 -
          (document.body.scrollHeight - window.innerHeight) /
            document.body.scrollHeight
      );
    } else {
      setZoom((document.body.scrollHeight - 40) / 885);
    }

    getSalesListV2(
      pageNum,
      getSortColumn(),
      "DESC",
      shopData.id,
      isMonth ? "monthly" : "daily",
      startDate.toISOString(),
      endDate.toISOString(),
      manager.id
    ).then((res) => {
      setSalesList(res.data.data.salesList);
      setSalesCount(res.data.data.salesCount);
    });

    getBookList(new Date().toISOString(), "monthly", true, true).then((res) => {
      let bookList = res.data.data.bookList;
      setBookList(bookList);
    });

    getManagerList(shopData.id).then((res) => {
      setManagerList(res.data.data.managerList);
    });

    getSalesStatisticV2(shopData.id, new Date().toISOString()).then((res) => {
      setDailyData(res.data.data.daily);
      setMonthlyData(res.data.data.monthly);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    active,
    active2,
    pageNum,
    updateCounter,
    date,
    manager,
    isMonth,
    startDate,
    endDate,
  ]);

  return (
    <MainLayout active={"revenue"}>
      <Container $zoom={zoom}>
        <Main>
          <Contents>
            <Content1>
              <h2>어서오세요! {shopData.name}님</h2>
              <span>핑프와 오늘도 좋은 하루 되세요 🙂</span>
            </Content1>
            <Content2>
              <Left>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    gap: "24px",
                    backgroundColor: "#ffffff",
                    height: "80px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "16px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "96px",
                        height: "96px",
                        backgroundColor: "#EB60773D",
                        borderRadius: "50%",
                        position: "absolute",
                        top: "-31px",
                        left: "-26px",
                      }}
                    ></div>
                    <Wallet
                      size="36"
                      color="#EB6077"
                      variant="Bold"
                      style={{
                        position: "absolute",
                        top: "10px",
                        left: "10px",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        marginLeft: "86px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          color: "#000000",
                        }}
                      >
                        금일 총 매출
                      </span>
                      <span
                        style={{
                          fontSize: "20px",
                          fontWeight: "700",
                          color: color_rose.rose8,
                        }}
                      >
                        {dailyData.length > 0
                          ? dailyData[dailyData.length - 1].totalSales
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : 0}
                        원
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "160px",
                      height: "44px",
                      backgroundColor: color_iris.iris4,
                      borderRadius: "22px",
                      color: "#ffffff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      marginRight: "24px",
                      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
                    }}
                    onClick={() => {
                      setIsSaleOpen(true);
                    }}
                  >
                    매출 추가 +
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "616px",
                    backgroundColor: "#ffffff",
                    borderRadius: "16px",
                    display: "flex",
                    flexDirection: "column",
                    padding: "24px",
                    boxSizing: "border-box",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: "16px",
                        backgroundColor: color_midnight.midnight1,
                        height: "44px",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          flex: 1,
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "16px",
                          color: "#000000",
                          fontWeight: "500",
                        }}
                      >
                        카테고리
                      </span>
                      <span
                        style={{
                          flex: 1,
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "16px",
                          color: "#000000",
                          fontWeight: "500",
                        }}
                      >
                        고객정보
                      </span>
                      <span
                        style={{
                          flex: 1,
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "16px",
                          color: "#000000",
                          fontWeight: "500",
                        }}
                      >
                        결제금액
                      </span>
                      <span
                        style={{
                          flex: 1,
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "16px",
                          color: "#000000",
                          fontWeight: "500",
                        }}
                      >
                        결제방법
                      </span>
                      <span
                        style={{
                          flex: 1,
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "16px",
                          color: "#000000",
                          fontWeight: "500",
                        }}
                      >
                        담당자
                      </span>
                      <span
                        style={{
                          flex: 1.3,
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "16px",
                          color: "#000000",
                          fontWeight: "500",
                        }}
                      >
                        매출등록일
                      </span>
                    </div>

                    <DataList>
                      {salesList.map((item, idx) => (
                        <div
                          key={item.id + idx}
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            borderRadius: "16px",
                            backgroundColor: "#F9FAFB",
                            height: "44px",
                            alignItems: "center",
                            cursor: "pointer",
                            marginBottom: "12px",
                          }}
                          onClick={() => {
                            if (item.salesCategory === "book") {
                              setSelData2(item);
                              setOpen(true);
                            } else {
                              setSelData2(item);
                              setOpen2(true);
                            }
                          }}
                          className="salesTableItem"
                        >
                          <span
                            style={{
                              flex: 1,
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "16px",
                              color: "#000000",
                              fontWeight: "500",
                            }}
                          >
                            {item.salesCategory === "book" ? "예약" : "기타"}
                          </span>
                          <span
                            style={{
                              flex: 1,
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "16px",
                              color: "#000000",
                              fontWeight: "500",
                            }}
                          >
                            {item.customerName ? item.customerName : "없음"}
                          </span>
                          <span
                            style={{
                              flex: 1,
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "16px",
                              color: "#000000",
                              fontWeight: "500",
                            }}
                          >
                            {item.price
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </span>

                          <span
                            style={{
                              flex: 1,
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "16px",
                              color: "#000000",
                              fontWeight: "500",
                            }}
                          >
                            {changeMethod(
                              item.salesPayment[0].paymentMethod,
                              false
                            )}
                            {item.salesPayment.length > 1
                              ? "외 " + (item.salesPayment.length - 1)
                              : ""}
                          </span>
                          <span
                            style={{
                              flex: 1,
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "16px",
                              color: "#000000",
                              fontWeight: "500",
                            }}
                          >
                            {item.managerName}
                          </span>
                          <span
                            style={{
                              flex: 1.3,
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "16px",
                              color: "#000000",
                              fontWeight: "500",
                            }}
                          >
                            {new Date(item.createdAt)
                              .toLocaleDateString("ko-KR", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              })
                              .replaceAll(".", "-")
                              .replaceAll(" ", "")
                              .slice(0, -1)}
                          </span>
                        </div>
                      ))}
                    </DataList>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "44px",
                      backgroundColor: color_midnight.midnight1,
                      borderRadius: "16px",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "24px",
                        marginRight: "40px",
                      }}
                    >
                      <span style={{ fontWeight: "700" }}>매출 합계</span>
                      <span style={{ fontWeight: "700" }}>
                        {salesList.length > 0
                          ? salesList
                              .filter((e) => e.price)
                              .map((e) => parseInt(e.price))
                              .reduce((sum, i) => (sum += i))
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : 0}
                        원
                      </span>
                    </div>
                  </div>
                </div>
              </Left>
              <Right>
                <div
                  style={{
                    width: "100%",
                    height: "290px",
                    padding: "20px",
                    boxSizing: "border-box",
                    backgroundColor: "#ffffff",
                    borderRadius: "16px",
                  }}
                >
                  <SalesChart
                    data={isStaticsMonthly ? monthlyData : dailyData}
                    isMonthly={isStaticsMonthly}
                    setIsStaticsMonthly={setIsStaticsMonthly}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "44px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      width: "212px",
                      height: "100%",
                      display: "flex",
                      gap: "16px",
                    }}
                  >
                    <span
                      style={{
                        width: "72px",
                        height: "100%",
                        borderRadius: "16px",
                        backgroundColor: "#ffffff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#F14262",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      오늘
                    </span>
                    <div
                      style={{
                        width: "124px",
                        height: "100%",
                        borderRadius: "16px",
                        backgroundColor: "#ffffff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#F14262",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      <span
                        style={{
                          width: "50%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: !isMonth ? "#A0A6B1" : "#F14262",
                          fontSize: "16px",
                          fontWeight: "500",
                          cursor: "pointer",
                        }}
                        onClick={() => setIsMonth(true)}
                      >
                        월
                      </span>
                      <span
                        style={{
                          width: "50%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: isMonth ? "#A0A6B1" : "#F14262",
                          fontSize: "16px",
                          fontWeight: "500",
                          cursor: "pointer",
                        }}
                        onClick={() => setIsMonth(false)}
                      >
                        일
                      </span>
                    </div>
                  </div>
                  <Select2
                    option={[{ name: "전체", id: "" }, ...managerList]}
                    defaultValue={manager.name}
                    setResult={setManager}
                    bgColor={"#ffffff"}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <DatePicker
                    value={startDate}
                    setValue={setStartDate}
                    backgroundColor={"#ffffff"}
                  />
                  {" ~ "}
                  <DatePicker
                    value={endDate}
                    setValue={setEndDate}
                    backgroundColor={"#ffffff"}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "320px",
                    borderRadius: "16px",
                    backgroundColor: "#ffffff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Calendar3
                    zoom={1.05}
                    date={date}
                    setDate={setDate}
                    managerId={manager.id}
                  />
                </div>
              </Right>
            </Content2>
          </Contents>
        </Main>
        {isSaleOpen && (
          <SalesRegisterModal
            open={isSaleOpen}
            setOpen={setIsSaleOpen}
            managerList={managerList}
            callback={() => {
              setUpdateCounter((old) => old + 1);
            }}
          />
        )}
        {open && (
          <SalesHistoryModal3
            open={open}
            setOpen={setOpen}
            data={selData2}
            callback={() => {
              setUpdateCounter((old) => old + 1);
            }}
          />
        )}
        {open2 && (
          <SalesHistoryModal2
            open={open2}
            setOpen={setOpen2}
            data={selData2}
            callback={() => {
              setUpdateCounter((old) => old + 1);
            }}
            managerList={managerList}
          />
        )}
        <MessageListener />
      </Container>
    </MainLayout>
  );
};

const Container = styled.div<{ $zoom: number }>`
  width: 100%;
  display: flex;

  zoom: ${(props) => props.$zoom};

  & .salesTableItem:hover {
    background-color: #f9e8ec !important;
  }
`;

const Main = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  min-width: 1200px;
`;

const Contents = styled.div`
  width: 1200px;
  margin-top: 50px;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Content1 = styled.div`
  min-width: 400px;
  height: 51px;

  margin-left: 15px;

  & h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.2px;
  }

  & span {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    color: #a0a6b1;
  }
`;

const Content2 = styled.div`
  display: flex;
  width: 100%;
  height: 720px;
  align-items: center;
  gap: 50px;

  border-radius: 16px;
`;

const Left = styled.div`
  width: 64%;
  height: 100%;

  gap: 24px;
  display: flex;
  flex-direction: column;
`;

const Right = styled.div`
  width: 384px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const DataList = styled.div`
  width: 100%;
  gap: 4px;
  height: 450px;

  overflow-y: scroll;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default Revenue2;
