import { styled } from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import { RegisterDataAtom, registerStepAtom } from "../../atoms/atom";
import InputBox from "../utility/InputBox";
import { useState } from "react";
import DaumPostcode from "react-daum-postcode";
import { Box, Modal } from "@mui/material";

const RegisterStep7 = () => {
  const [detail, setDetail] = useState("");

  const [address, setAddress] = useState("");
  const setStep = useSetRecoilState(registerStepAtom);
  const [data, setData] = useRecoilState(RegisterDataAtom);

  const [openPostcode, setOpenPostcode] = useState<boolean>(false);

  const handle = {
    // 버튼 클릭 이벤트
    clickButton: () => {
      setOpenPostcode(true);
    },

    // 주소 선택 이벤트
    selectAddress: (data: any) => {
      setAddress(data.address);
      setOpenPostcode(false);
    },
  };

  const nextHandler = () => {
    setData((old) => ({
      email: old.email,
      passwd: old.passwd,
      name: old.name,
      phone: old.phone,
      isAdmin: old.isAdmin,
      tos: old.tos,
      token: old.token,
      shopname: old.shopname,
      address: address + "@" + detail,
    }));
    setStep(8);
  };

  return (
    <>
      <ResultContainer>
        <div>
          <h2>{data.name}</h2>
          <span>대표</span>
        </div>
        <InputContainer>
          <Label>샵 이름</Label>
          {address === "" ? (
            <AddressBtn onClick={handle.clickButton}>
              <span>우편번호 찾기</span>
              <img src="/image/search.png" alt="" />
            </AddressBtn>
          ) : (
            <InputBox
              placeholder="우편번호 찾기"
              type="text"
              width={300}
              setValue={setAddress}
              value={address}
              disabled={true}
            />
          )}
        </InputContainer>
        <InputBox
          placeholder="상세주소를 입력해주세요."
          type="text"
          width={300}
          setValue={setDetail}
          value={detail}
        />
      </ResultContainer>
      <NextBtn disabled={!(detail && address)} onClick={() => nextHandler()}>
        다음
      </NextBtn>
      <Modal open={openPostcode} onClose={() => setOpenPostcode(false)}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            height: 500,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <DaumPostcode
            onComplete={handle.selectAddress}
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
      </Modal>
    </>
  );
};

const InputContainer = styled.div`
  width: 300px;
  height: 82px;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const ResultContainer = styled.div`
  width: 300px;
  height: 300px;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 16px;
  box-sizing: border-box;

  & > div:first-child {
    display: flex;
    align-items: center;
    width: 100%;
  }

  & h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.2px;
  }

  & > div:first-child > span {
    width: 35px;
    height: 22px;
    background: #fada07;
    border-radius: 4px;
    color: #ffffff;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 10px;
    font-weight: 500;
    line-height: 14px;

    margin-left: 10px;
  }
`;

const Label = styled.span`
  color: #a0a6b1;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.2px;
  font-weight: 700;
`;

const AddressBtn = styled.button`
  border: none;
  width: 300px;
  height: 44px;
  border-radius: 8px;
  background-color: #f4f5f7;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0px 24px 0px 24px;

  & > span {
    font-family: Noto Sans KR;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;

    color: #ff5d7a;
  }
  & > img {
    width: 24px;
    height: 24px;
  }
`;

const NextBtn = styled.button<{ disabled: boolean }>`
  border: none;
  width: 300px;
  height: 44px;
  border-radius: 8px;
  color: #f9fafb;
  background-color: ${(props) => (!props.disabled ? "#ff5d7a" : "#d3d6db")};

  font-family: "Noto Sans KR", sans-serif;

  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: -0.2px;
`;

export default RegisterStep7;
