import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { styled } from "styled-components";

type propType = {
  option: any[];
  defaultValue?: string;
  isTypeTag?: boolean;
  bgColor?: string;
  color?: string;
  disable?: boolean;
  isTypeColorChip?: boolean;
  setResult?: Dispatch<SetStateAction<any>>;
};

const Select2 = ({
  option,
  defaultValue,
  bgColor,
  color,
  disable,
  isTypeColorChip,
  setResult,
}: propType) => {
  const [value, setValue] = useState<string>(defaultValue ? defaultValue : "");

  const [show, setShow] = useState(false);

  const clickOutside = () => {
    setShow(false);
  };

  useEffect(() => {
    document.body.addEventListener("click", clickOutside);

    return () => {
      document.body.removeEventListener("click", clickOutside);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, isTypeColorChip]);

  return (
    <Container
      $show={show}
      $bgColor={bgColor}
      $color={color}
      className="selectBox"
    >
      <div
        onClickCapture={(e) => {
          e.stopPropagation();
          e.preventDefault();

          disable ? setShow(false) : setShow((old) => !old);
        }}
      >
        <span>{value}</span>
        <img src="/image/more.png" alt="" />
      </div>
      {option.map((item, i) => (
        <Option
          key={i}
          onClickCapture={(e) => {
            e.stopPropagation();
            e.preventDefault();

            setShow((old) => !old);
            setValue(item.name);

            if (setResult) {
              setResult(item);
            }
          }}
          $bgColor={bgColor}
          $color={color}
        >
          {item.name}
        </Option>
      ))}
    </Container>
  );
};

const Container = styled.div<{
  $show: boolean;
  $bgColor: string | undefined;
  $color: string | undefined;
}>`
  cursor: pointer;
  width: 144px;
  height: 44px;
  background-color: ${(props) => (props.$bgColor ? props.$bgColor : "#f4f5f7")};
  color: ${(props) => (props.$color ? props.$color : "#000000")};
  border-radius: ${(props) => (props.$show ? "8px 8px 0px 0px" : "8px")};

  position: relative;

  & > div:first-child {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > div:first-child > span {
    width: 90px;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.2px;
  }

  & > div:first-child > img {
    width: 8px;
    height: 4px;
  }

  & > div:last-child {
    border-radius: 0px 0px 8px 8px;
  }

  & > div {
    display: ${(props) => (props.$show ? "flex" : "none")};
  }
`;

const Option = styled.div<{
  $bgColor: string | undefined;
  $color: string | undefined;
}>`
  width: 144px;
  height: 40px;
  position: relative;

  z-index: 10;

  background-color: ${(props) => (props.$bgColor ? props.$bgColor : "#f9fafb")};
  color: ${(props) => (props.$color ? props.$color : "#000000")};

  display: none;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #ff5d7a;
    color: #ffffff;
  }
`;

export default Select2;
