import axios from "axios";
import { baseUrl, config } from "./api";

export const getNotiList = async () => {
  return await axios.get(baseUrl + `/admin/notification`, config());
};

export const deleteNoti = async (notificationId: string) => {
  return await axios.delete(baseUrl + "/admin/notification", {
    headers: config().headers,
    data: { notificationId: notificationId },
  });
};

export const getVotreNoti = async () => {
  return await axios.get(baseUrl + `/admin/shop/announcement`, config());
};
