import styled from "styled-components";
import { color_midnight, color_rose } from "../../util/color";
import { useState } from "react";
import { ReqCancel } from "../../apis/book";
import BookCancelModal from "../utility/BookCancelModal";
import BookRequestModal from "../utility/BookRequestModal";
import { getCustomerOne } from "../../apis/customer";
import useSendbirdStateContext from "@sendbird/uikit-react/useSendbirdStateContext";
import sendbirdSelectors, {
  getCreateGroupChannel,
} from "@sendbird/uikit-react/sendbirdSelectors";
import { useRecoilState } from "recoil";
import { ShopData } from "../../atoms/atom";

interface BookApprovalBoxProps {
  data: any;
  customerName: string;
  date: string;
  menuName: string;
  callback: () => void;
}

const BookApprovalBox = ({
  data,
  customerName,
  date,
  menuName,
  callback,
}: BookApprovalBoxProps) => {
  const [isCancelOPen, setIsCancelOpen] = useState(false);
  const [isRequestOpen, setIsRequestOpen] = useState(false);

  const [shopData] = useRecoilState(ShopData);

  const onApprove = (e: any) => {
    e.preventDefault();
    setIsRequestOpen(true);

    e.stopPropagation();
  };
  const onReject = (e: any) => {
    e.preventDefault();
    setIsCancelOpen(false);

    e.stopPropagation();
  };

  const globalStore = useSendbirdStateContext();
  sendbirdSelectors.getSdk(globalStore);

  const createChannel = getCreateGroupChannel(globalStore);

  const createChannelAndSendMessage = async (reason: string) => {
    const user = await getCustomerOne(data.customerId).then(
      (res) => res.data.data
    );

    try {
      const params = {
        invitedUserIds: [user.userId, shopData.id],
        name: shopData.name,
        coverUrl:
          "https://storage.finger-princess.com/5071c892-2053-4ac7-b7a8-e7726989f433",
        isDistinct: true,
      };
      const channel = await createChannel(params);

      const formattedDate = new Date(data.date); // 예약 날짜
      const dayOfWeek = formattedDate.toLocaleDateString("ko-KR", {
        weekday: "short", // 요일 (예: 월, 화)
      });
      const datePart = `${String(formattedDate.getMonth() + 1).padStart(
        2,
        "0"
      )}/${String(formattedDate.getDate()).padStart(2, "0")}`; // MM/DD 형식으로 날짜 생성
      const timePart = formattedDate.toLocaleTimeString("ko-KR", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false, // 24시간 형식
      });

      let korReason = "";

      switch (reason) {
        case "duplicate":
          korReason = "중복예약으로";
          break;
        case "noManager":
          korReason = "담당자 부재로";
          break;
        case "noTime":
          korReason = "일정 불가로";
          break;
        case "shop":
          korReason = "샵의 사정으로";
          break;
        case "noRequirement":
          korReason = "요청 사랑 불가로";
          break;
      }

      const messageText = `${korReason} ${datePart}(${dayOfWeek}) ${timePart} 예약이 취소되었습니다. 🥲`;
      channel.sendUserMessage({ message: messageText });
    } catch (error) {
      console.error("Error creating channel or sending message:", error);
    }
  };

  return (
    <>
      <Container onClick={onApprove}>
        <div>
          <div style={{ display: "none" }} />
          <div>
            <span>{customerName}</span>
            <span>
              {date} ~ {menuName}
            </span>
          </div>
        </div>
        <div>
          <span onClick={onReject}>거절</span>
          <span>승인</span>
        </div>
      </Container>
      {isRequestOpen && (
        <BookRequestModal
          open={isRequestOpen}
          setOpen={setIsRequestOpen}
          data={data}
          callback={callback}
          callback2={(bookId) => {
            setIsCancelOpen(true);
          }}
        />
      )}
      {isCancelOPen && (
        <BookCancelModal
          open={isCancelOPen}
          setOpen={setIsCancelOpen}
          data={undefined}
          callback={(reason) => {
            if (!reason) return;

            ReqCancel(data.bookId, reason)
              .then(() => {
                createChannelAndSendMessage(reason);
                callback();
                setIsCancelOpen(false);
              })
              .catch((e) => {});
          }}
        />
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 24px;
  box-sizing: border-box;
  flex-direction: column;
  gap: 24px;
  border-radius: 16px;
  background: #f9fafb;
  margin-bottom: 20px;

  cursor: pointer;

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  & > div:first-child {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 16px;
    height: 44px;

    & > div:first-child {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      background: #cccccc;
    }
    & > div:last-child {
      display: flex;
      flex-direction: column;
      gap: 2px;

      & > span:first-child {
        color: ${color_midnight.midnight8};
        font-size: 16px;
        font-weight: 500;
        line-height: 140%;
      }

      & > span:last-child {
        color: ${color_midnight.midnight4};
        font-size: 12px;
        font-weight: 500;
        line-height: 140%;
      }
    }
  }
  & > div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;

    & > span {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & > span:first-child {
      color: ${color_midnight.midnight8};
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;
    }
    & > span:first-child:hover {
      color: ${color_midnight.midnight4};
    }

    & > span:last-child {
      color: ${color_rose.rose9};
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;
    }

    & > span:last-child:hover {
      color: ${color_rose.rose6};
    }
  }
`;

export default BookApprovalBox;
